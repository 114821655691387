import { adt } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import type { BonusExercise } from './BonusExercise';
import { foldExerciseVisibility } from './ExerciseVisibility';
import type { TaskCollection } from './TaskCollection';
import { foldTaskCollectionPartial } from './TaskCollection';

export const foldExerciseTaskState = adt.foldFromKeys({
  /** The exercise is hidden */
  hidden: null,
  /** The exercise has been published, but it can’t be worked on yet */
  teaser: null,
  /** The exercise can be worked on, but it is not within the intended time window yet */
  earlywindow: null,
  /** The exercise should actively be worked on */
  inwindow: null,
  /** The time window to work on the exercise has passed */
  afterwindow: null,
});

export type ExerciseTaskState = adt.TypeOfKeys<typeof foldExerciseTaskState>;

export const foldBonusExerciseTaskState = adt.foldFromKeys({
  ...foldExerciseTaskState.spec,
  /** Bonus exercise need to be unlocked before they can be worked on */
  locked: null,
});

export type BonusExerciseTaskState = adt.TypeOfKeys<typeof foldBonusExerciseTaskState>;

/**
 * @see {@link isTaskCollectionVisible}
 */
export function getExerciseTaskState(
  now: Date,
  exercise: DistributivePick<
    TaskCollection,
    'type' | 'public' | 'handoutDate' | 'dueDate' | 'publishDate'
  >,
): ExerciseTaskState {
  return foldTaskCollectionPartial<typeof exercise, ExerciseTaskState>(exercise, {
    exam: () => 'inwindow',
    code_example: (e) => {
      const { handoutDate } = e;
      return foldExerciseVisibility(e.public, {
        no: () => 'hidden',
        yes: () => (handoutDate != null && now < handoutDate ? 'earlywindow' : 'inwindow'),
        auto: () => (handoutDate != null && now < handoutDate ? 'hidden' : 'inwindow'),
      });
    },
    exercise: (e) => {
      const { publishDate, handoutDate, dueDate } = e;

      /**
       * According to cx-1970 this can actually never happen, but we handle this case here to make
       * it easier to work with these props.
       */
      if (handoutDate == null || dueDate == null) {
        return 'hidden';
      }

      return foldExerciseVisibility(e.public, {
        no: () => 'hidden',
        yes: () => {
          if (now >= dueDate) return 'afterwindow';
          if (now >= handoutDate) return 'inwindow';
          return 'earlywindow';
        },
        auto: () => {
          if (now >= dueDate) return 'afterwindow';
          if (now >= handoutDate) return 'inwindow';
          if (publishDate != null && now >= publishDate) return 'teaser';
          return 'hidden';
        },
      });
    },
  });
}

export function getExerciseTaskStateNow(
  exercise: DistributivePick<
    TaskCollection,
    'type' | 'public' | 'handoutDate' | 'dueDate' | 'publishDate'
  >,
): ExerciseTaskState {
  return getExerciseTaskState(new Date(), exercise);
}

export function getBonusExerciseTaskState(
  now: Date,
  exercise: BonusExercise,
): BonusExerciseTaskState {
  const exerciseState = getExerciseTaskState(now, exercise);

  if (exerciseState === 'hidden') return exerciseState;
  if (exercise.conditional && exercise.earnedPrerequisiteXP < exercise.requiredPrerequisiteXP) {
    return 'locked';
  }

  return exerciseState;
}

export function getBonusExerciseTaskStateNow(exercise: BonusExercise): BonusExerciseTaskState {
  return getBonusExerciseTaskState(new Date(), exercise);
}
