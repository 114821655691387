import { iots } from '@code-expert/prelude';
import { LtiSessionId, ProjectId, SubmissionId, TaskId, UserId } from '/imports/domain/identity';

const LtiResult = iots.struct(
  { date: iots.date, request: iots.unknown },
  { response: iots.unknown, error: iots.unknown },
);

export type LtiResult = iots.TypeOf<typeof LtiResult>;

export const LtiSessionC = iots.struct(
  {
    _id: LtiSessionId,
    userId: UserId,
    taskId: TaskId,
    date: iots.date,
    ltiRequest: iots.unknown,
    sent: iots.boolean,
    ltiResults: iots.array(LtiResult),
    cxLtiVersion: iots.number,
    locked: iots.boolean,
  },
  {
    projectId: ProjectId,
    submissionId: SubmissionId,
    resumedSessionId: LtiSessionId,
    basedOnLastSessionId: LtiSessionId,
    /** used when starting a new LTI session based on last (cx-719) */
    basedOnLastSubmissionId: SubmissionId,
  },
);

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface LtiSession extends LtiSessionType {}

type LtiSessionType = iots.TypeOf<typeof LtiSessionC>;
