import {
  assertNonNull,
  either,
  invariant,
  iots,
  option,
  pipe,
  readonlyArray,
  record,
} from '@code-expert/prelude';
import { LegiFromString } from '/imports/domain/legi';

// TODO merge types with moodleutils.ts

const attemptStateKeys = {
  abandoned: null,
  finished: null,
  inprogress: null,
  preview: null,
};

export const attemptStates = record.keys(attemptStateKeys);

const ltiWorkflowBase = {
  lti_version: iots.literal('LTI-1p0'),
  lti_message_type: iots.literal('basic-lti-launch-request'),
  oauth_signature: iots.string,
  oauth_timestamp: iots.NumberFromString,
  oauth_nonce: iots.string,
  oauth_consumer_key: iots.string, // r
  oauth_signature_method: iots.literal('HMAC-SHA1'),
  ext_user_username: iots.string, // r
  ext_student_username: iots.string, // r
  ext_unique_connection: iots.string, // r
  ext_attempt_state: iots.keyof(attemptStateKeys), // r
  ext_attempt_onlast: iots.keyof({ '0': null, '1': null }),
  ext_instancecode: iots.string, // r
  roles: iots.string, // r
};

const ltiWorkflowOptional = {
  lis_person_contact_email_primary: iots.string,
  lis_person_name_given: iots.string,
  lis_person_name_family: iots.string,
  ext_student_matriculationnumber: iots.union([iots.literal(''), LegiFromString]), // r in newer versions of the Moodle plugin
};

const LtiWorkflowCorrection = iots.struct(
  {
    ext_workflow_mode: iots.literal('correction'),
    ...ltiWorkflowBase,
  },
  {
    ...ltiWorkflowOptional,
    lis_outcome_service_url: iots.string, // r if workflow = solve
  },
);

const LtiWorkflowReview = iots.struct(
  {
    ext_workflow_mode: iots.literal('review'),
    ...ltiWorkflowBase,
  },
  {
    ...ltiWorkflowOptional,
    lis_outcome_service_url: iots.string, // r if workflow = solve
  },
);

const LtiWorkflowSolve = iots.struct(
  {
    ext_workflow_mode: iots.literal('solve'),
    ...ltiWorkflowBase,
    lis_outcome_service_url: iots.string, // r if workflow = solve
  },
  ltiWorkflowOptional,
);

export const LtiMessage = iots.union([LtiWorkflowCorrection, LtiWorkflowReview, LtiWorkflowSolve]);

export type LtiMessage = iots.TypeOf<typeof LtiMessage>;

// --- LtiErrorReporter

export function failure(es: Array<iots.ValidationError>): Array<string> {
  return [
    ...new Set(
      es.map((err) => {
        const element = pipe(
          err.context,
          readonlyArray.filter((el) => !/^\d*$/.test(el.key)),
          readonlyArray.last,
          option.alt(() => pipe(err.context, readonlyArray.last)),
          option.toUndefined,
        );

        if (element != null) {
          // When a union type's discriminant is missing
          if ('tag' in element.type) {
            return `Missing required field '${String(element.type.tag)}'.`;
          }

          if (err.value == null) {
            return `Missing required field '${element.key}'.`;
          }
        }

        assertNonNull(element);
        switch (element.key) {
          case 'lti_version': {
            invariant(typeof err.value === 'string', 'Expected a string value');
            return `LTI version ${err.value} not supported.`;
          }
          case 'lti_message_type': {
            invariant(typeof err.value === 'string', 'Expected a string value');
            return `Unknown LTI message type ${err.value}.`;
          }
          case 'oauth_signature_method': {
            invariant(typeof err.value === 'string', 'Expected a string value');
            return `OAuth Signature method ${err.value} not supported.`;
          }
          case 'ext_student_matriculationnumber': {
            return `Invalid Legi`;
          }
          default: {
            return err.message ?? element.key;
          }
        }
      }),
    ),
  ];
}

export const LtiErrorReporter: iots.Reporter<Array<string>> = {
  report: either.fold(failure, () => ['No errors.']),
};
