import { array, boolean, date, identity, number, ord, pipe } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import type { TaskId } from '/imports/domain/identity';
import type { Appointment } from './appointment';
import type { Exercise, TaskCollection } from './exercise';
import { ordExerciseDueDate } from './exercise';
import type { PresentationSubmission, Submission } from './submission';
import { foldSubmissionStatus, ordSubmissionDate } from './submission';

export function isAppointmentOpen<A extends Pick<Appointment, 'start' | 'end'>>(
  now: Date,
  appointment: A,
) {
  return pipe(now, date.isWithinInterval({ start: appointment.start, end: appointment.end }));
}

export function isAppointmentOpenNow<A extends Pick<Appointment, 'start' | 'end'>>(appointment: A) {
  return isAppointmentOpen(new Date(), appointment);
}

const isCoveringDate =
  (x: Date) =>
  ({ dueDate, solutionDate }: Pick<Exercise, '_id' | 'dueDate' | 'solutionDate'>) =>
    solutionDate != null && pipe(x, date.isWithinInterval({ start: dueDate, end: solutionDate }));

const ordExerciseByPresentationStatus = (start: Date) =>
  ord.contramap(isCoveringDate(start))(ord.reverse(boolean.Ord));

export const ordExerciseForPresentation = (start: Date) => [
  ordExerciseByPresentationStatus(start),
  ordExerciseDueDate,
];

export const isPresentationSubmissionTodo = foldSubmissionStatus({
  subscribed: () => true,
  called: () => true,
  alreadyCalled: () => true,
  absent: () => false,
  marked: () => false,
  done: () => false,
});

export const partitionSubmissionCompletedRemaining = <
  S extends Pick<PresentationSubmission, 'status'>,
>(
  ps: S,
) => isPresentationSubmissionTodo(ps.status);

export const sortSubmissionStatusDate = [
  ord.contramap((x: Pick<PresentationSubmission, 'status'>) =>
    foldSubmissionStatus(x.status, {
      subscribed: () => 0,
      called: () => 0,
      alreadyCalled: () => 1,
      absent: () => 2,
      marked: () => 3,
      done: () => 4,
    }),
  )(number.Ord),
  ordSubmissionDate,
];

export const ordPresentationSubmissionByExercise = <T extends Pick<Submission, 'taskId'>>(
  exercises: Array<DistributivePick<TaskCollection, 'taskIds'>>,
): ord.Ord<T> => {
  const sortedTasks = exercises.flatMap((e) => e.taskIds);
  const taskIndex = pipe(sortedTasks, array.reverseIndexFirst(identity));
  const ordByTaskIndex = ord.contramap<number, TaskId>((id) => taskIndex[id] ?? 0)(number.Ord);
  return ord.contramap<TaskId, T>((ps) => ps.taskId)(ordByTaskIndex);
};
