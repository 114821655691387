import React from 'react';

import { Button } from 'antd';

import type { CourseUrl, SemesterId } from '/imports/domain';
import type { AccessCode } from '/imports/modules/Invitations/domain/AccessCode';
import {
  handleInvitationRoute,
  listInvitationsRoute,
} from '/imports/modules/Invitations/presentation/routes';
import type { Match } from '/imports/modules/Routing';
import { formatRoute, zero } from '/imports/modules/Routing';
import { MainContent } from '/imports/ui/components/MainContent';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import { pathToUrl } from '/imports/ui/helper/url';
import { DocumentTitle } from '/imports/ui/hooks/useTitle';
import { TopNavLayout } from '/imports/ui/layouts/TopNavLayout';
import { invitationHooks } from './presentation/hooks';

const CreateInvitation = React.lazy(
  () => import('./presentation/CreateInvitation/CreateFromSelectionModal'),
);
const ListInvitations = React.lazy(() => import('./presentation/ListInvitations'));
const HandleInvitation = React.lazy(() => import('./presentation/HandleInvitation'));

export const handleInvitationsRouter = zero().alt(
  handleInvitationRoute.parser.map((props) => (
    <PrivateRoute
      element={() => <HandleInvitation hooks={invitationHooks} {...props} />}
      denyCapabilities={['exam', 'lti']}
      layout={TopNavLayout}
    />
  )),
);

export const listInvitationsRouter = (
  root: Match<{ semester: SemesterId; courseUrl: CourseUrl }>,
) =>
  zero().alt(
    root.parser.chain(({ semester, courseUrl }) =>
      listInvitationsRoute.parser.map(({ tab }) => {
        const formatInvitationUrl = (accessCode: AccessCode): string =>
          pathToUrl(formatRoute(handleInvitationRoute)({ accessCode }));

        return (
          <MainContent
            title="Invitations"
            extra={
              <CreateInvitation
                hooks={invitationHooks}
                courseUrl={courseUrl}
                semester={semester}
                formatInvitationUrl={formatInvitationUrl}
              >
                {(onClick) => (
                  <Button type="primary" onClick={onClick}>
                    Create invitation
                  </Button>
                )}
              </CreateInvitation>
            }
          >
            <DocumentTitle title="Invitations" description="Courses" />
            <ListInvitations
              hooks={invitationHooks}
              courseUrl={courseUrl}
              semester={semester}
              formatInvitationUrl={formatInvitationUrl}
              tab={tab}
            />
          </MainContent>
        );
      }),
    ),
  );
