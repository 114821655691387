import React from 'react';

import type { BrowserNotifications } from '/imports/domain';

export const useBrowserNotification = () =>
  React.useCallback((notification: BrowserNotifications) => {
    if (Notification.permission === 'granted' && document.hidden) {
      const n = new Notification(notification.title, {
        body: notification.text,
        icon: '/favicon-192.png',
      });
      n.onclick = () => {
        window.focus();
        if (notification.link != null) {
          window.location.replace(notification.link);
        }
      };
      return true;
    }
    return false;
  }, []);
