import type { ReactNode } from 'react';
import React from 'react';

import { App } from 'antd';
import type { NotificationInstance } from 'antd/es/notification/interface';

import { identity, iots } from '@code-expert/prelude';
import type { AppNotification, Content } from '/imports/domain';
import { contentTypeAdt, foldClientNotification } from '/imports/domain';
import { useGlobalContext } from '/imports/ui/components/GlobalContext';
import { Loading } from '/imports/ui/components/Loading';
import { MarkdownArea } from '/imports/ui/components/Markdown';
import { useBrowserNotification } from '/imports/ui/components/Notifications/useBrowserNotification';
import { useEventListener3 } from '/imports/ui/hooks/useEventListener';
import { UiState } from '/imports/utils/UiState';
import useAllSources from './Sources/useAllSources';

const uiStates = {
  closedNotifications: UiState.keyValueProp((id: string) => id, iots.boolean),
};

function sendAntdNotification(
  notificationApi: NotificationInstance,
  notification: AppNotification,
  examMode: boolean,
) {
  const { id, key, title, severity, content, duration = 0 } = notification;
  const ignore = id != null && uiStates.closedNotifications.get(id);
  if (!ignore) {
    notificationApi[severity]({
      key,
      message: title || 'Global notification',
      onClose: () => {
        id != null && uiStates.closedNotifications.set(id, true);
      },
      description:
        content != null
          ? contentTypeAdt.fold<ReactNode>(content, {
              plain: identity,
              markdown: (text) => (
                <React.Suspense fallback={<Loading />}>
                  <MarkdownArea source={text} examMode={examMode} useDarkMode />
                </React.Suspense>
              ),
            })
          : undefined,
      duration,
    });
  }
}

function Notifications() {
  const { examMode, eventEmitter } = useGlobalContext();
  useAllSources();
  const { notification: notificationApi } = App.useApp();

  const sendBrowserNotifications = useBrowserNotification();

  useEventListener3(
    'notification',
    foldClientNotification({
      App: (notification) => {
        sendAntdNotification(notificationApi, notification, examMode);
      },
      Browser: (notification) => {
        // only send browser notification when user is not on page otherwise send Browser Notification
        if (!sendBrowserNotifications(notification)) {
          const { id, key, title, text, link } = notification;
          const content: Content =
            link == null
              ? contentTypeAdt.plain(text)
              : contentTypeAdt.markdown(`
${text}

<a href="${link}" target="_blank">open link</a>
`);
          const antdNotification: AppNotification = {
            kind: 'App',
            severity: 'info',
            duration: 30,
            title,
            content,
            id,
            key,
          };
          sendAntdNotification(notificationApi, antdNotification, examMode);
        }
      },
    }),
    eventEmitter,
  );

  useEventListener3(
    'close_notification',
    (notification) => {
      notificationApi.destroy(notification.key);
    },
    eventEmitter,
  );

  return null;
}

export default React.memo(Notifications);
