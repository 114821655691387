import { iots } from '@code-expert/prelude';
import {
  AnalyticsEventId,
  AnalyticsProjectId,
  CourseId,
  ProjectId,
  SemesterId,
} from '/imports/domain/identity';

export const AnalyticsEventC = iots.struct(
  {
    _id: AnalyticsEventId,
    timestamp: iots.date,
    /** The broad category of the tracked event, e.g. "form" */
    category: iots.string,
    /** The action being tracked, e.g. "newsletter subscription" */
    action: iots.string,
  },
  {
    /** An optional label, e.g. "example.com/blog/subscribe" */
    label: iots.string,
    /** An optional value, e.g. "123" */
    value: iots.string,
  },
);

export type AnalyticsEvent = iots.TypeOf<typeof AnalyticsEventC>;

export const AnalyticsProjectC = iots.strict({
  _id: AnalyticsProjectId,
  courseId: CourseId,
  semester: SemesterId,
  delay: iots.number,
  fileCount: iots.number,
  jobsCount: iots.number,
  projectId: ProjectId,
  runTime: iots.number,
  totalSize: iots.number,
});

export type AnalyticsProject = iots.TypeOf<typeof AnalyticsProjectC>;
