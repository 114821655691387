import type { ThemeConfig } from 'antd';
import { theme } from 'antd';
import deepmerge from 'deepmerge';

import type { MapToken, SeedToken } from '/imports/lib/antd';
import type { Palettes } from './colors';
import { genNamedColorTokens, getAlphaColor, getLighterColor } from './colors';
import type { DesignTokens, PublicAliasToken } from './DesignTokens';
import { defaultDesignTokens, defaultThemeConfig } from './Theme';

// -------------------------------------------------------------------------------------------------
// Palettes

const palettes: Palettes = {
  background: '#000000',
  gray: {
    1: '#1d1c19',
    2: '#32302b',
    3: '#48453f',
    4: '#5f5c54',
    5: '#77746b',
    6: '#908c83',
    7: '#a9a59d',
    8: '#c3c0ba',
    9: '#dcdbd7',
    10: '#f7f6f6',
  },
  magenta: {
    1: '#3c0022',
    2: '#62003b',
    3: '#890057',
    4: '#a92272',
    5: '#c04788',
    6: '#d26a9f',
    7: '#e08bb4',
    8: '#ecafcb',
    9: '#f5d1e1',
    10: '#fcf5f8',
  },
  blue: {
    1: '#001e33',
    2: '#003253',
    3: '#004974',
    4: '#006197',
    5: '#007aba',
    6: '#1e94db',
    7: '#40adf6',
    8: '#74c9ff',
    9: '#afe2ff',
    10: '#eff8ff',
  },
  green: {
    1: '#002025',
    2: '#003639',
    3: '#004e50',
    4: '#006769',
    5: '#008182',
    6: '#009d9d',
    7: '#00b8b9',
    8: '#6ad0d0',
    9: '#b0e5e4',
    10: '#eef9f9',
  },
  orange: {
    1: '#301300',
    2: '#502300',
    3: '#713500',
    4: '#924800',
    5: '#b55c00',
    6: '#d77200',
    7: '#f7890f',
    8: '#ffaf5b',
    9: '#ffd4a1',
    10: '#fff5eb',
  },
  red: {
    1: '#410000',
    2: '#670000',
    3: '#910000',
    4: '#bc0009',
    5: '#e80a21',
    6: '#ff4947',
    7: '#ff7f75',
    8: '#ffaba0',
    9: '#ffd0c9',
    10: '#fff4f3',
  },
};

// -------------------------------------------------------------------------------------------------
// Algorithm

/**
 * @see {@link lightAlgorithm}
 */
export const darkAlgorithm = (seedToken: SeedToken): MapToken => {
  const mapToken = theme.darkAlgorithm(seedToken);

  const colorBgBase = palettes.background;
  const colorTextBase = palettes.gray[10];

  return {
    ...mapToken,
    ...genNamedColorTokens(palettes),

    //
    // Various
    //
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorWhite: '#fff',

    //
    // Neutral palette
    //
    colorTextBase,
    colorText: palettes.gray[10], // getAlphaColor(colorTextBase, 0.85),
    colorTextSecondary: getAlphaColor(colorTextBase, 0.65),
    colorTextTertiary: getAlphaColor(colorTextBase, 0.45),
    colorTextQuaternary: getAlphaColor(colorTextBase, 0.25),

    colorFill: getAlphaColor(colorTextBase, 0.22),
    colorFillSecondary: getAlphaColor(colorTextBase, 0.16),
    colorFillTertiary: getAlphaColor(colorTextBase, 0.12),
    colorFillQuaternary: getAlphaColor(colorTextBase, 0.08),

    colorBgBase,
    colorBgElevated: palettes.gray[2], // getLighterColor(colorBgBase, 12),
    colorBgContainer: palettes.gray[1], // getLighterColor(colorBgBase, 8),
    colorBgLayout: palettes.background, // getLighterColor(colorBgBase, 0),
    colorBgSpotlight: getLighterColor(colorBgBase, 26),

    colorBorder: getLighterColor(colorBgBase, 26),
    colorBorderSecondary: getLighterColor(colorBgBase, 19),

    //
    // Link palette
    //
    colorLinkHover: palettes.magenta[8],
    colorLink: palettes.magenta[7],
    colorLinkActive: palettes.magenta[6],

    //
    // Primary palette
    //
    colorPrimaryBg: palettes.magenta[2],
    colorPrimaryBgHover: palettes.magenta[3],
    colorPrimaryBorder: palettes.magenta[3],
    colorPrimaryBorderHover: palettes.magenta[4],
    colorPrimaryHover: palettes.magenta[6],
    colorPrimary: palettes.magenta[5],
    colorPrimaryActive: palettes.magenta[4],
    colorPrimaryTextHover: palettes.magenta[9],
    colorPrimaryText: palettes.magenta[8],
    colorPrimaryTextActive: palettes.magenta[7],

    //
    // Info palette
    //
    colorInfoBg: palettes.blue[2],
    colorInfoBgHover: palettes.blue[3],
    colorInfoBorder: palettes.blue[3],
    colorInfoBorderHover: palettes.blue[4],
    colorInfoHover: palettes.blue[7],
    colorInfo: palettes.blue[6],
    colorInfoActive: palettes.blue[5],
    colorInfoTextHover: palettes.blue[9],
    colorInfoText: palettes.blue[8],
    colorInfoTextActive: palettes.blue[7],

    //
    // Success palette
    //
    colorSuccessBg: palettes.green[2],
    colorSuccessBgHover: palettes.green[3],
    colorSuccessBorder: palettes.green[3],
    colorSuccessBorderHover: palettes.green[4],
    colorSuccessHover: palettes.green[7],
    colorSuccess: palettes.green[6],
    colorSuccessActive: palettes.green[5],
    colorSuccessTextHover: palettes.green[9],
    colorSuccessText: palettes.green[8],
    colorSuccessTextActive: palettes.green[7],

    //
    // Warning palette
    //
    colorWarningBg: palettes.orange[2],
    colorWarningBgHover: palettes.orange[3],
    colorWarningBorder: palettes.orange[3],
    colorWarningBorderHover: palettes.orange[4],
    colorWarningHover: palettes.orange[8],
    colorWarning: palettes.orange[7],
    colorWarningActive: palettes.orange[6],
    colorWarningTextHover: palettes.orange[9],
    colorWarningText: palettes.orange[8],
    colorWarningTextActive: palettes.orange[7],

    //
    // Error palette
    //
    colorErrorBg: palettes.red[2],
    colorErrorBgHover: palettes.red[3],
    colorErrorBorder: palettes.red[3],
    colorErrorBorderHover: palettes.red[4],
    colorErrorHover: palettes.red[7],
    colorError: palettes.red[6],
    colorErrorActive: palettes.red[5],
    colorErrorTextHover: palettes.red[9],
    colorErrorText: palettes.red[8],
    colorErrorTextActive: palettes.red[7],
  };
};

// -------------------------------------------------------------------------------------------------
// Theme Config

/**
 * Get the ThemeConfig for a given ColorScheme.
 *
 * This is the main point of configuration for the Antd theme.
 */
export const getDarkThemeConfig = (seedToken: SeedToken): ThemeConfig => {
  const theme = darkAlgorithm(seedToken);
  return deepmerge(defaultThemeConfig, {
    algorithm: darkAlgorithm,
    components: {
      Layout: {
        headerBg: theme.colorBgContainer,
        colorBgContainer: theme.colorBgLayout,
        bodyBg: theme.colorBgLayout,
      },
    },
  });
};

// -------------------------------------------------------------------------------------------------
// Design Tokens

/**
 * Derive the app's definitive design tokens from Antd's AliasTokens.
 *
 * This extends Antd's AliasTokens (the tokens used for development) with additional
 * tokens we need in our application.
 */
export const getDarkDesignTokens = (tokens: PublicAliasToken): DesignTokens => ({
  ...tokens,
  ...defaultDesignTokens,

  colorAccent: tokens.orange8,

  colorAceEditorBg: tokens.colorBgContainer,

  colorCalendarPageBg: tokens.colorBgLayout,
  colorCalendarBorder: tokens.colorBorder,
  colorCalendarNeutralBg: tokens.colorFill,
  colorCalendarNeutralText: tokens.colorTextSecondary,
  colorCalendarNowIndicator: tokens.colorPrimary,
  colorCalendarTodayBg: getAlphaColor(palettes.orange[4], 0.2),

  colorGenderDiverse: tokens.colorTextSecondary,
  colorGenderFemale: tokens.magenta5,
  colorGenderMale: tokens.blue5,

  colorHeaderBlack: palettes.gray[3],
  colorHeaderRed: palettes.red[4],
  colorHeaderGold: palettes.orange[4],
  colorHeaderGreen: palettes.green[4],
  colorHeaderBlue: palettes.blue[4],
  colorHeaderMagenta: palettes.magenta[4],

  colorIdeBg: tokens.colorBgLayout,

  colorMarkdownBg: palettes.gray[8],

  colorMessageRead: palettes.gray[7],
  colorMessageUnread: tokens.blue7,

  colorTableDivider: palettes.gray[5],

  colorTaskCollectionBonus: tokens.magenta6,
  colorTaskCollectionBonusText: tokens.magenta8,
  colorTaskCollectionTodo: tokens.orange6,

  colorTaskFailed: tokens.red5,
  colorTaskNeutral: palettes.gray[6],
  colorTaskPending: tokens.green6,
  colorTaskProgressBg: tokens.green3,
  colorTaskTodo: tokens.orange6,
  colorTaskSuccess: tokens.green6,
  colorTaskUngraded: tokens.green2,

  colorTestOutputPassed: tokens.colorSuccess,
  colorTestOutputTodo: tokens.colorWarning,
  colorTestOutputSkipped: tokens.colorInfo,
  colorTestOutputFailed: tokens.colorError,

  colorTextWhite: 'rgba(255, 255, 255, 0.85)',

  linkDecoration: tokens.linkDecoration as unknown as string,
  linkHoverDecoration: tokens.linkHoverDecoration as unknown as string,
  linkFocusDecoration: tokens.linkFocusDecoration as unknown as string,
});
