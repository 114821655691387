import { date, iots, ord, pipe } from '@code-expert/prelude';
import { CourseId, ExamId, MessageId, SemesterId, UserId } from '/imports/domain/identity';

export interface Message {
  readonly _id: MessageId;
  readonly message: string;
  readonly subject?: string;
  readonly lastChange: Date;
  readonly from?: UserId;
  // store if real user if message was sent by another user
  readonly fromUser?: UserId;
  readonly to?: UserId;
  readonly courseId: CourseId;
  readonly examId?: ExamId;
  readonly semester: SemesterId;
  readonly state: {
    created: Date;
    read?: Date;
    replied?: Date;
  };
  readonly additionalData?: {
    url?: string;
  };
  readonly parent?: MessageId;
}

export const MessageC = iots.struct(
  {
    _id: MessageId,
    message: iots.string,
    lastChange: iots.date,
    courseId: CourseId,
    semester: SemesterId,
    state: iots.struct(
      {
        created: iots.date,
      },
      {
        read: iots.date,
        replied: iots.date,
      },
    ),
  },
  {
    subject: iots.string,
    from: UserId,
    // store if real user if message was sent by another user
    fromUser: UserId,
    to: UserId,
    examId: ExamId,
    additionalData: iots.exact(
      iots.partial({
        url: iots.string,
      }),
    ),
    parent: MessageId,
  },
) satisfies iots.Type<Message, unknown>;

export const ordMessageByCreatedDesc: ord.Ord<Message> = pipe(
  date.Ord,
  ord.contramap((m: Message) => m.state.created),
  ord.reverse,
);

export const ordMessageByLastChangeDesc: ord.Ord<Message> = pipe(
  date.Ord,
  ord.contramap((m: Message) => m.lastChange),
  ord.reverse,
);
