import React from 'react';

import { Switch } from 'antd';

import { Icon } from '/imports/ui/foundation/Icons';
import { isLight, styled, useTheme } from '/imports/ui/foundation/Theme';

const StyledSwitch = styled(Switch, ({ tokens }) => ({
  opacity: 0.8,
  '&.ant-switch.ant-switch-checked': {
    backgroundColor: tokens.colorTextQuaternary,
    '&:hover': {
      backgroundColor: tokens.colorTextTertiary,
    },
    '.ant-switch-inner': {
      paddingInlineStart: 5,
    },
  },
  '&.ant-switch:not(.ant-switch-checked)': {
    '.ant-switch-inner': {
      paddingInlineEnd: 5,
    },
  },
}));

export const ThemeSwitch = ({ small = false }: { small?: boolean }) => {
  const { colorScheme, setColorScheme } = useTheme();
  const toggleColorScheme = (checked: boolean) => setColorScheme(checked ? 'light' : 'dark');

  return (
    <StyledSwitch
      data-test-id="theme-switch"
      size={small ? 'small' : undefined}
      checked={isLight(colorScheme)}
      checkedChildren={<Icon name="sun" />}
      unCheckedChildren={<Icon name="moon" />}
      onChange={toggleColorScheme}
    />
  );
};
