import type { eq, predicate } from '@code-expert/prelude';
import {
  array,
  date,
  either,
  flow,
  map,
  option,
  pipe,
  string,
  tagged,
  tree,
} from '@code-expert/prelude';
import type { ProjectEntryKey, ProjectId } from '/imports/domain';
import type { Unauthorised } from '/imports/modules/Authorization/domain';
import type { ProjectDirectory, ProjectEntry } from '/imports/modules/Project/domain';

export type ProjectError =
  | Unauthorised
  | tagged.Tagged<'projectNotFound', ProjectId>
  | tagged.Tagged<'keyNotFound', ProjectEntryKey>
  | tagged.Tagged<'duplicateKey', ProjectEntryKey>;

export const projectErrorAdt = tagged.build<ProjectError>();

const isDirectory = (e: ProjectEntry): e is ProjectDirectory => e.type === 'inode/directory';

/**
 * Predicate to detect project entries belonging to a particular snapshot.
 * If no snapshot is given, only the current entries are detected.
 */
export const filterBySnapshotDate: (
  snapshotDate: option.Option<Date>,
) => predicate.Predicate<ProjectEntry> = option.fold(
  () =>
    ({ current }) =>
      current === true,
  (snapshotDate) =>
    ({ createdAt, current, replacedAt }) =>
      !date.isAfter(snapshotDate, createdAt) &&
      (current === true || (replacedAt != null && date.isAfter(snapshotDate, replacedAt))),
);

/**
 * Build an fp-ts tree of project directories and files.
 * If a snapshot date is passed, the tree is built for this snapshot.
 * Otherwise, the tree is built for the current version.
 * @param rootDirKey the key of the root directory
 * @param entries the project entries
 * @param snapshotDate an optional snapshot date
 */
export const buildTree = (
  rootDirKey: ProjectEntryKey,
  entries: Array<ProjectEntry>,
  snapshotDate: option.Option<Date>,
): either.Either<ProjectError, tree.Tree<ProjectEntry>> => {
  const keyEq: eq.Eq<ProjectEntryKey> = string.Eq;

  type KeyMap = Map<ProjectEntryKey, ProjectEntry>;
  type KeyMapEither = either.Either<ProjectError, KeyMap>;

  const key2entry: KeyMapEither = pipe(
    entries,
    array.filter(filterBySnapshotDate(snapshotDate)),
    array.reduce<ProjectEntry, KeyMapEither>(either.of(new Map()), (keyMapEither, entry) =>
      pipe(
        keyMapEither,
        either.chain((keyMap) =>
          pipe(
            entry.key,
            either.fromPredicate(
              (key: ProjectEntryKey) => !map.member(keyEq)(key, keyMap),
              projectErrorAdt.wide.duplicateKey,
            ),
            either.map(() => pipe(keyMap, map.upsertAt(keyEq)(entry.key, entry))),
          ),
        ),
      ),
    ),
  );

  const findByKey = (key: ProjectEntryKey): either.Either<ProjectError, ProjectEntry> =>
    pipe(
      key2entry,
      either.chain(
        flow(
          map.lookup(keyEq)(key),
          either.fromOption(() => projectErrorAdt.wide.keyNotFound(key)),
        ),
      ),
    );

  const buildTree = (root: ProjectEntry): either.Either<ProjectError, tree.Tree<ProjectEntry>> =>
    tree.unfoldTreeM(either.Monad)(root, (entry) =>
      pipe(
        isDirectory(entry) ? entry.children : [],
        array.traverse(either.Applicative)(findByKey),
        either.map((children) => [entry, children]),
      ),
    );

  return pipe(findByKey(rootDirKey), either.chain(buildTree));
};
