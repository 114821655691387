import React from 'react';

import { ConfigProvider, theme } from 'antd';
import deepmerge from 'deepmerge';

import { requireNonNull } from '@code-expert/prelude';
import type { ThemeConfig } from '/imports/lib/antd';
import type { DesignTokens, PublicAliasToken } from '/imports/ui/foundation/Theme/DesignTokens';
import { getPublicAliasTokens } from '/imports/ui/foundation/Theme/DesignTokens';
import { getDarkDesignTokens, getDarkThemeConfig } from '/imports/ui/foundation/Theme/themeDark';
import { getLightDesignTokens, getLightThemeConfig } from '/imports/ui/foundation/Theme/themeLight';
import type { ColorScheme } from './ColorScheme';
import { isLight } from './ColorScheme';
import type { Theme } from './Theme';
import { usePreferredColorScheme } from './usePreferredColorScheme';

type ThemeOverride = (_: {
  colorScheme: ColorScheme;
  theme: ThemeConfig;
  tokens: PublicAliasToken;
}) => ThemeConfig;

/**
 * Get the theme for a given color scheme.
 *
 * Overrides can be applied with the themeOverride function like so:
 *
 *     <ThemeProvider themeOverride={
 *       ({theme, tokens}) => ({ components: { Menu: { itemBg: tokens.colorPrimary }} })
 *     }>
 *       ...
 *     </ThemeProvider>
 */
const generateTheme = (
  colorScheme: ColorScheme,
  themeOverride?: ThemeOverride,
): {
  themeConfig: ThemeConfig;
  tokens: DesignTokens;
} => {
  const getThemeConfig = isLight(colorScheme) ? getLightThemeConfig : getDarkThemeConfig;
  const getDesignTokens = isLight(colorScheme) ? getLightDesignTokens : getDarkDesignTokens;

  const initialThemeConfig = getThemeConfig(theme.defaultSeed);
  const publicAliasTokens = getPublicAliasTokens(theme.getDesignToken(initialThemeConfig));
  const themeConfig = themeOverride
    ? deepmerge(
        initialThemeConfig,
        themeOverride({ colorScheme, theme: initialThemeConfig, tokens: publicAliasTokens }),
      )
    : initialThemeConfig;
  return {
    themeConfig,
    tokens: getDesignTokens(
      themeConfig.token ? deepmerge(publicAliasTokens, themeConfig.token) : publicAliasTokens,
    ),
  };
};

// -------------------------------------------------------------------------------------------------

/**
 * Theme provider with mutable color scheme.
 */
export const ThemeProvider = React.memo<{
  children?: React.ReactNode;
  colorScheme?: ColorScheme;
  themeOverride?: ThemeOverride;
}>(function MutableThemeProvider({ children, colorScheme: colorSchemeOverride, themeOverride }) {
  // Use the outermost parent context as the source of truth
  const parentTheme = React.useContext(themeContext);
  const [userColorScheme, setUserColorScheme] = usePreferredColorScheme();

  const colorScheme = colorSchemeOverride ?? parentTheme?.colorScheme ?? userColorScheme;
  const setColorScheme = parentTheme?.setColorScheme ?? setUserColorScheme;

  const { themeConfig, tokens } = generateTheme(colorScheme, themeOverride);

  return (
    <ConfigProvider theme={themeConfig}>
      <themeContext.Provider value={{ colorScheme, setColorScheme, tokens }}>
        {children}
      </themeContext.Provider>
    </ConfigProvider>
  );
});

export const useTheme = (): Theme =>
  requireNonNull(React.useContext(themeContext), 'ThemeProvider has not been initialised');

// -------------------------------------------------------------------------------------------------

const themeContext = React.createContext<Theme | undefined>(undefined);
