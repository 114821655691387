import { adt, either, flow, number, option, pipe } from '@code-expert/prelude';
import type { Grading } from '/imports/domain/grading';
import { submissionPasses } from '/imports/domain/grading';
import type { Submission, SubmissionPublic } from './submission';
import {
  foldSubmission,
  foldSubmissionStatus,
  isDispenseSubmission,
  isRelevantSubmission,
  isSubmissionLateFromO,
  isSubmissionReviewed,
} from './submission';

// -------------------------------------------------------------------------------------------------
// Types

/** If a dispensed submission is associated with a task, the student succeeded at the task. */
export type SubmissionStateDispensed = { type: 'dispensed' };
export const submissionStateDispensed: SubmissionState = { type: 'dispensed' };
export const isSubmissionStateDispensed = (x: SubmissionState): x is SubmissionStateDispensed =>
  x.type === 'dispensed';

/** The submission was submitted after the due date and was not cherry picked for grading. */
export type SubmissionStateLate = { type: 'late'; result: option.Option<number> };
export const submissionStateLate: (result: option.Option<number>) => SubmissionState = (
  result,
) => ({ type: 'late', result });
export const isSubmissionStateLate = (x: SubmissionState): x is SubmissionStateLate =>
  x.type === 'late';

/** The submission is in a transitional state and has no definitive result. */
export type SubmissionStatePending = { type: 'pending'; result: option.Option<number> };
export const submissionStatePending: (result: option.Option<number>) => SubmissionState = (
  result,
) => ({
  type: 'pending',
  result,
});
export const isSubmissionStatePending = (x: SubmissionState): x is SubmissionStatePending =>
  x.type === 'pending';

/** The submission has been successfully submitted but was not considered for grading. */
export type SubmissionStateSubmitted = {
  type: 'submitted';
  result: option.Option<number>;
  reviewed: boolean;
};
export const submissionStateSubmitted: (
  reviewed: boolean,
) => (result: option.Option<number>) => SubmissionState = (reviewed: boolean) => (result) => ({
  type: 'submitted',
  result,
  reviewed,
});
export const isSubmissionStateSubmitted = (x: SubmissionState): x is SubmissionStateSubmitted =>
  x.type === 'submitted';

/** The submission is used for grading because it was automatically or manually cherry-picked. */
export type SubmissionStatePicked = {
  type: 'picked';
  result: option.Option<either.Either<number, number>>;
  reviewed: boolean;
};
export const submissionStatePicked: (
  reviewed: boolean,
) => (result: option.Option<either.Either<number, number>>) => SubmissionState =
  (reviewed: boolean) => (result) => ({
    type: 'picked',
    result,
    reviewed,
  });
export const isSubmissionStatePicked = (x: SubmissionState): x is SubmissionStatePicked =>
  x.type === 'picked';

/**
 * The current state of a submission from which a {@link TaskProgress} can be derived.
 */
export type SubmissionState =
  | SubmissionStateDispensed
  | SubmissionStateLate
  | SubmissionStatePending
  | SubmissionStateSubmitted
  | SubmissionStatePicked;

export const foldSubmissionState = adt.foldFromTags<SubmissionState, 'type'>('type');

// -------------------------------------------------------------------------------------------------
// Functions

/**
 * Extract the numeric result value of a submission, if any.
 */
export const submissionResultValue: (submission: Submission) => option.Option<number> = flow(
  foldSubmission({
    code: ({ reviewed, result, preResult }) =>
      !reviewed && preResult !== undefined ? preResult : result,
    dispense: ({ result }) => result,
    link: ({ result }) => result,
    presentation: ({ result }) => result,
  }),
  option.fromPredicate(number.isFinite),
);

/**
 * {@link SubmissionState} determines the current state of a single submission. It can be used to
 * derive a {@link TaskProgress} or {@link TaskStatus} from a list of submissions.
 */
export const submissionState =
  ({ dueDate, grading: grading_ }: { dueDate: option.Option<Date>; grading?: Grading }) =>
  (submission_: SubmissionPublic): SubmissionState =>
    pipe(
      either.right<SubmissionState, SubmissionPublic>(submission_),

      // Dispensed submissions can't be in any other state
      // fixme samuelv: we're mixing up stuff here
      either.stopIfW(isDispenseSubmission, () => submissionStateDispensed),

      // A submission is late only if a due date has been set. It may or may not have a result.
      either.stopIfW(
        isSubmissionLateFromO(dueDate),
        flow(submissionResultValue, submissionStateLate),
      ),

      // Only code and presentation submissions can be pending.
      either.chainW((submission) =>
        foldSubmission<either.Either<SubmissionState, Submission>>(submission, {
          // Only code submissions can have pre-results
          code: (x) =>
            // todo: Not nice: we are checking x.preResult twice - should have a better data type
            !isSubmissionReviewed(x) && x.preResult !== undefined
              ? either.left(submissionStatePending(submissionResultValue(x)))
              : either.right(x),
          // Dispense submissions are never pending
          dispense: () => either.right(submission),
          // Link submissions are never pending
          link: () => either.right(submission),
          presentation: (x) =>
            foldSubmissionStatus(x.status, {
              subscribed: () => either.left(submissionStatePending(option.none)),
              called: () => either.left(submissionStatePending(option.none)),
              alreadyCalled: () => either.left(submissionStatePending(option.none)),
              marked: () => either.left(submissionStatePending(option.fromNullable(x.result))),
              done: () => either.right(x),
              absent: () => either.right(x),
            }),
        }),
      ),

      // A cherry-picked submission, if available, is relevant for grading
      either.chainW((submission) =>
        pipe(
          submission,
          option.fromPredicate(isRelevantSubmission),
          option.map(submissionResultValue),
          option.map((value_) =>
            pipe(
              option.sequenceS({
                value: value_,
                grading: option.fromNullable(grading_),
              }),
              option.map(({ value, grading }) =>
                submissionPasses(grading)(value) ? either.right(value) : either.left(value),
              ),
            ),
          ),
          option.map(submissionStatePicked(isSubmissionReviewed(submission))),
          either.fromOption(() => submission),
          either.swap,
        ),
      ),

      either.swap,

      // If no state before matched, it is a marked submission
      either.getOrElseW((x) =>
        pipe(submissionResultValue(x), submissionStateSubmitted(isSubmissionReviewed(x))),
      ),
    );
