import React from 'react';

import { Badge } from 'antd';

import type { InboxCountHookProps } from '/imports/ui/components/Messages/hooks';
import { useInboxCount, useNotificationCount } from '/imports/ui/components/Messages/hooks';
import { ThemeProvider } from '/imports/ui/foundation/Theme';

interface MessageInboxCountProps {
  children?: React.ReactElement;
  course?: boolean;
  offset?: [number, number];
  courseProps?: InboxCountHookProps;
}

export const MessageInboxCount = React.memo(function MessageInboxCount(
  props: MessageInboxCountProps,
) {
  const { children, courseProps, offset, ...rest } = props;

  const { inboxCount } = useInboxCount(courseProps);
  const { notificationCount } = useNotificationCount();
  const totalCount = 'courseProps' in props ? inboxCount : inboxCount + notificationCount;

  return (
    <ThemeProvider
      themeOverride={({ tokens }) => ({
        components: {
          Badge: {
            colorBgContainer: tokens.colorWhite,
            colorError: tokens.colorPrimary,
            colorErrorHover: tokens.colorPrimaryHover,
          },
        },
      })}
    >
      {children != null ? (
        <Badge count={totalCount} offset={offset}>
          {React.Children.map(children, (child) => React.cloneElement(child, { ...rest }))}
        </Badge>
      ) : (
        <Badge count={totalCount} offset={offset} />
      )}
    </ThemeProvider>
  );
});
