// "Access Roles" / Permissions / Capabilities
// What can this user do?
import type { Endomorphism, eq } from '@code-expert/prelude';
import { identity, record, string } from '@code-expert/prelude';
import type { Setting } from '/imports/domain/setting';
import type { UserPublic } from '/imports/domain/user';
import { isExamUser, isLTIUser, isSuperAdmin } from '/imports/domain/user';

export type UserCapability =
  /** User can log in; this is the most basic capability */
  | 'login'
  /** User has access to groups they are enrolled in */
  | 'enrolled'
  /** User has access to groups they manage */
  | 'groupAdmin'
  /** User has access to courses they manage */
  | 'courseAdmin'
  /** User has access to the admin pages */
  | 'admin'
  /** This is an LTI user */
  | 'lti'
  /** This is an exam user */
  | 'exam';

const ALL_ROLES: Record<UserCapability, null> = {
  login: null,
  enrolled: null,
  groupAdmin: null,
  courseAdmin: null,
  admin: null,
  lti: null,
  exam: null,
};

export const userCapabilityEq: eq.Eq<UserCapability> = string.Eq;

/**
 * Check a user's roles according to an allow and deny list. The deny list takes
 * precedence over the allow list.
 */
export function checkCapabilities(
  {
    allow,
    deny,
  }: {
    allow?: Array<UserCapability>;
    deny?: Array<UserCapability>;
  },
  capabilities: Array<UserCapability>,
): boolean {
  const isDenied = () => capabilities.some((x) => deny != null && deny.includes(x));
  const isAllowed = () => capabilities.some((x) => allow != null && allow.includes(x));
  if (capabilities.includes('admin')) return true;
  if (deny != null && allow != null) return !isDenied() && isAllowed();
  if (deny != null) return !isDenied();
  if (allow != null) return isAllowed();
  return true;
}

export const calculateCapabilities = ({
  user,
  hasAsAdminCourses,
  hasAsStudentGroups,
  hasAsAssistantGroups,
}: {
  user: UserPublic;
  hasAsAdminCourses: boolean;
  hasAsStudentGroups: boolean;
  hasAsAssistantGroups: boolean;
}): Array<UserCapability> => {
  const capabilities = new Set<UserCapability>();

  if (isSuperAdmin(user)) {
    return record.keys(ALL_ROLES);
  }

  if (hasAsAdminCourses) {
    capabilities.add('courseAdmin');
    capabilities.add('groupAdmin');
  }

  if (hasAsAssistantGroups) {
    capabilities.add('groupAdmin');
  }

  if (hasAsStudentGroups) {
    capabilities.add('enrolled');
  }

  if (!user.blocked) capabilities.add('login');
  if (isLTIUser(user)) capabilities.add('lti');
  if (isExamUser(user)) capabilities.add('exam');
  return Array.from(capabilities);
};

export const transformSettings: Endomorphism<Setting> = identity;
