import type { Binary } from '@code-expert/mongo-ts';
import { array, eq, iots, nonEmptyArray, option, pipe, string, tagged } from '@code-expert/prelude';
import type { UserId, WebAuthId } from '/imports/domain/identity';
import type { ConnectionId } from './connection';

export const unknownUser = 'unknown' as const;

export const authenticatorTransportKeys = {
  ble: null,
  usb: null,
  hybrid: null,
  nfc: null,
  internal: null,
} satisfies Record<AuthenticatorTransport, null>;

export const AuthenticatorTransport = iots.keyof(authenticatorTransportKeys);

export type Authenticator = {
  id: string;
  name: string;
  connectionId?: ConnectionId;
  registeredAt: Date;
  credentialID: Binary;
  credentialPublicKey: Binary;
  counter: number;
  transports?: AuthenticatorTransport[];
};

export interface WebAuth {
  _id: WebAuthId;
  userId: UserId | typeof unknownUser;
  registrationChallenge?: string;
  loginChallenge?: string;
  authenticators: Authenticator[];
}

export type PublicAuthenticator = Pick<
  Authenticator,
  'id' | 'registeredAt' | 'connectionId' | 'name'
>;

export const publicAuthenticatorEq: eq.Eq<PublicAuthenticator> = pipe(
  eq.nullable(string.Eq),
  eq.contramap((x: PublicAuthenticator) => x.connectionId),
);

export type WebAuthStatus =
  | tagged.Tagged<'notRegistered'>
  | tagged.Tagged<'registered', { authenticators: NonEmptyArray<PublicAuthenticator> }>
  | tagged.Tagged<
      'auth',
      {
        activeAuthenticator: PublicAuthenticator;
        authenticators: NonEmptyArray<PublicAuthenticator>;
      }
    >;

export const webAuthStatusAdt = tagged.build<WebAuthStatus>();

export interface WebAuthPublic {
  _id: WebAuthId;
  status: WebAuthStatus;
}

export const webAuthToWebAuthPublic =
  (connectionId: ConnectionId) =>
  (webAuth: WebAuth): WebAuthPublic => ({
    _id: webAuth._id,
    status: pipe(
      nonEmptyArray.fromArray(webAuth.authenticators),
      option.match(webAuthStatusAdt.wide.notRegistered, (authenticators) =>
        pipe(
          authenticators,
          array.findFirst(
            (a) => a.connectionId != null && string.Eq.equals(a.connectionId, connectionId),
          ),
          option.fold(
            () => webAuthStatusAdt.wide.registered({ authenticators }),
            (activeAuthenticator) =>
              webAuthStatusAdt.wide.auth({ activeAuthenticator, authenticators }),
          ),
        ),
      ),
    ),
  });

export function isPublicKeyCredential(credential: Credential): credential is PublicKeyCredential {
  return credential.type === 'public-key';
}

const ClientExtensionResults = iots.partial({
  appid: iots.boolean,
  credProps: iots.partial({
    rk: iots.boolean,
  }),
  uvm: iots.array(iots.array(iots.number)),
});

export const PublicKeyAttestation = iots.strict({
  id: iots.string,
  rawId: iots.string,
  response: iots.strict({
    attestationObject: iots.string,
    clientDataJSON: iots.string,
  }),
  clientExtensionResults: ClientExtensionResults,
  transports: iots.union([
    iots.array(
      iots.keyof({ ble: null, internal: null, nfc: null, usb: null, cable: null, hybrid: null }),
    ),
    iots.undefined,
  ]),
  type: iots.literal('public-key'),
});

export type PublicKeyAttestation = iots.TypeOf<typeof PublicKeyAttestation>;

export function isPublicKeyAttestation(c: Credential | null): c is PublicKeyAttestation {
  return c != null && isPublicKeyCredential(c) && 'attestationObject' in c.response;
}

export const PublicKeyAssertion = iots.strict({
  id: iots.string,
  type: iots.literal('public-key'),
  rawId: iots.string,
  clientExtensionResults: ClientExtensionResults,
  response: iots.strict({
    clientDataJSON: iots.string,
    authenticatorData: iots.string,
    signature: iots.string,
    userHandle: iots.union([iots.string, iots.undefined]),
  }),
});

export type PublicKeyAssertion = iots.TypeOf<typeof PublicKeyAssertion>;

export function isPublicKeyAssertion(c: Credential | null): c is PublicKeyAssertion {
  return c != null && isPublicKeyCredential(c) && 'signature' in c.response;
}
