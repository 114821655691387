import React from 'react';

import { Tag } from 'antd';

import type { UserPublic } from '/imports/domain';
import { isLTIUser } from '/imports/domain';
import { Icon } from '/imports/ui/foundation/Icons';

export interface LtiLabelProps {
  user: UserPublic;
}

export function LtiLabel({ user }: LtiLabelProps) {
  if (!isLTIUser(user)) {
    return null;
  }
  return (
    <Tag color="orange" style={{ marginLeft: 4 }}>
      <Icon name="external-link-alt" />
      LTI
    </Tag>
  );
}
