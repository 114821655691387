import EventEmitter from 'eventemitter3';

import type { ProjectEntryKey, ProjectId } from '@code-expert/pfs/dist/identity';
import type { ClientNotification, JobId } from '/imports/domain';

export type InternalEvents = {
  notification: (notification: ClientNotification) => void;
  close_notification: (notification: Pick<ClientNotification, 'key'>) => void;
  newJob: (args: { jobId: JobId }) => void;
  lastTerminalInput: (args: { jobId: JobId; date: Date }) => void;
  tigerPython: (args: { projectId: ProjectId; fileKey: ProjectEntryKey; end: true }) => void;
  browserExecutionOutput: (args: { output?: string }) => void;
};

export type CxEvents = InternalEvents & {
  /** Events from the runner */
  [TYPE: string]: (args: {
    /** TYPE chunk begins */
    start?: boolean;
    /** TYPE chunk ends */
    end?: boolean;
    /** TYPE attributes (space-separated string) */
    attributes?: string;
    /** TYPE chunk, e.g. "<p>Hello wo" or "rld</p>" */
    payload?: string;
  }) => void;
};

export class CxEventEmitter extends EventEmitter<CxEvents> {}
