import type { LocationDescriptorObject } from 'history';
import { createPath } from 'history';

import { iots } from '@code-expert/prelude';
import { UiState } from '/imports/utils/UiState';

const lastLocationUiState = UiState.valueProp('lastLocation', iots.string);

export function pushLastLocation(
  location: LocationDescriptorObject = { pathname: '/', search: '' },
): void {
  lastLocationUiState.set(createPath(location), '1h');
}

export function popLastLocation() {
  return lastLocationUiState.get();
}

export function popLastLocationOrDefault() {
  return popLastLocation() ?? '/';
}
