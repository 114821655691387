import type { Snapshot as PfsSnapshot } from '@code-expert/pfs';
import { record } from '@code-expert/prelude';
import type { ProjectId, SnapshotId, UserId } from '/imports/domain/identity';

export const snapshotReasonProps = {
  manual: null,
  compile: null,
  submit: null,
  /** Created automatically after a submission has been submitted and processed */
  postSubmit: null,
  autosave: null,
  regrade: null,
};

export type SnapshotReason = keyof typeof snapshotReasonProps;

export const snapshotReasons = record.keys(snapshotReasonProps);

export interface Snapshot extends Omit<PfsSnapshot, 'projectId'> {
  readonly _id: SnapshotId;
  readonly projectId: ProjectId;
  readonly reason: SnapshotReason;
  readonly result?: number | null;
  readonly preResult?: number | null;
  readonly hidden?: boolean;
  readonly userId?: UserId;
}
