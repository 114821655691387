import {
  adt,
  array,
  either,
  flow,
  functor,
  iots,
  isOneOf,
  nonEmptyArray,
  number,
  option,
  ord,
  pipe,
} from '@code-expert/prelude';
import type { predicate } from '@code-expert/prelude';
import { allocationFraction, foldLateAppointment } from '/imports/domain/appointment';
import { AppointmentId, TaskId, UserId } from '/imports/domain/identity';
import type { PresentationSubmission } from '/imports/domain/submission';
import { isAbsent, isPresentationSubmission } from '/imports/domain/submission';
import type {
  EligibleAppointment,
  SignUpContext,
  SignUpContextPair,
  SignUpContextSolo,
  SignUpContextSubmission,
} from './SignUpContext';

const isContextPair = (ctx: SignUpContext): ctx is SignUpContextPair =>
  // typescript doesn't warn when checking for non-existant props with 'in'
  // therefore we do a silly '!= null' check, just to make sure
  'partner' in ctx && ctx.partner != null;
/**
 * A pair appointment requires the organiser and their partner to be different, it's not allowed
 * for an organiser to invite themselves as a partner.
 */
const isValidPair = ({ user, partner }: SignUpContextPair): boolean => user._id !== partner._id;

/**
 * Parameters for signing up a student for an appointment.
 */
export const SignupPayloadC = iots.struct(
  {
    appointmentIds: iots.nonEmptyArray(AppointmentId),
    taskId: TaskId,
  },
  {
    partnerId: UserId,
  },
);

export type SignupPayloadC = typeof SignupPayloadC;

export type SignupPayload = iots.TypeOf<SignupPayloadC>;

const signupErrorSpec = {
  afterStart: 0,
  appointmentsDisabled: 0,
  exerciseLocked: 0,
  hasSubmissions: 0,
  invalidPair: 0,
  late: 0,
  multipleDisabled: 0,
  noEligibleAppointment: 0,
  noSlotAvailable: 0,
  partnerHasSubmissions: 0,
  windowClosed: 0,
};

/**
 * Potential errors when signing up a student for an appointment.
 */
export type SignupError = keyof typeof signupErrorSpec;

export const foldSignupError = adt.foldFromKeys(signupErrorSpec);

const {
  afterStart,
  appointmentsDisabled,
  exerciseLocked,
  hasSubmissions,
  invalidPair,
  late,
  multipleDisabled,
  noEligibleAppointment,
  noSlotAvailable,
  partnerHasSubmissions,
  windowClosed,
} = adt.valuesFromKeys<SignupError>();

const validateBeforeAppointmentStart = (
  now: Date,
): ((appointments: NonEmptyArray<EligibleAppointment>) => either.Either<SignupError, void>) =>
  flow(
    either.fromPredicate(
      array.every(({ start }) => start.getTime() >= now.getTime()),
      () => afterStart,
    ),
    functor.asUnit(either.Functor),
  );

const eligibleAppointmentOrd: ord.Ord<EligibleAppointment> = pipe(
  number.Ord,
  ord.contramap(({ allocation }) => allocationFraction(allocation)),
);

const findEligibleAppointment =
  (now: Date) =>
  (ctx: SignUpContext): either.Either<SignupError, EligibleAppointment> => {
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const submissions = ctx.submissions.filter(isPresentationSubmission);
    const partnerSubmissions = pipe(
      ctx,
      option.fromPredicate(isContextPair),
      option.map((c) => c.partnerSubmissions),
      option.map(array.filter(isPresentationSubmission)),
    );
    let isLateOrAbsent = now.getTime() > ctx.exercise.solutionDate.getTime();
    if (isAbsent(submissions)) isLateOrAbsent = true;
    if (option.exists(isAbsent)(partnerSubmissions)) isLateOrAbsent = true;

    const validateInTime: predicate.Predicate<EligibleAppointment> = (appointment) => {
      if (isLateOrAbsent) {
        return appointment.start.getTime() <= now.getTime() + oneWeek;
      }
      return (
        ctx.exercise.dueDate.getTime() <= appointment.start.getTime() &&
        appointment.start.getTime() <= ctx.exercise.solutionDate.getTime()
      );
    };

    const validateSlots: predicate.Predicate<EligibleAppointment> = ({
      allocation,
      lateAppointment,
    }) => {
      const lateDeduction = foldLateAppointment({
        all: () => 0,
        half: () => (isLateOrAbsent ? allocation.maxStudents / 2 : 0),
        no: () => (isLateOrAbsent ? allocation.maxStudents : 0),
      });
      const openSlots =
        allocation.maxStudents - allocation.currentStudentCount - lateDeduction(lateAppointment);
      const requiredSlots = isContextPair(ctx) ? 2 : 1;
      return openSlots >= requiredSlots;
    };

    return pipe(
      either.of(ctx.appointments),
      either.chainFirst(validateBeforeAppointmentStart(now)),
      either.chain(
        array.matchSingleton<EligibleAppointment, either.Either<SignupError, EligibleAppointment>>({
          onEmpty: () => either.left(noEligibleAppointment),
          onSingleton: flow(
            either.fromPredicate(validateInTime, () => late),
            either.filterOrElseW(validateSlots, () => noSlotAvailable),
          ),
          otherwise: flow(
            array.filter(validateInTime),
            array.filter(validateSlots),
            array.sort(eligibleAppointmentOrd),
            either.fromPredicate(array.isNonEmpty, () => noEligibleAppointment),
            either.map(nonEmptyArray.head),
          ),
        }),
      ),
    );
  };

export interface SignUpSoloResult {
  submission: Omit<PresentationSubmission, '_id'>;
  appointmentId: AppointmentId;
}

export const signUpStudentSolo =
  (now: Date) =>
  (ctx: SignUpContextSolo): either.Either<SignupError, SignUpSoloResult> => {
    const hasExistingSubmission = array.exists<SignUpContextSubmission>(
      (s) =>
        s.kind === 'dispense' ||
        isOneOf('subscribed', 'called', 'alreadyCalled', 'marked', 'done')(s.status),
    );
    return pipe(
      either.right(ctx),
      either.filterOrElse(
        ({ course }) => course.appointments.active,
        () => appointmentsDisabled,
      ),
      either.filterOrElseW(
        (c) => c.exercise.handoutDate.getTime() <= now.getTime(),
        () => windowClosed,
      ),
      either.filterOrElseW(
        (c) => c.isExerciseUnlocked,
        () => exerciseLocked,
      ),
      either.filterOrElseW(
        (c) => !hasExistingSubmission(c.submissions),
        () => hasSubmissions,
      ),
      either.chain(findEligibleAppointment(now)),
      either.map(
        (appointment): SignUpSoloResult => ({
          submission: {
            kind: 'presentation',
            submissionDate: now,

            taskId: ctx.task._id,
            semester: ctx.task.semester,

            status: 'subscribed',
            appointmentId: appointment._id,
            studentId: ctx.user._id,

            result: null,
          },
          appointmentId: appointment._id,
        }),
      ),
    );
  };

export interface SignUpPairResult extends SignUpSoloResult {
  partnerSubmission: Omit<PresentationSubmission, '_id'>;
}

export const signUpStudentPair =
  (now: Date) =>
  (ctx: SignUpContextPair): either.Either<SignupError, SignUpPairResult> => {
    const hasExistingSubmission = array.exists<SignUpContextSubmission>(
      (s) =>
        s.kind === 'dispense' ||
        isOneOf('subscribed', 'called', 'alreadyCalled', 'marked', 'done')(s.status),
    );
    return pipe(
      either.right(ctx),
      either.filterOrElse(isValidPair, () => invalidPair),
      either.filterOrElseW(
        ({ course }) => course.appointments.active,
        () => appointmentsDisabled,
      ),
      either.filterOrElseW(
        ({ course }) => course.appointments.allowMultiple,
        () => multipleDisabled,
      ),
      either.filterOrElseW(
        (c) => c.exercise.handoutDate.getTime() <= now.getTime(),
        () => windowClosed,
      ),
      either.filterOrElseW(
        (c) => now.getTime() < c.exercise.solutionDate.getTime(),
        () => late,
      ),
      either.filterOrElseW(
        (c) => c.isExerciseUnlocked && c.isPartnerExerciseUnlocked,
        () => exerciseLocked,
      ),
      either.filterOrElseW(
        (c) => !hasExistingSubmission(c.submissions),
        () => hasSubmissions,
      ),
      either.filterOrElseW(
        (c) => !hasExistingSubmission(c.partnerSubmissions),
        () => partnerHasSubmissions,
      ),
      either.chain(findEligibleAppointment(now)),
      either.map(
        (appointment): SignUpPairResult => ({
          submission: {
            kind: 'presentation',
            submissionDate: now,

            taskId: ctx.task._id,
            semester: ctx.task.semester,

            status: 'subscribed',
            appointmentId: appointment._id,
            studentId: ctx.user._id,
            partnerId: ctx.partner._id,
            organizedBy: ctx.user._id,

            result: null,
          },
          partnerSubmission: {
            kind: 'presentation',
            submissionDate: now,

            taskId: ctx.task._id,
            semester: ctx.task.semester,

            status: 'subscribed',
            appointmentId: appointment._id,
            studentId: ctx.partner._id,
            partnerId: ctx.user._id,
            organizedBy: ctx.user._id,

            result: null,
          },
          appointmentId: appointment._id,
        }),
      ),
    );
  };

export type SignUpResult = SignUpSoloResult | SignUpPairResult;

export const isSignUpPairResult = (r: SignUpResult): r is SignUpPairResult =>
  ('partnerSubmission' satisfies keyof SignUpPairResult) in r;

export const signUpStudentVariadic = (
  ctx: SignUpContext,
  now: Date,
): either.Either<SignupError, SignUpResult> =>
  isContextPair(ctx) ? signUpStudentPair(now)(ctx) : signUpStudentSolo(now)(ctx);
