import { iots } from '@code-expert/prelude';
import { AutosaveId, ProjectEntryKey, ProjectId } from '/imports/domain/identity';

export const AutosaveC = iots.struct(
  {
    _id: AutosaveId,
    projectId: ProjectId,
    fileKey: ProjectEntryKey,
  },
  {
    fileContent: iots.string,
  },
);

export type Autosave = iots.TypeOf<typeof AutosaveC>;
