import React, { useState } from 'react';

import { Button, Descriptions, Dropdown, Form, Radio, Space, Spin, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { CompareFn } from 'antd/es/table/interface';

import type { PermissionsInterface } from '@code-expert/pfs/dist/Permissions';
import {
  array,
  constant,
  date,
  either,
  flow,
  nonEmptyArray,
  option,
  ord,
  pipe,
  remoteEither,
  string,
  tagged,
  tree,
} from '@code-expert/prelude';
import { GuardEither, GuardOption } from '@code-expert/react';
import type { ProjectEntryKey, ProjectId, SnapshotId } from '/imports/domain';
import { Unauthorised } from '/imports/modules/Authorization/presentation/Unauthorised';
import type { ProjectEntries } from '/imports/modules/Project/application/rpc/entries';
import type { Project, ProjectEntry, Snapshot } from '/imports/modules/Project/domain';
import { getOriginId, getPublishedSnapshotId } from '/imports/modules/Project/domain';
import type { ProjectError } from '/imports/modules/Project/domain/shared';
import { buildTree, filterBySnapshotDate } from '/imports/modules/Project/domain/shared';
import { GuardRemoteEither } from '/imports/ui/components/GuardRemoteEither';
import { MainContent } from '/imports/ui/components/MainContent';
import { formatDateTime } from '/imports/ui/foundation/DateTime/format';
import { Icon } from '/imports/ui/foundation/Icons';
import { useTitle } from '/imports/ui/hooks/useTitle';
import { ContentPreview } from './ContentPreview';
import type { ProjectHooks } from './hooks';
import { Permissions } from './Permissions';
import { renderProjectError } from './shared';
import { VersionNav } from './VersionNav';

const formLayout = {
  labelCol: { span: 2 },
};

const projectIdLink = (projectId: ProjectId): React.ReactNode => (
  <Typography.Link code href={projectId} data-mask-in-tests>
    {projectId}
  </Typography.Link>
);

const projectLink = (project: Project): React.ReactNode => (
  <Typography.Text>
    {projectIdLink(project._id)} ({project.useCase})
  </Typography.Text>
);

const ProjectLink = ({
  projectId,
  hooks: { useProject },
}: {
  projectId: ProjectId;
  hooks: ProjectHooks;
}) => (
  <GuardRemoteEither
    value={useProject(projectId)}
    pending={() => <Spin size="small" />}
    alt={renderProjectError}
    render={projectLink}
  />
);

const ProjectsWithOrigin = ({
  projectId,
  hooks: { useWithOrigin },
}: {
  projectId: ProjectId;
  hooks: ProjectHooks;
}) => (
  <GuardRemoteEither
    value={useWithOrigin(projectId)}
    alt={({ value }) => <Unauthorised reason={value} />}
    pending={() => <Spin size="small" />}
    render={flow(
      nonEmptyArray.fromArray,
      option.fold(
        () => '–',
        nonEmptyArray.matchLeft((head, tail) =>
          array.isEmpty(tail)
            ? projectLink(head)
            : (() => (
                <Dropdown
                  menu={{
                    items: pipe(
                      array.prepend(head)(tail),
                      array.mapWithIndex((i, project) => ({
                        key: i,
                        label: projectLink(project),
                      })),
                    ),
                  }}
                >
                  <Button
                    type="link"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      Show list
                      <Icon name="angle-down" />
                    </Space>
                  </Button>
                </Dropdown>
              ))(),
        ),
      ),
    )}
  />
);

const ProjectBrowserInner = ({
  entries: { project, entries },
  snapshots,
  publishedSnapshotId,
  hooks,
}: {
  entries: ProjectEntries;
  snapshots: Array<Snapshot>;
  publishedSnapshotId: option.Option<SnapshotId>;
  hooks: ProjectHooks;
}) => {
  type Mode = tagged.Tagged<'tree'> | tagged.Tagged<'version'> | tagged.Tagged<'all'>;
  const modeAdt = tagged.build<Mode>();

  const [mode, setMode] = useState<Mode>(modeAdt.tree);
  const [snapshotDate, setSnapshotDate] = useState<option.Option<Date>>(option.none);

  type Node = Omit<ProjectEntry, '_id' | 'key' | 'children'> & {
    key: ProjectEntryKey;
    projectEntryKey: ProjectEntryKey;
    children?: Node[];
  };

  const field = <A,>(a?: A): React.ReactNode =>
    a == null ? null : <Typography.Text code>{String(a)}</Typography.Text>;

  const copyableField = (s?: string): React.ReactNode =>
    s == null ? null : (
      <Typography.Text code copyable data-mask-in-tests>
        {s}
      </Typography.Text>
    );

  const formatDate = formatDateTime('timestamp');

  const dateField = (d?: Date): React.ReactNode =>
    d == null ? null : (
      <Typography.Text
        code
        strong={pipe(snapshotDate, option.elem(date.Eq)(d)) && !modeAdt.is.all(mode)}
        data-mask-in-tests
      >
        {formatDate(d)}
      </Typography.Text>
    );

  const booleanField = (b?: boolean): React.ReactNode =>
    b === true ? <Icon name="circle-check" /> : null;

  const sortBy = <A,>(o: ord.Ord<A>, f: (_: Node) => A): CompareFn<Node> =>
    pipe(o, ord.contramap<A, Node>(f), ({ compare }) => compare);

  const sortByKey = sortBy(string.Ord, ({ projectEntryKey }) => projectEntryKey);
  const sortByName = sortBy(string.Ord, ({ name }) => name);
  const sortById = sortBy(string.Ord, ({ key }) => key);

  const renderPermissions = (permissions: PermissionsInterface) => (
    <Permissions {...{ permissions }} />
  );

  const columns: ColumnsType<Node> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sortByName,
      defaultSortOrder: 'ascend',
      render: field,
    },
    {
      title: 'Key',
      dataIndex: 'projectEntryKey',
      key: 'key',
      sorter: sortByKey,
      render: copyableField,
    },
    { title: 'ID', dataIndex: 'key', key: 'key', sorter: sortById, render: copyableField },
    { title: 'Type', dataIndex: 'type', key: 'type', render: field },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: renderPermissions,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateField,
    },
    {
      title: 'Current / Replaced at',
      dataIndex: ['current', 'replacedAt'],
      key: 'replacedAt',
      align: 'center',
      render: (_, { current, replacedAt }) => (
        <>
          {current && 'current'} {replacedAt != null && dateField(replacedAt)}
        </>
      ),
    },
    {
      title: 'Deleted',
      dataIndex: 'deleted',
      key: 'deleted',
      align: 'center',
      render: booleanField,
    },
    {
      title: 'Version',
      //onHeaderCell: () => ({ colSpan: 2 }),
      dataIndex: 'version',
      key: 'version',
      align: 'right',
      render: field,
    },
    { title: 'Origin key', dataIndex: 'originKey', key: 'originKey', render: copyableField },
    {
      title: 'Origin version',
      dataIndex: 'originVersion',
      key: 'originVersion',
      align: 'right',
      render: field,
    },
    {
      title: 'Origin deleted',
      dataIndex: 'originDeleted',
      key: 'originDeleted',
      align: 'center',
      render: booleanField,
    },
    {
      render: (_, { projectEntryKey: key, type, version }) =>
        type === 'inode/directory' ? null : (
          <ContentPreview hooks={hooks} projectId={project._id} fileKey={key} version={version} />
        ),
    },
  ];

  const entry2node = ({
    _id,
    key,
    children,
    ...rest
  }: ProjectEntry & { children?: Array<string> }): Node => ({
    ...rest,
    key: _id,
    projectEntryKey: key,
  });

  /**
   * Converts an fp-ts forest of ProjectEntries to an antd table tree of Nodes.
   */
  const convertTree: (_: tree.Tree<ProjectEntry>) => Node = tree.fold<ProjectEntry, Node>(
    (entry, children) => ({
      ...entry2node(entry),
      ...(array.isEmpty(children) ? {} : { children }),
    }),
  );

  type GetNodes = () => either.Either<ProjectError, Array<Node>>;

  /**
   * Get an fp-ts forest of ProjectEntries for the current snapshot date.
   */
  const getProjectTree = (): either.Either<ProjectError, tree.Tree<ProjectEntry>> =>
    buildTree(project.rootDirKey, entries, snapshotDate);

  /**
   * Get an antd table tree of nodes for the current snapshot date.
   */
  const getTree: GetNodes = () => pipe(getProjectTree(), either.map(flow(convertTree, array.of)));

  /**
   * Get a list of nodes for the current snapshot date.
   */
  const getVersioned: GetNodes = () =>
    pipe(
      entries,
      array.filter(filterBySnapshotDate(snapshotDate)),
      array.map(entry2node),
      either.of,
    );

  /**
   * Get a list of all nodes regardless of the date.
   */
  const getAll: GetNodes = () =>
    pipe(
      entries,
      array.map((entry) => ({ ...entry2node(entry), children: undefined })),
      either.of,
    );

  const nodesE = modeAdt.fold(mode, {
    tree: getTree,
    version: getVersioned,
    all: getAll,
  });

  return (
    <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
      <Descriptions layout="vertical" column={100} bordered size="small">
        <Descriptions.Item label="Project">
          {copyableField(project._id)} ({field(project.useCase)})
        </Descriptions.Item>
        <Descriptions.Item label="Name">{project.name}</Descriptions.Item>
        <Descriptions.Item label="Origin project">
          <GuardOption
            value={getOriginId(project)}
            alt={() => '–'}
            render={(projectId) => <ProjectLink {...{ projectId, hooks }} />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Derived projects">
          <ProjectsWithOrigin {...{ projectId: project._id, hooks }} />
        </Descriptions.Item>
        <Descriptions.Item label="Forked from">
          <GuardOption
            value={option.fromNullable(project.forked)}
            alt={() => '–'}
            render={({ source }) => <ProjectLink projectId={source} {...{ hooks }} />}
          />
        </Descriptions.Item>
      </Descriptions>
      <Form {...formLayout}>
        <Form.Item label="Display">
          <Radio.Group
            onChange={(e) => {
              switch (e.target.value) {
                case 'tree':
                  setMode(modeAdt.tree);
                  break;
                case 'version':
                  setMode(modeAdt.version);
                  break;
                case 'all':
                  setMode(modeAdt.all);
                  break;
              }
            }}
            value={modeAdt.fold(mode, {
              tree: constant('tree'),
              version: constant('version'),
              all: constant('all'),
            })}
          >
            <Radio value="tree">Tree (versioned)</Radio>
            <Radio value="version">List (versioned)</Radio>
            <Radio value="all">List (all)</Radio>
          </Radio.Group>
        </Form.Item>
        {!modeAdt.is.all(mode) && (
          <VersionNav {...{ entries, snapshots, publishedSnapshotId, setSnapshotDate }} />
        )}
      </Form>
      <GuardEither
        value={nodesE}
        alt={renderProjectError}
        render={(dataSource) => (
          <Table
            expandable={{ defaultExpandAllRows: true }}
            pagination={false}
            size="small"
            {...{ columns, dataSource }}
          />
        )}
      />
    </Space>
  );
};

type Props = {
  hooks: ProjectHooks;
  projectId: ProjectId;
};

export const ProjectBrowser = ({ hooks, projectId }: Props) => {
  useTitle('Project browser');
  return (
    <MainContent title="Project browser">
      <GuardRemoteEither
        value={remoteEither.sequenceS({
          entries: hooks.useEntries(projectId),
          snapshots: hooks.useSnapshots(projectId),
          publishedSnapshotId: pipe(
            hooks.useProject(projectId),
            remoteEither.map(getPublishedSnapshotId),
          ),
        })}
        alt={renderProjectError}
        render={(data) => <ProjectBrowserInner {...data} {...{ hooks }} />}
      />
    </MainContent>
  );
};
