import type { Phase, Roles } from '@code-expert/pfs/dist/Permissions';
import { adt, date, iots, ord } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import { PfsRoleC } from '/imports/domain/user';
import type { RunnerActions } from './environment';
import type { GroupId } from './identity';
import {
  CourseId,
  ExerciseId,
  FileId,
  JobId,
  ProjectId,
  SemesterId,
  ServiceJobId,
  SnapshotId,
  TaskId,
  UserId,
} from './identity';

export interface JobState {
  created: Date;
  fetched?: Date;
  queued?: Date;
  rejected?: Date;
  started?: Date;
  done?: Date;
  canceled?: Date;
  terminated?: Date;
}

export interface Job {
  _id: JobId;
  projectId: ProjectId;
  snapshotId: SnapshotId;
  userId: UserId;
  hostName?: string;
  port?: number;
  dockerImageRepoDigest?: string;
  action: RunnerActions;
  persistStreams?: boolean;
  runnerName?: string;
  streamLog?: string;
  state: JobState;

  failureReason?: string;
  exitCode?: string;
  taskId?: string;
  envVars?: Record<string, string | undefined>;
}

const ActionC = iots.keyof({
  compile: null,
  run: null,
  repl: null,
  test: null,
  submit: null,
});

export const JobC = iots.struct(
  {
    _id: JobId,
    projectId: ProjectId,
    snapshotId: SnapshotId,
    userId: UserId,
    action: ActionC,
    state: iots.struct(
      {
        created: iots.date,
      },
      {
        fetched: iots.date,
        queued: iots.date,
        rejected: iots.date,
        started: iots.date,
        done: iots.date,
        canceled: iots.date,
        terminated: iots.date,
      },
    ),
  },
  {
    hostName: iots.string,
    dockerImageRepoDigest: iots.string,
    persistStreams: iots.boolean,
    runnerName: iots.string,
    streamLog: iots.string,
    failureReason: iots.string,
    exitCode: iots.string,
    taskId: iots.string,
    envVars: iots.record(iots.string, iots.union([iots.string, iots.undefined])),
  },
) satisfies iots.Type<Job, unknown>;

const serviceJobFailureReasonKeys = {
  UNEXPECTED_ERROR: null,
  NO_DATA: null,
  UNKNOWN_JOB: null,
  SYSTEM_RECOVER: null,
  USER_WRONG_PARAMETERS: null,
  LIMIT_TERMINATED: null,
  CONTAINER_TIMEOUT: null,
  WRONG_IMPORT_FILE: null,
  WRONG_VERSION_IMPORT: null,
};

export const foldServiceJobFailure = adt.foldFromKeys(serviceJobFailureReasonKeys);
export type ServiceJobFailureReason = adt.TypeOfKeys<typeof foldServiceJobFailure>;

const ServiceJobFailureReasonC = iots.keyof(serviceJobFailureReasonKeys);

export type ServiceJob = JobType & {
  _id: ServiceJobId;
  userId: UserId;
  allHosts?: boolean;
  originServiceJobId?: ServiceJobId;
  managedBy?: string;
  state: {
    created: Date;
    fetched?: Date;
    started?: Date;
    done?: Date;
    rejected?: Date;
    canceled?: Date;
    terminated?: Date;
  };
  result?: {
    fileId?: string;
    validUntil?: Date;
  };
  failureReason?: ServiceJobFailureReason;
  progress?: number;
};

export type JobType =
  | {
      action: 'updateEnvironments';
      params: {
        slug?: string;
        cxwebVersion?: string;
      };
    }
  | {
      action: 'courseBackup';
      params: {
        courseUrl: string;
        semester: SemesterId;
        cxwebVersion: string;
      };
    }
  | {
      action: 'exportExercise';
      params: {
        exerciseId?: ExerciseId;
        cxwebVersion?: string;
      };
    }
  | {
      action: 'exportTask';
      params: {
        taskId?: TaskId;
        cxwebVersion?: string;
      };
    }
  | {
      action: 'userProjectExport';
      params: {
        cxwebVersion?: string;
      };
    }
  | {
      action: 'importTaskOrExercise';
      params: {
        exerciseId?: ExerciseId;
        courseId?: CourseId;
        fileId?: FileId;
        userId?: UserId;
        taskId?: TaskId; // todo cx-1237-stage2 not actually used in runner...?
        semester?: SemesterId;
        type: 'exercise' | 'code_example';
        cxwebVersion?: string;
      };
    }
  | {
      action: 'importProject';
      params: {
        projectId?: ProjectId;
        fileId?: FileId;
        userId?: UserId;
        options?: 'replace';
        cxwebVersion?: string;
      };
    }
  | {
      action: 'exportProject';
      params: {
        projectId?: ProjectId;
        role?: Roles;
        phase?: Phase;
        snapshotDate?: Date;
        cxwebVersion?: string;
      };
    }
  | {
      action: 'taskProjectExport';
      params: {
        courseUrl: string;
        semester: SemesterId;
        taskId: TaskId;
        groupId?: GroupId;
        options: 'graded' | 'all';
        excludedPaths?: Array<string>;
        cxwebVersion?: string;
      };
    }
  | {
      action: 'courseProjectExport';
      params: {
        courseUrl?: string;
        semester?: SemesterId;
        options?: 'graded' | 'all';
        cxwebVersion?: string;
      };
    };

const serviceJobStrictProps = {
  _id: ServiceJobId,
  userId: UserId,
  state: iots.struct(
    {
      created: iots.date,
    },
    {
      fetched: iots.date,
      started: iots.date,
      done: iots.date,
      rejected: iots.date,
      canceled: iots.date,
      terminated: iots.date,
    },
  ),
};

const serviceJobPartialProps = {
  allHosts: iots.boolean,
  originServiceJobId: ServiceJobId,
  managedBy: iots.string,
  result: iots.exact(
    iots.partial({
      fileId: iots.string,
      validUntil: iots.date,
    }),
  ),
  failureReason: ServiceJobFailureReasonC,
  progress: iots.number,
};

const UpdateEnvironments = iots.struct(
  {
    action: iots.literal('updateEnvironments'),
    params: iots.exact(
      iots.partial({
        slug: iots.string,
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const CourseBackup = iots.struct(
  {
    action: iots.literal('courseBackup'),
    params: iots.strict({
      courseUrl: iots.string,
      semester: SemesterId,
      cxwebVersion: iots.string,
    }),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const ExportExercise = iots.struct(
  {
    action: iots.literal('exportExercise'),
    params: iots.exact(
      iots.partial({
        exerciseId: ExerciseId,
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const ExportTask = iots.struct(
  {
    action: iots.literal('exportTask'),
    params: iots.exact(
      iots.partial({
        taskId: TaskId,
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const UserProjectExport = iots.struct(
  {
    action: iots.literal('userProjectExport'),
    params: iots.exact(
      iots.partial({
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const ImportTaskOrExercise = iots.struct(
  {
    action: iots.literal('importTaskOrExercise'),
    params: iots.struct(
      {
        type: iots.keyof({ exercise: null, code_example: null }),
      },
      {
        exerciseId: ExerciseId,
        courseId: CourseId,
        fileId: FileId,
        userId: UserId,
        taskId: TaskId, // todo cx-1237-stage2 not actually used in runner...?
        semester: SemesterId,
        cxwebVersion: iots.string,
      },
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const ImportProject = iots.struct(
  {
    action: iots.literal('importProject'),
    params: iots.exact(
      iots.partial({
        projectId: ProjectId,
        fileId: FileId,
        userId: UserId,
        options: iots.literal('replace'),
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

export const PhaseC = iots.keyof({
  interactive: null,
  submission: null,
});

const ExportProject = iots.struct(
  {
    action: iots.literal('exportProject'),
    params: iots.exact(
      iots.partial({
        projectId: ProjectId,
        role: PfsRoleC,
        phase: PhaseC,
        snapshotDate: iots.date,
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const TaskProjectExport = iots.struct(
  {
    action: iots.literal('taskProjectExport'),
    params: iots.struct(
      {
        courseUrl: iots.string,
        semester: SemesterId,
        taskId: TaskId,
        options: iots.keyof({ graded: null, all: null }),
      },
      {
        excludedPaths: iots.array(iots.string),
        cxwebVersion: iots.string,
      },
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

const CourseProjectExport = iots.struct(
  {
    action: iots.literal('courseProjectExport'),
    params: iots.exact(
      iots.partial({
        courseUrl: iots.string,
        semester: SemesterId,
        options: iots.keyof({ graded: null, all: null }),
        cxwebVersion: iots.string,
      }),
    ),
    ...serviceJobStrictProps,
  },
  serviceJobPartialProps,
);

export const ServiceJobC = iots.union([
  UpdateEnvironments,
  CourseBackup,
  ExportExercise,
  ExportTask,
  UserProjectExport,
  ImportTaskOrExercise,
  ImportProject,
  ExportProject,
  TaskProjectExport,
  CourseProjectExport,
]) satisfies iots.Type<ServiceJob, unknown>;

export const serviceJobPublicFields = {
  _id: 1,
  state: 1,
  userId: 1,
  action: 1,
  result: 1,
  progress: 1,
  params: 1,
  failureReason: 1,
};

export type ServiceJobPublic = DistributivePick<ServiceJob, keyof typeof serviceJobPublicFields>;

export const serviceJobCreatedOrd = ord.contramap(
  (serviceJob: Pick<ServiceJob, 'state'>) => serviceJob.state.created,
)(date.Ord);

export const ExerciseImportBackupProps = iots.struct(
  {
    fileId: FileId,
    courseId: CourseId,
    semester: SemesterId,
    type: iots.union([iots.literal('exercise'), iots.literal('code_example')]),
  },
  {
    exerciseId: ExerciseId,
  },
);

export type ExerciseImportBackupProps = iots.TypeOf<typeof ExerciseImportBackupProps>;

export const ProjectImportBackupProps = iots.struct(
  {
    fileId: FileId,
    projectId: ProjectId,
  },
  {
    options: iots.literal('replace'),
  },
);

export type ProjectImportBackupProps = iots.TypeOf<typeof ProjectImportBackupProps>;
