import React, { useEffect } from 'react';

import { App, Typography } from 'antd';
import type { ModalFunc } from 'antd/es/modal/confirm';

import { useGlobalContext } from '/imports/ui/components/GlobalContext';

function LockedUsers() {
  const { user } = useGlobalContext();
  const { modal: modalApi } = App.useApp();
  const modal = React.useRef<ReturnType<ModalFunc> | null>(null);

  useEffect(() => {
    if (user != null && user.locked) {
      if (modal.current == null) {
        modal.current = modalApi.info({
          title: 'Your account is temporarily locked.',
          maskClosable: false,
          closable: false,
          zIndex: 100000,
          footer: null,
          keyboard: false,
          content: (
            <div>
              <Typography.Paragraph>
                Please wait a few minutes as this lock will be be removed soon.
              </Typography.Paragraph>
              <Typography.Paragraph type="secondary">
                This is due to automatic maintenance on your user account. After the maintenance is
                finished everything should work again.
              </Typography.Paragraph>
            </div>
          ),
        });
      }
    } else {
      modal.current?.destroy();
      modal.current = null;
    }
  }, [modalApi, user]);

  return null;
}

export default LockedUsers;
