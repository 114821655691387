import { genEntityId, mkEntityIdCodec } from '@code-expert/mongo-ts/identity';
import type { eq, io, monoid } from '@code-expert/prelude';
import { iots, number, ord, string } from '@code-expert/prelude';
import type { AuthStrategy } from '/imports/api/session/domain';
import type { UserId } from '/imports/domain/identity';

export type OnlineStatus = 'active' | 'away';

export const OnlineStatus = iots.keyof({ active: null, away: null });

export const onlineStatusEq: eq.Eq<OnlineStatus> = string.Eq;

export const onlineStatusOrd: ord.Ord<OnlineStatus> = ord.contramap(
  (x: OnlineStatus) => ({ away: 0, active: 1 })[x],
)(number.Ord);

export const onlineStatusMonoid: monoid.Monoid<OnlineStatus> = {
  empty: 'away',
  concat: ord.max(onlineStatusOrd),
};

export interface LocationBrand {
  readonly Location: unique symbol;
}

export const Location = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, LocationBrand> => string.isString(s),
  'Location',
);

export type Location = iots.TypeOf<typeof Location>;

export const ordLocation: ord.Ord<Location> = string.Ord;

const ConnectionIdBrand = Symbol('ConnectionId');
export const ConnectionId = mkEntityIdCodec(ConnectionIdBrand);
export type ConnectionId = iots.TypeOf<typeof ConnectionId>;
export const connectionIdEq: eq.Eq<ConnectionId> = string.Eq;

export interface Connection {
  _id: ConnectionId;
  userId: UserId;
  firstname: string;
  lastname: string;
  authStrategy: AuthStrategy;
  location: Location;
  status: OnlineStatus;
  updatedAt: Date;
}

export const genConnectionId: io.IO<ConnectionId> = genEntityId;
