import { array, iots, pipe, struct } from '@code-expert/prelude';
import type { SemesterId } from '/imports/domain/identity';
import { CourseId, CourseUrl, ProjectId, UserId } from '/imports/domain/identity';
import { SemesterC } from '/imports/domain/semester';
import { SwitchOrganisationUrlC } from '/imports/modules/SwitchOrganisations/domain/SwitchOrganisation';
import { CherryPickMechanismC, DEFAULT_CHERRY_PICK_MECHANISM } from './grading';

export interface CourseNameBrand {
  readonly CourseName: unique symbol;
}

export const CourseNameC = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, CourseNameBrand> => s.length <= 80,
  'CourseName',
);
export type CourseName = iots.TypeOf<typeof CourseNameC>;

export const CourseAppointmentsC = iots.strict({
  active: iots.boolean,
  /** Allow multiple students to sign up for the same appointment. Default: false */
  allowMultiple: iots.boolean,
  /** Disable feedback from Students to TAs. Default: false */
  disableTAFeedback: iots.boolean,
});

const courseStrictProps = {
  name: CourseNameC,
  url: CourseUrl,
  adminIds: iots.nonEmptyArray(UserId),
  analytics: iots.struct(
    {
      active: iots.boolean,
    },
    {
      displayedData: iots.withFallback(iots.array(iots.string), ['score']),
    },
  ),
  cherryPickMechanism: iots.withFallback(CherryPickMechanismC, DEFAULT_CHERRY_PICK_MECHANISM),
  codeExamples: iots.boolean,
  messages: iots.struct({ active: iots.boolean }, { hint: iots.string }),
  codeExpertSync: iots.strict({ active: iots.boolean }),
  acceptedAffiliations: iots.array(SwitchOrganisationUrlC),
  appointments: CourseAppointmentsC,
  lti: iots.strict({ active: iots.boolean }),
  semesters: iots.nonEmptyArray(SemesterC),
};

const coursePartialProps = {
  mainAdminId: UserId,
  defaultEnvironmentProject: ProjectId,
  examSettings: iots.strict({
    ide: iots.strict({
      showFilePane: iots.boolean,
      showHTMLPane: iots.boolean,
      showTigerPythonPane: iots.boolean,
    }),
  }),
};

export const CourseWriteC = iots.struct(courseStrictProps, coursePartialProps);

export type CourseWrite = iots.TypeOf<typeof CourseWriteC>;

export const CourseC = iots.struct({ _id: CourseId, ...courseStrictProps }, coursePartialProps);
export const CourseSettingsC = iots.exact(
  iots.partial(
    pipe(
      {
        ...courseStrictProps,
        ...coursePartialProps,
      },
      struct.pick([
        'adminIds',
        'analytics',
        'codeExamples',
        'messages',
        'defaultEnvironmentProject',
        'appointments',
        'semesters',
        'lti',
        'codeExamples',
        'codeExpertSync',
        'acceptedAffiliations',
      ]),
    ),
  ),
);

export type CourseSettings = iots.TypeOf<typeof CourseSettingsC>;

export type CourseC = typeof CourseC;
export type Course = iots.TypeOf<CourseC>;

export const coursesPublicFields = {
  _id: 1,
  name: 1,
  url: 1,
  adminIds: 1,
  analytics: 1,
  acceptedAffiliations: 1,
  cherryPickMechanism: 1,
  codeExamples: 1,
  defaultEnvironmentProject: 1,
  mainAdminId: 1,
  messages: 1,
  appointments: 1,
  codeExpertSync: 1,
  semesters: 1,
  lti: 1,
} as const;

export type CoursePublicFields = keyof typeof coursesPublicFields;
export type CoursePublic = Pick<Course, CoursePublicFields>;

export const isEnrollmentOpen = (
  course: Pick<Course, 'semesters'>,
  semester: SemesterId,
): boolean =>
  pipe(
    course.semesters,
    array.exists(({ id, enrollmentOpen }) => id === semester && enrollmentOpen),
  );

export const isCourseAdmin = (course: Pick<Course, 'adminIds'>, userId: UserId): boolean =>
  course.adminIds.includes(userId);
