import type { option } from '@code-expert/prelude';
import { tagged } from '@code-expert/prelude';

// -------------------------------------------------------------------------------------------------
// Types

/** No work has been done on this task */
type None = tagged.Tagged<'none'>;

/** The task has been submitted, but no definitive mark has been assigned */
type Pending = tagged.Tagged<'pending', option.Option<number>>;

/** The task was marked but no attempt was found that passed */
type Failed = tagged.Tagged<
  'failed',
  /** A value in the range [0, 1] */
  number
>;

/** The task was completed successfully */
type Success = tagged.Tagged<
  'success',
  /** A value in the range [0, 1] */
  number
>;

/** The overall progress of a task, derived from exercise, task, and submissions */
export type TaskProgress = None | Pending | Failed | Success;

export const taskProgress = tagged.build<TaskProgress>();

// -------------------------------------------------------------------------------------------------
// Functions
