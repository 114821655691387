import {
  adt,
  constFalse,
  constTrue,
  date,
  iots,
  ord,
  refinement,
  string,
} from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import { CourseId, ExerciseId, SemesterId, TaskId } from '/imports/domain/identity';
import { ExerciseVisibility, foldExerciseVisibility } from './ExerciseVisibility';

export const foldTaskCollectionPartial = adt.foldFromProp('type');

export const foldTaskCollection = adt.foldFromTags<TaskCollection, 'type'>('type');

export const foldTaskCollectionType = adt.foldFromKeys({
  exercise: null,
  code_example: null,
  exam: null,
});

export interface BaseTaskCollection {
  readonly _id: ExerciseId;
  readonly name: string;
  readonly courseId: CourseId;
  readonly semester: SemesterId;
  taskIds: Array<TaskId>;
}

export interface Exercise extends BaseTaskCollection {
  type: 'exercise';
  dueDate: Date;
  public: ExerciseVisibility;
  solutionPublic: ExerciseVisibility;

  publishDate?: Date;
  handoutDate?: Date;
  solutionDate?: Date;

  conditional?: boolean;
  prerequisites?: NonEmptyArray<ExerciseId>;
  requiredXP?: number;

  copiedFrom?: ExerciseId;
}

export interface Example extends BaseTaskCollection {
  type: 'code_example';
  public: ExerciseVisibility;
  solutionPublic: ExerciseVisibility;

  handoutDate?: Date;
  solutionDate?: Date;

  copiedFrom?: ExerciseId;
}

export interface ExamTasks extends BaseTaskCollection {
  type: 'exam';
  public: 'no';
  solutionPublic: ExerciseVisibility;
}

export type TaskCollection = Exercise | Example | ExamTasks;

// -------------------------------------------------------------------------------------------------
// Codec

const baseTaskCollection = {
  _id: ExerciseId,
  name: iots.string,
  courseId: CourseId,
  semester: SemesterId,
  taskIds: iots.array(TaskId),
} satisfies Record<keyof BaseTaskCollection, unknown>;

export const Exercise = iots.struct(
  {
    ...baseTaskCollection,
    type: iots.literal('exercise'),
    dueDate: iots.date,
    public: ExerciseVisibility,
    solutionPublic: ExerciseVisibility,
  },
  {
    publishDate: iots.date,
    handoutDate: iots.date,
    solutionDate: iots.date,

    conditional: iots.boolean,
    prerequisites: iots.nonEmptyArray(ExerciseId),
    requiredXP: iots.number,

    copiedFromId: ExerciseId,
  },
) satisfies iots.Type<Exercise, unknown>;

export const Example = iots.struct(
  {
    ...baseTaskCollection,
    type: iots.literal('code_example'),
    public: ExerciseVisibility,
    solutionPublic: ExerciseVisibility,
  },
  {
    handoutDate: iots.date,
    solutionDate: iots.date,
    copiedFromId: ExerciseId,
  },
) satisfies iots.Type<Example, unknown>;

export const ExamTasks = iots.strict({
  ...baseTaskCollection,
  type: iots.literal('exam'),
  public: iots.literal('no'),
  solutionPublic: ExerciseVisibility,
}) satisfies iots.Type<ExamTasks, unknown>;

export const TaskCollection = iots.union([Exercise, Example, ExamTasks]) satisfies iots.Type<
  TaskCollection,
  unknown
>;

// -------------------------------------------------------------------------------------------------
// Ord

export const ordTaskCollection: ord.Ord<
  DistributivePick<TaskCollection, '_id' | 'handoutDate' | 'dueDate'>
> = ord.contramap(
  (x: { _id: ExerciseId; handoutDate?: Date; dueDate?: Date }) => x.dueDate ?? x.handoutDate,
)(date.ordNullable);

export const ordExerciseHandoutDate = ord.contramap(
  (e: DistributivePick<TaskCollection, 'handoutDate'>) => e.handoutDate,
)(date.ordNullable);

export const ordExerciseDueDate: ord.Ord<DistributivePick<TaskCollection, '_id' | 'dueDate'>> =
  ord.contramap((e: { _id: ExerciseId; dueDate?: Date }) => e.dueDate)(date.ordNullable);

export const ordExerciseName = ord.contramap(
  (x: DistributivePick<TaskCollection, 'name'>) => x.name,
)(string.OrdLocale);

export const ordCodeExample = ord.concatAll<DistributivePick<Example, 'handoutDate' | 'name'>>(
  ordExerciseHandoutDate,
  ordExerciseName,
);

export const ordExercise = ord.concatAll<
  DistributivePick<TaskCollection, '_id' | 'dueDate' | 'handoutDate' | 'name'>
>(ordExerciseDueDate, ordExerciseName);

// -------------------------------------------------------------------------------------------------

export const {
  code_example: isExample,
  exam: isExamTasks,
  exercise: isExercise,
} = adt.refinementFromProp<TaskCollection, 'type'>('type');

export const requireExercise = refinement.invariant(isExercise, () => 'Expected exercise');

// -------------------------------------------------------------------------------------------------

const checkVisibility =
  (now: Date) =>
  (visibility: ExerciseVisibility, date?: Date): boolean =>
    foldExerciseVisibility(visibility, {
      no: constFalse,
      yes: constTrue,
      auto: () => date != null && now >= date,
    });

/**
 * Determine whether a task collection is visible to all users.
 *
 * @see {@link getExerciseTaskState}
 */
export const isTaskCollectionVisible = (
  now: Date,
  taskCollection: DistributivePick<
    TaskCollection,
    'type' | 'publishDate' | 'handoutDate' | 'public'
  >,
): boolean =>
  foldTaskCollectionPartial(taskCollection, {
    exam: constTrue,
    code_example: (x) => checkVisibility(now)(x.public, x.handoutDate),
    exercise: (x) =>
      checkVisibility(now)(x.public, x.publishDate) ||
      checkVisibility(now)(x.public, x.handoutDate),
  });

/**
 * Determine whether solutions within a {@link TaskCollection} are visible to all users.
 *
 * @see {@link getExerciseSolutionState}
 */
export const isSolutionVisible = (
  now: Date,
  exercise: DistributivePick<
    TaskCollection,
    'type' | 'handoutDate' | 'public' | 'solutionDate' | 'solutionPublic'
  >,
): boolean =>
  isTaskCollectionVisible(now, exercise) &&
  foldTaskCollectionPartial(exercise, {
    exam: ({ solutionPublic }) =>
      foldExerciseVisibility(solutionPublic, {
        no: constFalse,
        yes: constTrue,
        auto: constTrue,
      }),
    code_example: (x) => checkVisibility(now)(x.solutionPublic, x.solutionDate),
    exercise: (x) => checkVisibility(now)(x.solutionPublic, x.solutionDate),
  });

/**
 * Determine whether solutions within a {@link TaskCollection} are visible to LTI users.
 *
 * @see {@link getExerciseSolutionState}
 */
export const isSolutionVisibleForLTI = (
  now: Date,
  exercise: DistributivePick<
    TaskCollection,
    'type' | 'handoutDate' | 'public' | 'solutionDate' | 'solutionPublic'
  >,
): boolean =>
  foldTaskCollectionPartial(exercise, {
    exam: constFalse,
    code_example: constFalse,
    exercise: (x) => checkVisibility(now)(x.solutionPublic, x.solutionDate),
  });
