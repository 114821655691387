import type React from 'react';

// eslint-disable-next-line no-restricted-imports
import type { Match, Parser } from 'fp-ts-routing';
// eslint-disable-next-line no-restricted-imports
import { Route as ftrRoute, zero as ftrZero, getParserMonoid, parse } from 'fp-ts-routing';

import { array, identity, pipe } from '@code-expert/prelude';

// eslint-disable-next-line no-restricted-imports
export { end, int, lit, query, str, succeed, type, Match, Parser } from 'fp-ts-routing';

export * as parser from './parser';

export const zero = () => ftrZero<React.JSX.Element>();

type UnwrapMatch<M> = M extends Match<infer A> ? A : never;

type IntersectionNeutralElement = {}; // eslint-disable-line @typescript-eslint/no-empty-object-type

type CombinedMatches<MS extends Array<unknown>, Acc = IntersectionNeutralElement> = MS extends [
  infer Head,
  ...infer Tail,
]
  ? CombinedMatches<Tail, UnwrapMatch<Head> & Acc>
  : Match<Acc>;

export function url<MS extends [Match<$IntentionalAny>, ...Array<Match<$IntentionalAny>>]>(
  ...matches: MS
): CombinedMatches<MS>;
export function url(
  ...ms: NonEmptyArray<Match<IntersectionNeutralElement>>
): Match<IntersectionNeutralElement> {
  const [head, ...tail] = ms;
  return tail.reduce((m, x) => m.then(x), head);
}

export function formatRoute<A>(route: Match<A>, encode = true) {
  return (a: A): string => route.formatter.run(ftrRoute.empty, a).toString(encode);
}

export function concatRoutes<A>(routes: Array<Parser<A>>): Parser<A> {
  return pipe(routes, array.foldMap(getParserMonoid<A>())(identity));
}

export function runRoute(
  router: Parser<React.JSX.Element>,
  pathname: string,
  fallback: React.JSX.Element,
): React.JSX.Element {
  return parse(router, ftrRoute.parse(pathname), fallback);
}
