import React from 'react';

import { Layout, Menu } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';
import { Link, matchPath, useLocation } from 'react-router-dom';

import logoSvgInverted from '/assets/logo-inverted.svg';
import logoSvg from '/assets/logo.svg';
import { isProduction } from '/imports/config/client';
import { useGlobalContext } from '/imports/ui/components/GlobalContext';
import { ThemeSwitch } from '/imports/ui/components/ThemeSwitch';
import { Icon } from '/imports/ui/foundation/Icons';
import { css, important, isLight, styled, useTheme } from '/imports/ui/foundation/Theme';
import { getUserMenu } from '/imports/ui/layouts/getUserName';

function usePageParams() {
  const { pathname } = useLocation();
  const {
    params: { page },
  } = matchPath<{ page: string }>(pathname, [`/:page/:semester/:courseUrl/*`, `/:page`]) || {
    params: {},
  };
  return page;
}

const StyledLayoutHeader = styled(Layout.Header, ({ tokens }) => ({
  '@media print': {
    display: 'none',
  },
  '&.ant-layout-header': {
    boxSizing: 'content-box',
  },
  padding: '0 !important',
  variants: {
    headerColor: {
      Black: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderBlack}` },
      Red: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderRed}` },
      Gold: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderGold}` },
      Green: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderGreen}` },
      Blue: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderBlue}` },
      Magenta: { boxShadow: `inset 0 -2px 0 0 ${tokens.colorHeaderMagenta}` },
    },
  },
}));

const StyledMenu = styled(Menu, ({ tokens }) => ({
  background: 'none',
  borderBottom: 'none',
  fontSize: tokens.fontSizeLG,
  /* Increase the overflow menu item's click target */
  '.ant-menu-overflow-item-rest': {
    marginLeft: 'auto',
    paddingInline: 0,
    '& > *': {
      paddingInline: tokens.padding,
    },
  },
}));

/**
 * This targets the popup menu that is rendered in a React portal, so can't be targeted
 * by the menu's class hierarchy directly.
 */
const useMenuPopupStyles = css(() => ({
  '&.ant-menu-submenu': {
    '.ant-menu-item': {
      position: 'relative',
      ':is(a, button)::after': {
        /* Make the whole menu item a click target */
        position: 'absolute',
        inset: 0,
        zIndex: 1,
        content: "' '",
      },
    },
  },
}));

const useLogoStyles = css(() => ({
  display: 'grid !important',
  placeItems: 'center',
  width: 238,
  marginInlineStart: important('-2px'),
}));

export function TopNavLayoutWrapper({
  menu,
  children,
}: React.PropsWithChildren<{ menu: ItemType[] }>) {
  const { headerColor } = useGlobalContext();
  const { colorScheme } = useTheme();
  const page = usePageParams();
  const popupClassName = useMenuPopupStyles();

  const logoClassName = useLogoStyles();
  const logoPath = isLight(colorScheme) ? logoSvg : logoSvgInverted;

  return (
    <Layout style={{ minHeight: '100%' }}>
      <StyledLayoutHeader headerColor={headerColor}>
        <StyledMenu
          mode="horizontal"
          rootClassName={popupClassName}
          overflowedIndicator={<Icon name="bars" />}
          selectedKeys={page != null ? [page] : []}
          triggerSubMenuAction="click"
          items={[
            {
              key: 'cxLayoutOverview',
              className: logoClassName,
              label: (
                <Link to="/">
                  <img src={logoPath} height="50" alt="Code Expert" />
                </Link>
              ),
            },
            ...menu,
          ]}
        />
      </StyledLayoutHeader>
      {children}
    </Layout>
  );
}

export function TopNavLayout({
  disableLogin,
  children,
}: React.PropsWithChildren<{ disableLogin?: boolean }>) {
  const { user, userCapabilities, examMode } = useGlobalContext();

  return (
    <TopNavLayoutWrapper
      menu={[
        userCapabilities.includes('enrolled')
          ? {
              key: 'enrolled',
              label: <Link to="/enrolled">Courses</Link>,
            }
          : null,
        userCapabilities.includes('groupAdmin')
          ? {
              key: 'mygroups',
              label: <Link to="/mygroups">Exercise groups</Link>,
            }
          : null,
        userCapabilities.includes('courseAdmin')
          ? {
              key: 'mycourses',
              label: <Link to="/mycourses">Course management</Link>,
            }
          : null,
        examMode && userCapabilities.includes('courseAdmin')
          ? {
              key: 'myexams',
              label: <Link to="/myexams">Exams</Link>,
            }
          : null,
        userCapabilities.includes('admin')
          ? {
              key: 'admin',
              label: <Link to="/admin">Admin</Link>,
            }
          : null,
        user
          ? {
              ...getUserMenu(user),
              style: {
                marginLeft: 'auto',
              },
            }
          : null,
        !user && !disableLogin
          ? {
              key: 'login',
              label: <Link to={isProduction ? '/login' : '/loginDemo'}>Login</Link>,
              style: { marginLeft: 'auto' },
            }
          : null,
        {
          key: 'themeSwitch',
          label: <ThemeSwitch />,
        },
      ]}
    >
      {children}
    </TopNavLayoutWrapper>
  );
}

export function TopNavLayoutNoLogin(props: React.PropsWithChildren) {
  return <TopNavLayout {...props} disableLogin />;
}
