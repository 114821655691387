import type { ThemeConfig } from 'antd';
import { theme } from 'antd';
import deepmerge from 'deepmerge';

import type { MapToken, SeedToken } from '/imports/lib/antd';
import type { Palettes } from './colors';
import { genNamedColorTokens, getAlphaColor, getDarkerColor } from './colors';
import type { DesignTokens, PublicAliasToken } from './DesignTokens';
import { defaultDesignTokens, defaultThemeConfig } from './Theme';

// -------------------------------------------------------------------------------------------------
// Palettes

const palettes: Palettes = {
  background: '#ffffff',
  gray: {
    1: '#f8f8f7',
    2: '#dfdedb',
    3: '#c7c4be',
    4: '#afaba3',
    5: '#959189',
    6: '#7e7a71',
    7: '#65625a',
    8: '#4e4b45',
    9: '#383631',
    10: '#24221f',
  },
  magenta: {
    1: '#fdf8fa',
    2: '#f6d6e4',
    3: '#eeb4ce',
    4: '#e393b9',
    5: '#d571a3',
    6: '#c5508e',
    7: '#af2c77',
    8: '#950061',
    9: '#6d0043',
    10: '#4a002b',
  },
  blue: {
    1: '#f9fcff',
    2: '#b8e6ff',
    3: '#7bcdff',
    4: '#47b1fa',
    5: '#1f96dd',
    6: '#0079b9',
    7: '#005f94',
    8: '#004670',
    9: '#003050',
    10: '#001d33',
  },
  green: {
    1: '#f0fcfb',
    2: '#93edec',
    3: '#4ed8d8',
    4: '#03bebe',
    5: '#00a2a3',
    6: '#008889',
    7: '#006d6e',
    8: '#005556',
    9: '#003d3e',
    10: '#002728',
  },
  orange: {
    1: '#fff8f1',
    2: '#ffd8a4',
    3: '#ffb65c',
    4: '#fd9005',
    5: '#dd7800',
    6: '#bc6200',
    7: '#994e00',
    8: '#793b00',
    9: '#592900',
    10: '#3b1900',
  },
  red: {
    1: '#fff7f6',
    2: '#ffd4cb',
    3: '#ffb2a5',
    4: '#ff8a7b',
    5: '#ff584c',
    6: '#f01a1d',
    7: '#c70000',
    8: '#9d0000',
    9: '#740000',
    10: '#4e0000',
  },
};

// -------------------------------------------------------------------------------------------------
// Algorithm

/**
 * Map Token is a gradient variable derived from Seed Token. It is recommended to implement custom
 * Map Token through theme.algorithm, which can ensure the gradient relationship between
 * Map Tokens. It can also be overridden by theme.token to modify the value of some map
 * tokens individually.
 * @see https://ant.design/docs/react/customize-theme
 */
export const lightAlgorithm = (seedToken: SeedToken): MapToken => {
  const mapToken = theme.defaultAlgorithm(seedToken);

  const colorTextBase = palettes.gray[10];
  const colorBgBase = palettes.gray[1];

  return {
    ...mapToken,
    ...genNamedColorTokens(palettes),

    //
    // Various
    //
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorWhite: '#fff',

    //
    // Neutral palette
    //
    colorTextBase,
    colorText: palettes.gray[10], // getAlphaColor(colorTextBase, 0.88),
    colorTextSecondary: getAlphaColor(colorTextBase, 0.65),
    colorTextTertiary: getAlphaColor(colorTextBase, 0.45),
    colorTextQuaternary: getAlphaColor(colorTextBase, 0.25),

    colorFill: getAlphaColor(colorTextBase, 0.15),
    colorFillSecondary: getAlphaColor(colorTextBase, 0.06),
    colorFillTertiary: getAlphaColor(colorTextBase, 0.04),
    colorFillQuaternary: getAlphaColor(colorTextBase, 0.02),

    colorBgBase,
    colorBgLayout: palettes.gray[1], // getDarkerColor(colorBgBase, 4),
    colorBgContainer: palettes.background, // getDarkerColor(colorBgBase, 0),
    colorBgElevated: palettes.background, // getDarkerColor(colorBgBase, 0),
    colorBgSpotlight: getAlphaColor(colorTextBase, 0.85),

    colorBorder: getDarkerColor(colorBgBase, 15),
    colorBorderSecondary: getDarkerColor(colorBgBase, 6),

    //
    // Link palette
    //
    colorLinkHover: palettes.magenta[6],
    colorLink: palettes.magenta[8],
    colorLinkActive: palettes.magenta[9],

    //
    // Primary palette
    //
    colorPrimaryBg: palettes.magenta[2],
    colorPrimaryBgHover: palettes.magenta[3],
    colorPrimaryBorder: palettes.magenta[3],
    colorPrimaryBorderHover: palettes.magenta[4],
    colorPrimaryHover: palettes.magenta[6],
    colorPrimary: palettes.magenta[8],
    colorPrimaryActive: palettes.magenta[9],
    colorPrimaryTextHover: palettes.magenta[5],
    colorPrimaryText: palettes.magenta[7],
    colorPrimaryTextActive: palettes.magenta[8],

    //
    // Info palette
    //
    colorInfoBg: palettes.blue[2],
    colorInfoBgHover: palettes.blue[3],
    colorInfoBorder: palettes.blue[3],
    colorInfoBorderHover: palettes.blue[4],
    colorInfoHover: palettes.blue[5],
    colorInfo: palettes.blue[6],
    colorInfoActive: palettes.blue[7],
    colorInfoTextHover: palettes.blue[5],
    colorInfoText: palettes.blue[7],
    colorInfoTextActive: palettes.blue[8],

    //
    // Success palette
    //
    colorSuccessBg: palettes.green[2],
    colorSuccessBgHover: palettes.green[3],
    colorSuccessBorder: palettes.green[5],
    colorSuccessBorderHover: palettes.green[4],
    colorSuccessHover: palettes.green[3],
    colorSuccess: palettes.green[4],
    colorSuccessActive: palettes.green[5],
    colorSuccessTextHover: palettes.green[5],
    colorSuccessText: palettes.green[7],
    colorSuccessTextActive: palettes.green[8],

    //
    // Warning palette
    //
    colorWarningBg: palettes.orange[2],
    colorWarningBgHover: palettes.orange[3],
    colorWarningBorder: palettes.orange[5],
    colorWarningBorderHover: palettes.orange[4],
    colorWarningHover: palettes.orange[3],
    colorWarning: palettes.orange[4],
    colorWarningActive: palettes.orange[5],
    colorWarningTextHover: palettes.orange[5],
    colorWarningText: palettes.orange[7],
    colorWarningTextActive: palettes.orange[8],

    //
    // Error palette
    //
    colorErrorBg: palettes.red[2],
    colorErrorBgHover: palettes.red[3],
    colorErrorBorder: palettes.red[3],
    colorErrorBorderHover: palettes.red[4],
    colorErrorHover: palettes.red[5],
    colorError: palettes.red[6],
    colorErrorActive: palettes.red[7],
    colorErrorTextHover: palettes.red[5],
    colorErrorText: palettes.red[7],
    colorErrorTextActive: palettes.red[8],
  };
};

// -------------------------------------------------------------------------------------------------
// Theme Config

/**
 * Get the ThemeConfig for a given ColorScheme.
 *
 * This is the main point of configuration for the Antd theme.
 */
export const getLightThemeConfig = (seedToken: SeedToken): ThemeConfig => {
  const theme = lightAlgorithm(seedToken);
  return deepmerge(defaultThemeConfig, {
    algorithm: lightAlgorithm,
    components: {
      Layout: {
        headerBg: theme.colorBgContainer,
        colorBgContainer: theme.colorBgLayout,
        bodyBg: theme.colorBgLayout,
      },
    },
  });
};

// -------------------------------------------------------------------------------------------------
// Design Tokens

/**
 * Derive the app's definitive design tokens from Antd's AliasTokens.
 *
 * This extends Antd's AliasTokens (the tokens used for development) with additional
 * tokens we need in our application.
 */
export const getLightDesignTokens = (tokens: PublicAliasToken): DesignTokens => ({
  ...tokens,
  ...defaultDesignTokens,

  colorAccent: tokens.orange3,

  colorAceEditorBg: tokens.colorWhite,

  colorCalendarPageBg: tokens.colorBgContainer,
  colorCalendarBorder: tokens.colorBorder,
  colorCalendarNeutralBg: tokens.colorFill,
  colorCalendarNeutralText: tokens.colorTextSecondary,
  colorCalendarNowIndicator: tokens.colorPrimary,
  colorCalendarTodayBg: getAlphaColor(palettes.orange[4], 0.05),

  colorGenderDiverse: tokens.colorTextSecondary,
  colorGenderFemale: tokens.magenta5,
  colorGenderMale: tokens.blue5,

  colorHeaderBlack: palettes.gray[2],
  colorHeaderRed: palettes.red[3],
  colorHeaderGold: palettes.orange[3],
  colorHeaderGreen: palettes.green[3],
  colorHeaderBlue: palettes.blue[3],
  colorHeaderMagenta: palettes.magenta[3],

  colorIdeBg: tokens.colorBgLayout,

  colorMarkdownBg: tokens.colorBgLayout,

  colorMessageRead: palettes.gray[6],
  colorMessageUnread: tokens.blue4,

  colorTableDivider: palettes.gray[7],

  colorTaskCollectionBonus: tokens.magenta6,
  colorTaskCollectionBonusText: tokens.magenta6,
  colorTaskCollectionTodo: tokens.orange4,

  colorTaskFailed: tokens.red5,
  colorTaskNeutral: palettes.gray[4],
  colorTaskPending: tokens.green5,
  colorTaskProgressBg: tokens.green3,
  colorTaskTodo: tokens.orange4,
  colorTaskSuccess: tokens.green3,
  colorTaskUngraded: tokens.green2,

  colorTestOutputPassed: tokens.colorSuccess,
  colorTestOutputTodo: tokens.colorWarning,
  colorTestOutputSkipped: tokens.colorInfo,
  colorTestOutputFailed: tokens.colorError,

  colorTextWhite: 'rgba(255, 255, 255, 0.85)',

  linkDecoration: tokens.linkDecoration as unknown as string,
  linkHoverDecoration: tokens.linkHoverDecoration as unknown as string,
  linkFocusDecoration: tokens.linkFocusDecoration as unknown as string,
});
