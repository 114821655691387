import { useEffect } from 'react';

import { connectionAdt } from '@code-expert/react-socketio';
import { contentTypeAdt } from '/imports/domain';
import { useNotification } from '/imports/ui/components/Notifications/useNotification';
import { useConnectionStatus } from '/imports/ui/hooks/useConnectionStatus';

function useConnectionNotifications() {
  const [sendNotification, clearNotification] = useNotification();
  const { status } = useConnectionStatus();

  useEffect(() => {
    const timeoutDuration = 1500;
    let timeout: NodeJS.Timeout | undefined;

    connectionAdt.fold(status, {
      disconnected: (reason) => {
        console.error(reason);
        sendNotification({
          key: 'connectionStatus',
          severity: 'error',
          title: 'Offline',
          content: contentTypeAdt.plain('You are currently offline'),
          kind: 'App',
        });
      },
      connectError: (reason) => {
        console.error(reason);
        sendNotification({
          key: 'connectionStatus',
          severity: 'error',
          title: 'Failed to connect',
          content: contentTypeAdt.plain('The connection has failed, please reload the page.'),
          kind: 'App',
        });
      },
      connecting: (reason) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (reason === 'recovering') {
          timeout = setTimeout(() => {
            sendNotification({
              key: 'connectionStatus',
              severity: 'warning',
              title: 'Connecting …',
              content: contentTypeAdt.plain('Trying to reconnect, please wait.'),
              kind: 'App',
            });
          }, timeoutDuration);
        }
      },
      connected: () => {
        clearNotification({ key: 'connectionStatus' });
      },
    });

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
    };
  }, [clearNotification, sendNotification, status]);
}

export default useConnectionNotifications;
