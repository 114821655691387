import { assertNonNull, invariant, refinement } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import type {
  CodeSubmissionPublic,
  DispenseSubmissionPublic,
  Submission,
  SubmissionPublic,
} from '/imports/domain/submission';
import type { ProjectDifference } from '/imports/modules/Review/domain/projectDifference';

export type ReviewSubmission =
  | (CodeSubmissionPublic & {
      late: boolean;
      modified: ProjectDifference;
    })
  | DispenseSubmissionPublic;

export function isReviewSubmission<S extends DistributivePick<Submission, '_id' | 'kind'>>(
  submission?: S,
): submission is Extract<S, Pick<ReviewSubmission, 'kind'>> {
  return submission?.kind === 'dispense' || submission?.kind === 'code';
}

export function assertReviewSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): asserts submission is Extract<S, Pick<ReviewSubmission, 'kind'>> {
  assertNonNull(submission, 'Submission not found');
  invariant(isReviewSubmission(submission), 'Review assertion failed');
}

export const requireReviewSubmission = refinement.invariant(
  isReviewSubmission,
  () => 'Expected review submission',
);
