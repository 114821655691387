import { record, struct } from '@code-expert/prelude';
import type { StartsWith } from '@code-expert/type-utils';
import type { AliasToken, PresetColorKey } from '/imports/lib/antd';
import { PresetColors } from '/imports/lib/antd';
import type { ColorPalettes } from './colors';

/**
 * Not all keys on Antd's AliasToken should be exposed; this represents the subset of keys we want.
 */
export type PublicAliasToken = Omit<AliasToken, InternalTokenKeys>;

/**
 * Filter out internal keys that should not appear in the types and documentation. This is a bit
 * of a hackery because the values are in the record but not exposed on the type level.
 */
export const getPublicAliasTokens = (aliasTokens: Partial<AliasToken>): PublicAliasToken =>
  struct.omit(aliasTokens as $Inexpressible, record.keys(internalTokenKeys)) as PublicAliasToken;

/**
 * All DesignTokens that can be used in the application.
 *
 * We extend Antd's tokens and add our own here.
 */
export interface DesignTokens extends PublicAliasToken {
  /** An accent color used to complement the primary color. */
  colorAccent: string;

  /** Needs to be aligned with the Ace editor's theme */
  colorAceEditorBg: string;

  colorCalendarPageBg: string;
  colorCalendarBorder: string;
  colorCalendarNeutralBg: string;
  colorCalendarNeutralText: string;
  colorCalendarNowIndicator: string;
  colorCalendarTodayBg: string;

  colorGenderDiverse: string;
  colorGenderFemale: string;
  colorGenderMale: string;

  colorHeaderBlack: string;
  colorHeaderRed: string;
  colorHeaderGold: string;
  colorHeaderGreen: string;
  colorHeaderBlue: string;
  colorHeaderMagenta: string;

  colorIdeBg: string;

  colorMarkdownBg: string;

  colorMessageRead: string;
  colorMessageUnread: string;

  /** Strong divider between table row or column groups */
  colorTableDivider: string;

  colorTaskCollectionBonus: string;
  colorTaskCollectionBonusText: string;
  colorTaskCollectionTodo: string;

  colorTaskFailed: string;
  colorTaskNeutral: string;
  colorTaskPending: string;
  colorTaskProgressBg: string;
  colorTaskTodo: string;
  colorTaskSuccess: string;
  colorTaskUngraded: string;

  colorTestOutputPassed: string;
  colorTestOutputTodo: string;
  colorTestOutputSkipped: string;
  colorTestOutputFailed: string;

  colorTextWhite: string;

  fontFeatureSettingsCode: string;
  fontLineHeightCode: number;
  fontSizeXS: string;

  linkDecoration: string;
  linkHoverDecoration: string;
  linkFocusDecoration: string;

  tabSize: number;
}

/**
 * Color-only tokens, derived from the app's DesignTokens.
 */
export type ColorTokens = {
  [K in keyof ColorPalettes | StartsWith<keyof DesignTokens, 'color'>]: DesignTokens[K];
};

// -------------------------------------------------------------------------------------------------

type PresetColorTokens = { [K in PresetColorKey]: null };

const presetColorTokenKeys: PresetColorTokens = PresetColors.reduce((acc, name) => {
  acc[name] = null;
  return acc;
}, {} as PresetColorTokens);

/**
 * Antd asks us not to use some of these directly. Others we don't want to expose.
 */
type InternalTokenKeys = keyof typeof internalTokenKeys;

export const internalTokenKeys = {
  ...presetColorTokenKeys,
  _hashId: null,
  _tokenKey: null,
  boxShadowPopoverArrow: null,
  boxShadowCard: null,
  boxShadowDrawerRight: null,
  boxShadowDrawerLeft: null,
  boxShadowDrawerUp: null,
  boxShadowDrawerDown: null,
  boxShadowTabsOverflowLeft: null,
  boxShadowTabsOverflowRight: null,
  boxShadowTabsOverflowTop: null,
  boxShadowTabsOverflowBottom: null,
  colorBgBase: null,
  colorTextBase: null,
  motionBase: null,
  wireframe: null,

  // Hidden color palettes
  cyan1: null,
  cyan2: null,
  cyan3: null,
  cyan4: null,
  cyan5: null,
  cyan6: null,
  cyan7: null,
  cyan8: null,
  cyan9: null,
  cyan10: null,
  geekblue1: null,
  geekblue2: null,
  geekblue3: null,
  geekblue4: null,
  geekblue5: null,
  geekblue6: null,
  geekblue7: null,
  geekblue8: null,
  geekblue9: null,
  geekblue10: null,
  gold1: null,
  gold2: null,
  gold3: null,
  gold4: null,
  gold5: null,
  gold6: null,
  gold7: null,
  gold8: null,
  gold9: null,
  gold10: null,
  lime1: null,
  lime2: null,
  lime3: null,
  lime4: null,
  lime5: null,
  lime6: null,
  lime7: null,
  lime8: null,
  lime9: null,
  lime10: null,
  pink1: null,
  pink2: null,
  pink3: null,
  pink4: null,
  pink5: null,
  pink6: null,
  pink7: null,
  pink8: null,
  pink9: null,
  pink10: null,
  purple1: null,
  purple2: null,
  purple3: null,
  purple4: null,
  purple5: null,
  purple6: null,
  purple7: null,
  purple8: null,
  purple9: null,
  purple10: null,
  volcano1: null,
  volcano2: null,
  volcano3: null,
  volcano4: null,
  volcano5: null,
  volcano6: null,
  volcano7: null,
  volcano8: null,
  volcano9: null,
  volcano10: null,
  yellow1: null,
  yellow2: null,
  yellow3: null,
  yellow4: null,
  yellow5: null,
  yellow6: null,
  yellow7: null,
  yellow8: null,
  yellow9: null,
  yellow10: null,
};
