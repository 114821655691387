import React from 'react';

import equal from 'fast-deep-equal';

import { array, constVoid, iots, option, panic, pipe, remote } from '@code-expert/prelude';
import type { Same, Unpack } from '@code-expert/type-utils';
import type {
  HeaderColor,
  Period,
  PublicSetting,
  SettingRecordWithDefault,
  SettingSemesters,
  UserCapability,
  UserPublic,
} from '/imports/domain';
import { CourseUrl, defaultSettings, GroupId, perpetual, SemesterId } from '/imports/domain';
import { CxEventEmitter } from '/imports/ui/components/CxEventEmitter';
import { Loading } from '/imports/ui/components/Loading';
import { useCachedProcedure } from '/imports/ui/hooks/useProcedure';
import { usePublication } from '/imports/ui/hooks/usePublication';
import type { ValueProp } from '/imports/utils/UiState';
import { UiState } from '/imports/utils/UiState';

export type SavedEntryElements = 'path' | 'semester' | 'courseUrl' | 'groupId';

type Semester = Omit<Unpack<SettingSemesters['value']>, 'index'>;

export interface GlobalContext {
  readonly suspensionPeriods: Array<Period>;
  readonly headerColor: HeaderColor;
  readonly eventEmitter: CxEventEmitter;
  readonly pageTitle: string;
  readonly ltiUrl: option.Option<string>;
  readonly rootUrl: string;
  readonly examMode: boolean;
  readonly currentSemester: SettingSemesters['value'][number];
  readonly tigerPythonUrl: string;
  readonly semesters: Array<Semester>;
  readonly visibleSemesters: Array<SemesterId>;
  readonly user?: UserPublic;
  readonly userCapabilities: Array<UserCapability>;
  readonly preferredEntry?: {
    path?: string;
    courseUrl?: CourseUrl;
    semester?: SemesterId;
    groupId?: GroupId;
  };
  readonly webAuthnEnabled: boolean;
}

type MandatoryFields = keyof Pick<
  GlobalContext,
  | 'suspensionPeriods'
  | 'currentSemester'
  | 'examMode'
  | 'tigerPythonUrl'
  | 'ltiUrl'
  | 'rootUrl'
  | 'semesters'
  | 'visibleSemesters'
  | 'userCapabilities'
  | 'webAuthnEnabled'
>;

export function initialState({
  headerColor = 'Black',
  pageTitle = '',
  eventEmitter = new CxEventEmitter(),
  ...defaults
}: Pick<GlobalContext, MandatoryFields> &
  Partial<Omit<GlobalContext, MandatoryFields>>): GlobalContext {
  return { headerColor, pageTitle, eventEmitter, ...defaults };
}

const uiStates = {
  path: UiState.valueProp('preferredEntry.path', iots.string),
  courseUrl: UiState.valueProp('preferredEntry.courseUrl', CourseUrl),
  semester: UiState.valueProp('preferredEntry.semester', SemesterId),
  groupId: UiState.valueProp('preferredEntry.groupId', GroupId),
};

// -------------------------------------------------------------------------------------------------

export type Action = Partial<GlobalContext>;

const reducer = (state: GlobalContext | undefined, action: Action & { _init?: GlobalContext }) => {
  if (action._init) return action._init;
  if (state == null) return undefined;

  const setOrClear = <A, B>(prop: ValueProp<A & Same<A, B>>, value?: B & Same<A, B>) => {
    if (value == null) {
      prop.clear();
    } else {
      prop.set(value);
    }
  };

  const preferredEntry = action.preferredEntry;
  if (preferredEntry != null) {
    setOrClear(uiStates.path, preferredEntry.path);
    setOrClear(uiStates.courseUrl, preferredEntry.courseUrl);
    setOrClear(uiStates.semester, preferredEntry.semester);
    setOrClear(uiStates.groupId, preferredEntry.groupId);
  }

  const nextState = { ...state, ...action };
  return equal(state, nextState) ? state : nextState;
};

// -------------------------------------------------------------------------------------------------

const useSetting = <K extends PublicSetting['key']>(
  key: K,
): remote.Remote<SettingRecordWithDefault[K]> =>
  pipe(
    usePublication('settings.getPublicSettings', { props: { key }, method: 'findOne' }),
    remote.map(
      option.fold(
        () => defaultSettings[key],
        ({ value }) => value as SettingRecordWithDefault[K],
      ),
    ),
  );

const context = React.createContext<[GlobalContext, React.Dispatch<Action>]>([
  initialState({
    suspensionPeriods: [],
    currentSemester: {
      key: perpetual,
      current: true,
      visible: true,
    },
    semesters: [],
    visibleSemesters: [],
    examMode: false,
    tigerPythonUrl: defaultSettings.TigerPython.url,
    ltiUrl: option.none,
    rootUrl: '',
    userCapabilities: [],
    webAuthnEnabled: true,
  }),
  constVoid,
]);

export const GlobalContextProvider = React.memo(function GlobalContextProvider({
  children,
}: React.PropsWithChildren) {
  const [state, stateDispatch] = React.useReducer(reducer, undefined);

  const headerColor = pipe(
    useSetting('HeaderColor'),
    remote.map(({ color }) => color),
  );

  const pageTitle = pipe(
    useSetting('PageTitle'),
    remote.map(({ title }) => title),
  );

  const examMode = pipe(
    useSetting('ExamMode'),
    remote.map(({ active }) => active),
  );

  const semesterData = pipe(
    useSetting('Semesters'),
    remote.map((semesters) => {
      const currentSemester = semesters.find((s) => s.current);
      if (!currentSemester) panic('Invariant violation: Could not find currentSemester');
      return {
        currentSemester,
        semesters,
        visibleSemesters: pipe(
          semesters,
          array.filter(({ visible }) => visible),
          array.map(({ key }) => key),
        ),
      };
    }),
  );

  const suspensionPeriods = useSetting('SuspensionPeriods');

  const tigerPythonUrl = pipe(
    useSetting('TigerPython'),
    remote.map(({ url }) => url),
  );

  const userO = usePublication('users.profile', { method: 'findOne' });

  const userCapabilities = pipe(
    usePublication('user.capabilities', { method: 'getOne' }),
    remote.map((c) => c.roles),
  );

  const [config] = useCachedProcedure('config.config', { props: undefined });

  React.useEffect(() => {
    pipe(
      remote.sequenceS({
        headerColor,
        pageTitle,
        examMode,
        semesterData,
        suspensionPeriods,
        tigerPythonUrl,
        userO,
        userCapabilities,
        config,
      }),
      remote.map(
        ({
          semesterData: semesterData_,
          userO: user_,
          userCapabilities,
          config: { ltiUrl, rootUrl, webAuthnEnabled },
          ...rest
        }) => {
          const nextState = {
            user: pipe(user_, option.toUndefined),
            userCapabilities,
            ltiUrl,
            rootUrl,
            webAuthnEnabled,
            ...semesterData_,
            ...rest,
          };

          return state == null
            ? {
                _init: initialState({
                  preferredEntry: {
                    path: uiStates.path.get(),
                    courseUrl: uiStates.courseUrl.get(),
                    semester: uiStates.semester.get(),
                    groupId: uiStates.groupId.get(),
                  },
                  ...nextState,
                }),
              }
            : nextState;
        },
      ),
      remote.fold(constVoid, constVoid, stateDispatch),
    );
  }, [
    state,
    tigerPythonUrl,
    headerColor,
    pageTitle,
    examMode,
    semesterData,
    suspensionPeriods,
    userO,
    userCapabilities,
    config,
  ]);

  return state == null
    ? React.createElement(Loading, { delay: 'long' })
    : React.createElement(context.Provider, { value: [state, stateDispatch] }, children);
});

// -------------------------------------------------------------------------------------------------

export const useGlobalContext = () => React.useContext(context)[0];

export const useGlobalContextWithActions = () => React.useContext(context);
