import React from 'react';

import type { Lazy } from '@code-expert/prelude';

/**
 * Used like the regular useState, but only sets the next state if it is safe
 * to do so, i.e. if the component is mounted.
 *
 * The dispatch function is made stable via what I call the "inverted useRef"-pattern; instead
 * of doing using multiple useRef's like this:
 * ```
 * const isMounted = React.useRef(true);
 * const setSafeState = React.useRef((next: React.SetStateAction<A | undefined>) => {
 *   if (isMounted.current) setState(next);
 * })
 * ```
 * we can put them into a single one, like this:
 * ```
 * const {current} = React.useRef({
 *   isMounted: true,
 *   setSafeState: (next: React.SetStateAction<A | undefined>) => {
 *     if (current.isMounted) setState(next);
 *   },
 * })
 * ```
 *
 * Note how we now get and set the current value via `current.isMounted` instead of `isMounted.current`.
 */
export function useSafeState<A>(
  initialState: A | Lazy<A>,
): [A, React.Dispatch<React.SetStateAction<A>>];
export function useSafeState<A>(): [
  A | undefined,
  React.Dispatch<React.SetStateAction<A | undefined>>,
];
export function useSafeState<A>(initialState?: A | Lazy<A>) {
  const [state, setState] = React.useState(initialState);

  const { current } = React.useRef({
    isMounted: false,
    setSafeState: (next: React.SetStateAction<A | undefined>) => {
      if (current.isMounted) setState(next);
    },
  });

  React.useEffect(() => {
    current.isMounted = true;
    return () => {
      current.isMounted = false;
    };
  }, [current]);

  return [state, current.setSafeState];
}
