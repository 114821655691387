import { iso } from 'monocle-ts';

import type { eq } from '@code-expert/prelude';
import { date, iots, ord, pipe } from '@code-expert/prelude';

export type Period = date.NormalizedInterval;

const PeriodC = iots.strict({ start: iots.date, end: iots.date });

export const PeriodsC = iots.array(PeriodC);

type DateTuple = [Date, Date];

export const periodAsTuple: iso.Iso<Period, DateTuple> = iso.iso(
  ({ start, end }) => [start, end],
  ([start, end]) => ({ start, end }),
);

export const eqPeriod: eq.Eq<Period> = date.eqNormalizedInterval;

export const ordPeriod: ord.Ord<Period> = pipe(
  ord.tuple(date.Ord, date.Ord),
  ord.contramap(periodAsTuple.get),
);
