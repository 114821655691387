import type { DocumentD, EntityD } from '@code-expert/mongo-ts';
import type { eq, monoid } from '@code-expert/prelude';
import {
  adt,
  constant,
  constFalse,
  constTrue,
  iots,
  number,
  ord,
  string,
  struct,
} from '@code-expert/prelude';
import type { DistributiveKeyof, DistributivePick } from '@code-expert/type-utils';
import { ExamId, ExerciseId, UserId } from '/imports/domain/identity';
import type { SwitchOrganisationUrl } from '/imports/modules/SwitchOrganisations/domain/SwitchOrganisation';
import { SwitchOrganisationUrlC } from '/imports/modules/SwitchOrganisations/domain/SwitchOrganisation';

export type UserRole = string;

export type VerifiedEmail = { address: string; verified: boolean };

/**
 * m: male
 * f: female
 * d: diverse
 * u: unknown
 */
export const genders = ['m', 'f', 'd', 'u'] as const;

interface GenderBrand {
  readonly Gender: unique symbol;
}

type Gender_ = (typeof genders)[number];
export const Gender = iots.withMessage(
  iots.brand(
    iots.keyof<Record<Gender_, null>>({ m: null, f: null, d: null, u: null }),
    (u): u is iots.Branded<Gender_, GenderBrand> => genders.includes(u),
    'Gender',
  ),
  () => 'Invalid gender',
);
export type Gender = iots.TypeOf<typeof Gender>;

const baseUserStrict = {
  _id: UserId,
  firstname: iots.string,
  lastname: iots.string,
  username: iots.string,
  email: iots.string,
  gender: Gender,
};

const baseUserPartial = {
  title: iots.string,
  createdAt: iots.date,
  legi: iots.string,
  language: iots.string,
  zoomLink: iots.string,
  blocked: iots.boolean,
  locked: iots.date,
  emails: iots.array(iots.strict({ address: iots.string, verified: iots.boolean })),
  roles: iots.array(iots.string),
  notifications: iots.strict({
    newMessage: iots.boolean,
  }),
  lastLogin: iots.date,
};

export const BaseUserC = iots.struct(baseUserStrict, baseUserPartial);

export type BaseUser = iots.TypeOf<typeof BaseUserC>;

export interface UserOAuthETH extends BaseUser {
  service: 'eth';
  emails?: Array<VerifiedEmail>;
  oauthUserId?: string;
  services?: {
    eth?: { id: string };
  };
}

export const UserOAuthETHC = iots.struct(
  {
    ...baseUserStrict,
    service: iots.literal('eth'),
  },
  {
    ...baseUserPartial,
    emails: iots.array(iots.strict({ address: iots.string, verified: iots.boolean })),
    oauthUserId: iots.string,
    services: iots.exact(
      iots.partial({
        eth: iots.strict({ id: iots.string }),
      }),
    ),
  },
) satisfies EntityD<UserOAuthETH>;

export interface EduIdService {
  id: string;
  access_token: string;
  id_token: string;
  token_type: 'Bearer';
  expires_at: number;
}

export interface UserAffiliation {
  id: string; //The id, which must be the value of the swissEduPersonUniqueID attribute in the organisation’s identity system. Must be a valid swissEduPersonUniqueID as defined in the SWITCHaai attribute specification.
  email: Array<string>; //Preferred address for the 'To:' field of e-mail to be sent to this person.
  surname: string; //Surname or family name.
  givenName: string; // Given name of a person.
  preferredLanguage?: string; // Preferred written or spoken language for a person. Must match the regular expression ^[\p{IsAlpha}]{2,3}(-[\p{IsAlpha}]{2})?$.
  swissEduPersonGender?: '0' | '1' | '2' | '9'; // Codes for the representation of human sexes (see ISO 5218).
  swissEduPersonHomeOrganization?: SwitchOrganisationUrl; // Domain name of a home organization. Must be the domain name of the home organization.
  swissEduPersonMatriculationNumber?: string; // Matriculation number of a student. Must be a string of numerical characters of length 8.
}

export interface UserEduId extends BaseUser {
  service: 'eduId';
  oauthUserId?: string; // is set if the user was an oauth eth user before. This can be removed as soon as migration is finished.
  swissEduPersonUniqueID: string;
  currentAffiliationId: string;
  swissEduIDLinkedAffiliationUniqueID: Array<string>;
  swissEduIDLinkedAffiliationMail: Array<string>;
  swissEduIDAssociatedMail: Array<string>;
  affiliations: Array<UserAffiliation>;
  language: string;
  services: {
    eduId: EduIdService;
  };
}

const AffiliationC = iots.struct(
  {
    id: iots.string, //The id, which must be the value of the swissEduPersonUniqueID attribute in the organisation’s identity system. Must be a valid swissEduPersonUniqueID as defined in the SWITCHaai attribute specification.
    email: iots.array(iots.string), //Preferred address for the 'To:' field of e-mail to be sent to this person.
    givenName: iots.string, // Given name of a person.
    surname: iots.string, //Surname or family name.
  },
  {
    swissEduPersonHomeOrganization: SwitchOrganisationUrlC, // Domain name of a home organization. Must be the domain name of the home organization.
    swissEduPersonGender: iots.keyof({ '0': 0, '1': 0, '2': 0, '9': 0 }), // Codes for the representation of human sexes (see ISO 5218).
    swissEduPersonMatriculationNumber: iots.string, // Matriculation number of a student. Must be a string of numerical characters of length 8.
    preferredLanguage: iots.string, // Preferred written or spoken language for a person. Must match the regular expression ^[\p{IsAlpha}]{2,3}(-[\p{IsAlpha}]{2})?$.
  },
);

const EduIdServiceC = iots.strict({
  id: iots.string,
  access_token: iots.string,
  id_token: iots.string,
  token_type: iots.literal('Bearer'),
  expires_at: iots.number,
});

export const UserEduIdC = iots.struct(
  {
    ...baseUserStrict,
    service: iots.literal('eduId'),
    swissEduPersonUniqueID: iots.string,
    swissEduIDLinkedAffiliationUniqueID: iots.array(iots.string),
    swissEduIDLinkedAffiliationMail: iots.array(iots.string),
    swissEduIDAssociatedMail: iots.array(iots.string),
    affiliations: iots.array(AffiliationC),
    services: iots.strict({ eduId: EduIdServiceC }),
    language: iots.string,
    currentAffiliationId: iots.string,
  },
  {
    ...struct.omit(baseUserPartial, ['language']),
    oauthUserId: iots.string, // is set if the user was an oauth eth user before. This can be removed as soon as migration is finished.
  },
) satisfies EntityD<UserEduId>;

export const ExamUsernameC = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, ExamUsernameBrand> => ExamUsernameRegex.test(s),
  'examUsername',
);

export type ExamUsername = iots.TypeOf<typeof ExamUsernameC>;

export interface UserExam extends BaseUser {
  username: ExamUsername;
  examId: ExamId;
  legi?: string;
  examExerciseId?: ExerciseId;
  examProblems?: Array<string>;
  examIPAddress?: string;
  examReviewMode?: string;
}

export const UserExamC = iots.struct(
  {
    ...baseUserStrict,
    examId: ExamId,
    username: ExamUsernameC,
  },
  {
    ...baseUserPartial,
    legi: iots.string,
    examExerciseId: ExerciseId,
    examProblems: iots.array(iots.string),
    examIPAddress: iots.string,
    examReviewMode: iots.string,
  },
) satisfies DocumentD & iots.Type<UserExam, unknown>;

export interface UserLTI extends BaseUser {
  ltiConsumerId: string;
  ltiUserId: string;
  isLtiStaff?: boolean;
  changedFields?: Array<'email' | 'firstname' | 'lastname' | 'legi'>;
}

export const UserLtiC = iots.struct(
  {
    ...baseUserStrict,
    ltiConsumerId: iots.string,
    ltiUserId: iots.string,
  },
  {
    ...baseUserPartial,
    isLtiStaff: iots.boolean,
    changedFields: iots.array(iots.keyof({ email: 0, firstname: 0, lastname: 0, legi: 0 })),
  },
) satisfies EntityD<UserLTI>;

export type User = UserExam | UserEduId | UserOAuthETH | UserLTI;

export const UserC = iots.union([
  UserEduIdC,
  UserOAuthETHC,
  UserExamC,
  UserLtiC,
]) satisfies DocumentD & iots.Type<User, unknown>;

export const ExamUsernameRegex = /^[1-9a-f][0-9a-f]{5}$/;

export interface ExamUsernameBrand {
  readonly examUsername: unique symbol;
}

export const ExamLegiRegex = /\d{8}/;

export interface ExamLegiBrand {
  readonly examLegi: unique symbol;
}

export const ExamLegiC = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, ExamLegiBrand> => ExamLegiRegex.test(s),
  'examLegi',
);

export const userPublicFields = {
  _id: 1,
  username: 1,
  email: 1,
  gender: 1,
  title: 1,
  firstname: 1,
  lastname: 1,
  legi: 1,
  language: 1,
  roles: 1,
  ltiUserId: 1,
  ltiConsumerId: 1,
  examId: 1,
  examExerciseId: 1,
  examReviewMode: 1,
  blocked: 1,
  locked: 1,
} satisfies Partial<Record<DistributiveKeyof<User>, 1>>;

export type UserPublicFields = keyof typeof userPublicFields;
export type UserExamPublic = Pick<UserExam, UserPublicFields & keyof UserExam>;
export type UserEduIdPublic = Pick<UserEduId, UserPublicFields & keyof UserEduId>;
export type UserOAuthETHPublic = Pick<UserOAuthETH, UserPublicFields & keyof UserOAuthETH>;
export type UserLTIPublic = Pick<UserLTI, UserPublicFields & keyof UserLTI>;

export type UserPublic = UserExamPublic | UserEduIdPublic | UserOAuthETHPublic;

export function hasRole(u: Pick<User, 'roles'>, role: UserRole) {
  return u.roles !== undefined && u.roles.includes(role);
}

export function fullName(user: Pick<User, 'firstname' | 'lastname'>) {
  return user.firstname !== undefined ? `${user.firstname} ${user.lastname}` : '';
}

export function titleAndName(user: Pick<User, 'title' | 'firstname' | 'lastname'>) {
  return user.title !== undefined && user.title !== ''
    ? `${user.title} ${fullName(user)}`
    : fullName(user);
}

export const isSuperAdmin = (user: Pick<User, 'roles'>): boolean => hasRole(user, 'admin');

export function isExamUser<A extends DistributivePick<User, '_id' | 'examId'>>(
  user: A,
): user is Extract<A, Pick<UserExamPublic, '_id' | 'examId'>> {
  return 'examId' in user;
}

export function isLTIUser<A extends DistributivePick<User, '_id' | 'ltiUserId'>>(
  user: A,
): user is Extract<A, Pick<UserLTIPublic, '_id' | 'ltiUserId'>> {
  return 'ltiUserId' in user;
}

export function isEduIDUser<A extends DistributivePick<User, '_id' | 'service'>>(
  user: A,
): user is Extract<A, Pick<UserEduId, '_id' | 'service'>> {
  return 'service' in user && user.service === 'eduId';
}

export function isETHUser<A extends DistributivePick<User, '_id' | 'service'>>(
  user: A,
): user is Extract<A, Pick<UserOAuthETH, '_id' | 'service'>> {
  return 'service' in user && user.service === 'eth';
}

const pfsRoles = {
  admin: null,
  assistant: null,
  student: null,
  everyone: null,
};

export const foldPfsRole = adt.foldFromKeys(pfsRoles);

export const PfsRoleC = iots.keyof(pfsRoles);

/**
 * Note: `admin` is used for super admins (can access everything) as well as PFS users, where
 * users with enough access rights are treated as "admins", e.g. course admins.
 */
export type PfsRole = adt.TypeOfKeys<typeof foldPfsRole>;

export const ROLE_ADMIN: PfsRole = 'admin';
export const ROLE_ASSISTANT: PfsRole = 'assistant';
export const ROLE_STUDENT: PfsRole = 'student';
export const ROLE_EVERYONE: PfsRole = 'everyone';

export const ROLES_ALL: Array<PfsRole> = [ROLE_ADMIN, ROLE_ASSISTANT, ROLE_STUDENT, ROLE_EVERYONE];

export const isRoleAdmin = (role: PfsRole): role is 'admin' => role === ROLE_ADMIN;
export const isRoleAssistant = (role: PfsRole): role is 'assistant' => role === ROLE_ASSISTANT;
export const isRoleStudent = (role: PfsRole): role is 'student' => role === ROLE_STUDENT;

export const pfsRoleEq: eq.Eq<PfsRole> = string.Eq;

export const pfsRoleOrd: ord.Ord<PfsRole> = ord.contramap(
  foldPfsRole({
    admin: constant(0),
    assistant: constant(1),
    student: constant(2),
    everyone: constant(3),
  }),
)(number.Ord);

export const pfsRoleMonoid: monoid.Monoid<PfsRole> = {
  empty: ROLE_EVERYONE,
  concat: (a, b) => (pfsRoleOrd.compare(a, b) > 0 ? b : a),
};

/**
 * Check if the role has at least some higher level of access than ROLE_EVERYONE
 */
export const isElevatedRole = foldPfsRole({
  admin: constTrue,
  assistant: constTrue,
  student: constTrue,
  everyone: constFalse,
});
