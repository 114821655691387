import React from 'react';

import { iots, pipe } from '@code-expert/prelude';
import { AppAuthorise } from '/imports/modules/API/presentation/AppAuthorise';
import { end, lit, parser, query, succeed, url } from '/imports/modules/Routing';
import NotFound from '/imports/ui/components/NotFound';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import { TopNavLayout } from '/imports/ui/layouts/TopNavLayout';

const authroriseBaseRoute = lit('app');

const authorizeRoute = url(
  lit('authorize'),
  query(iots.strict({ clientId: iots.string, code_challenge: iots.string })),
  end,
);

const InvalidAuthoriseLink = () => (
  <PrivateRoute
    element={() => (
      <NotFound
        title="Invalid authorisation link"
        text="You have followed an invalid authorisation link."
        showBackButton={false}
      />
    )}
    denyCapabilities={['exam', 'lti']}
    layout={TopNavLayout}
  />
);

export const appRouter = pipe(
  // Match /app
  authroriseBaseRoute.parser,
  parser.chain((_) =>
    pipe(
      // Match /authorize?clientId={string}&code_challenge={string}
      authorizeRoute.parser,
      parser.map((props) => (
        <PrivateRoute
          element={() => <AppAuthorise {...props} />}
          denyCapabilities={['exam', 'lti']}
        />
      )),
      // Fall back to error message
      parser.alt(succeed(<InvalidAuthoriseLink />).parser),
    ),
  ),
);
