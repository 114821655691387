import { useEffect, useState } from 'react';

import { fn } from '@code-expert/prelude';
import { UiState } from '/imports/utils/UiState';
import type { ColorScheme } from './ColorScheme';
import { ColorSchemeC } from './ColorScheme';

const persistedColorScheme = UiState.valueProp('colorScheme', ColorSchemeC);

export const usePreferredColorScheme = (): [ColorScheme, (_: ColorScheme) => void] => {
  const prefersDarkColorScheme: MediaQueryList = window.matchMedia(`(prefers-color-scheme: dark)`);

  const [colorScheme, setColorScheme] = useState(persistedColorScheme.get() ?? 'light');

  const updateColorScheme = (scheme: ColorScheme) => {
    persistedColorScheme.set(scheme);
    setColorScheme(scheme);
  };

  useEffect(() => {
    if (persistedColorScheme.get() == null && prefersDarkColorScheme.matches) {
      updateColorScheme('dark');
    }

    const handlePreferenceChange = ({ matches }: MediaQueryListEvent) => {
      updateColorScheme(matches ? 'dark' : 'light');
    };
    prefersDarkColorScheme.addEventListener('change', handlePreferenceChange);
    return () => {
      prefersDarkColorScheme.removeEventListener('change', handlePreferenceChange);
    };
  });

  return fn.tuple(colorScheme, updateColorScheme);
};
