import React from 'react';

import { Alert } from 'antd';

import { iots } from '@code-expert/prelude';
import { useUiState } from '/imports/ui/hooks/useUiState';
import { UiState } from '/imports/utils/UiState';

export const supportsArrayAt = () => typeof Array.prototype.at === 'function';

const uiStates = {
  ignoreOutdatedBrowser: UiState.valueProp('ignore-outdated-browser', iots.boolean),
};

/**
 * Shows a notification if the browser is not supported.
 * @returns {null}
 * @constructor
 */
export const BrowserUpdate: React.FC = () => {
  const [ignoreError, setIgnoreError] = useUiState(uiStates.ignoreOutdatedBrowser, false, '7d');
  if (!supportsArrayAt() && !ignoreError) {
    return (
      <Alert
        type="error"
        banner
        closable
        onClose={() => setIgnoreError(true)}
        message="Your browser does not support some features which Code Expert needs to run smoothly."
        description={
          <>
            You can try to use Code Expert but it might crash.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://browser-update.org/update-browser.html"
            >
              Update your browser
            </a>
          </>
        }
      />
    );
  }
  return null;
};
