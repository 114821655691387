import { array } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';

/**
 * Expand an array of patterns by duplicating those with `repeat` properties
 * @param {Array.<Object>} format An array of patterns
 * @returns {Array.<Object>} An expanded array of patterns (1 per character in value)
 */
function expandFormatRepetitions(format: Array<FormatPattern>) {
  return format.reduce<Array<DistributivePick<FormatPattern, 'char' | 'exactly'>>>(
    (patterns, nextItem) => {
      if ('repeat' in nextItem && nextItem.repeat !== undefined && nextItem.repeat > 1) {
        const expanded = [];
        const copy = { ...nextItem };
        delete copy.repeat;
        for (let i = 0; i < nextItem.repeat; i += 1) {
          expanded.push({ ...copy });
        }
        return [...patterns, ...expanded];
      }
      return [...patterns, nextItem];
    },
    [],
  );
}

type RegexBuilderCharPattern = { char: [string, string]; repeat?: number };

type RegexCharPattern = { char: RegExp; repeat?: number };

type StringCharPattern = { char: string; repeat?: number };

type ExactlyPattern = { exactly: string };

export type FormatPattern =
  | RegexBuilderCharPattern
  | RegexCharPattern
  | StringCharPattern
  | ExactlyPattern;

/**
 * Format a value for a pattern
 *
 * The format is a collection of patterns and delimiters that control what values can be entered.
 * By default there is no format (so any input is allowed), but it can be set to an array of objects
 * that are used to process the value upon every change:
 *
 * * **Character match** groups: A character match (`char`) is a regular expression designed to match
 * just 1 character. It may also contain a `repeat` property to specify how many characters this
 * pattern should match. `repeat` defaults to `1` if not specified. For example, `{ char: /\d/ }`
 * will match exactly 1 number, whereas `{ char: /-/, repeat: 3 }` will match 3 dashes.
 * * **Exact** groups: An exact group represents a string or character that must come next in the
 * value. It is used to specify mandatory delimiters in the value. For instance, `{ exactly: "." }`
 * will enforce that a period appears next in the value. Exact groups also support the `repeat`
 * property. Characters added using exact groups **do not appear in raw values**.
 *
 * When used in combination together, complex values like credit-card numbers can be easily
 * represented:
 *
 * ```javascript
 * [
 * { char: /\d/, repeat: 4 },
 * { exactly: "-" },
 * { char: /\d/, repeat: 4 },
 * { exactly: "-" },
 * { char: /\d/, repeat: 4 },
 * { exactly: "-" },
 * { char: /\d/, repeat: 4 }
 * ]
 * ```
 *
 * Or even the expiry date of such a credit card:
 *
 * ```javascript
 * [
 * { char: /[01]/ },  // month, 2 digits
 * { char: /[0-9]/ }, // "
 * { exactly: "/" },
 * { char: /2/ },                  // year, 4 digits
 * { char: /[0-9]/, repeat: 3 }    // "
 * ]
 * ```
 *
 * @param {String} value The value to format
 * @param {Array.<Object>} formatSpec The formatting specification to apply to the value
 * @returns {{ raw, formatted }} The formatted and raw values
 */
export function formatPattern(
  value: string,
  formatSpec: Array<FormatPattern> = [],
): { raw: string; formatted: string } {
  const format = expandFormatRepetitions(formatSpec);
  if (value && format.length > 0) {
    const characters = value.split('');
    let formattedValue = '';
    let rawValue = '';
    while (format.length > 0 && characters.length > 0) {
      const pattern = format.shift();
      if (pattern && 'char' in pattern) {
        let charRexp;
        if (pattern.char instanceof RegExp) {
          charRexp = pattern.char;
        } else if (typeof pattern.char === 'string') {
          charRexp = new RegExp(pattern.char);
        } else if (Array.isArray(pattern.char) && pattern.char.length >= 1) {
          const [rexp, mod = ''] = pattern.char;
          charRexp = new RegExp(rexp, mod);
        } else {
          throw new Error('Invalid pattern provided');
        }
        while (array.isNonEmpty(characters) && !charRexp.test(characters[0])) {
          characters.shift();
        }
        if (array.isNonEmpty(characters)) {
          formattedValue += characters[0];
          rawValue += characters[0];
          characters.shift();
        }
      } else if (pattern?.exactly != null) {
        if (pattern.exactly.length !== 1) {
          throw new Error("Unable to format value: 'exactly' value should be of length 1", {
            cause: { exactly: pattern.exactly },
          });
        }
        formattedValue += pattern.exactly;
        if (pattern.exactly === characters[0]) {
          characters.shift();
        }
      } else {
        throw new Error('Unable to format value: Invalid format specification', {
          cause: { pattern },
        });
      }
    }
    return { formatted: formattedValue, raw: rawValue };
  }
  return { formatted: value, raw: value };
}

export const hexPattern: Array<FormatPattern> = [
  {
    char: ['[a-f0-9]', 'i'],
    repeat: 3,
  },
  { exactly: '-' },
  { char: ['[a-f0-9]', 'i'], repeat: 3 },
];

export const legiPattern: Array<FormatPattern> = [
  {
    char: /\d/,
    repeat: 2,
  },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
];
