import type { ReactNode } from 'react';
import React from 'react';

import { Form, Pagination, Popover } from 'antd';

import {
  array,
  constant,
  date as date_,
  option,
  ord,
  pipe,
  string,
  tagged,
} from '@code-expert/prelude';
import type { SnapshotId } from '/imports/domain';
import type { ProjectEntry, Snapshot } from '/imports/modules/Project/domain';
import { formatDateTime } from '/imports/ui/foundation/DateTime/format';

type VersionType =
  | tagged.Tagged<'change'>
  | tagged.Tagged<'snapshot'>
  | tagged.Tagged<'publishedSnapshot'>;

const versionTypeAdt = tagged.build<VersionType>();

interface Version {
  date: Date;
  type: VersionType;
}

const versionOrd: ord.Ord<Version> = pipe(
  date_.Ord,
  ord.contramap(({ date }) => date),
);

export const VersionNav = ({
  entries,
  snapshots,
  publishedSnapshotId,
  setSnapshotDate,
}: {
  entries: Array<ProjectEntry>;
  snapshots: Array<Snapshot>;
  publishedSnapshotId: option.Option<SnapshotId>;
  setSnapshotDate: React.Dispatch<React.SetStateAction<option.Option<Date>>>;
}) => {
  const versions: Array<Version> = pipe(
    entries,
    array.map(({ createdAt }) => ({ type: versionTypeAdt.wide.change(), date: createdAt })),
    array.concat(
      pipe(
        snapshots,
        array.map(({ _id, date }) => ({
          date,
          type: pipe(publishedSnapshotId, option.elem(string.Eq)(_id))
            ? versionTypeAdt.wide.publishedSnapshot()
            : versionTypeAdt.wide.snapshot(),
        })),
      ),
    ),
    array.uniqSort(versionOrd),
  );

  const selectVersion = (version: number) =>
    setSnapshotDate(
      pipe(
        versions,
        array.lookup(version - 1),
        option.map(({ date }) => date),
      ),
    );

  const renderVersion = (original: ReactNode, version: number) =>
    pipe(versions, array.lookupOrThrow(version - 1), ({ date, type }) => {
      const label = versionTypeAdt.fold(type, {
        change: constant('Change'),
        snapshot: constant('Snapshot'),
        publishedSnapshot: constant('Published snapshot'),
      });

      return (
        <Popover
          placement="bottom"
          overlayInnerStyle={{
            textAlign: 'center',
            fontSize: 'small',
          }}
          content={
            <>
              <strong>{label}</strong>
              <br />
              {formatDateTime('iso8601Date')(date)}
              <br />
              {formatDateTime('iso8601Time')(date)}
            </>
          }
        >
          {original}
        </Popover>
      );
    });

  return (
    <Form.Item label="Versions">
      <Pagination
        pageSize={1}
        defaultCurrent={versions.length}
        onChange={selectVersion}
        total={versions.length}
        itemRender={(i, type, original) =>
          type === 'page' ? renderVersion(original, i) : original
        }
      />
    </Form.Item>
  );
};
