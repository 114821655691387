import useConnectionNotification from '/imports/ui/components/Notifications/Sources/useConnectionNotification';
import useGlobalNotification from '/imports/ui/components/Notifications/Sources/useGlobalNotifications';
import { usePresentationNotifications } from '/imports/ui/components/Notifications/Sources/usePresentationNotifications';

const useAllSources = () => {
  useConnectionNotification();
  useGlobalNotification();
  usePresentationNotifications();
};

export default useAllSources;
