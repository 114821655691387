import React from 'react';

import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import type { IdeRouteParams } from './routes';
import { ideLink, ideRoute } from './routes';

const Ide = React.lazy(() => import('./IdeLoader'));

const ide = (params: IdeRouteParams) => (
  <PrivateRoute element={() => <Ide {...params} {...{ navigate: ideLink }} />} />
);

export const ideRouter = ideRoute.parser.map(ide);
