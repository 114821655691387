import type { readerIO } from '@code-expert/prelude';
import {
  boolean,
  eq,
  flow,
  fn,
  iots,
  nonEmptyArray,
  not,
  number,
  option,
  ord,
  pipe,
} from '@code-expert/prelude';
import type { TimeEnv } from '/imports/utils/timeService';
import { SemesterId, semesterIdEq } from './identity';

export type Season = 'AS' | 'SS';

export const SemesterC = iots.strict({
  id: SemesterId,
  enrollmentOpen: iots.boolean,
  locked: iots.boolean,
});

export type Semester = iots.TypeOf<typeof SemesterC>;

export const isPerpetualSemester = (id: SemesterId) => id === 'p';

export const isSpringSemester = (season: Season) => season === 'SS';

export const getSeason = flow(
  option.fromPredicate(not(isPerpetualSemester)),
  option.map((id) => id.substring(0, 2) as Season),
);

export const getYear = flow(
  option.fromPredicate(not(isPerpetualSemester)),
  option.map((id) => parseInt(id.substring(2, 4), 10)),
);

export const incrementSemester =
  (id: SemesterId): readerIO.ReaderIO<TimeEnv, SemesterId> =>
  ({ time }) =>
  () =>
    pipe(
      option.sequenceT(getSeason(id), getYear(id)),
      option.getOrElse(() => {
        const now = time.now();
        const season: Season = now.getMonth() < 5 ? 'SS' : 'AS';
        const year = now.getFullYear() - 2000;
        return fn.tuple(season, year);
      }),
      ([season, year]) => (isSpringSemester(season) ? `AS${year}` : `SS${year + 1}`) as SemesterId,
    );

const getSeasonCardinality = flow(
  getSeason,
  option.map(
    flow(
      isSpringSemester,
      boolean.fold(
        () => 1,
        () => 0,
      ),
    ),
  ),
);

export const eqSemesters: eq.Eq<NonEmptyArray<Semester>> = nonEmptyArray.getEq(
  pipe(
    semesterIdEq,
    eq.contramap((s) => s.id),
  ),
);

/**
 * Sort semesters, e.g. "AS19", "SS17", or "p"
 */
export const ordSemesterIdAsc = ord.concatAll<SemesterId>(
  ord.contramap(
    flow(
      getYear,
      option.getOrElse(() => 0),
    ),
  )(number.Ord),
  ord.contramap(
    flow(
      getSeasonCardinality,
      option.getOrElse(() => -1),
    ),
  )(number.Ord),
);

export const ordSemesterIdDesc: ord.Ord<SemesterId> = ord.reverse(ordSemesterIdAsc);
