import { useEffect, useState } from 'react';

import { option } from '@code-expert/prelude';
import { isLTIUser } from '/imports/domain';
import { Logger } from '/imports/modules/Logger/client';
import { useGlobalContext } from '/imports/ui/components/GlobalContext';
import { trackError } from '/imports/ui/helper/error';
import { joinPath } from '/imports/utils/absoluteUrl/joinPath';

function isAllowed(path: string) {
  // note: /lti/<task id> is handled in picker routes
  const [, p] = path.split('/');
  return p === 'loginLti';
}

function RedirectNonLtiUsers() {
  const { user, ltiUrl, rootUrl } = useGlobalContext();
  const [redirectUrl, setRedirectUrl] = useState<string>();

  useEffect(() => {
    if (option.isSome(ltiUrl)) {
      if (user != null) {
        // redirect non LTI users to default URL
        if (window.location.origin === ltiUrl.value && !isLTIUser(user)) {
          Logger.persist.warn('A non LTI user tried to access LTI_URL', {
            ltiUrl: ltiUrl.value,
            referrer: document.referrer,
          });
          trackError(new Error('A non LTI user tried to access LTI_URL'));
          setRedirectUrl(joinPath(rootUrl, window.location.pathname));
        }
        // redirect LTI users to LTI URL
        if (isLTIUser(user) && `${window.location.origin}/` === joinPath(rootUrl)) {
          Logger.persist.warn('An LTI user tried to access ROOT_URL', {
            ltiUrl: ltiUrl.value,
            referrer: document.referrer,
          });
          trackError(new Error('An LTI user tried to access ROOT_URL'));
          setRedirectUrl(`${ltiUrl.value}${window.location.pathname}`);
        }
      } else if (
        user == null &&
        window.location.origin === ltiUrl.value &&
        !isAllowed(window.location.pathname)
      ) {
        // redirect anonymous user to default URL if it's not an LTI login attempt
        Logger.persistAlways.warn('An anonymous user tried to access LTI_URL', {
          ltiUrl: ltiUrl.value,
          referrer: document.referrer,
        });
        trackError(new Error('An anonymous user tried to access LTI_URL'));
        setRedirectUrl(joinPath(rootUrl, window.location.pathname));
      }
    }
  }, [user, ltiUrl, rootUrl]);

  useEffect(() => {
    if (redirectUrl != null) window.location.replace(redirectUrl);
  }, [redirectUrl]);

  return null;
}

export default RedirectNonLtiUsers;
