import type { ComponentProps } from 'react';
import React from 'react';

import { GuardRemote as GuardRemote_ } from '@code-expert/react';
import type { PartialProps } from '@code-expert/type-utils';
import { Loading } from '/imports/ui/components/Loading';

type GuardRemoteProps<A> = PartialProps<ComponentProps<typeof GuardRemote_<A>>, 'pending'>;

/**
 * Guard a render tree from rendering unless we have a value
 */
export const GuardRemote = <A,>({
  value,
  render,
  pending = () => <Loading />,
  initial = pending,
}: GuardRemoteProps<A>) => (
  <GuardRemote_ value={value} initial={initial} pending={pending} render={render} />
);
