import type { remoteEither } from '@code-expert/prelude';
import type { ProjectEntryKey, ProjectId } from '/imports/domain';
import type { Unauthorised } from '/imports/modules/Authorization/domain';
import type { Content } from '/imports/modules/Project/application/rpc/content';
import type { ProjectEntries } from '/imports/modules/Project/application/rpc/entries';
import type { Project, Snapshot } from '/imports/modules/Project/domain';
import type { ProjectError } from '/imports/modules/Project/domain/shared';
import { useCachedProcedure } from '/imports/ui/hooks/useProcedure';
import { usePublication } from '/imports/ui/hooks/usePublication';

export interface ProjectHooks {
  useProject: (_: ProjectId) => remoteEither.RemoteEither<ProjectError, Project>;
  useEntries: (_: ProjectId) => remoteEither.RemoteEither<ProjectError, ProjectEntries>;
  useWithOrigin: (_: ProjectId) => remoteEither.RemoteEither<Unauthorised, Array<Project>>;
  useSnapshots: (_: ProjectId) => remoteEither.RemoteEither<ProjectError, Array<Snapshot>>;
  useContent: (_: {
    projectId: ProjectId;
    key: ProjectEntryKey;
    version: number;
  }) => remoteEither.RemoteEither<ProjectError, Content>;
}

export const projectHooks: ProjectHooks = {
  useProject: (projectId) => useCachedProcedure('project_project', { props: { projectId } })[0],
  useEntries: (projectId) =>
    useCachedProcedure('project_entries', { props: { projectId } }, [
      usePublication('project.entries', { props: { projectId }, method: 'trigger' }),
    ])[0],
  useWithOrigin: (projectId) =>
    useCachedProcedure('project_projectsWithOrigin', { props: { projectId } })[0],
  useSnapshots: (projectId) => useCachedProcedure('project_snapshots', { props: { projectId } })[0],
  useContent: ({ projectId, key, version }) =>
    useCachedProcedure('project_content', { props: { projectId, key, version } })[0],
};
