import type {
  Attributes,
  CElement,
  ClassAttributes,
  ClassType,
  Component,
  ComponentClass,
  ComponentState,
  DetailedReactHTMLElement,
  DOMAttributes,
  DOMElement,
  FunctionComponent,
  FunctionComponentElement,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactElement,
  ReactHTML,
  ReactSVG,
  ReactSVGElement,
  SVGAttributes,
} from 'react';
import React from 'react';

import { theme as antdTheme } from 'antd';
import hashIt from 'hash-it';
import { nanoid } from 'nanoid/non-secure';

import { flow, record, string } from '@code-expert/prelude';
import type { Theme as AntTheme, CSSInterpolation, MapToken, SeedToken } from '/imports/lib/antd';
import { Keyframes, useStyleRegisterNoSSR } from '/imports/lib/antd';
import type { Theme } from '/imports/ui/foundation/Theme/Theme';
import { useTheme } from '/imports/ui/foundation/Theme/ThemeContext';
import type { CSSWithVariants, ExtractVariantProps } from './variants';
import { classListFromProps, rulesFromStyles, separateVariantProps } from './variants';

// -------------------------------------------------------------------------------------------------
// Styled

export type GetStyles<C extends CSSWithVariants> = (theme: Theme) => C;

export function styled<C extends CSSWithVariants>(
  type: 'input',
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  | ((InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>) &
      ExtractVariantProps<C>)
  | (null &
      React.RefAttributes<
        DetailedReactHTMLElement<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
      >)
>;
export function styled<
  P extends HTMLAttributes<T>,
  T extends HTMLElement,
  C extends CSSWithVariants,
>(
  type: keyof ReactHTML,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  | (ClassAttributes<T> & P & ExtractVariantProps<C>)
  | (null & React.RefAttributes<DetailedReactHTMLElement<P, T>>)
>;
export function styled<P extends SVGAttributes<T>, T extends SVGElement, C extends CSSWithVariants>(
  type: keyof ReactSVG,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  (ClassAttributes<T> & P & ExtractVariantProps<C>) | (null & React.RefAttributes<ReactSVGElement>)
>;
export function styled<P extends DOMAttributes<T>, T extends Element, C extends CSSWithVariants>(
  type: string,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  (ClassAttributes<T> & P & ExtractVariantProps<C>) | (null & React.RefAttributes<DOMElement<P, T>>)
>;
export function styled<P extends object, C extends CSSWithVariants>(
  type: FunctionComponent<P>,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  | (Attributes & P & ExtractVariantProps<C>)
  | (null & React.RefAttributes<FunctionComponentElement<P>>)
>;
export function styled<
  P extends object,
  T extends Component<P, ComponentState>,
  R extends ComponentClass<P>,
  C extends CSSWithVariants,
>(
  type: ClassType<P, T, R>,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  (ClassAttributes<T> & P & ExtractVariantProps<C>) | (null & React.RefAttributes<CElement<P, T>>)
>;
export function styled<P extends object, C extends CSSWithVariants>(
  type: FunctionComponent<P> | ComponentClass<P> | string,
  styles: GetStyles<C>,
): React.ForwardRefExoticComponent<
  (Attributes & P & ExtractVariantProps<C>) | (null & React.RefAttributes<ReactElement<P>>)
>;

/**
 * This is an implementation of the "styled" API for @ant-design/cssinjs. It's based on the API
 * spearheaded by styled-components and similar.
 *
 * The type overloads mimick {@link React.createElement} in order to provide
 * proper typings for named elements.
 */
export function styled(
  Element: $Inexpressible,
  getStyles: GetStyles<$Inexpressible>,
): React.ForwardRefExoticComponent<
  $Inexpressible & ExtractVariantProps<$Inexpressible> & React.RefAttributes<unknown>
> {
  const displayName =
    record.isObject(Element) &&
    typeof Element['displayName'] === 'string' &&
    Element['displayName'] !== ''
      ? Element['displayName']
      : 'Styled';

  const Component = React.forwardRef(({ className, ...overloadedProps }: $Inexpressible, ref) => {
    const appTheme = useTheme();
    const { theme, token, hashId } = antdTheme.useToken();
    const { styles, animations } = parseStyles(getStyles(appTheme));
    const { props, variantClassList } = separateVariantProps(styles, overloadedProps);
    const namespace = getNamespace('styled', styles);
    const scopedClassNames = useStyleRegisterNoSSR(
      { theme, token, hashId, path: [namespace] },
      () =>
        new Array<CSSInterpolation>()
          .concat(styles)
          .map(rulesFromStyles(namespace))
          .concat(animations),
    );
    return React.createElement(Element, {
      ...props,
      className: [className, scopedClassNames, ...variantClassList].filter(Boolean).join(' '),
      ref,
    });
  });
  Component.displayName = `styled.${displayName}`;

  return Component;
}

// -------------------------------------------------------------------------------------------------
// CSS

export const css: <C extends CSSWithVariants>(
  _: GetStyles<C>,
) => (props?: ExtractVariantProps<C>) => string = (getStyles) =>
  function useCss(props): string {
    const appTheme = useTheme();
    const { theme, token, hashId } = antdTheme.useToken();
    const { styles, animations } = parseStyles(getStyles(appTheme));
    const namespace = getNamespace('css', styles);
    const baseClasses = useStyleRegisterNoSSR(
      {
        theme: theme as unknown as AntTheme<SeedToken, MapToken>,
        token,
        hashId,
        path: [namespace],
      },
      () =>
        new Array<CSSInterpolation>()
          .concat(styles)
          .map(rulesFromStyles(namespace))
          .concat(animations),
    );
    return [baseClasses, ...classListFromProps(props ?? {})].join(' ');
  };

// -------------------------------------------------------------------------------------------------
// Keyframes

export const keyframes = (styles: CSSInterpolation) => {
  const namespace = `keyframe-${nanoid(8)}`;
  return new Keyframes(namespace, styles);
};

// -------------------------------------------------------------------------------------------------
// Utils

/**
 * Compute a unique, stable namespace based on the given style object's contents.
 *
 * - Uses a 🔹 character to make our class names easy to find in the generated HTML.
 * - Encodes the unique numeric ID as Base36 to keep it short.
 */
const getNamespace = (prefix: string, styles: CSSInterpolation): string =>
  `${prefix}🔹${hashIt(styles).toString(36)}`;

const parseStyles = (
  styles: CSSInterpolation,
): { styles: CSSInterpolation; animations: Array<Keyframes> } =>
  styles != null && Array.isArray(styles)
    ? {
        styles: styles.filter(
          (x: unknown): x is CSSInterpolation =>
            !record.isObject(x) || !('_keyframe' in x && x['_keyframe'] === true),
        ),
        animations: styles.filter(
          (x: unknown): x is Keyframes =>
            record.isObject(x) && '_keyframe' in x && x['_keyframe'] === true,
        ),
      }
    : { styles, animations: [] };

/**
 * Add the !important keyword after a value.
 */
export const important: (_: unknown) => string = flow(String, string.append(' !important'));
