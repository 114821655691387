import { mkEntityIdCodec } from '@code-expert/mongo-ts/identity';
import type { ProjectEntryKey, ProjectId } from '@code-expert/pfs/dist/identity';
import type { eq } from '@code-expert/prelude';
import { iots, string } from '@code-expert/prelude';

export { ProjectEntryKey, ProjectId } from '@code-expert/pfs/dist/identity';

export const projectIdEq: eq.Eq<ProjectId> = string.Eq;

export const eqProjectEntryKey: eq.Eq<ProjectEntryKey> = string.Eq;

// -------------------------------------------------------------------------------------------------
// Branded IDs

export const semesterRegex = /^[AS]S\d{2}|p/;

export interface SemesterIdBrand {
  readonly SemesterId: unique symbol;
}
export const SemesterId = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, SemesterIdBrand> => semesterRegex.test(s),
  'SemesterId',
);
export type SemesterId = iots.TypeOf<typeof SemesterId>;

export const semesterIdEq: eq.Eq<SemesterId> = string.Eq;

export const perpetual: SemesterId = iots.brandFromLiteral('p');

export interface CourseUrlBrand {
  readonly CourseUrl: unique symbol;
}
export const courseUrlRegex = /^[a-z0-9A-Z_]{1,15}$/;
export const CourseUrl = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, CourseUrlBrand> => courseUrlRegex.test(s),
  'CourseUrl',
);
export type CourseUrl = iots.TypeOf<typeof CourseUrl>;

// -------------------------------------------------------------------------------------------------
// SessionId

export const SessionIdBrand = Symbol('SessionId');
const sessionIdRegex = /^[A-Za-z0-9+/=_-]{32}/;
const isSessionId = (
  u: unknown,
): u is string & { readonly _entityIdBrand: typeof SessionIdBrand } =>
  typeof u === 'string' && sessionIdRegex.test(u);
export const SessionId = new iots.Type(
  SessionIdBrand.description ?? SessionIdBrand.toString(),
  isSessionId,
  (u, c) => (isSessionId(u) ? iots.success(u) : iots.failure(u, c)),
  String,
);
export type SessionId = iots.TypeOf<typeof SessionId>;

// -------------------------------------------------------------------------------------------------
// EntityIDs

export const ExamIdBrand = Symbol('ExamId');
export const ExamId = mkEntityIdCodec(ExamIdBrand);
export type ExamId = iots.TypeOf<typeof ExamId>;
export const examIdEq: eq.Eq<ExamId> = string.Eq;

export const AnalyticsProjectIdBrand = Symbol('AnalyticsProjectId');
export const AnalyticsProjectId = mkEntityIdCodec(AnalyticsProjectIdBrand);
export type AnalyticsProjectId = iots.TypeOf<typeof AnalyticsProjectId>;

export const AnalyticsEventIdBrand = Symbol('AnalyticsEventId');
export const AnalyticsEventId = mkEntityIdCodec(AnalyticsEventIdBrand);
export type AnalyticsEventId = iots.TypeOf<typeof AnalyticsEventId>;

export const AppointmentIdBrand = Symbol('AppointmentId');
export const AppointmentId = mkEntityIdCodec(AppointmentIdBrand);
export type AppointmentId = iots.TypeOf<typeof AppointmentId>;
export const appointmentIdEq: eq.Eq<AppointmentId> = string.Eq;

export const AppointmentGroupIdBrand = Symbol('AppointmentGroupId');
export const AppointmentGroupId = mkEntityIdCodec(AppointmentGroupIdBrand);
export type AppointmentGroupId = iots.TypeOf<typeof AppointmentGroupId>;

export const AutosaveIdBrand = Symbol('AutosaveId');
export const AutosaveId = mkEntityIdCodec(AutosaveIdBrand);
export type AutosaveId = iots.TypeOf<typeof AutosaveId>;

export const CourseIdBrand = Symbol('CourseId');
export const CourseId = mkEntityIdCodec(CourseIdBrand);
export type CourseId = iots.TypeOf<typeof CourseId>;
export const courseIdEq: eq.Eq<CourseId> = string.Eq;

export const ExerciseIdName = 'ExerciseId';
export const ExerciseIdBrand = Symbol('ExerciseId');
export const ExerciseId = mkEntityIdCodec(ExerciseIdBrand);
export type ExerciseId = iots.TypeOf<typeof ExerciseId>;

export const SubmissionIdBrand = Symbol('SubmissionId');
export const SubmissionId = mkEntityIdCodec(SubmissionIdBrand);
export type SubmissionId = iots.TypeOf<typeof SubmissionId>;

export const GameResultIdBrand = Symbol('GameResultId');
export const GameResultId = mkEntityIdCodec(GameResultIdBrand);
export type GameResultId = iots.TypeOf<typeof GameResultId>;

export const JobIdBrand = Symbol('JobId');
export const JobId = mkEntityIdCodec(JobIdBrand);
export type JobId = iots.TypeOf<typeof JobId>;
export const jobIdEq: eq.Eq<JobId> = string.Eq;

export const LtiSessionIdBrand = Symbol('LtiSessionId');
export const LtiSessionId = mkEntityIdCodec(LtiSessionIdBrand);
export type LtiSessionId = iots.TypeOf<typeof LtiSessionId>;

export const NotificationIdBrand = Symbol('NotificationId');
export const NotificationId = mkEntityIdCodec(NotificationIdBrand);
export type NotificationId = iots.TypeOf<typeof NotificationId>;

export const ProjectUploadFileIdBrand = Symbol('ProjectUploadFileId');
export const ProjectUploadFileId = mkEntityIdCodec(ProjectUploadFileIdBrand);
export type ProjectUploadFileId = iots.TypeOf<typeof ProjectUploadFileId>;

export const ProjectUploadChunkIdBrand = Symbol('ProjectUploadChunkId');
export const ProjectUploadChunkId = mkEntityIdCodec(ProjectUploadChunkIdBrand);
export type ProjectUploadChunkId = iots.TypeOf<typeof ProjectUploadChunkId>;

export const RunnerSettingsIdBrand = Symbol('RunnerSettingsId');
export const RunnerSettingsId = mkEntityIdCodec(RunnerSettingsIdBrand);
export type RunnerSettingsId = iots.TypeOf<typeof RunnerSettingsId>;

export const ServiceJobIdBrand = Symbol('ServiceJobId');
export const ServiceJobId = mkEntityIdCodec(ServiceJobIdBrand);
export type ServiceJobId = iots.TypeOf<typeof ServiceJobId>;

export const SettingIdBrand = Symbol('SettingId');
export const SettingId = mkEntityIdCodec(SettingIdBrand);
export type SettingId = iots.TypeOf<typeof SettingId>;

export const SnapshotIdBrand = Symbol('SnapshotId');
export const SnapshotId = mkEntityIdCodec(SnapshotIdBrand);
export type SnapshotId = iots.TypeOf<typeof SnapshotId>;
export const snapshotIdEq: eq.Eq<SnapshotId> = string.Eq;

export const TaskIdName = 'TaskId';
export const TaskIdBrand = Symbol('TaskId');
export const TaskId = mkEntityIdCodec(TaskIdBrand);
export type TaskId = iots.TypeOf<typeof TaskId>;

export const taskIdEq: eq.Eq<TaskId> = string.Eq;

export const UserIdBrand = Symbol('UserId');
export const UserId = mkEntityIdCodec(UserIdBrand);
export type UserId = iots.TypeOf<typeof UserId>;
export const userIdEq: eq.Eq<UserId> = string.Eq;

export const GroupIdBrand = Symbol('GroupId');
export const GroupId = mkEntityIdCodec(GroupIdBrand);
export type GroupId = iots.TypeOf<typeof GroupId>;
export const groupIdEq: eq.Eq<GroupId> = string.Eq;

export const WebAuthIdBrand = Symbol('WebAuthId');
export const WebAuthId = mkEntityIdCodec(WebAuthIdBrand);
export type WebAuthId = iots.TypeOf<typeof WebAuthId>;

export const EnvironmentIdBrand = Symbol('EnvironmentId');
export const EnvironmentId = mkEntityIdCodec(EnvironmentIdBrand);
export type EnvironmentId = iots.TypeOf<typeof EnvironmentId>;

export const FileIdBrand = Symbol('FileId');
export const FileId = mkEntityIdCodec(FileIdBrand);
export type FileId = iots.TypeOf<typeof FileId>;

export const MessageIdBrand = Symbol('MessageId');
export const MessageId = mkEntityIdCodec(MessageIdBrand);
export type MessageId = iots.TypeOf<typeof MessageId>;
