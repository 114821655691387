import { adt, iots, number } from '@code-expert/prelude';
import type { PartialProps } from '@code-expert/type-utils';
import {
  AppointmentGroupId,
  AppointmentId,
  CourseId,
  SemesterId,
  UserId,
} from '/imports/domain/identity';

export const AppointmentChangeAction = iots.keyof({ this: null, future: null, all: null });
export type AppointmentChangeAction = iots.TypeOf<typeof AppointmentChangeAction>;

export function foldAppointmentChangeAction<R>(
  t: AppointmentChangeAction,
  pattern: Record<AppointmentChangeAction, () => R>,
): R {
  return pattern[t]();
}

export const LateAppointment = iots.keyof({ no: null, half: null, all: null });
export const foldLateAppointment = adt.foldFromKeys(LateAppointment.keys);
export const lateAppointmentSettings = foldLateAppointment.keys;
export type LateAppointment = iots.TypeOf<typeof LateAppointment>;

export type Allocation = {
  maxTAs: number;
  maxStudents: number;
  currentStudentCount: number;
  assistantIds: Array<UserId>;
};

export const AllocationC = iots.strict({
  maxTAs: iots.number,
  maxStudents: iots.number,
  currentStudentCount: iots.number,
  assistantIds: iots.array(UserId),
}) satisfies iots.Type<Allocation, unknown>;

export interface Appointment {
  readonly _id: AppointmentId;
  readonly start: Date;
  readonly end: Date;
  readonly location: string;
  readonly remote?: boolean;
  readonly courseId: CourseId;
  readonly semester: SemesterId;
  allocation: Allocation;
  readonly description?: string;
  readonly lateAppointment: LateAppointment;
  readonly groupId?: AppointmentGroupId;
}

export const AppointmentC = iots.struct(
  {
    _id: AppointmentId,
    start: iots.date,
    end: iots.date,
    location: iots.string,
    courseId: CourseId,
    semester: SemesterId,
    allocation: AllocationC,
    lateAppointment: LateAppointment,
  },
  {
    remote: iots.boolean,
    description: iots.string,
    groupId: AppointmentGroupId,
  },
) satisfies iots.Type<Appointment, unknown>;

export const appointmentsPublicFields = {
  _id: 1,
  start: 1,
  end: 1,
  location: 1,
  remote: 1,
  courseId: 1,
  semester: 1,
  allocation: 1,
  description: 1,
  lateAppointment: 1,
  groupId: 1,
} as const;

export type AppointmentPublic = Pick<Appointment, keyof typeof appointmentsPublicFields>;

export const appointmentsPublicFieldsStudent = {
  _id: 1,
  start: 1,
  end: 1,
  remote: 1,
  courseId: 1,
  semester: 1,
  allocation: 1,
  lateAppointment: 1,
};
export type AppointmentPublicStudent = Pick<
  Appointment,
  keyof typeof appointmentsPublicFieldsStudent
>;

export function isTASignedUp(appointment: Pick<Appointment, 'allocation'>): boolean {
  return appointment.allocation.assistantIds.length > 0;
}

export function isStudentSignedUp(appointment: Pick<Appointment, 'allocation'>): boolean {
  return (
    !!appointment?.allocation?.currentStudentCount && appointment.allocation.currentStudentCount > 0
  );
}

const occupancyKeys = { low: 0, high: 0, full: 0 };

export type Occupancy = keyof typeof occupancyKeys;

export const foldOccupancy = adt.foldFromKeys(occupancyKeys);

type StudentAllocation = Pick<Allocation, 'maxStudents' | 'currentStudentCount'>;

/**
 * Fraction of seats occupied for an appointment.
 * @returns Unit interval [0, 1] where 1 is fully occupied.
 */
export const allocationFraction = ({ maxStudents, currentStudentCount }: StudentAllocation) =>
  number.unitIntervalFromPartsOrElse(1)(currentStudentCount, maxStudents);

export const getOccupancyAdjustedForLateSetting = (
  { maxStudents, currentStudentCount }: StudentAllocation,
  lateAppointment: LateAppointment,
  isLateOrAbsent: boolean,
): Occupancy => {
  const fractionToOccupancy = (fraction: number.UnitInterval) =>
    fraction === 1 ? 'full' : fraction < 0.75 ? 'low' : 'high';

  const calcOccupancy = (maxStudents: number, currentStudentCount: number) =>
    fractionToOccupancy(allocationFraction({ maxStudents, currentStudentCount }));

  return isLateOrAbsent
    ? foldLateAppointment(lateAppointment, {
        no: () => 'full',
        half: () => calcOccupancy(Math.floor(maxStudents / 2), currentStudentCount),
        all: () => calcOccupancy(maxStudents, currentStudentCount),
      })
    : calcOccupancy(maxStudents, currentStudentCount);
};

export type AppointmentPartialAllocation = Omit<Appointment, 'allocation'> & {
  allocation: PartialProps<Allocation, 'currentStudentCount' | 'assistantIds'>;
};

export type Repetition = { active: boolean; freq?: 'weekly' | 'biweekly'; until?: Date };

export type AddAppointment = Omit<AppointmentPartialAllocation, '_id'> & { repeat: Repetition };
