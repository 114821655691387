import type { MessageInstance } from 'antd/es/message/interface';

import { fromThrown, string } from '@code-expert/prelude';
import { Logger } from '/imports/modules/Logger/client';

/**
 * Reports an error to the user and logs it.
 */
export const logError = (message: MessageInstance) => (error: unknown) => {
  if (error == null) {
    const msg = 'An unknown error occurred';
    void message.error(msg);
    Logger.error(msg);
  } else if (string.isString(error)) {
    void message.error(error);
    Logger.error(error);
  } else {
    const e = fromThrown(error as unknown);
    void message.error(e.message);
    Logger.error(e.message, { error: e });
  }
};

export const trackError = (_error: Error) => {
  // FIXME provide alternative for Monti
};
