import { useCallback } from 'react';

import type { ClientNotification } from '/imports/domain';
import { useGlobalContext } from '/imports/ui/components/GlobalContext';

export const useNotification: () => [
  (notification: ClientNotification) => void,
  (notification: Pick<ClientNotification, 'key'>) => void,
] = () => {
  const { eventEmitter } = useGlobalContext();

  const sendNotification = useCallback(
    (notification: ClientNotification) => {
      eventEmitter.emit('notification', notification);
    },
    [eventEmitter],
  );
  const clearNotification = useCallback(
    (notification: Pick<ClientNotification, 'key'>) => {
      eventEmitter.emit('close_notification', notification);
    },
    [eventEmitter],
  );

  return [sendNotification, clearNotification];
};
