import React from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';

import type { UserPublic } from '/imports/domain';
import { isExamUser } from '/imports/domain';
import { ExamUserDataMatrix } from '/imports/ui/components/ExamUserDataMatrix';
import { LtiLabel } from '/imports/ui/components/LtiLabel';
import { MessageInboxCount } from '/imports/ui/components/Messages/MessageInboxCount';
import UserName from '/imports/ui/components/UserName';
import { Icon } from '/imports/ui/foundation/Icons';
import { styled } from '/imports/ui/foundation/Theme';

const DEFAULT_OFFSET: [number, number] = [5, -20];

const StyledUserMenuTitle = styled('span', () => ({
  display: 'inline-block',
}));

const StyledLogoutButton = styled(Button, () => ({
  backgroundColor: 'transparent !important',
  paddingInline: 0,
  // Allow ::after pseudo-elements from useMenuPopupStyles() to escape the button's bounding box
  position: 'unset',
}));

export function getUserMenu(user: UserPublic) {
  const userIcon = () => {
    const now = new Date();
    if (now.getMonth() === 11 && now.getDate() > 5 && now.getDate() < 25) {
      return <Icon name="snowman" />;
    }
    return <Icon name="user" />;
  };

  return {
    key: 'cxUserMenu',
    'data-test-id': 'user-menu',
    style: {
      minWidth: 120,
      cursor: 'pointer',
    },
    label: (
      <StyledUserMenuTitle data-username={user.username} data-test="user-menu-title">
        {userIcon()}{' '}
        {(!isExamUser(user) || (isExamUser(user) && user.legi != null)) && (
          <UserName userId={user._id} showTitle={false} />
        )}
        <MessageInboxCount offset={!isExamUser(user) ? DEFAULT_OFFSET : [-20, -30]} />
        <LtiLabel user={user} />
        {isExamUser(user) && (
          <span style={{ display: 'inline-block', float: 'right', lineHeight: 0, marginLeft: 24 }}>
            <ExamUserDataMatrix examId={user.examId} user={user} />
          </span>
        )}
      </StyledUserMenuTitle>
    ),
    children: [
      {
        key: 'overview',
        icon: <Icon name="home" />,
        label: <Link to="/">Overview</Link>,
      },
      {
        key: 'messages',
        icon: <Icon name="inbox" />,
        label: (
          <Link to="/user/messages">
            <span>Messages</span>
            <MessageInboxCount offset={[5, -20]} />
          </Link>
        ),
      },
      {
        key: 'settings',
        icon: <Icon name="user-gear" />,
        label: (
          <Link to="/user/">
            <span>Settings</span>
          </Link>
        ),
      },
      { type: 'divider' },
      {
        key: 'help',
        icon: <Icon name="question-circle" />,
        label: (
          <a href="https://docs.expert.ethz.ch" target="_blank" rel="noreferrer">
            Help
          </a>
        ),
      },
      !isExamUser(user)
        ? {
            key: 'logout',
            icon: <Icon name="sign-out-alt" />,
            label: (
              <form action="/logout" method="post" style={{ display: 'inline-block' }}>
                <StyledLogoutButton type="text" htmlType="submit">
                  Logout
                </StyledLogoutButton>
              </form>
            ),
          }
        : undefined,
    ],
  };
}
