import { CourseUrl, SemesterId } from '/imports/domain';
import { end, lit, type, url } from '/imports/modules/Routing';

export const myCoursesBaseR = url(
  lit('mycourses'),
  type('semester', SemesterId),
  type('courseUrl', CourseUrl),
);

export const exercisesR = url(myCoursesBaseR, lit('exercises'), end);
