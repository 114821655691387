import type { Endomorphism } from '@code-expert/prelude';
import { flow, number, pipe, string } from '@code-expert/prelude';

/**
 * Rounds percentages to the nearest integer, but takes care to only round to 100% if the
 * value is really close to 100, i.e. would render 99.9 as 99.
 */
export const roundPercent = (percentage: number): number => {
  const isApproximatelyOneHundred = number.getEqAbsolute(1e-6).equals(percentage, 100);
  const rounded = Math.round(percentage);
  // Don't round up to 100% if threshold is not reached.
  return rounded === 100 && !isApproximatelyOneHundred ? 99 : rounded;
};

const replaceMinus: Endomorphism<string> = string.replace('-', '\u2212');

/**
 * Renders percentages rounded to the nearest integer, but takes care to only round to 100% if the
 * value is really close to 100, i.e. would render 99.9 as 99%.
 */
export const renderPercent =
  (suffix = '%') =>
  (percentage: number): string =>
    `${replaceMinus(roundPercent(percentage).toString())}${suffix}`;
/**
 * Format a number within the unit interval [0, 1] as a percentage (e.g. 0.56 as "56%").
 * Only displays percentages in the range of [0, 100].
 */
export const formatPercent = flow(number.percentFromUnitInterval, renderPercent());

/**
 * Turns a unit interval (result in [0, 1]) into a percentage (e.g. "60%").
 * Useful for displaying a result to students.
 * For TA-facing code, consider {@link formatResultAsRatio}
 */
export const formatResultAsPercent = (result: number | null | undefined, fallback = 'N/A') =>
  number.isFinite(result) ? formatPercent(result) : fallback;

const renderPercentAsDecimal = (percentage: number): string => {
  const fmt = new Intl.NumberFormat('en-IN', { maximumFractionDigits: percentage < 1 ? 4 : 0 });
  return `${fmt.format(percentage).toString()}%`;
};

/**
 * Formats a unit interval (result in [0, 1]) as percentage (e.g. "60%").
 * In contrast to {@link formatResultAsPercent}, 99.9 is not rounded to 99.
 * Percentages smaller than 1 are formatted with decimal places.
 */
export const formatResultDifferenceAsPercent = (result: number, fallback = 'N/A') =>
  number.isFinite(result)
    ? pipe(result, number.percentFromUnitInterval, renderPercentAsDecimal, replaceMinus)
    : fallback;

/**
 * Format a numerator and denominator as a ratio of integers.
 */
export const formatPartsAsRatio = (numerator: number, denominator: number) =>
  `${Math.round(numerator)}/${Math.round(denominator)}`;

/**
 * Turns a unit interval (result in [0, 1]) into a ratio (e.g. "3/5").
 * Useful for displaying a result to TAs.
 * For student-facing code, consider {@link formatResultAsPercent}
 * @see {@link formatPercentAsRatio}
 */
export const formatResultAsRatio =
  (maximumPoints: number | null | undefined) => (result: number | null | undefined) => {
    if (maximumPoints == null || maximumPoints === 0) return '';
    if (result == null || result > 1 || result < 0) return 'N/A';
    return formatPartsAsRatio(result * maximumPoints, maximumPoints);
  };

/**
 * Turns a percentage (value in [0, 100]) into a ratio (e.g. "3/5").
 * @see {@link formatResultAsRatio}
 */
export const formatPercentAsRatio = (maximumPoints: number | null | undefined) =>
  flow(number.unitIntervalFromRange(0, 100), formatResultAsRatio(maximumPoints));
