import type { task } from '@code-expert/prelude';
import * as rpc from '@code-expert/rpc-client';
import type { ProcedureArgs, ProcedureName, ProcedureResult } from '@code-expert/rpc-common';
import type { Procedures } from '/imports/api/rpc';
import { socket } from '/imports/ui/hooks/socket';

type Args<P> = [P] extends [undefined] ? [] : [P];

export const invokeProcedure: <N extends ProcedureName<Procedures>>(
  name: N,
) => (...args: Args<ProcedureArgs<Procedures, N>>) => task.Task<ProcedureResult<Procedures, N>> =
  rpc.invokeProcedure(socket) as $Inexpressible;

// We require this to be present in Playwright e2e tests.
(globalThis as $IntentionalAny).__PLAYWRIGHT_invokeProcedure__ = invokeProcedure;

export const invokeProcedureINTERNAL: <N extends ProcedureName<Procedures>>(
  name: N,
) => (args: ProcedureArgs<Procedures, N>) => task.Task<ProcedureResult<Procedures, N>> =
  rpc.invokeProcedure(socket);

export const useProcedure = rpc.makeUseProcedure<Procedures>(socket);

export const useCachedProcedure = rpc.makeUseCachedProcedure<Procedures>(socket);
