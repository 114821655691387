import React from 'react';

import { pipe } from '@code-expert/prelude';
import { parser } from '/imports/modules/Routing';
import { ltiErrorRoute } from '/imports/startup/shared/routes/lti';
import { PublicRoute } from '/imports/ui/components/PrivateRoute';
import { TopNavLayout } from '/imports/ui/layouts/TopNavLayout';

const LtiError = React.lazy(() => import('/imports/ui/pages/lti/LtiError'));

export const ltiRouter = pipe(
  ltiErrorRoute.parser,
  parser.map((props) => (
    <PublicRoute
      component={() => <LtiError {...props} />}
      denyCapabilities={['exam']}
      layout={TopNavLayout}
    />
  )),
);
