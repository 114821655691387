import type { Newtype } from 'newtype-ts';
import { iso } from 'newtype-ts';

import type { eq } from '@code-expert/prelude';
import { adt, assertNonNull, flow, iots, number, option, pipe } from '@code-expert/prelude';
import type {
  CodeSubmission,
  Submission,
  SubmissionPublic,
} from '/imports/domain/submission/submission';
import { foldSubmissionPublic } from '/imports/domain/submission/submission';

export const xpModeProps = {
  binary: null,
  proportional: null,
};
export const XpMode = iots.keyof(xpModeProps);

export type XpMode = iots.TypeOf<typeof XpMode>;
export const xpModes = Object.keys(XpMode.keys) as Array<XpMode>;

export const foldXpMode = adt.foldFromKeys(xpModeProps);

const viewXpModeProps = { ...xpModeProps, off: null };
export const foldViewXpMode = adt.foldFromKeys(viewXpModeProps);
export const ViewXpMode = iots.keyof(viewXpModeProps);
export type ViewXpMode = adt.TypeOfKeys<typeof foldViewXpMode>;

export const Grading = iots.strict({
  maximumPoints: iots.number,
  minimumPoints: iots.number,
  xp: iots.number,
  xpMode: XpMode,
});

export type Grading = iots.TypeOf<typeof Grading>;

// -------------------------------------------------------------------------------------------------
// Cherry Picking

export const CherryPickMechanismC = iots.keyof({ best: null, last: null });
export type CherryPickMechanismC = typeof CherryPickMechanismC;
export type CherryPickMechanism = iots.TypeOf<CherryPickMechanismC>;

export const foldCherryPickMechanism = adt.foldFromKeys(CherryPickMechanismC.keys);

export const DEFAULT_CHERRY_PICK_MECHANISM: CherryPickMechanism = 'last';

// -------------------------------------------------------------------------------------------------

/**
 * Test if two autograderResults are approximately equal.
 */
export const EqAutograderResult: eq.Eq<number> = number.getEqAbsolute(1e-6);

// -------------------------------------------------------------------------------------------------

/**
 * Points that a student can earn. They are expressed as non-negative integers.
 */
export type Points = Newtype<'Points', number>;

export const pointsIso = iso<Points>();

/**
 * Autograded points are similar to {@link Points}, but they can be non-negative real numbers.
 */
export type AutograderPoints = Newtype<'AutograderPoints', number>;

export const autograderPointsIso = iso<AutograderPoints>();

/**
 * PointsLike is a mix of {@link Points} and {@link AutograderPoints} to be able to represent both
 * values in the UI.
 */
export type PointsLike = Points | AutograderPoints;

export const pointsLikeIso = iso<PointsLike>();

export const autograderPointsFromUnitInterval =
  (maximumPoints: number) => (result: number.UnitInterval) =>
    pipe(result, number.unitIntervalToRange(0, maximumPoints), autograderPointsIso.from);

export const autograderPointsToUnitInterval =
  (maximumPoints: number) =>
  (points: PointsLike): number.UnitInterval =>
    pipe(pointsLikeIso.to(points), number.unitIntervalFromRange(0, maximumPoints));

/**
 * Given a {@link PointsLike} value, determine whether it could be an autograded result. We can only
 * determine this using approximate equality because Points are converted from and to floating point
 * numbers under the hood, which is a lossy conversion.
 */
export const isAutograderPoints =
  (grading: Grading, submission: Pick<CodeSubmission, '_id' | 'autograderResult' | 'result'>) =>
  (points: PointsLike): points is AutograderPoints =>
    submission.autograderResult != null &&
    EqAutograderResult.equals(
      submission.autograderResult,
      autograderPointsToUnitInterval(grading.maximumPoints)(points),
    );

/**
 * Turns a unit interval (result in [0, 1]) into an integer in the range [0, maximumPoints].
 * Useful for actual data transformations. If you just care about displaying the result to a
 * TA, consider {@link formatResultAsRatio}.
 *
 * @see pointsToUnitInterval
 */
export const pointsFromUnitInterval = (maximumPoints: number) => (result: number | null) => {
  if (result == null) return 0;
  return pipe(result, number.unitIntervalToRange(0, maximumPoints), Math.round);
};

/**
 * Turns an integer in the range [0, maximumPoints] into value in the unit interval ([0, 1]).
 *
 * @see pointsFromUnitInterval
 */
export const pointsToUnitInterval =
  (maximumPoints: number) =>
  (points: number): number.UnitInterval =>
    number.clampUnitInterval(maximumPoints === 0 ? 0 : points / maximumPoints);

/**
 * If a submission has an autograder result and it is approximately equal to the submission's
 * result, pick the autograder result as the "active result" that should be worked with.
 */
export const activeResultFromSubmission = ({
  autograderResult,
  result,
}: Pick<CodeSubmission, '_id' | 'autograderResult' | 'result'>): number.UnitInterval => {
  if (autograderResult != null) {
    assertNonNull(result, 'If an autograderResult exists, result must never be null.');
    if (EqAutograderResult.equals(autograderResult, result)) {
      return autograderResult as number.UnitInterval;
    }
  }
  return (result ?? 0) as number.UnitInterval;
};

/**
 * Given a {@link CodeSubmission}, return {@link AutograderPoints} if the submission's `result` and
 * `autograderResult` are similar, or return {@link Points} otherwise.
 */
export const pointsLikeFromSubmission = (
  grading: Grading,
  { autograderResult, result }: Pick<CodeSubmission, '_id' | 'autograderResult' | 'result'>,
): PointsLike => {
  if (autograderResult != null) {
    assertNonNull(result, 'If an autograderResult exists, result must never be null.');
    if (EqAutograderResult.equals(autograderResult, result)) {
      return autograderPointsIso.from(
        number.unitIntervalToRange(0, grading.maximumPoints)(autograderResult),
      );
    }
  }
  return pointsIso.from(pointsFromUnitInterval(grading.maximumPoints)(result ?? null));
};

/**
 * Check whether the result for this submission is unsound. A probable source of this
 * problem is that the submission has crashed and did not return a result.
 */
export const isSubmissionUnsound: (grading?: Grading) => (x: SubmissionPublic) => boolean = (
  grading,
) =>
  flow(
    // The result is only relevant for graded submissions
    option.fromPredicate(() => grading != null),
    option.map(
      foldSubmissionPublic({
        // Always use a code submission's result (if any), or fall back to the pre-result (if any)
        code: ({ result, preResult }) => {
          const value = result !== undefined ? result : preResult;
          return value !== undefined && !Number.isFinite(value);
        }, // Other submissions can't have unsound results
        dispense: () => false,
        link: () => false,
        presentation: () => false,
      }),
    ),
    option.getOrElse<boolean>(() => false),
  );

/**
 * The point threshold above which a submission passes is computed from
 * the ratio of the minimum and maximum points defined by the grading.
 *
 * If maximum points are set to zero, the pass threshold is also zero to make the submission pass.
 *
 * Make sure to keep this logic in sync with {@link submissionPassesMongo}.
 */
const submissionPassThreshold = ({ minimumPoints, maximumPoints }: Grading) => {
  const precision = 1e4;
  const threshold = Math.floor((minimumPoints * precision) / maximumPoints) / precision;
  return Number.isFinite(threshold) ? threshold : 0;
};

/**
 * Determine whether a submission is passed or failed. How partial success is handled
 * depends on {@link Grading}.
 *
 * If the difference between the result and the minimum points that need to be reached
 * is below a threshold, the submission is considered passed.
 *
 * Make sure to keep this logic in sync with {@link submissionPassesMongo}.
 */
export const submissionPasses =
  (grading: Grading) =>
  (result: Submission['result']): boolean => {
    if (result == null) return false;
    if (result < 0 || 1 < result) return false;
    return foldXpMode(grading.xpMode, {
      binary: () => result >= submissionPassThreshold(grading),
      proportional: () => true,
    });
  };

/**
 * A variation of {@link submissionPasses} that works with {@link PointsLike} instead.
 */
export const submissionPassesPoints = (grading: Grading) => (points: PointsLike) =>
  submissionPasses(grading)(pointsToUnitInterval(grading.maximumPoints)(pointsLikeIso.to(points)));
