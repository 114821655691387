import { option, pipe, remoteOption } from '@code-expert/prelude';
import { useDeepCompareEffect } from '@code-expert/react';
import { contentTypeAdt } from '/imports/domain';
import { useNotification } from '/imports/ui/components/Notifications/useNotification';
import { usePublication } from '/imports/ui/hooks/usePublication';

function useGlobalNotification() {
  const [sendNotification, clearNotification] = useNotification();
  const notification = usePublication('notifications.global', { method: 'findOne' });

  useDeepCompareEffect(() => {
    pipe(
      notification,
      remoteOption.toOption,
      option.fold(
        () => {
          clearNotification({ key: 'global' });
        },
        ({ _id, title, severity, text }) => {
          sendNotification({
            key: 'global',
            id: _id,
            title,
            severity,
            content: text == null ? undefined : contentTypeAdt.markdown(text),
            kind: 'App',
          });
        },
      ),
    );
  }, [clearNotification, notification, sendNotification]);
}

export default useGlobalNotification;
