import { iots } from '@code-expert/prelude';

export const ltiErrorCodes = {
  invalidWorkflow: null,
  ltiSessionExpired: null,
  ltiSessionNotFound: null,
  ltiSessionUpdateFailed: null,
  ltiSessionUserMismatch: null,
  projectCloningFailed: null,
  projectUpdateFailed: null,
  solvedSessionNotFound: null,
  studentTemplateNotFound: null,
  submissionDeleted: null,
  submissionNotFound: null,
  unfinishedAttempt: null,
};

export const LtiErrorCode = iots.keyof(ltiErrorCodes);

export type LtiErrorCode = keyof typeof ltiErrorCodes;
