import React from 'react';

import NotFound from '/imports/ui/components/NotFound';

export const InvalidEnrolmentLink = () => (
  <NotFound
    title="Could not enrol in course"
    text="You tried to enrol in a course that doesn't exist. Please ask your course staff for the correct enrolment link."
    showBackButton={false}
  />
);
