import { ProjectEntryKey } from '@code-expert/pfs/dist/identity';
import type {
  ProjectDirectory as PfsProjectDirectory,
  ProjectFile as PfsProjectFile,
} from '@code-expert/pfs/dist/ProjectFile';
import { isDirectory } from '@code-expert/pfs/dist/ProjectFile';
import { iots } from '@code-expert/prelude';
import { PfsRoleC, ProjectId, UserId } from '/imports/domain';

// FIXME We need to be careful not to import the full PFS here!

type ReplaceUserId<A extends { userId: string }> = Omit<A, 'userId'> & {
  userId: UserId;
};

export type ProjectFile = ReplaceUserId<PfsProjectFile>;
export type ProjectDirectory = ReplaceUserId<PfsProjectDirectory>;
export type ProjectEntry = ProjectFile | ProjectDirectory;

export const PhaseC = iots.keyof({ interactive: null, submission: null });

export const PermissionsC = iots.strict({
  phase: iots.array(PhaseC),
  read: iots.array(PfsRoleC),
  write: iots.array(PfsRoleC),
});

const projectEntryPropsStrict = {
  _id: ProjectEntryKey,
  key: ProjectEntryKey,
  version: iots.number,
  current: iots.boolean,
  projectId: ProjectId,
  name: iots.string,
  createdAt: iots.date,
  userId: UserId,
};

const projectEntryPropsPartial = {
  originKey: ProjectEntryKey,
  originVersion: iots.number,
  permissions: PermissionsC,
  replacedAt: iots.date,
  deleted: iots.boolean,
  originDeleted: iots.boolean,
};

export const ProjectFileC = iots.struct(
  {
    ...projectEntryPropsStrict,
    fileId: iots.string,
    size: iots.number,
    type: iots.string,
  },
  projectEntryPropsPartial,
) satisfies iots.Type<ProjectFile, unknown>;

export const ProjectDirectoryC = iots.struct(
  {
    ...projectEntryPropsStrict,
    children: iots.array(ProjectEntryKey),
    type: iots.literal('inode/directory'),
  },
  projectEntryPropsPartial,
) satisfies iots.Type<ProjectDirectory, unknown>;

export const ProjectEntryC = iots.union([ProjectFileC, ProjectDirectoryC]);

export const foldProjectEntry =
  <A>({
    file,
    directory,
  }: {
    file: (_: ProjectFile) => A;
    directory: (_: ProjectDirectory) => A;
  }) =>
  (f: ProjectEntry) =>
    isDirectory(f) ? directory(f) : file(f);
