import { iots } from '@code-expert/prelude';
import {
  EnvironmentId,
  FileId,
  JobId,
  ProjectId,
  ProjectUploadChunkId,
  ProjectUploadFileId,
  ServiceJobId,
  SnapshotId,
  UserId,
} from '/imports/domain/identity';

export const ProjectUploadFileC = iots.strict({
  _id: ProjectUploadFileId,
  chunkSize: iots.number,
  filename: iots.string,
  length: iots.number,
  md5: iots.string,
  metadata: iots.struct(
    {
      createdAt: iots.date,
      isSubmission: iots.boolean,
    },
    {
      environmentId: EnvironmentId,
      jobId: iots.union([JobId, ServiceJobId]),
      name: iots.string,
      projectId: ProjectId,
      snapshotId: SnapshotId,
      type: iots.string,
      userId: UserId,
    },
  ),
  uploadDate: iots.date,
});

export type ProjectUploadFile = iots.TypeOf<typeof ProjectUploadFileC>;

export const ProjectUploadChunkC = iots.struct(
  {
    _id: ProjectUploadChunkId,
    data: iots.unknown,
    files_id: iots.array(FileId),
    n: iots.number,
  },
  {
    metadata: iots.strict({
      createdAt: iots.date,
      isSubmission: iots.boolean,
    }),
  },
);

export type ProjectUploadChunk = iots.TypeOf<typeof ProjectUploadChunkC>;
