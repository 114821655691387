import type { DistributiveOmit } from '@code-expert/type-utils';
import type { ExerciseId } from '/imports/domain/identity';
import type { Example, Exercise } from './TaskCollection';

export type BonusExerciseFree = DistributiveOmit<
  Exercise | Example,
  'conditional' | 'prerequisites'
> & {
  conditional: false;
};

export interface BonusExercisePrerequisite {
  _id: ExerciseId;
  name: string;
  handoutDate?: Date;
  dueDate: Date;
  earnedXP: number;
  achievableXP: number;
  totalXP: number;
}

export type BonusExerciseLockable = DistributiveOmit<Exercise, 'conditional' | 'prerequisites'> & {
  conditional: true;
  prerequisites: NonEmptyArray<BonusExercisePrerequisite>;
  earnedPrerequisiteXP: number;
  requiredPrerequisiteXP: number;
};
/**
 * A BonusExercise must be unlocked by collecting experience points before it
 * can be worked on.
 */
export type BonusExercise = BonusExerciseFree | BonusExerciseLockable;

export const getPrerequisitesMaxXP = (prerequisitesExercises: Array<{ xp: number }>): number =>
  prerequisitesExercises.reduce((accumulator, prereq) => accumulator + prereq.xp, 0);
