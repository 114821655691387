import React from 'react';

import { useForceUpdateWithDep } from '/imports/ui/hooks/useForceUpdate';
import type { KeyValueProp, ValueProp } from '/imports/utils/UiState';

export const useUiState = <V>(
  prop: ValueProp<V>,
  initialState: V,
  ttl = '90d',
): readonly [V, (value: V) => void] => {
  const [shouldUpdate, forceRerender] = useForceUpdateWithDep();

  const value = React.useMemo(
    () => prop.get() ?? initialState,
    // TODO cx-3392 Waiting for eslint v9 compatible version - eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUpdate, initialState, prop],
  );

  const updateValue = React.useCallback(
    (nextValue: V) => {
      prop.set(nextValue, ttl);
      forceRerender();
    },
    [forceRerender, ttl, prop],
  );

  return [value, updateValue] as const;
};

export const useKeyValueUiState = <K extends string, V>(
  prop: KeyValueProp<K, V>,
  key: K,
  initialState: V,
  ttl = '90d',
): readonly [V, (value: V) => void] => {
  const [shouldUpdate, forceRerender] = useForceUpdateWithDep();

  const value = React.useMemo(
    () => prop.get(key) ?? initialState,
    // TODO cx-3392 Waiting for eslint v9 compatible version - eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUpdate, key, initialState, prop],
  );

  const updateValue = React.useCallback(
    (nextValue: V) => {
      prop.set(key, nextValue, ttl);
      forceRerender();
    },
    [forceRerender, key, ttl, prop],
  );

  return [value, updateValue] as const;
};
