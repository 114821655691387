import React from 'react';

import { Popover, Row, Typography } from 'antd';

import type { ExamId, UserExamPublic } from '/imports/domain';
import DataMatrix from '/imports/ui/components/DataMatrix';
import UserName from '/imports/ui/components/UserName';
import { Icon } from '/imports/ui/foundation/Icons';
import { formatPattern, hexPattern } from '/imports/utils/format';

/**
 * Exam usernames are hexadecimal strings. We encode them as a string of numbers
 * here for display in the datamatrix barcode.
 */
function encodeExamUsername(username: string) {
  return parseInt(username, 16).toString(10);
}

export interface ExamUserDataMatrixProps {
  // This field is used to enforce the presence of an examId, but the value is not used internally
  examId: ExamId;
  user: UserExamPublic;
  scale?: number;
}

export function ExamUserDataMatrix({ user, scale = 64 }: ExamUserDataMatrixProps) {
  return (
    <span onClick={(e) => e.stopPropagation()} aria-hidden="true" data-test-id="dataMatrix">
      <Popover
        placement="bottomRight"
        trigger="click"
        overlayStyle={{ width: 170 }}
        content={
          <Row justify="center">
            <Typography.Text strong style={{ fontSize: 18 }}>
              <span data-test-id="examUserId">
                {formatPattern(user.username, hexPattern).formatted}
              </span>
              <br />
              <UserName userId={user._id} />
            </Typography.Text>
          </Row>
        }
      >
        <div>
          {user.legi != null ? (
            <div style={{ display: 'flex', alignItems: 'center', height: 64 }}>
              <Icon name="check" />
            </div>
          ) : (
            <DataMatrix message={encodeExamUsername(user.username)} scale={scale} />
          )}
        </div>
      </Popover>
    </span>
  );
}
