import { adt, iots } from '@code-expert/prelude';
import { RunnerSettingsId } from '/imports/domain/identity';

export interface DedicatedScheduleSetting {
  type: 'dedicatedCpus';
  cpuAffinity: Array<number>;
  dedicatedCpus: number;
  interactiveJobReserve: number;
  maxConcurrentUserJobs: number;
}

const DedicatedScheduleSettingC = iots.strict({
  type: iots.literal('dedicatedCpus'),
  cpuAffinity: iots.array(iots.number),
  dedicatedCpus: iots.number,
  interactiveJobReserve: iots.number,
  maxConcurrentUserJobs: iots.number,
});

export interface SharedScheduleSetting {
  type: 'shared';
  maxConcurrentJobs: number;
  interactiveJobReserve: number;
  maxConcurrentUserJobs: number;
}

const SharedScheduleSettingC = iots.strict({
  type: iots.literal('shared'),
  maxConcurrentJobs: iots.number,
  interactiveJobReserve: iots.number,
  maxConcurrentUserJobs: iots.number,
});

export type ScheduleSettings = DedicatedScheduleSetting | SharedScheduleSetting;

const ScheduleSettingsC = iots.union([DedicatedScheduleSettingC, SharedScheduleSettingC]);

export interface RunnerSettings {
  _id: RunnerSettingsId;
  name: string;
  lastUpdate: Date;
  settings: {
    scheduleSettings: ScheduleSettings;
    projectFileRoot: string;
    queueProcessingInterval: number;
    runnerName?: string;
    dockerWorkdir: string;
    streamTimeout: number;
  };
  info?: {
    lastStartup: Date;
    cpuCount: number;
    memory: number;
    version: string;
    lastBoot: Date;
  };
}

export const RunnerSettingsC = iots.struct(
  {
    _id: RunnerSettingsId,
    name: iots.string,
    lastUpdate: iots.date,
    settings: iots.struct(
      {
        scheduleSettings: ScheduleSettingsC,
        projectFileRoot: iots.string,
        queueProcessingInterval: iots.number,
        dockerWorkdir: iots.string,
        streamTimeout: iots.number,
      },
      {
        runnerName: iots.string,
      },
    ),
  },
  {
    info: iots.strict({
      lastStartup: iots.date,
      cpuCount: iots.number,
      memory: iots.number,
      version: iots.string,
      lastBoot: iots.date,
    }),
  },
) satisfies iots.Type<RunnerSettings, unknown>;

export const foldScheduleSettings = adt.foldFromTags<ScheduleSettings, 'type'>('type');
