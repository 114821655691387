import React from 'react';

import type { Brand } from '@code-expert/type-utils';

export type ForceUpdateDependency = Brand<unknown, 'ForceUpdateDependency'>;
type ForceUpdateTrigger = Brand<() => void, 'ForceUpdateTrigger'>;

/**
 * In addition to a function that can trigger a component update, the `ForceUpdateDependency` value
 * can be used as a dependency to other hooks to force them to update, too.
 * CAUTION: Use rarely, force updating should only be used as a last resort.
 */
export function useForceUpdateWithDep(): [ForceUpdateDependency, ForceUpdateTrigger] {
  return React.useReducer((s: number) => (s + 1) % Number.MAX_SAFE_INTEGER, 1) as $IntentionalAny;
}

/**
 * Trigger a component update.
 * CAUTION: Use rarely, force updating should only be used as a last resort.
 */
export function useForceUpdate() {
  const [, forceUpdate] = useForceUpdateWithDep();
  return forceUpdate;
}
