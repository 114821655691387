import type { ReactNode } from 'react';
import React from 'react';

import { Col, Row, Typography } from 'antd';

export type MainContentProps = React.PropsWithChildren<{
  title: string;
  extra?: ReactNode;
  /** This is an escape hatch: only use in dire situations. */
  style?: React.CSSProperties;
}>;

export const MainContent = ({ title, extra = null, style = {}, children }: MainContentProps) => (
  <div style={{ padding: '16px 24px', ...style }}>
    <Row justify="space-between">
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          lineHeight: 1.5715,
          margin: '4px 0',
        }}
      >
        <Typography.Title
          data-test="main-content-title"
          style={{
            marginRight: 12,
            marginBottom: 0,
            fontSize: 20,
            lineHeight: '32px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography.Title>
      </Col>
      <Col style={{ lineHeight: 1.5715, margin: '4px 0', whiteSpace: 'nowrap' }}>{extra}</Col>
    </Row>
    <div data-test="main-content" style={{ paddingTop: 12 }}>
      {children}
    </div>
  </div>
);
