import React, { useEffect } from 'react';

import { option, pipe, struct } from '@code-expert/prelude';
import { GuardOption } from '@code-expert/react';
import type { UserId } from '/imports/domain';
import { useSafeState } from '/imports/ui/hooks/useSafeState';
import type { UserNameInterface } from '/imports/ui/hooks/useUserNameCache';
import { useUserNameCache } from '/imports/ui/hooks/useUserNameCache';

export const userNameHandle = {
  useUserName(userId: UserId, showTitle = true) {
    const { getUserFromCache } = useUserNameCache();

    const [user, setUser] = useSafeState<option.Option<UserNameInterface>>(option.none);

    useEffect(() => {
      void (async () => {
        const user = await getUserFromCache(userId);
        setUser(option.fromNullable(user));
      })();
    }, [getUserFromCache, userId, setUser]);

    return pipe(user, option.map(struct.get(showTitle ? 'userTitleName' : 'userName')));
  },
};

export type UserNameHandle = typeof userNameHandle;

// -------------------------------------------------------------------------------------------------

export interface UserNamePureProps {
  handle: UserNameHandle;
  userId: UserId;
  showTitle?: boolean;
}

export const UserNamePure = React.memo(function UserNamePure({
  handle: { useUserName },
  userId,
  showTitle,
}: UserNamePureProps) {
  return (
    <GuardOption
      value={useUserName(userId, showTitle)}
      render={(username) => <span>{username}</span>}
    />
  );
});

// -------------------------------------------------------------------------------------------------

export type UserNameProps = Omit<UserNamePureProps, 'handle'>;

/**
 * Use this if the Name of the user rarely changes. This is valid except during exams.
 */
function UserName(props: UserNameProps) {
  return <UserNamePure handle={userNameHandle} {...props} />;
}

export default UserName;
