import type { nonEmptyArray } from '@code-expert/prelude';
import {
  adt,
  assertNonNull,
  eq,
  invariant,
  iots,
  isOneOf,
  option,
  refinement,
} from '@code-expert/prelude';
import {
  foldXpMode,
  Grading,
  isSubmissionUnsound,
  submissionPasses,
} from '/imports/domain/grading';
import type { UserId } from '/imports/domain/identity';
import {
  CourseId,
  ProjectId,
  projectIdEq,
  SemesterId,
  SnapshotId,
  snapshotIdEq,
  TaskId,
} from '/imports/domain/identity';
import type { SubmissionPublic } from '/imports/domain/submission';
import {
  foldSubmissionPublic,
  pickRelevantCodeSubmission,
  pickRelevantLinkSubmission,
  pickRelevantPresentationSubmission,
} from '/imports/domain/submission';
import type { TaskProgress } from './taskProgress';
import { taskProgress } from './taskProgress';

// -------------------------------------------------------------------------------------------------
// Query

export const TaskKind = iots.keyof({
  code: null,
  link: null,
  etLink: null,
  presentation: null,
});
export type TaskKind = iots.TypeOf<typeof TaskKind>;
export const taskKinds = Object.keys(TaskKind.keys) as nonEmptyArray.NonEmptyArray<TaskKind>;

export const foldTaskKind = adt.foldFromKeys({
  code: null,
  link: null,
  etLink: null,
  presentation: null,
});

export const taskKindName = foldTaskKind({
  code: () => 'Code',
  link: () => 'Link',
  etLink: () => 'E.Tutorial',
  presentation: () => 'Presentation',
});

export const RegradeTemplateRefInit = iots.struct(
  {
    regradeProjectId: ProjectId,
  },
  {
    regradeSnapshotId: iots.undefined,
    state: iots.undefined,
  },
);

export type RegradeTemplateRefInit = iots.TypeOf<typeof RegradeTemplateRefInit>;

export const RegradeTemplateRefCreated = iots.strict({
  regradeProjectId: ProjectId,
  regradeSnapshotId: SnapshotId,
  state: iots.literal('created'),
});
export type RegradeTemplateRefCreated = iots.TypeOf<typeof RegradeTemplateRefCreated>;

export const RegradeTemplateRefInprogress = iots.strict({
  regradeProjectId: ProjectId,
  regradeSnapshotId: SnapshotId,
  state: iots.literal('inprogress'),
});

export const RegradeTemplateRefDone = iots.strict({
  regradeProjectId: ProjectId,
  regradeSnapshotId: SnapshotId,
  state: iots.literal('done'),
});
export type RegradeTemplateRefDone = iots.TypeOf<typeof RegradeTemplateRefDone>;

export const isDone = (t: RegradeTemplateRef): t is RegradeTemplateRefDone => t.state === 'done';

export const RegradeTemplateRefError = iots.strict({
  regradeProjectId: ProjectId,
  regradeSnapshotId: SnapshotId,
  state: iots.literal('error'),
});

const RegradeTemplateRef = iots.union([
  RegradeTemplateRefInit,
  RegradeTemplateRefCreated,
  RegradeTemplateRefInprogress,
  RegradeTemplateRefDone,
  RegradeTemplateRefError,
]);
export type RegradeTemplateRef = iots.TypeOf<typeof RegradeTemplateRef>;

export const foldRegradeState = adt.foldFromKeys({
  created: null,
  inprogress: null,
  done: null,
  error: null,
});
export type RegradeState = adt.TypeOfKeys<typeof foldRegradeState>;
export const regradeStates: Array<RegradeState> = foldRegradeState.keys;

export const TaskName = iots.maxLengthString(80);

const BaseTask = iots.strict({
  _id: TaskId,
  name: TaskName,
  courseId: CourseId,
  semester: SemesterId,
});

export type BaseTask = iots.TypeOf<typeof BaseTask>;

export const CodeTask = iots.intersection([
  BaseTask,
  iots.struct(
    {
      kind: iots.literal('code'),
      masterSolutionId: ProjectId,
      studentTemplateId: ProjectId,
    },
    {
      copiedFromId: TaskId,
      regradeTemplates: iots.array(RegradeTemplateRef),
      regradeProjectId: ProjectId,
      regradeSnapshotId: SnapshotId,
      grading: Grading,
    },
  ),
]);

export type CodeTask = iots.TypeOf<typeof CodeTask>;

export const LinkTask = iots.intersection([
  BaseTask,
  iots.struct(
    {
      kind: iots.literal('link'),
      url: iots.string,
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
]);

export type LinkTask = iots.TypeOf<typeof LinkTask>;

export const ETLinkTask = iots.intersection([
  BaseTask,
  iots.struct(
    {
      kind: iots.literal('etLink'),
      url: iots.string,
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
]);

export type ETLinkTask = iots.TypeOf<typeof ETLinkTask>;

export const PresentationTask = iots.intersection([
  BaseTask,
  iots.struct(
    {
      kind: iots.literal('presentation'),
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
]);

export type PresentationTask = iots.TypeOf<typeof PresentationTask>;

export const Task = iots.union([CodeTask, LinkTask, ETLinkTask, PresentationTask]);

export type Task = iots.TypeOf<typeof Task>;
// This represents the keys from Lists objects that should be published
// to the client. If we add secret properties to List objects, don't list
// them here to keep them private to the server.
export const tasksPublicFields = {
  _id: 1,
  name: 1,
  kind: 1,
  courseId: 1,
  semester: 1,

  grading: 1,

  masterSolutionId: 1,
  studentTemplateId: 1,
  regradeTemplates: 1,
  regradeProjectId: 1,
  regradeSnapshotId: 1,

  url: 1,
} as const;
export type TaskPublicFields = keyof typeof tasksPublicFields;
export type CodeTaskPublic = Pick<CodeTask, TaskPublicFields & keyof CodeTask>;
export type LinkTaskPublic = Pick<LinkTask, TaskPublicFields & keyof LinkTask>;
export type ETLinkTaskPublic = Pick<ETLinkTask, TaskPublicFields & keyof ETLinkTask>;
export type PresentationTaskPublic = Pick<
  PresentationTask,
  TaskPublicFields & keyof PresentationTask
>;
export type TaskPublic =
  | CodeTaskPublic
  | LinkTaskPublic
  | ETLinkTaskPublic
  | PresentationTaskPublic;
export const foldTask = adt.foldFromTags<Task, 'kind'>('kind');
type RequireKeys<T, K extends keyof T> = Required<Pick<T, K>> & Partial<Omit<T, K>>;
export type TaskDiffOf<T extends Task> = RequireKeys<T, 'kind'>;
/**
 * Intermediate helper type for the task creation/persistence and editing process.
 * Identical to Task, but every property except 'kind' is optional.
 */
export type TaskDiff =
  | TaskDiffOf<CodeTask>
  | TaskDiffOf<LinkTask>
  | TaskDiffOf<ETLinkTask>
  | TaskDiffOf<PresentationTask>;

const foldTaskDiff = adt.foldFromTags<TaskDiff, 'kind'>('kind');

type PickSubmission = <S extends SubmissionPublic>(submissions: Array<S>) => option.Option<S>;

export const pickRelevantSubmissionForTask: (kind: TaskKind) => PickSubmission = foldTaskKind({
  code: () => pickRelevantCodeSubmission,
  presentation: () => pickRelevantPresentationSubmission,
  link: () => pickRelevantLinkSubmission,
  etLink: () => pickRelevantLinkSubmission,
});

export const UnpersistedTask = iots.union([
  iots.struct(
    {
      name: iots.maxLengthString(80),
      kind: iots.literal('code'),
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
  iots.struct(
    {
      name: iots.maxLengthString(80),
      kind: iots.literal('link'),
      url: iots.string,
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
  iots.struct(
    {
      name: iots.maxLengthString(80),
      kind: iots.literal('etLink'),
      url: iots.string,
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
  iots.struct(
    {
      name: iots.maxLengthString(80),
      kind: iots.literal('presentation'),
    },
    {
      copiedFromId: TaskId,
      grading: Grading,
    },
  ),
]) satisfies iots.Type<UnpersistedTask, unknown>;
/**
 * Intermediate helper type for the task creation/persistence process.
 * Missing mainly relational data.
 */
export type UnpersistedTask =
  | Omit<
      CodeTask,
      | '_id'
      | 'courseId'
      | 'semester'
      | 'masterSolutionId'
      | 'studentTemplateId'
      | 'regradeTemplates'
      | 'regradeProjectId'
      | 'regradeSnapshot'
    >
  | Omit<LinkTask, '_id' | 'courseId' | 'semester'>
  | Omit<ETLinkTask, '_id' | 'courseId' | 'semester'>
  | Omit<PresentationTask, '_id' | 'courseId' | 'semester'>;

export function getUnpersistedTask(overrides: TaskDiff) {
  return foldTaskDiff<UnpersistedTask>(overrides, {
    code: (values) => ({
      name: '',
      ...values,
    }),
    link: (values) => ({
      name: '',
      url: '',
      ...values,
    }),
    etLink: (values) => ({
      name: '',
      url: '',
      ...values,
    }),
    presentation: (values) => ({
      name: '',
      ...values,
    }),
  });
}

export function isCodeTask<T extends Pick<Task, '_id' | 'kind'>>(
  task: T,
): task is Extract<T, Pick<CodeTask, 'kind'>> {
  return task.kind === 'code';
}

export const requireCodeTask = refinement.invariant(isCodeTask, () => 'Expected code task');

export function assertCodeTask<T extends Pick<Task, '_id' | 'kind'>>(
  task?: T,
): asserts task is Extract<T, Pick<CodeTask, 'kind'>> {
  assertNonNull(task, 'Task not found');
  invariant(isCodeTask(task), 'Expected code task', { taskId: task._id });
}

export function isLinklikeTask<T extends Pick<Task, '_id' | 'kind'>>(
  task?: T,
): task is Extract<T, Pick<LinkTask | ETLinkTask, 'kind'>> {
  return task != null && isOneOf('link', 'etLink')(task.kind);
}

export const foldUnpersistedTask = adt.foldFromTags<UnpersistedTask, 'kind'>('kind');

// fixme samuelv: naming
export function FIXMEgetXp(grading: Grading, result: number) {
  return foldXpMode(grading.xpMode, {
    binary: () => (submissionPasses(grading)(result) ? grading.xp : 0),
    proportional: () => Math.round(result * grading.xp),
  });
}

/**
 * Calculate the amount of earned XP depending on {@link XpMode} and {@link TaskProgress}.
 */
export function earnedXp(grading: Grading, progress: TaskProgress): option.Option<number> {
  return taskProgress.fold(progress, {
    none: () => option.none,
    pending: () => option.none,
    failed: () => option.none,
    success: (result) => option.some(FIXMEgetXp(grading, result)),
  });
}

// -------------------------------------------------------------------------------------------------
// Predicates

export const isTaskGraded = (x: Task): boolean => x.grading != null;

/**
 * Check whether the result for this task is unsound. A probable source of this
 * problem is that the submission has crashed and did not return a result.
 */
export const isUnsoundResult = (task: Task) => isSubmissionUnsound(task.grading);

interface HasRegradeRef {
  regradeProjectId: ProjectId;
  regradeSnapshotId: SnapshotId;
}

const hasRegradeRef = (x: Partial<HasRegradeRef>): x is HasRegradeRef =>
  'regradeProjectId' in x && 'regradeSnapshotId' in x;

const eqHasRegradeRefStrict = eq.struct({
  regradeProjectId: projectIdEq,
  regradeSnapshotId: snapshotIdEq,
});

export const eqRegradeRef = eq.fromEquals((x: Partial<HasRegradeRef>, y: Partial<HasRegradeRef>) =>
  hasRegradeRef(x) && hasRegradeRef(y) ? eqHasRegradeRefStrict.equals(x, y) : false,
);

/**
 * If a task references a regrade
 * - only keep submissions towards that regrade
 * - otherwise only keep submissions that do not reference any regrade
 */
const regradeFilter = (task: TaskPublic) =>
  foldSubmissionPublic({
    code: (x) => {
      if (task.kind === 'code') {
        return hasRegradeRef(task) ? eqRegradeRef.equals(task, x) : !hasRegradeRef(x);
      }
      return true;
    },
    link: () => true,
    presentation: () => true,
    dispense: () => true,
  });
const excludesRegrading = foldSubmissionPublic({
  code: (x) => !hasRegradeRef(x),
  link: () => true,
  presentation: () => true,
  dispense: () => true,
});
export const belongsToStudent = (id: string) => (x: SubmissionPublic) => x.studentId === id;
/**
 * Filter all submissions from a student to
 * - only those made towards a specific regrade
 * - or those that do not reference a regrade at all
 */
export const filterSubmissionsByRegradeRef =
  <T extends TaskPublic>(studentId: UserId, task: T) =>
  <S extends SubmissionPublic>(
    xs_: Array<S>,
  ): { submissions: Array<S>; isPartiallyRegraded: boolean } => {
    const xs = xs_.filter(belongsToStudent(studentId));
    const submissions = xs.filter(regradeFilter(task));
    const totalSubmissionCount = xs.filter(excludesRegrading).length;
    return {
      submissions,
      isPartiallyRegraded: totalSubmissionCount > submissions.length,
    };
  };
