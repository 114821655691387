import type { ComponentProps } from 'react';
import React from 'react';

import { GuardRemoteEither as GuardRemoteEither_ } from '@code-expert/react';
import type { PartialProps } from '@code-expert/type-utils';
import { Loading } from '/imports/ui/components/Loading';

type GuardRemoteEitherProps<E, A> = PartialProps<
  ComponentProps<typeof GuardRemoteEither_<E, A>>,
  'pending'
>;

/**
 * Guard a render tree from rendering unless we have a value
 */
export const GuardRemoteEither = <E, A>({
  value,
  render,
  pending = () => <Loading />,
  initial = pending,
  alt,
}: GuardRemoteEitherProps<E, A>) => (
  <GuardRemoteEither_ value={value} initial={initial} pending={pending} render={render} alt={alt} />
);
