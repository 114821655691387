import { iots } from '@code-expert/prelude';
import type { CourseUrl, SemesterId } from '/imports/domain';
import type { AccessCode } from '/imports/modules/Invitations/domain/AccessCode';
import { AccessCodeC } from '/imports/modules/Invitations/domain/AccessCode';
import { end, formatRoute, lit, type, url } from '/imports/modules/Routing';
import { pathToUrl } from '/imports/ui/helper/url';
import { myCoursesBaseR } from '/imports/ui/pages/myCourses/routes';

export const handleInvitationRoute = url(
  lit('invitations'),
  lit('accept'),
  type('accessCode', AccessCodeC),
  end,
);

export const formatHandleInvitationsUrl = (accessCode: AccessCode) =>
  pathToUrl(formatRoute(handleInvitationRoute)({ accessCode }));

export type ListInvitationsTab = 'pending' | 'archive';

const ListInvitationsTab: iots.Type<ListInvitationsTab> = iots.keyof({
  pending: null,
  archive: null,
});

export const listInvitationsRoute = url(lit('invitations'), type('tab', ListInvitationsTab), end);

export const formatListInvitationsUrl = ({
  semester,
  courseUrl,
  tab,
}: {
  semester: SemesterId;
  courseUrl: CourseUrl;
  tab?: ListInvitationsTab;
}): string =>
  formatRoute(url(myCoursesBaseR, listInvitationsRoute))({
    semester,
    courseUrl,
    tab: tab ?? 'pending',
  });
