import React from 'react';

import type { StyledSystemProp } from './styled-system';
import { useStyledSystem } from './styled-system';
import type { PolymorphicComponentPropWithRef } from './type-utils';

export type BoxProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  StyledSystemProp
>;

export type BoxComponent = <C extends React.ElementType = 'div'>(
  props: BoxProps<C>,
) => React.ReactNode;

export const Box: BoxComponent = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, children, ...props }: BoxProps<C>,
    ref?: React.ForwardedRef<C>,
  ) => {
    const Component = as ?? 'div';
    return (
      <Component {...useStyledSystem({ ...props, display: 'block' })} ref={ref}>
        {children}
      </Component>
    );
  },
);

(Box as $Inexpressible).displayName = 'Box';
