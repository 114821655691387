import React from 'react';

import { useTheme } from '/imports/ui/foundation/Theme';
import type { BoxProps } from './Box';
import type { Align, Justify, Size, StyledSystemProp } from './styled-system';
import { alignItems, justifyContent, sizeToNumber, useStyledSystem } from './styled-system';
import type { PolymorphicComponentPropWithRef } from './type-utils';

export type StackProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  StyledSystemProp &
    BoxProps<C> & {
      justify?: Justify;
      align?: Align;
      gap?: Size | number | boolean;
      wrap?: boolean;
      marginTrim?: 'block';
    }
>;

export type StackComponent = <C extends React.ElementType = 'div'>(
  props: StackProps<C>,
) => React.ReactNode;

const Stack = <C extends React.ElementType = 'div'>(
  { align, as, children, gap, justify, style, wrap, ...props }: StackProps<C>,
  ref?: React.ForwardedRef<C>,
) => {
  const { tokens } = useTheme();
  const Component = as ?? 'div';
  const elementStyles: React.CSSProperties = {
    justifyContent: justifyContent(justify),
    alignItems: alignItems(align),
    gap: sizeToNumber(tokens, 'size')(gap),
    flexWrap: wrap ? 'wrap' : 'nowrap',
    ...style,
  };
  return (
    <Component {...useStyledSystem({ ...props, style: elementStyles })} ref={ref}>
      {children}
    </Component>
  );
};

export const HStack: StackComponent = React.forwardRef(Stack);
(HStack as $Inexpressible).displayName = 'HStack';
(HStack as $Inexpressible).defaultProps = { display: 'flexRow' };

export const VStack: StackComponent = React.forwardRef(Stack);
(VStack as $Inexpressible).displayName = 'VStack';
(VStack as $Inexpressible).defaultProps = { display: 'flexColumn' };
