import {
  adt,
  array,
  assertNonNull,
  date,
  eq,
  flow,
  invariant,
  iots,
  not,
  number,
  option,
  ord,
  pipe,
  predicate,
  prism,
  record,
  refinement,
  string,
} from '@code-expert/prelude';
import type { DistributiveKeyof, DistributivePick } from '@code-expert/type-utils';
import {
  AppointmentId,
  JobId,
  ProjectId,
  SemesterId,
  SnapshotId,
  SubmissionId,
  TaskId,
  UserId,
  userIdEq,
} from '/imports/domain/identity';

export const submissionStatuses = {
  subscribed: null,
  called: null,
  alreadyCalled: null,
  marked: null,
  done: null,
  absent: null,
};
export const foldSubmissionStatus = adt.foldFromKeys(submissionStatuses);
export const StatusC = iots.keyof(submissionStatuses);
export type Status = adt.TypeOfKeys<typeof foldSubmissionStatus>;
export const statuses = foldSubmissionStatus.keys;
export const ordSubmissionStatus = ord.contramap(
  foldSubmissionStatus({
    subscribed: () => 1,
    called: () => 2,
    alreadyCalled: () => 2,
    marked: () => 3,
    done: () => 4,
    absent: () => 6,
  }),
)(number.Ord);

export const foldSubmissionKind = adt.foldFromKeys({
  code: null,
  dispense: null,
  link: null,
  presentation: null,
});
export type SubmissionKind = adt.TypeOfKeys<typeof foldSubmissionKind>;
export const submissionKinds = foldSubmissionKind.keys;

export interface BaseSubmission {
  readonly _id: SubmissionId;
  readonly semester: SemesterId;
  readonly taskId: TaskId;
  readonly studentId: UserId;
  readonly submissionDate: Date;
  readonly result?: number | null; // When the submission failed, this is set to null
}

export const baseSubmissionStrict = {
  _id: SubmissionId,
  semester: SemesterId,
  taskId: TaskId,
  studentId: UserId,
  submissionDate: iots.date,
};

export const baseSubmissionPartial = {
  result: iots.union([iots.number, iots.null]),
};

export interface CodeSubmission extends BaseSubmission {
  readonly kind: 'code';
  readonly feedbackProjectId: ProjectId;
  readonly preResult?: number | null; // When the submission failed, this is set to null
  readonly autograderResult?: number;
  readonly submissionJobId?: JobId;
  readonly additionalResults?: Record<string, string | undefined>;
  readonly preAdditionalResults?: Record<string, string | undefined>;
  readonly projectId: ProjectId;
  readonly snapshotId: SnapshotId;
  readonly reviewed?: boolean;
  readonly comments?: string;
  readonly reviewerId?: UserId;
  readonly reviewFaked?: boolean;
  readonly unread?: boolean;
  readonly regradeProjectId?: ProjectId;
  readonly regradeSnapshotId?: SnapshotId;
  readonly cherryPicked?: boolean;
}

export const CodeSubmissionC = iots.struct(
  {
    ...baseSubmissionStrict,
    kind: iots.literal('code'),
    feedbackProjectId: ProjectId,
    projectId: ProjectId,
    snapshotId: SnapshotId,
  },
  {
    ...baseSubmissionPartial,
    cherryPicked: iots.boolean,
    preResult: iots.union([iots.number, iots.null]),
    autograderResult: iots.number,
    submissionJobId: JobId,
    additionalResults: iots.record(iots.string, iots.union([iots.string, iots.undefined])),
    preAdditionalResults: iots.record(iots.string, iots.union([iots.string, iots.undefined])),
    reviewed: iots.boolean,
    comments: iots.string,
    reviewerId: UserId,
    reviewFaked: iots.boolean,
    unread: iots.boolean,
    regradeProjectId: ProjectId,
    regradeSnapshotId: SnapshotId,
  },
) satisfies iots.Type<CodeSubmission, unknown>;

export interface LinkSubmission extends BaseSubmission {
  readonly kind: 'link';
}

export const LinkSubmissionC = iots.struct(
  {
    ...baseSubmissionStrict,
    kind: iots.literal('link'),
  },
  {
    ...baseSubmissionPartial,
  },
) satisfies iots.Type<LinkSubmission, unknown>;

export interface DispenseSubmission extends BaseSubmission {
  readonly kind: 'dispense';
}

export const DispenseSubmissionC = iots.struct(
  {
    ...baseSubmissionStrict,
    kind: iots.literal('dispense'),
  },
  {
    ...baseSubmissionPartial,
  },
) satisfies iots.Type<DispenseSubmission, unknown>;

export const TaResult = iots.union([iots.literal(0), iots.literal(1), iots.literal(2)]);
export type TaResult = iots.TypeOf<typeof TaResult>;
export const foldTaResult = adt.foldFromKeys({ 0: null, 1: null, 2: null });

export interface PresentationSubmission extends BaseSubmission {
  readonly kind: 'presentation';
  status: Status;
  readonly appointmentId: AppointmentId;
  readonly reviewerId?: UserId;
  taFeedback?: {
    result: TaResult;
    freeText?: string;
  };
  partnerId?: UserId;
  organizedBy?: UserId;
  comment?: string;
}

export const PresentationSubmissionC = iots.struct(
  {
    ...baseSubmissionStrict,
    kind: iots.literal('presentation'),
    status: StatusC,
    appointmentId: AppointmentId,
  },
  {
    ...baseSubmissionPartial,
    reviewerId: UserId,
    taFeedback: iots.struct(
      {
        result: TaResult,
      },
      {
        freeText: iots.string,
      },
    ),
    partnerId: UserId,
    organizedBy: UserId,
    comment: iots.string,
  },
) satisfies iots.Type<PresentationSubmission, unknown>;

export const foldSubmission = adt.foldFromTags<Submission, 'kind'>('kind');

export const foldSubmissionPartial = adt.foldFromProp('kind');

export const foldSubmissionPublic = adt.foldFromTags<SubmissionPublic, 'kind'>('kind');
export const submissionPublicPrism = prism.fromTag<SubmissionPublic, 'kind'>('kind');

export function isCodeSubmission<S extends Pick<SubmissionPublic, 'kind'>>(
  submission?: S,
): submission is Extract<S, Pick<CodeSubmission, 'kind'>> {
  return submission?.kind === 'code';
}

export function assertCodeSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): asserts submission is Extract<S, Pick<CodeSubmission, 'kind'>> {
  assertNonNull(submission, 'Submission not found');
  invariant(isCodeSubmission(submission), 'CodeSubmission assertion failed');
}

export const requireCodeSubmission: <S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission: S,
) => Extract<S, Pick<CodeSubmission, 'kind'>> = refinement.invariant(
  isCodeSubmission,
  () => 'Expected code submission',
);

export function isLinkSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): submission is Extract<S, Pick<LinkSubmission, 'kind'>> {
  return submission?.kind === 'link';
}

export function assertLinkSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): asserts submission is Extract<S, Pick<LinkSubmission, 'kind'>> {
  assertNonNull(submission, 'Submission not found');
  invariant(isLinkSubmission(submission), 'LinkSubmission assertion failed');
}

export function isPresentationSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): submission is Extract<S, Pick<PresentationSubmission, 'kind'>> {
  return submission?.kind === 'presentation';
}

export function assertPresentationSubmission<S extends Pick<SubmissionPublic, '_id' | 'kind'>>(
  submission?: S,
): asserts submission is Extract<S, Pick<PresentationSubmission, 'kind'>> {
  assertNonNull(submission, 'Submission not found');
  invariant(isPresentationSubmission(submission), 'PresentationSubmission assertion failed');
}

export const requirePresentationSubmission = (submission: Submission): PresentationSubmission => {
  assertPresentationSubmission(submission);
  return submission;
};

export function isDispenseSubmission<S extends DistributivePick<Submission, '_id' | 'kind'>>(
  submission?: S,
): submission is Extract<S, Pick<DispenseSubmission, 'kind'>> {
  return submission?.kind === 'dispense';
}

export type Submission =
  | CodeSubmission
  | LinkSubmission
  | PresentationSubmission
  | DispenseSubmission;

export const SubmissionC = iots.union([
  CodeSubmissionC,
  LinkSubmissionC,
  PresentationSubmissionC,
  DispenseSubmissionC,
]) satisfies iots.Type<Submission, unknown>;

type SubmissionPublicFields = keyof typeof submissionsPublicFields;
// This represents the keys from Lists objects that should be published
// to the client. If we add secret properties to List objects, don't list
// them here to keep them private to the server.
export const submissionsPublicFields = {
  _id: 1,
  kind: 1,
  semester: 1,
  status: 1,
  appointmentId: 1,
  taskId: 1,
  studentId: 1,
  submissionDate: 1,
  result: 1,
  preResult: 1,
  autograderResult: 1,
  submissionJobId: 1,
  additionalResults: 1,
  preAdditionalResults: 1,
  partnerId: 1,
  organizedBy: 1,
  comment: 1,

  projectId: 1,
  snapshotId: 1,

  reviewed: 1,
  comments: 1,
  reviewerId: 1,

  unread: 1,
  cherryPicked: 1,
  regradeProjectId: 1,
  regradeSnapshotId: 1,
  feedbackProjectId: 1,
} as const satisfies Partial<Record<DistributiveKeyof<Submission>, 1>>;

export type CodeSubmissionPublic = Pick<
  CodeSubmission,
  keyof CodeSubmission & SubmissionPublicFields
>;
export type LinkSubmissionPublic = Pick<
  LinkSubmission,
  keyof LinkSubmission & SubmissionPublicFields
>;
export type PresentationSubmissionPublic = Pick<
  PresentationSubmission,
  keyof PresentationSubmission & SubmissionPublicFields
>;
export type DispenseSubmissionPublic = Pick<
  DispenseSubmission,
  keyof DispenseSubmission & SubmissionPublicFields
>;
export type SubmissionPublic =
  | CodeSubmissionPublic
  | LinkSubmissionPublic
  | PresentationSubmissionPublic
  | DispenseSubmissionPublic;

// -------------------------------------------------------------------------------------------------
// Ord

export const ordSubmissionDate: ord.Ord<Pick<Submission, 'submissionDate'>> = pipe(
  date.Ord,
  ord.contramap((s) => s.submissionDate),
);

export const ordSubmissionDateDesc = ord.reverse(ordSubmissionDate);

// -------------------------------------------------------------------------------------------------
// Eq

export const eqSubmissionStudentId: eq.Eq<Pick<Submission, 'studentId'>> = pipe(
  userIdEq,
  eq.contramap((s) => s.studentId),
);

export const eqSubmissionResult: eq.Eq<Pick<Submission, 'result'>> = pipe(
  number.Eq,
  eq.nullable,
  eq.contramap((s) => s.result),
);

export const eqSubmissionAdditionalResults: eq.Eq<Pick<CodeSubmission, 'additionalResults'>> = pipe(
  record.getEq(eq.nullable(string.Eq)),
  eq.nullable,
  eq.contramap((s) => s.additionalResults),
);

// -------------------------------------------------------------------------------------------------
// Predicates

/**
 * Check if a code submission has been cherry-picked, i.e. selected for grading.
 */
export const isSubmissionCherryPicked = (
  x: DistributivePick<CodeSubmission, 'cherryPicked'>,
): boolean => !!x.cherryPicked;

export const isPresentationSubmissionLate = (x: Pick<PresentationSubmission, 'status' | 'kind'>) =>
  foldSubmissionStatus(x.status, {
    // Presentations in progress are never late
    subscribed: () => false,
    called: () => false,
    alreadyCalled: () => false,
    marked: () => false,
    done: () => false,
    // Absent presentations are always late
    absent: () => true,
  });

type TimeSensitiveSubmission = DistributivePick<
  Submission,
  'kind' | 'cherryPicked' | 'status' | 'submissionDate'
>;

/**
 * Check if a submission was submitted late, i.e. after the date it was due.
 *
 * @see isSubmissionLateFromO
 */
export const isSubmissionLate = (dueDate: Date) =>
  foldSubmissionPartial<TimeSensitiveSubmission, boolean>({
    code: (x) => !isSubmissionCherryPicked(x) && x.submissionDate > dueDate,
    dispense: () => false,
    link: () => false,
    presentation: isPresentationSubmissionLate,
  });

type PickSubmission = <S extends SubmissionPublic>(submissions: Array<S>) => option.Option<S>;

export const pickRelevantCodeSubmission: PickSubmission = flow(
  array.filter(isCodeSubmission),
  array.findFirst(isSubmissionCherryPicked),
);
export const pickRelevantPresentationSubmission: PickSubmission = flow(
  array.filter(isPresentationSubmission),
  array.filter(not(isPresentationSubmissionLate)),
  array.sort(ordSubmissionDateDesc),
  array.head,
);
export const pickRelevantLinkSubmission: PickSubmission = flow(
  array.filter(isLinkSubmission),
  array.sort(ordSubmissionDateDesc),
  array.head,
);

export const pickRelevantDispenseSubmission: PickSubmission = flow(
  array.filter(isDispenseSubmission),
  array.sort(ordSubmissionDateDesc),
  array.head,
);

export const pickRelevantSubmission: PickSubmission = (submissions) =>
  option.altAllBy(
    pickRelevantCodeSubmission,
    pickRelevantPresentationSubmission,
    pickRelevantLinkSubmission,
    pickRelevantDispenseSubmission,
  )(submissions);

export const isRelevantSubmission = foldSubmissionPartial<Submission, boolean>({
  code: (x) => isSubmissionCherryPicked(x),
  dispense: () => true,
  link: () => true,
  presentation: not(isPresentationSubmissionLate),
});

/**
 * A version of {@link isSubmissionLate} that works with optional dates. If no date is given,
 * the submission can't be late.
 */
export const isSubmissionLateFromO =
  (dueDate: option.Option<Date>) =>
  (x: TimeSensitiveSubmission): boolean =>
    pipe(
      dueDate,
      option.exists((d) => isSubmissionLate(d)(x)),
    );

/**
 * Check if a submission has been reviewed. Note that not all submissions have to be reviewed,
 * the process applied by course admins might differ between courses.
 */
export const isSubmissionReviewed = foldSubmissionPublic<boolean>({
  code: (x) => !!x.reviewed,
  // Dispense submissions are never reviewed
  dispense: () => false,
  // Link submissions are never reviewed
  link: () => false,
  presentation: (x) =>
    foldSubmissionStatus(x.status, {
      subscribed: () => false,
      called: () => false,
      alreadyCalled: () => false,
      marked: () => false,
      done: () => true,
      absent: () => false,
    }),
});

/**
 * Some courses make use of pre-results. These can represent an initial result that is shown to
 * students until a TA manually reviews the submission or until all submissions are evaluated
 * again with additional tests.
 */
export const isSubmissionPreResult: (x: SubmissionPublic) => boolean = foldSubmissionPublic({
  // Only code submissions can have pre-results
  code: (x) => !isSubmissionReviewed(x) && x.preResult !== undefined,
  dispense: () => false,
  link: () => false,
  presentation: () => false,
});

/**
 * Courses can also make use of preliminary additional-results. These follow the same rules as
 * pre-results in that they are shown to students in place of the additional results until a TA
 * manually reviews the submission or until all submissions are evaluated again with additional
 * tests. However, they are independent of the presence of pre-results.
 *
 * FIXME: This should really reuse isSubmissionReviewed, but it is currently in use at FeedbackStudent.tsx, where we only have a partial Submission which doesn't fit into isSubmissionReviewed.
 */
export const isSubmissionPreAdditionalResult: (
  x: Pick<CodeSubmissionPublic, 'preAdditionalResults' | 'reviewed'>,
) => boolean = (x) => !x.reviewed && x.preAdditionalResults !== undefined;

/**
 * Code submissions can stem from a Regrade. Those that do reference a regrade-project/-snapshot that
 * must be used for grading instead of the originally referenced project/snapshot.
 */
export const isSubmissionRegraded = foldSubmissionPublic<boolean>({
  code: (x) => x.regradeProjectId != null && x.regradeSnapshotId != null,
  dispense: () => false,
  link: () => false,
  presentation: () => false,
});

// -------------------------------------------------------------------------------------------------
// Functions

/**
 * Returns either preAdditionalResults or additionalResults if the submission has been reviewed.
 */
export const getAdditionalResultsForDisplay = (
  x: Pick<CodeSubmissionPublic, 'additionalResults' | 'preAdditionalResults'>,
) => (isSubmissionPreAdditionalResult(x) ? x.preAdditionalResults : x.additionalResults);

/**
 * Check whether a {@link CodeSubmission} has additional results.
 */
export const hasAdditionalResults = flow(getAdditionalResultsForDisplay, record.isNonEmpty);

type PresentationStatus = Pick<PresentationSubmissionPublic, 'status'>;

/**
 * Returns true iff all submissions are absent.
 */
export const noOpenSubmission: predicate.Predicate<Array<PresentationStatus>> = //
  array.every(({ status }) => status === 'absent');

/**
 * Returns true iff the array of submissions is non-empty and all submissions are absent.
 */
export const isAbsent: predicate.Predicate<Array<PresentationStatus>> = //
  predicate.and(array.isNonEmpty)(noOpenSubmission);

export const isMatchingPairSubmission =
  (head: PresentationSubmissionPublic) => (sub: PresentationSubmissionPublic) =>
    sub.partnerId === head.studentId && sub.studentId === head.partnerId;
