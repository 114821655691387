// eslint-disable-next-line no-restricted-imports
import { Formatter, query, zero } from 'fp-ts-routing';

import { iots } from '@code-expert/prelude';
import { FileId, ProjectId, SnapshotId } from '/imports/domain';
import { end, formatRoute, lit, Match, type, url } from '/imports/modules/Routing';

export interface IdeRouteParams {
  projectId: ProjectId;
  showSolution: boolean;
  fileKey?: FileId;
  snapshotId?: SnapshotId;
}

const baseRoute = lit('ide2');
const projectRoute = url(baseRoute, type('projectId', ProjectId));
const mainRoute = url(
  projectRoute,
  query(
    iots.strict({
      fileKey: iots.union([FileId, iots.undefined]),
      snapshotId: iots.union([SnapshotId, iots.undefined]),
    }),
  ),
  end,
);
const solutionRoute = url(
  projectRoute,
  lit('solution'),
  query(
    iots.strict({
      fileKey: iots.union([FileId, iots.undefined]),
      snapshotId: iots.union([SnapshotId, iots.undefined]),
    }),
  ),
  end,
);

export const ideRoute: Match<IdeRouteParams> = new Match(
  zero<IdeRouteParams>()
    .alt(mainRoute.parser.map((p) => ({ ...p, showSolution: false })))
    .alt(solutionRoute.parser.map((p) => ({ ...p, showSolution: true }))),
  new Formatter((route, { showSolution, projectId, snapshotId, fileKey }) =>
    (showSolution ? solutionRoute.formatter : mainRoute.formatter).run(route, {
      projectId,
      fileKey,
      snapshotId,
    }),
  ),
);

export type IdeNavigation = (_: { projectId: ProjectId; showSolution?: boolean }) => string;

export const ideLink: IdeNavigation = ({ projectId, showSolution }) =>
  formatRoute(ideRoute)({ projectId, showSolution: showSolution ?? false });
