import { array, monoid, pipe, string } from '@code-expert/prelude';

type FontFeature = 'cpsp' | 'ss01' | 'ss04' | 'tnum';

const defaultFeatures: Array<FontFeature> = ['cpsp', 'ss01', 'ss04'];

export const fontFeatureSettings = (
  ...features: Array<FontFeature>
): Record<'fontFeatureSettings', string> => ({
  fontFeatureSettings: pipe(
    [...defaultFeatures, ...features],
    array.map((f) => `'${f}'`),
    monoid.concatAll(string.commaSeparated),
  ),
});
