import createStorage from 'localstory';

import type { iots } from '@code-expert/prelude';
import { flow, fromThrown, io, option, pipe } from '@code-expert/prelude';
import { Logger } from '/imports/modules/Logger/client';

/**
 * UI state property for a single key-value pair.
 */
export interface ValueProp<V> {
  get: () => V | undefined;
  set: (value: V, ttl?: string) => void;
  clear: () => void;
}

/**
 * UI state property for multiple key-value pairs.
 */
export interface KeyValueProp<K, V> {
  get: (key: K) => V | undefined;
  set: (key: K, value: V, ttl?: string) => void;
  clear: (key: K) => void;
}

export interface Store {
  /**
   * Create a property to store a single key-value pair.
   * @param key storage key
   * @param decoder decoder to retrieve the value from the store
   * @template V the value type
   */
  valueProp: <V>(
    key: string, //
    decoder: iots.Decoder<unknown, V>,
  ) => ValueProp<V>;

  /**
   * Create a property to store multiple key-value pairs.
   * @param encodeKey function to derive a storage key from the logical key
   * @param decoder decoder to retrieve the value from the store
   * @template K the key type
   * @template V the value type
   */
  keyValueProp: <K, V>(
    encodeKey: (_: K) => string,
    decoder: iots.Decoder<unknown, V>,
  ) => KeyValueProp<K, V>;
}

const wrapStore = (...args: Parameters<typeof createStorage>): Store => {
  // localstory does not export its types, hence we use this assertion to any. It's imprecise,
  // but not more so than before ... Improve, if possible.
  const store = createStorage(...(args as [$IntentionalAny]));

  const get =
    <V>(decoder: iots.Decoder<unknown, V>) =>
    (key: string): V | undefined =>
      pipe(
        store.get(key),
        option.fromNullable,
        option.flatTraverse(io.Applicative)(
          flow(
            decoder.decode,
            option.fromEither,
            option.altM(io.Pointed)(() => (): $Inexpressible => {
              store.unset(key);
              return option.none;
            }),
          ),
        ),
        io.map(option.toUndefined),
        io.run,
      );

  const set = <V>(key: string, value: V, ttl = '90d') => {
    try {
      store.set(key, value, { ttl });
    } catch (e) {
      Logger.error('Failed to store UI state', { error: fromThrown(e) });
    }
  };

  const clear = (key: string) => {
    store.unset(key);
  };

  return {
    keyValueProp: (encodeKey, decoder) => ({
      get: flow(encodeKey, get(decoder)),

      set: (key, value, ttl = '90d') => {
        set(encodeKey(key), value, ttl);
      },

      clear: (key) => {
        clear(encodeKey(key));
      },
    }),

    valueProp: (key, decoder) => ({
      get: () => get(decoder)(key),

      set: (value, ttl = '90d') => {
        set(key, value, ttl);
      },

      clear: () => {
        clear(key);
      },
    }),
  };
};

export const UiState = wrapStore(window.localStorage, 'ui', { ttl: '90d' });

export const UiSessionState = wrapStore(window.sessionStorage, 'ui-session', { ttl: '90d' });
