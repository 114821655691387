import { flow, iots, struct } from '@code-expert/prelude';
import { CourseUrl, ExerciseId, SemesterId, TaskId } from '/imports/domain';
import { end, formatRoute, lit, query, type } from '/imports/modules/Routing';
import { TrueFromOne } from '/imports/utils/codecs';

const baseRoute = lit('print')
  .then(type('courseUrl', CourseUrl))
  .then(type('semester', SemesterId))
  .then(type('exerciseId', ExerciseId));

// -------------------------------------------------------------------------------------------------
// Exercise print view
// -------------------------------------------------------------------------------------------------

export const exercisePrintViewRoute = baseRoute.then(end);

export interface ExercisePrintViewParams {
  courseUrl: CourseUrl;
  semester: SemesterId;
  exerciseId: ExerciseId;
}

export const exercisePrintViewPath = (args: ExercisePrintViewParams): string =>
  formatRoute(exercisePrintViewRoute)(args);

// -------------------------------------------------------------------------------------------------
// Task print view
// -------------------------------------------------------------------------------------------------

export const taskPrintViewRoute = baseRoute
  .then(type('taskId', TaskId))
  .then(query(iots.exact(iots.partial({ solution: TrueFromOne, selection: iots.string }))))
  .then(end);

interface TaskPrintViewParams extends ExercisePrintViewParams {
  taskId: TaskId;
  solution: boolean;
  selection?: string;
}

export type TaskPrintViewUrlParams = Omit<TaskPrintViewParams, 'solution'> & { solution?: true };

export const taskPrintViewPath: (_: TaskPrintViewParams) => string = flow(
  struct.evolvePartial({ solution: (solution) => (solution ? true : undefined) }),
  formatRoute(taskPrintViewRoute),
);
