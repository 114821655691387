import type { ProjectFileSystem, SyncState } from '@code-expert/pfs';
import type { Lazy, readerTask } from '@code-expert/prelude';
import {
  adt,
  array,
  boolean,
  constFalse,
  flow,
  option,
  pipe,
  string,
  task,
  taskOption,
} from '@code-expert/prelude';
import type { PfsRole, ProjectEntryKey } from '/imports/domain';
import type { ProjectRepository } from '/imports/modules/Project/domain/ProjectRepository';
import type { CopiedProject, OriginProject } from './Project';
import { canRefreshCopyFromOrigin, isOriginProject } from './Project';
import type { Snapshot } from './Snapshot';

const elem: (a: string, as: Array<string>) => boolean = array.elem(string.Eq);

export interface SyncStateStack {
  projectRepo: ProjectRepository;
}

/**
 * Return a map to resolve the sync state for a project file key.
 */
export const getSyncStates =
  ({ projectRepo }: SyncStateStack) =>
  (
    copiedProject: CopiedProject,
    role: PfsRole,
    getPfs: () => Promise<ProjectFileSystem>,
    keys: NonEmptyArray<ProjectEntryKey>,
  ): task.Task<Map<ProjectEntryKey, SyncState>> => {
    const originProject: taskOption.TaskOption<OriginProject> = pipe(
      option.fromNullable(copiedProject.originId),
      option.flatTraverse(task.ApplicativeSeq)((_id) => projectRepo.findProject(_id)),
      taskOption.filter(isOriginProject),
    );

    const considerSyncStates: task.Task<boolean> = pipe(
      option.guard(elem(role, ['admin', 'assistant'])),
      option.flatTraverse(task.ApplicativeSeq)(() => originProject),
      taskOption.map(canRefreshCopyFromOrigin(copiedProject)),
      taskOption.getOrElse(flow(constFalse, task.of)),
    );

    const fetchSyncStates: Lazy<task.Task<Map<ProjectEntryKey, SyncState>>> = () => {
      const originPublishedSnapshot: taskOption.TaskOption<Snapshot> = pipe(
        option.guard(copiedProject.useCase === 'masterSolution'),
        option.flatTraverse(task.ApplicativeSeq)(() => originProject),
        taskOption.chainNullableK(({ publishedSnapshotId }) => publishedSnapshotId),
        taskOption.chain((_id) => projectRepo.findSnapshot(_id)),
      );

      const withPfs = <A>(f: (_: ProjectFileSystem) => task.Task<A>) => pipe(getPfs, task.chain(f));

      return pipe(
        originPublishedSnapshot,
        task.chain(
          option.fold(
            () => withPfs((pfs) => () => pfs.getSyncStates({ keys })),
            ({ date }) => withPfs((pfs) => () => pfs.getSyncStates({ keys, snapshotDate: date })),
          ),
        ),
      );
    };

    return pipe(
      considerSyncStates,
      task.chain(
        boolean.fold(
          () => task.of(new Map()),
          () => fetchSyncStates(),
        ),
      ),
    );
  };

/**
 * Determine the sync state for a project file key.
 * Returns `identical` if the sync state could not be determined.
 */
export const getSyncState =
  (
    project: CopiedProject,
    role: PfsRole,
    pfs: () => Promise<ProjectFileSystem>,
    key: ProjectEntryKey,
  ): readerTask.ReaderTask<SyncStateStack, SyncState> =>
  (stack) =>
    pipe(
      getSyncStates(stack)(project, role, pfs, [key]),
      task.map((syncStates) => syncStates.get(key) ?? 'identical'),
    );

export const foldSyncState = adt.foldFromKeys<SyncState>({
  identical: null,
  'copy-changed': null,
  'origin-changed': null,
  'both-changed': null,
});
