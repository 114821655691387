import React from 'react';

import { useGlobalContext } from '/imports/ui/components/GlobalContext';

export interface UseTitleProps {
  title: string;
  description?: string;
}

/**
 * Set the title in the browser.
 * @param title The primary page title, intended to be visible in the browser tab
 * @param [description] An optional brief description of the page's context
 */
export const useTitle = (
  title: UseTitleProps['title'],
  description: UseTitleProps['description'] = undefined,
) => {
  const { pageTitle } = useGlobalContext();
  React.useEffect(() => {
    document.title =
      typeof description === 'string' && description.length > 0
        ? `${title} – ${description} | ${pageTitle}`
        : `${title} | ${pageTitle}`;
    return () => {
      document.title = pageTitle;
    };
  }, [title, description, pageTitle]);
};

export function DocumentTitle({ title, description }: UseTitleProps) {
  useTitle(title, description);
  return null;
}
