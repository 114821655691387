import { tagged } from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import type { ExerciseVisibility } from './ExerciseVisibility';
import { foldExerciseVisibility } from './ExerciseVisibility';
import type { TaskCollection } from './TaskCollection';
import { foldTaskCollectionPartial } from './TaskCollection';

export type ExerciseSolutionState =
  | tagged.Tagged<'none'>
  | tagged.Tagged<'hidden', Date>
  | tagged.Tagged<'public'>;

export const exerciseSolutionState = tagged.build<ExerciseSolutionState>();

/**
 * @see {@link isSolutionVisible}
 */
export function getExerciseSolutionState(
  now: Date,
  exercise: DistributivePick<TaskCollection, 'type' | 'solutionDate' | 'solutionPublic'>,
): ExerciseSolutionState {
  const { none, hidden, public: pub /* protected keyword */ } = exerciseSolutionState;

  const fromVisibility = (visibility: ExerciseVisibility, date?: Date) =>
    date != null
      ? foldExerciseVisibility(visibility, {
          no: () => hidden(date),
          yes: () => pub(),
          auto: () => (now >= date ? pub() : hidden(date)),
        })
      : none();

  return foldTaskCollectionPartial<typeof exercise, ExerciseSolutionState>(exercise, {
    exercise: ({ solutionPublic, solutionDate }) => fromVisibility(solutionPublic, solutionDate),
    code_example: ({ solutionPublic, solutionDate }) =>
      fromVisibility(solutionPublic, solutionDate),
    exam: ({ solutionPublic }) =>
      foldExerciseVisibility<ExerciseSolutionState>(solutionPublic, {
        no: none,
        yes: pub,
        auto: pub,
      }),
  });
}

export function getExerciseSolutionStateNow(
  exercise: DistributivePick<TaskCollection, 'type' | 'solutionDate' | 'solutionPublic'>,
): ExerciseSolutionState {
  return getExerciseSolutionState(new Date(), exercise);
}
