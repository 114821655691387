import { either, iots, pipe } from '@code-expert/prelude';

interface LegiBrand {
  readonly Legi: unique symbol;
}

const legiRegex = /^\d{2}-\d{3}-\d{3}$/;
const Legi = iots.withMessage(
  iots.brand(iots.string, (u): u is iots.Branded<string, LegiBrand> => legiRegex.test(u), 'Legi'),
  () => 'Invalid Legi',
);
export type Legi = iots.TypeOf<typeof Legi>;

/**
 * Decode from a valid legi-string to a branded {@link Legi}.
 */
export const LegiFromString = new iots.Type<Legi, string, unknown>(
  'LegiFromString',
  Legi.is,
  (input, context) =>
    pipe(
      iots.string.validate(input, context),
      either.chain((s) => Legi.decode(s.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1-$2-$3'))),
    ),
  (l) => l.replace(/-/g, ''),
);

/**
 * Decode from a valid legi-string to a branded {@link Legi} or from `null | undefined | ''` to `undefined`
 */
export const NullableLegiFromString = new iots.Type<Legi | undefined, string | undefined, unknown>(
  'LegiFromString',
  Legi.is,
  (input, context) =>
    pipe(
      iots.string.validate(input, context),
      either.chain((s) => Legi.decode(s.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1-$2-$3'))),
      either.fold(
        (e) => (input == null || input === '' ? either.right(undefined) : either.left(e)),
        (x) => either.right(x),
      ),
    ),
  (l) => (typeof l === 'string' ? l.replace(/-/g, '') : undefined),
);
