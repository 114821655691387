// eslint-disable-next-line no-restricted-imports
import type { Parser } from 'fp-ts-routing';

/**
 * Pipeable version of {@link Parser.map}
 */
export const map =
  <A, B>(f: (_: A) => B) =>
  (parser: Parser<A>): Parser<B> =>
    parser.map(f);

/**
 * Pipeable version of {@link Parser.chain}
 */
export const chain =
  <A, B>(f: (_: A) => Parser<B>) =>
  (parser: Parser<A>): Parser<B> =>
    parser.chain(f);

/**
 * Pipeable version of {@link Parser.alt}
 */
export const alt =
  <A>(a: Parser<A>) =>
  (parser: Parser<A>): Parser<A> =>
    parser.alt(a);
