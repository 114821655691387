import { TinyColor } from '@ctrl/tinycolor';
import type { PresetColorKey } from 'antd/es/theme/interface';
import type { ColorMap } from 'antd/es/theme/themes/ColorMap';

type ColorPaletteKeyIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type PaletteKeys = Extract<PresetColorKey, 'magenta' | 'blue' | 'green' | 'orange' | 'red'>;

export type ColorPalettes = {
  [key in `${PaletteKeys}${ColorPaletteKeyIndex}`]: string;
};

export type Palettes = {
  background: string;
} & Record<'gray' | PaletteKeys, ColorMap>;

type NamedColorMap<N extends string> = {
  [K in keyof ColorMap as `${N}${K}`]: ColorMap[K];
};

const genNamedColorToken = <N extends string>(name: N, colors: ColorMap): NamedColorMap<N> =>
  Object.fromEntries(Object.entries(colors).map(([k, v]) => [`${name}${k}`, v])) as $Inexpressible;

export const genNamedColorTokens = (colors: Palettes): NamedColorMap<PaletteKeys> => ({
  ...genNamedColorToken('magenta', colors.magenta),
  ...genNamedColorToken('blue', colors.blue),
  ...genNamedColorToken('green', colors.green),
  ...genNamedColorToken('orange', colors.orange),
  ...genNamedColorToken('red', colors.red),
});

export const getAlphaColor = (baseColor: string, alpha: number) =>
  new TinyColor(baseColor).setAlpha(alpha).toRgbString();

export const getDarkerColor = (baseColor: string, brightness: number) => {
  const instance = new TinyColor(baseColor);
  return instance.darken(brightness).toHexString();
};

export const getLighterColor = (baseColor: string, brightness: number) => {
  const instance = new TinyColor(baseColor);
  return instance.lighten(brightness).toHexString();
};
