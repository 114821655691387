import { iots } from '@code-expert/prelude';
import { GameResultId, ProjectId, UserId } from '/imports/domain/identity';

export const GameResultC = iots.strict({
  _id: GameResultId,
  projectId: ProjectId,
  userId: UserId,
  game: iots.literal('snake'),
  date: iots.date,
  result: iots.strict({
    targets: iots.number,
    timeTicks: iots.number,
    fields: iots.strict({
      width: iots.number,
      height: iots.number,
    }),
  }),
});

export type GameResult = iots.TypeOf<typeof GameResultC>;
