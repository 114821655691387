import {
  adt,
  array,
  either,
  flow,
  identity,
  nonEmptyArray,
  option,
  pipe,
  separated,
} from '@code-expert/prelude';
import type { DistributivePick } from '@code-expert/type-utils';
import type { Example, Exercise } from '/imports/domain/exercise';
import { foldTaskCollectionPartial, getExerciseTaskState } from '/imports/domain/exercise';
import type {
  CodeSubmission,
  DispenseSubmission,
  LinkSubmission,
  PresentationSubmission,
  Submission,
  SubmissionPublic,
} from '/imports/domain/submission';
import {
  isDispenseSubmission,
  isSubmissionStateDispensed,
  isSubmissionStateLate,
  isSubmissionStatePending,
  isSubmissionStatePicked,
  submissionState,
} from '/imports/domain/submission';
import type { CodeTask, ETLinkTask, LinkTask, PresentationTask, Task } from './task';
import { foldTask, foldTaskKind, isTaskGraded, pickRelevantSubmissionForTask } from './task';
import type { TaskProgress } from './taskProgress';
import { taskProgress } from './taskProgress';

// -------------------------------------------------------------------------------------------------
// Types

type TaskSubmissionMapping<K extends Task['kind']> = {
  code: CodeSubmission | DispenseSubmission;
  link: LinkSubmission | DispenseSubmission;
  etLink: LinkSubmission | DispenseSubmission;
  presentation: PresentationSubmission | DispenseSubmission;
}[K];

export type GetSubmission<
  T extends Pick<Task, '_id' | 'kind'>,
  S extends Pick<Submission, '_id' | 'kind'> = Submission,
> = Extract<S, Pick<TaskSubmissionMapping<T['kind']>, 'kind'>>;

/**
 * This type constructs a {@link TaskSubmissions} type for a {@link Task} and {@link Submission}
 * tuple. It ensures that both are of the same kind (e.g. CodeTask x CodeSubmission).
 */
export type MkTaskSubmissions<T extends Task> = {
  kind: T['kind'];
  task: T;
  submissions: option.Option<NonEmptyArray<GetSubmission<T, SubmissionPublic>>>;
  isPartiallyRegraded: boolean;
};

export type CodeTaskSubmissions = MkTaskSubmissions<CodeTask>;
export type LinkTaskSubmissions = MkTaskSubmissions<LinkTask>;
export type EtLinkTaskSubmissions = MkTaskSubmissions<ETLinkTask>;
export type PresentationTaskSubmissions = MkTaskSubmissions<PresentationTask>;

/**
 * A container for a tuple of {@link Task} and {@link Submission} that belong together and must
 * be processed as a whole.
 *
 * This type carries the `isPartiallyRegraded` flag that is set to `true` if submissions exist
 * that have not been regraded.
 */
export type TaskSubmissions =
  | CodeTaskSubmissions
  | LinkTaskSubmissions
  | EtLinkTaskSubmissions
  | PresentationTaskSubmissions;

export const foldTaskSubmissions = adt.foldFromTags<TaskSubmissions, 'kind'>('kind');

/**
 * The tuple of {@link BonusExercise}, {@link Task}, and {@link Submission} is used to derive
 * the state of a task. This container type helps carry the necessary information around.
 */
export interface ExerciseTaskSubmission<T extends Task = Task> {
  exercise: Exercise;
  task: T;
  submissions: option.Option<NonEmptyArray<GetSubmission<T, SubmissionPublic>>>;
  isPartiallyRegraded: boolean;
}

/**
 * Determine the due date of a task.
 *
 * Note that the due date can vary between task kinds, e.g. presentations are due
 * only after an exercise was due.
 *
 * @see taskDueDateO
 */
export const taskDueDate = ({
  exercise,
  task,
}: {
  exercise: DistributivePick<Exercise | Example, 'type' | 'dueDate' | 'solutionDate'>;
  task: Pick<Task, 'kind'>;
}): Date | undefined =>
  foldTaskCollectionPartial(exercise, {
    code_example: () => undefined,
    exercise: (e) =>
      foldTaskKind(task.kind, {
        code: () => e.dueDate,
        link: () => e.dueDate,
        etLink: () => e.dueDate,
        presentation: () => e.solutionDate,
      }),
  });

/**
 * A variation of {@link taskDueDate} that returns an `Option` instead of `Date | undefined`.
 */
export const taskDueDateO = flow(taskDueDate, option.fromNullable);

/**
 * Check if a task's submission window is currently active.
 */
export const isTaskInWindow = (
  now: Date,
  ets: {
    exercise: DistributivePick<
      Exercise | Example,
      'type' | 'public' | 'handoutDate' | 'dueDate' | 'publishDate' | 'solutionDate'
    >;
    task: Pick<Task, 'kind'>;
  },
) => {
  // FIXME Document why we no longer compute bonus exercise state
  const betState = getExerciseTaskState(now, ets.exercise);
  const dueDate = taskDueDate(ets);
  return foldTaskKind(ets.task.kind, {
    code: () => betState === 'inwindow',
    link: () => betState === 'inwindow',
    etLink: () => betState === 'inwindow',
    presentation: () => betState === 'afterwindow' && (dueDate != null ? now < dueDate : false),
  });
};

/**
 * Check if a task's submission window is closed. Presentations have a different window with
 * regard to the exercise they are contained in, as they can only be scheduled after the
 * exercise has been completed.
 */
export const isTaskWindowClosed = (
  now: Date,
  ets: {
    exercise: DistributivePick<
      Exercise | Example,
      'type' | 'public' | 'handoutDate' | 'dueDate' | 'publishDate' | 'solutionDate'
    >;
    task: Task;
  },
) => {
  // FIXME Document why we no longer compute bonus exercise state
  const betState = getExerciseTaskState(now, ets.exercise);
  const dueDate = taskDueDate(ets);
  return foldTask(ets.task, {
    code: () => betState === 'afterwindow',
    link: () => betState === 'afterwindow',
    etLink: () => betState === 'afterwindow',
    presentation: () => (dueDate != null ? now > dueDate : false),
  });
};

// fixme samuelv: use fp-ts/These
type RelevantSubmissionPartition<S extends SubmissionPublic> = separated.Separated<
  option.Option<S | DispenseSubmission>,
  option.Option<NonEmptyArray<S | DispenseSubmission>>
>;
export const partitionRelevantSubmission =
  (task: Pick<Task, 'kind'>) =>
  <S extends SubmissionPublic>(
    submissions: NonEmptyArray<S | DispenseSubmission>,
  ): RelevantSubmissionPartition<S> =>
    pipe(
      submissions,

      option.altAllBy(
        array.findFirst(isDispenseSubmission),
        pickRelevantSubmissionForTask(task.kind),
      ),

      option.fold(
        () => separated.separated(option.none, option.some(submissions)),
        (submission) =>
          separated.separated(
            option.some(submission),
            pipe(
              submissions,
              array.filter((s) => s !== submission),
              nonEmptyArray.fromArray,
            ),
          ),
      ),
    );

/**
 * Given a list of submissions, pick the most relevant one, if any. Relevancy here is defined
 * as "relevant for grading". That is why dispensed submissions always come first, as they
 * exempt the whole list of submissions from grading.
 */
export const findRelevantSubmission = (task: Pick<Task, 'kind'>) =>
  flow(partitionRelevantSubmission(task), separated.left);

/**
 * {@link TaskProgress} answers one question: is it done? The main perspective is that of a student
 * working their way through a course and trying to find the next relevant task they should work on.
 *
 * @see SubmissionState
 * @see taskStatus
 */
export function getTaskProgress(
  ets: Omit<ExerciseTaskSubmission, 'exercise'> & {
    exercise: DistributivePick<Exercise | Example, 'type' | 'dueDate' | 'solutionDate'>;
  },
): TaskProgress {
  return pipe(
    ets.submissions,

    // No progress has been made if there are no submissions
    either.fromOption<TaskProgress>(() => taskProgress.wide.none()),

    // The task is done if submissions have been made against an ungraded task
    either.stopIf(
      () => !isTaskGraded(ets.task),
      () => taskProgress.wide.success(1),
    ),

    // Try to pick a relevant submission
    // If none of the submissions is relevant for grading, the task is failed
    either.chainOptionK(() => taskProgress.wide.failed(0))(findRelevantSubmission(ets.task)),

    // Map to SubmissionState
    either.map(
      submissionState({
        dueDate: taskDueDateO({ exercise: ets.exercise, task: ets.task }),
        grading: ets.task.grading,
      }),
    ),

    // The task is done if we have a dispense-submission
    either.stopIf(isSubmissionStateDispensed, () => taskProgress.wide.success(1)),

    // The task is pending if the submission is pending
    either.stopIf(isSubmissionStatePending, (s) => taskProgress.wide.pending(s.result)),

    // The task is late if all submissions are late
    // todo this should actually be impossible; submissionState uses isSubmissionLateFromO,
    //   which should take cherryPicked into account -> unit tests!
    either.stopIf(isSubmissionStateLate, () => taskProgress.wide.failed(0)),

    // The task can be marked if we find a cherry-picked submission.
    either.stopIf(
      isSubmissionStatePicked,
      flow(
        (x) => x.result,
        option.map(either.fold(taskProgress.wide.failed, taskProgress.wide.success)),
        option.getOrElse(() => taskProgress.wide.failed(0)),
      ),
    ),

    either.fold(identity, () => taskProgress.wide.failed(0)),
  );
}
