import { iots, pipe } from '@code-expert/prelude';

export const Config = iots.strict({
  NODE_ENV: iots.keyof({ development: null, production: null }),
  VERSION: iots.string,
});

export type Config = iots.TypeOf<typeof Config>;

export const config: Config = pipe(
  iots.parseSync(Config)({
    NODE_ENV: process.env.NODE_ENV,
    VERSION: process.env.VERSION,
  }),
);

export const isProduction: boolean = config.NODE_ENV === 'production';
