import React from 'react';

import { pipe } from '@code-expert/prelude';
import { CourseUrl, SemesterId } from '/imports/domain';
import { end, lit, parser, succeed, type, url } from '/imports/modules/Routing';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import { TopNavLayout } from '/imports/ui/layouts/TopNavLayout';
import { InvalidEnrolmentLink } from './InvalidEnrolmentLink';

const Enroll = React.lazy(() => import('./Enroll'));

const enrollBaseRoute = lit('enroll');

const enrollRoute = url(type('semester', SemesterId), type('courseUrl', CourseUrl), end);

const InvalidEnrollmentLinkRoute = () => (
  <PrivateRoute
    element={() => <InvalidEnrolmentLink />}
    denyCapabilities={['exam', 'lti']}
    layout={TopNavLayout}
  />
);

export const enrollRouter = pipe(
  // Match /enroll
  enrollBaseRoute.parser,
  parser.chain((_) =>
    pipe(
      // Match /{semester}/{courseUrl}
      enrollRoute.parser,
      parser.map((props) => (
        <PrivateRoute
          element={() => <Enroll {...props} />}
          denyCapabilities={['exam', 'lti']}
          layout={TopNavLayout}
        />
      )),
      // Fall back to error message
      parser.alt(succeed(<InvalidEnrollmentLinkRoute />).parser),
    ),
  ),
);
