import React, { useState } from 'react';

import { Drawer } from 'antd';

import type { CourseId } from '/imports/domain';
import { Loading } from '/imports/ui/components/Loading';

const SendMessageForm = React.lazy(() => import('./SendMessageForm'));

export interface SendMessageProps {
  hideCourse?: boolean;
  defaultRequest?: 'codeExpert' | 'course';
  messageText?: string;
  additionalText?: string;
  defaultCourse?: CourseId;
  style?: React.CSSProperties;
}

export function SendMessage(props: SendMessageProps & { children: React.ReactElement }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          onClick: (...args: Array<unknown>) => {
            child.props.onClick?.(...args);
            setOpen(true);
          },
        }),
      )}
      <Drawer
        destroyOnClose
        title="Send message"
        rootStyle={props.style}
        width={400}
        onClose={() => setOpen(false)}
        maskClosable={false}
        open={open}
      >
        <React.Suspense fallback={<Loading />}>
          <SendMessageForm
            close={() => setOpen(false)}
            hideCourse={props.hideCourse}
            defaultCourse={props.defaultCourse}
            defaultRequest={props.defaultRequest}
            messageText={props.messageText}
            additionalText={props.additionalText}
          />
        </React.Suspense>
      </Drawer>
    </>
  );
}
