import { array, option, pipe, record, remote, task } from '@code-expert/prelude';
import { useDeepCompareEffect } from '@code-expert/react';
import { fullName, isPresentationSubmission } from '/imports/domain';
import { useNotification } from '/imports/ui/components/Notifications/useNotification';
import { invokeProcedure } from '/imports/ui/hooks/useProcedure';
import { usePublication } from '/imports/ui/hooks/usePublication';
import { absoluteUrlClient } from '/imports/utils/absoluteUrl/absoluteUrlClient';

export function usePresentationNotifications() {
  const [sendNotification, clearNotification] = useNotification();

  const presentations = pipe(
    usePublication('submissions.forStudentAppointment'),
    remote.map(record.values),
    remote.map(array.filter(isPresentationSubmission)),
  );

  useDeepCompareEffect(() => {
    const getAppointmentInfo = invokeProcedure('submissions_getPresentationInfo');
    if (remote.isDone(presentations)) {
      presentations.value.forEach(async (presentation) => {
        const { ta, appointment } = await task.run(getAppointmentInfo({ id: presentation._id }));
        if (option.isSome(ta) && option.isSome(appointment)) {
          const name = fullName(ta.value);
          if (appointment.value.remote != null) {
            sendNotification({
              key: presentation._id,
              title: 'Called for remote presentation',
              text: `You have been called for the presentation ${appointment.value.location} by ${name}`,
              link: ta.value.zoomLink,
              kind: 'Browser',
            });
          } else {
            const { location } = appointment.value;
            sendNotification({
              key: presentation._id,
              title: 'Called for presentation',
              text: `You have been called for the presentation in room ${location} by ${name}`,
              link: absoluteUrlClient('enrolled'),
              kind: 'Browser',
            });
          }
        }
      });
    }
  }, [clearNotification, presentations, sendNotification]);
}
