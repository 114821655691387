import React from 'react';

import { DATAMatrix } from '/imports/ui/components/DataMatrix/datamatrix';

export interface DataMatrixProps {
  message: string;
  scale?: number;
}

export default function DataMatrix({ message, scale = 64 }: DataMatrixProps) {
  const svgContainer = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (svgContainer.current) {
      const svgNode = DATAMatrix({
        msg: message,
        dim: scale,
        pal: ['#000', '#fff'],
      });
      svgContainer.current.innerHTML = '';
      svgContainer.current.appendChild(svgNode);
    }
  }, [message, scale]);

  if (!message) {
    return null;
  }

  return <span ref={svgContainer} />;
}
