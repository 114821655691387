import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import * as parser from 'socket.io-msgpack-parser';

import type {
  ClientToServerEvents,
  ServerToClientEvents,
} from '/imports/startup/server/shared/socket';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io('/cx', {
  parser,
  transports: ['websocket'],
});
