import { useEffect, useRef } from 'react';

import type EventEmitter from 'eventemitter3';

import type { CxEventEmitter, CxEvents } from '/imports/ui/components/CxEventEmitter';

/**
 *  Wraps `addEventListener`. To ensure that they are removed correctly.
 *
 * @param eventName {String} The name of the event e.g. 'resize'
 * @param handler {Function} The handler method
 * @param target The target the eventListener is added default to window.
 */

export function useEventListener(
  eventName: string,
  handler: EventListener,
  target: EventTarget = window,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<EventListener>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const eventListener = (event: Event) => {
        if (savedHandler.current) {
          savedHandler.current(event);
        }
      };

      // Add event listener
      target.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        target.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, target], // Re-run if eventName or element changes
  );
}

export function useEventListener3<T extends EventEmitter.EventNames<CxEvents>>(
  eventName: T,
  handler: EventEmitter.EventListener<CxEvents, T>,
  target: CxEventEmitter,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<EventEmitter.EventListener<CxEvents, T>>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const eventListener: EventEmitter.EventListener<CxEvents, T> = (event) => {
        if (savedHandler.current) {
          savedHandler.current(event);
        }
      };

      // Add event listener
      target.on(eventName, eventListener);
      return () => {
        target.off(eventName, eventListener);
      };
    },
    [eventName, target], // Re-run if eventName or element changes
  );
}
