import React, { useEffect } from 'react';

import type { RouteProps } from 'react-router-dom';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import type { Lazy } from '@code-expert/prelude';
import { iots, option, pipe } from '@code-expert/prelude';
import { useDeepCompareMemoize } from '@code-expert/react';
import { isProduction } from '/imports/config/client';
import type { UserCapability, UserPublic } from '/imports/domain';
import { checkCapabilities, isExamUser, isLTIUser } from '/imports/domain';
import type { SavedEntryElements } from '/imports/ui/components/GlobalContext';
import {
  useGlobalContext,
  useGlobalContextWithActions,
} from '/imports/ui/components/GlobalContext';
import NotFound, { NotAuthorized } from '/imports/ui/components/NotFound';
import { pushLastLocation } from '/imports/ui/pages/login/utils';
import { UiSessionState } from '/imports/utils/UiState';

export const uiSessionStates = {
  isExamSession: UiSessionState.valueProp('is-exam-session', iots.boolean),
};

function RedirectToLogin(props: RouteProps) {
  return (
    <Route
      {...props}
      render={(componentProps) => {
        pushLastLocation(componentProps.location);
        if (uiSessionStates.isExamSession.get() === true) {
          uiSessionStates.isExamSession.clear();
          return <Redirect to="/logoutExam" />;
        }
        return <Redirect to={isProduction ? '/login' : '/loginDemo'} />;
      }}
    />
  );
}

function Unauthorized({ user }: { user: UserPublic }) {
  useEffect(() => {
    if (user != null && isLTIUser(user)) {
      void fetch('/logout', { method: 'POST' });
    }
  }, [user]);
  if (user != null) {
    if (isExamUser(user)) return <Redirect to={`/examStudent/${user.examId}`} />;
  }
  return <NotAuthorized />;
}

// -------------------------------------------------------------------------------------------------

interface PrivateRouteBaseProps extends RouteProps {
  allowCapabilities?: Array<UserCapability>;
  denyCapabilities?: Array<UserCapability>;
  layout?: React.ComponentType<React.PropsWithChildren>;
  onMatch?: (params: Record<SavedEntryElements, string>) => void;
}

interface PrivateRouteComponentProps extends PrivateRouteBaseProps {
  component: React.ComponentType<$Inexpressible /* Unsafe, but unexpressable at the moment */>;
  element?: never;
}

interface PrivateRouteRenderProps extends PrivateRouteBaseProps {
  component?: never;
  element: Lazy<React.JSX.Element>;
}

type PrivateRouteProps = PrivateRouteComponentProps | PrivateRouteRenderProps;

export function PrivateRoute({
  component,
  element = () => <NotFound />,
  allowCapabilities: allow,
  denyCapabilities: deny,
  layout: Layout = React.Fragment,
  onMatch,
  ...routerProps
}: PrivateRouteProps) {
  const params = pipe(
    useRouteMatch('/:path?/:semester?/:courseUrl?/:groupId?')?.params,
    (p) => [p],
    useDeepCompareMemoize,
    (l) => l?.[0] as Record<SavedEntryElements, string>,
  );

  const [{ user, userCapabilities }, dispatch] = useGlobalContextWithActions();
  const isAuthorized = user != null && checkCapabilities({ allow, deny }, userCapabilities);

  React.useEffect(() => {
    if (isAuthorized) {
      if (onMatch) {
        onMatch(params);
      }
    } else {
      if (user != null) {
        // Access to the preferred entry route is restricted, so we remove it.
        dispatch({ preferredEntry: undefined });
      }
    }
  }, [isAuthorized, onMatch, dispatch, params, user]);

  return user != null ? (
    <Route
      {...routerProps}
      render={(componentProps) => (
        <Layout>
          {isAuthorized ? (
            pipe(
              option.fromNullable(component),
              option.map((Component) => <Component {...componentProps} />),
              option.alt(() =>
                pipe(
                  option.fromNullable(element),
                  option.map((r) => r()),
                ),
              ),
              option.getOrElse(() => <NotFound />),
            )
          ) : (
            <Unauthorized user={user} />
          )}
        </Layout>
      )}
    />
  ) : (
    <RedirectToLogin />
  );
}

// -------------------------------------------------------------------------------------------------

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<$Inexpressible /* Unsafe, but unexpressable at the moment */>;
  allowCapabilities?: Array<UserCapability>;
  denyCapabilities?: Array<UserCapability>;
  layout?: React.ComponentType<React.PropsWithChildren>;
  onMatch?: (params: Record<SavedEntryElements, string>) => void;
}

export function PublicRoute({
  component: Component,
  layout: Layout = React.Fragment,
  onMatch,
  ...routerProps
}: PublicRouteProps) {
  const { user } = useGlobalContext();
  const params = useRouteMatch('/:path?/:semester?/:courseUrl?/:groupId?')?.params;
  const isAuthenticated = user != null;

  React.useEffect(() => {
    if (!isAuthenticated && onMatch) onMatch(params as Record<SavedEntryElements, string>);
  }, [isAuthenticated, onMatch, params]);

  return isAuthenticated ? (
    <PrivateRoute component={Component} layout={Layout} onMatch={onMatch} {...routerProps} />
  ) : (
    <Route
      {...routerProps}
      render={(componentProps) => (
        <Layout>
          <Component {...componentProps} />
        </Layout>
      )}
    />
  );
}
