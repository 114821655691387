import type { eq, ord } from '@code-expert/prelude';
import { adt, iots, record, string } from '@code-expert/prelude';

export const useCaseProps = {
  environmentTemplate: null,
  masterSolution: null,
  studentTemplate: null,
  studentAttempt: null,
  submissionFeedback: null,
  regradeTemplate: null,
};

export type UseCase = keyof typeof useCaseProps;

export const useCases: Array<UseCase> = record.keys(useCaseProps);

export const foldUseCase = adt.foldFromKeys(useCaseProps);

export const useCaseEq: eq.Eq<UseCase> = string.Eq;

export const useCaseOrd: ord.Ord<UseCase> = string.Ord;

export const UseCaseC = iots.keyof(useCaseProps);
