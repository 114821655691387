import { either, flow, iots, nonEmptyArray, option } from '@code-expert/prelude';

type DeprecatedReport = iots.TypeOf<typeof DeprecatedReport>;
const DeprecatedReport = iots.strict(
  {
    'csp-report': iots.strict({
      'document-uri': iots.string,
      referrer: iots.string,
      'blocked-uri': iots.string,
      'violated-directive': iots.string,
      'original-policy': iots.string,
    }),
  },
  'DeprecatedReport',
);
type BrowserReport = iots.TypeOf<typeof BrowserReport>;
const BrowserReport = iots.nonEmptyArray(
  iots.strict({
    url: iots.string,
    body: iots.strict({
      blocked: iots.string,
      directive: iots.string,
      policy: iots.string,
      referrer: iots.union([iots.literal(''), iots.string]),
    }),
  }),
  'BrowserReport',
);
type Report = DeprecatedReport | BrowserReport;
const Report = iots.union([DeprecatedReport, BrowserReport]);

export function isDeprecatedReport(r: Report): r is DeprecatedReport {
  return 'csp-report' in r;
}

export function isBrowserReport(r: Report): r is BrowserReport {
  return Array.isArray(r);
}

export const foldReport: <A>(
  onDeprecatedReport: (c: DeprecatedReport) => A,
  onBrowserReport: (b: BrowserReport) => A,
) => (report: Report) => A = (onDeprecatedReport, onBrowserReport) => (report) => {
  if (isDeprecatedReport(report)) return onDeprecatedReport(report);
  return onBrowserReport(report);
};

interface StandardViolation {
  date: Date;
  fallback: false;
  page: string;
  referrer: option.Option<string>;
  blockedResource: string;
  violatedDirective: string;
  fullPolicy: string;
}

interface FallbackViolation {
  date: Date;
  fallback: true;
  report: unknown;
}

export type Violation = StandardViolation | FallbackViolation;

const filterEmptyString: (s: string) => option.Option<string> = option.fromPredicate((s) => !!s);
const browserReportToViolationReport: (br: BrowserReport) => NonEmptyArray<StandardViolation> =
  nonEmptyArray.map((br) => ({
    date: new Date(),
    fallback: false,
    page: br.url,
    referrer: filterEmptyString(br.body.referrer),
    blockedResource: br.body.blocked,
    violatedDirective: br.body.directive,
    fullPolicy: br.body.policy,
  }));
const deprecatedReportToViolationReport = ({
  'csp-report': dr,
}: DeprecatedReport): NonEmptyArray<StandardViolation> => [
  {
    date: new Date(),
    fallback: false,
    page: dr['document-uri'],
    referrer: filterEmptyString(dr.referrer),
    blockedResource: dr['blocked-uri'],
    violatedDirective: dr['violated-directive'],
    fullPolicy: dr['original-policy'],
  },
];
export const parseViolationReport = flow(
  Report.decode,
  either.map(foldReport(deprecatedReportToViolationReport, browserReportToViolationReport)),
);

export function getFallbackViolations(body: unknown): Array<FallbackViolation> {
  return [body].flatMap((report) => ({
    date: new Date(),
    fallback: true,
    report,
  }));
}
