import { useCallback, useMemo } from 'react';

import { task } from '@code-expert/prelude';
import type { UserId } from '/imports/domain';
import { invokeProcedure } from '/imports/ui/hooks/useProcedure';

export interface UserNameInterface {
  _id: UserId;
  userName: string;
  email: string;
  userTitleName: string;
}

/**
 * In memory cache of usernames, independent of live time of hook.
 */
const cache = new Map<string, Promise<UserNameInterface>>();

function getUncachedUserIds(userIds: Array<UserId>) {
  return userIds.filter((uId) => !cache.get(uId));
}

const getUserNames = invokeProcedure('user_getNames');

/**
 * Hook to improve performance of getting the userName by using an in memory cache
 *
 * @returns [getUserFromCache, getUsersFromCache, fillCache]
 */
export const useUserNameCache = () => {
  /**
   * Stores the username as a promise in the cache
   */
  const fillCache = useCallback((userIds: Array<UserId>) => {
    const unCached = getUncachedUserIds(userIds);
    if (unCached.length > 0) {
      const methodPromise = task.run(getUserNames(unCached));
      unCached.forEach((u) => {
        const promise = methodPromise.then((userNamesData) => {
          const data = userNamesData?.find((userName: UserNameInterface) => userName._id === u);
          if (!data) {
            throw new Error('Invalid userId');
          }
          return data;
        });
        cache.set(u, promise);
      });
    }
  }, []);

  /**
   * Returns an array of usernames
   */
  const getUsersFromCache = useCallback(
    (userIds: Array<UserId>) => {
      fillCache(userIds);
      // after the fillCache method is called it is ensured that all users are in the cache as promise.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return Promise.all(userIds.map((uId) => cache.get(uId)!));
    },
    [fillCache],
  );

  /**
   * Returns a username
   */
  const getUserFromCache = useCallback(
    (userId: UserId) => getUsersFromCache([userId]).then((xs) => xs[0]),
    [getUsersFromCache],
  );

  return useMemo(
    () => ({ getUserFromCache, getUsersFromCache, fillCache }),
    [getUserFromCache, getUsersFromCache, fillCache],
  );
};
