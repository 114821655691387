import type { ThemeConfig } from 'antd';

import type { ColorScheme } from './ColorScheme';
import type { DesignTokens } from './DesignTokens';

export interface Theme {
  colorScheme: ColorScheme;
  setColorScheme: (scheme: ColorScheme) => void;
  tokens: DesignTokens;
}

export const defaultThemeConfig: ThemeConfig = {
  token: {
    fontFamily: 'Inter, sans-serif',
    fontFamilyCode: '"IBM Plex Mono", "Monaco", "Menlo", "Ubuntu Mono", "Consolas", monospace',
  },
  components: {
    Typography: {
      fontSizeHeading1: 38,
      fontSizeHeading2: 24,
      fontSizeHeading3: 18,
      fontSizeHeading4: 16,
    },
  },
};

export const defaultDesignTokens = {
  fontFeatureSettingsCode: 'normal',
  fontLineHeightCode: 1.5,
  fontSizeXS: '10px',

  tabSize: 2,
} satisfies Partial<DesignTokens>;
