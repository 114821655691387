import React from 'react';

import { zero } from '/imports/modules/Routing';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import type { ExamReviewRouteParams, ReviewRouteParams } from '/imports/ui/pages/review/routes';
import { examReviewRoute, reviewRoute } from '/imports/ui/pages/review/routes';

const Review = React.lazy(() => import('./Review'));
const ReviewExam = React.lazy(() => import('./ReviewExam'));

const review = (params: ReviewRouteParams) => (
  <PrivateRoute element={() => <Review {...params} />} denyCapabilities={['exam', 'lti']} />
);

const reviewExam = (params: ExamReviewRouteParams) => (
  <PrivateRoute element={() => <ReviewExam {...params} />} denyCapabilities={['exam', 'lti']} />
);

export const reviewRouter = zero()
  .alt(reviewRoute.parser.map(review))
  .alt(examReviewRoute.parser.map(reviewExam));
