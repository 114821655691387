import { adt, array, boolean, flow, number, option, ord, pipe, string } from '@code-expert/prelude';
import type { SubmissionPublic } from './submission';
import { foldSubmissionPublic, submissionPublicPrism } from './submission';

// -------------------------------------------------------------------------------------------------
// Types

export const foldSubmissionFeedbackStatus = adt.foldFromKeys({
  unreadWithComments: null,
  readWithComments: null,
  unread: null,
  read: null,
});

/**
 * Submissions can receive feedback. If they do, we distinguish the following cases of feedback:
 *
 * - `unreadWithComments` The student has not yet seen the comments given for this submission
 * - `unread` The student has not yet seen that this submission has been reviewed (without comments)
 * - `read` The student is aware of the feedback
 */
export type SubmissionFeedbackStatus = adt.TypeOfKeys<typeof foldSubmissionFeedbackStatus>;

// -------------------------------------------------------------------------------------------------
// Ord

/** Sort higher priority items to the front (lower numbers) */
export const ordSubmissionFeedbackStatus = ord.contramap(
  foldSubmissionFeedbackStatus({
    unreadWithComments: () => 0,
    unread: () => 1,
    readWithComments: () => 2,
    read: () => 3,
  }),
)(number.Ord);

// -------------------------------------------------------------------------------------------------
// Predicates

/**
 * Check if there is unread feedback for this submission.
 */
export const hasUnreadFeedback = <A extends SubmissionPublic>(submission: A): boolean =>
  foldSubmissionPublic(submission, {
    code: ({ unread = true, comments = '' }) => string.isNotBlank(comments) && unread,
    dispense: () => false,
    link: () => false,
    presentation: () => false,
  });

// -------------------------------------------------------------------------------------------------
// Functions

/**
 * Determine the level of feedback on a submission, if any. We distinguish between:
 *
 * 1. A submission has not been reviewed
 * 2. A submission has been reviewed, and
 *   1. the student has seen the review, or
 *   2. the TA has reviewed the submission, and may have added a comment
 */
export const feedbackFromSubmission: (
  _: SubmissionPublic,
) => option.Option<SubmissionFeedbackStatus> = flow(
  submissionPublicPrism.code.getOption,
  option.filter(({ reviewed = false }) => reviewed),
  option.map(({ unread = true, comments = '' }) =>
    pipe(
      string.isNotBlank(comments),
      boolean.fold(
        () => (unread ? 'unread' : 'read'),
        () => (unread ? 'unreadWithComments' : 'readWithComments'),
      ),
    ),
  ),
);

/**
 * Given a list of SubmissionFeedbackStatus, pick the one with the highest priority.
 */
export const pickHighestPriorityFeedback = (
  xs: Array<SubmissionFeedbackStatus>,
): option.Option<SubmissionFeedbackStatus> =>
  pipe(xs, array.sort(ordSubmissionFeedbackStatus), array.head);
