import {
  adt,
  constant,
  constFalse,
  constTrue,
  eq,
  iots,
  isOneOf,
  option,
  pipe,
  readonlySet,
  readonlyTuple,
  refinement,
  struct,
} from '@code-expert/prelude';
import type { DistributiveKeyof, DistributivePick } from '@code-expert/type-utils';
import {
  CourseId,
  EnvironmentId,
  ExamId,
  LtiSessionId,
  ProjectEntryKey,
  ProjectId,
  SemesterId,
  SnapshotId,
  SubmissionId,
  TaskId,
  UserId,
} from '/imports/domain/identity';
import type { UseCase } from './UseCase';
import { UseCaseC, useCaseEq, useCaseOrd } from './UseCase';

/**
 * The primary project type that encompasses all kinds of projects available.
 */
export type Project =
  | EnvironmentTemplate
  | MasterSolution
  | StudentTemplate
  | RegradeTemplate
  | StudentAttempt
  | SubmissionFeedback;

/**
 * Task projects are always associated with a task.
 */
export type TaskProject = Extract<Project, BelongsToTask>;

/**
 * Student projects have been originally authored by a student, but in the case of
 * {@link SubmissionFeedback} might be later changed by assistants and admins.
 */
export type StudentProject = Extract<Project, BelongsToStudent>;

/**
 * Exam projects are associated with an exam through an `examId`.
 */
export type ExamProject = Extract<Project, BelongsToExam> & Required<BelongsToExam>;

/**
 * LTI projects have been created by an LTI user.
 */
export type LtiProject = Extract<Project, BelongsToLtiSession> & Required<BelongsToLtiSession>;

// -------------------------------------------------------------------------------------------------
// Synchronisation

/**
 * Pairs of projects for which synchronisation is allowed in the direction origin -> copy.
 */
export type OriginCopyPairs =
  | [EnvironmentTemplate, MasterSolution] // Pull relation
  | [MasterSolution, StudentTemplate] // Push relation
  | [MasterSolution, RegradeTemplate] // Pull relation
  | [StudentTemplate, StudentAttempt]; // Force push relation
// TODO Find a place for the [StudentAttempt, SubmissionFeedback] relation, which is neither pull nor push

/**
 * Origin projects can be copied from to create {@link CopiedProject}s.
 */
export type OriginProject = OriginCopyPairs[0];

export const isOriginProject = (project: Project): project is OriginProject =>
  foldProject(project, {
    environmentTemplate: constTrue,
    masterSolution: constTrue,
    studentTemplate: constTrue,
    studentAttempt: constFalse,
    regradeTemplate: constFalse,
    submissionFeedback: constFalse,
  });

/**
 * Copied projects are downstream from and kept in sync with their upstream {@link OriginProject}.
 */
export type CopiedProject = OriginCopyPairs[1];

// -------------------------------------------------------------------------------------------------
// Base traits

/**
 * The base type for projects that includes all shared fields.
 *
 * Project use-cases build on the `Project` type from @code-expert/pfs. Not all fields that are
 * used by PFS are exposed in our domain, so there might be more data in the persisted documents
 * than is apparent here.
 */
interface BaseProject {
  readonly _id: ProjectId;
  readonly useCase: UseCase;
  readonly envId: EnvironmentId;
  readonly name: string;
  readonly rootDirKey: ProjectEntryKey;
  readonly defaultSelectionKey?: ProjectEntryKey;
  readonly envVars?: Record<string, unknown>;
}

const basePropsStrict = {
  _id: ProjectId,
  useCase: UseCaseC,
  envId: EnvironmentId,
  name: iots.string,
  rootDirKey: ProjectEntryKey,
};

const basePropsPartial = {
  defaultSelectionKey: ProjectEntryKey,
  envVars: iots.record(iots.string, iots.unknown),
};

/**
 * A project that belongs to a {@link CodeTask}.
 *
 * For convenience, this also includes references to course and semester.
 */
interface BelongsToTask {
  readonly courseId: CourseId;
  readonly semester: SemesterId;
  readonly taskId: TaskId;
}

const belongsToTaskPropsStrict = {
  courseId: CourseId,
  semester: SemesterId,
  taskId: TaskId,
};

/**
 * A project that belongs to a student.
 */
interface BelongsToStudent {
  /**
   * This is a reference to the student who originally created this project. In the case of a
   * SubmissionFeedback project, this refers to the student who created the original StudentAttempt.
   */
  readonly studentId: UserId;
  readonly submitSnapshotId?: SnapshotId;
}

const belongsToStudentPropsStrict = {
  studentId: UserId,
};

const belongsToStudentPropsPartial = {
  submitSnapshotId: SnapshotId,
};

/**
 * When a project is created during a Code Expert exam, a reference is kept to the original exam
 * in order to be able to make the student-exam connection during exam review.
 */
interface BelongsToExam {
  readonly examId?: ExamId;
}

const belongsToExamPropsPartial = {
  examId: ExamId,
};

/**
 * During Moodle exams students create projects using the LTI API and are not logged in as
 * "normal" Code Expert users.
 */
interface BelongsToLtiSession {
  readonly ltiSessionId?: LtiSessionId;
}

const belongsToLtiSessionPropsPartial = {
  ltiSessionId: LtiSessionId,
};

/**
 * A project that serves as an origin from which copies can be derived (see {@link IsCopied}).
 */
interface IsPublishable {
  /** If a snapshot is available, the project has been initialised and published. */
  readonly publishedSnapshotId?: SnapshotId;
}

const isPublishablePropsPartial = {
  publishedSnapshotId: SnapshotId,
};

/**
 * A project that has been copied from an origin project (see {@link IsPublishable}).
 *
 * Copied projects can be updated from the origin to stay in sync.
 */
interface IsCopied {
  /** The original project that this copy was derived from */
  readonly originId: ProjectId;
}

const isCopiedPropsStrict = {
  originId: ProjectId,
};

// -------------------------------------------------------------------------------------------------
// Primary project types

/**
 * An EnvironmentTemplate project can be used as a default project when a new {@link CodeTask}
 * is created.
 */
export interface EnvironmentTemplate extends BaseProject, IsPublishable {
  readonly useCase: 'environmentTemplate';
  readonly lastUpdate: Date;
}

export const EnvironmentTemplateC = iots.struct(
  {
    ...basePropsStrict,
    useCase: iots.literal('environmentTemplate'),
    lastUpdate: iots.date,
  },
  {
    ...basePropsPartial,
    ...isPublishablePropsPartial,
  },
) satisfies iots.Type<EnvironmentTemplate, unknown>;

/**
 * A MasterSolution project provides a suggested solution for a {@link CodeTask}.
 *
 * It is usually created by forking an {@link EnvironmentTemplate}, but it can also be created
 * through importing.
 *
 * Tests are usually programmed against the MasterSolution. If the MasterSolution needs to be
 * changed for some reason at a later time, a {@link RegradeTemplate} can be created to serve
 * as a new MasterSolution in the context of the regrade.
 *
 * The `originId` is not set when the project was imported from the file system (i.e. from an
 * external system that might have a project that we don't have).
 */
export interface MasterSolution
  extends BaseProject,
    BelongsToTask,
    IsPublishable,
    Partial<IsCopied> {
  readonly useCase: 'masterSolution';
  readonly lastUpdate: Date;
  /**
   * This can be used by course admins to test a project with a new environment image (e.g. with a
   * new version of Java) before rolling it out to students.
   */
  readonly tempEnvId?: EnvironmentId;
}

export const MasterSolutionC = iots.struct(
  {
    ...basePropsStrict,
    ...belongsToTaskPropsStrict,
    useCase: iots.literal('masterSolution'),
    lastUpdate: iots.date,
  },
  {
    ...basePropsPartial,
    ...isPublishablePropsPartial,
    ...isCopiedPropsStrict,
    tempEnvId: EnvironmentId,
  },
) satisfies iots.Type<MasterSolution, unknown>;

/**
 * A StudentTemplate project is usually copied from a {@link MasterSolution}, but it can also be
 * forked from a StudentTemplate of a previous semester.
 *
 * If the StudentTemplate was created through copying a MasterSolution, it retains an origin-copy
 * relationship and can be updated from the MasterSolution.
 *
 * It is used to provide a starting point for students without revealing the solution that the
 * corresponding MasterSolution suggest.
 */
export interface StudentTemplate extends BaseProject, BelongsToTask, IsPublishable, IsCopied {
  readonly useCase: 'studentTemplate';
  readonly lastUpdate?: Date;
  /**
   * This can be used by course admins to test a project with a new environment image (e.g. with a
   * new version of Java) before rolling it out to students.
   */
  readonly tempEnvId?: EnvironmentId;
}

export const StudentTemplateC = iots.struct(
  {
    ...basePropsStrict,
    ...belongsToTaskPropsStrict,
    ...isCopiedPropsStrict,
    useCase: iots.literal('studentTemplate'),
  },
  {
    ...basePropsPartial,
    ...isPublishablePropsPartial,
    lastUpdate: iots.date,
    tempEnvId: EnvironmentId,
  },
) satisfies iots.Type<StudentTemplate, unknown>;

/**
 * A RegradeTemplate project is always copied from a {@link MasterSolution}.
 *
 * It can be used in place of the original MasterSolution to run tests against, e.g. when tests
 * have to be adjusted because of flaws or to test additional cases.
 *
 * During regrading, a RegradeTemplate is used to create new {@link SubmissionFeedback} projects
 * based on a student's existing SubmissionFeedback projects. These are then graded against the
 * new tests.
 */
export interface RegradeTemplate extends BaseProject, BelongsToTask, IsCopied {
  readonly useCase: 'regradeTemplate';
  readonly lastUpdate?: Date;
}

export const RegradeTemplateC = iots.struct(
  {
    ...basePropsStrict,
    ...belongsToTaskPropsStrict,
    ...isCopiedPropsStrict,
    useCase: iots.literal('regradeTemplate'),
  },
  {
    ...basePropsPartial,
    lastUpdate: iots.date,
  },
) satisfies iots.Type<RegradeTemplate, unknown>;

/**
 * A StudentAttempt project is always copied from a {@link StudentTemplate}.
 *
 * Given the starting point from the StudentTemplate, a student will attempt to arrive
 * at a solution iteratively, creating attempts in the process. A course admin may choose to
 * show their {@link MasterSolution} to students, so the attempt can be compared to the
 * course admin's solution.
 */
export interface StudentAttempt
  extends BaseProject,
    BelongsToTask,
    BelongsToStudent,
    BelongsToExam,
    BelongsToLtiSession,
    IsCopied {
  readonly useCase: 'studentAttempt';
  readonly lastUpdate?: Date;
}

export const StudentAttemptC = iots.struct(
  {
    ...basePropsStrict,
    ...belongsToTaskPropsStrict,
    ...belongsToStudentPropsStrict,
    ...isCopiedPropsStrict,
    useCase: iots.literal('studentAttempt'),
  },
  {
    ...basePropsPartial,
    ...belongsToStudentPropsPartial,
    ...belongsToExamPropsPartial,
    ...belongsToLtiSessionPropsPartial,
    lastUpdate: iots.date,
  },
) satisfies iots.Type<StudentAttempt, unknown>;

/**
 * A SubmissionFeedback project is always copied from a {@link StudentAttempt} that contains the
 * solution the student came up with.
 *
 * It is used by course admins and TAs to provide feedback to a student.
 */
export interface SubmissionFeedback
  extends BaseProject,
    BelongsToTask,
    BelongsToStudent,
    BelongsToExam,
    BelongsToLtiSession,
    IsCopied {
  readonly useCase: 'submissionFeedback';
  readonly assistantFeedbackSnapshotId?: SnapshotId;
  readonly feedbackSubmissionId: SubmissionId;
  readonly lastUpdate?: Date;
}

export const SubmissionFeedbackC = iots.struct(
  {
    ...basePropsStrict,
    ...belongsToTaskPropsStrict,
    ...belongsToStudentPropsStrict,
    ...isCopiedPropsStrict,
    useCase: iots.literal('submissionFeedback'),
    feedbackSubmissionId: SubmissionId,
  },
  {
    ...basePropsPartial,
    ...belongsToStudentPropsPartial,
    ...belongsToExamPropsPartial,
    ...belongsToLtiSessionPropsPartial,
    assistantFeedbackSnapshotId: SnapshotId,
    lastUpdate: iots.date,
  },
) satisfies iots.Type<SubmissionFeedback, unknown>;

export const ProjectC = iots.union([
  EnvironmentTemplateC,
  MasterSolutionC,
  StudentTemplateC,
  RegradeTemplateC,
  StudentAttemptC,
  SubmissionFeedbackC,
]) satisfies iots.Type<Project, unknown>;

// -------------------------------------------------------------------------------------------------
// ADT

export const foldProject = adt.foldFromTags<Project, 'useCase'>('useCase');

export const foldPartialProject = adt.foldFromProp('useCase');

// -------------------------------------------------------------------------------------------------
// Functions

type PickIdAndUseCase<A extends { _id: ProjectId; useCase: UseCase }> = DistributivePick<
  A,
  '_id' | 'useCase'
>;

export const belongsToTask = <
  A extends DistributivePick<Project, '_id' | 'useCase' | 'courseId' | 'semester' | 'taskId'>,
>(
  x: A,
): x is Extract<
  A,
  DistributivePick<TaskProject, '_id' | 'useCase' | 'courseId' | 'semester' | 'taskId'>
> => 'courseId' in x && 'semester' in x && 'taskId' in x;

export const isSolutionOrTemplate = <A extends PickIdAndUseCase<Project>>(
  x: A,
): x is Extract<A, PickIdAndUseCase<MasterSolution> | PickIdAndUseCase<StudentTemplate>> =>
  isOneOf('masterSolution', 'studentTemplate')(x.useCase);

export const isStudentAttempt = <A extends PickIdAndUseCase<Project>>(
  x: A,
): x is Extract<A, PickIdAndUseCase<StudentAttempt>> => x.useCase === 'studentAttempt';

export const requireStudentAttempt = refinement.invariant(
  isStudentAttempt,
  () => 'Expected student attempt',
);

export const isStudentTemplate = <A extends PickIdAndUseCase<Project>>(
  x: A,
): x is Extract<A, PickIdAndUseCase<StudentTemplate>> => x.useCase === 'studentTemplate';

export const requireStudentTemplate = refinement.invariant(
  isStudentTemplate,
  () => 'Expected student template',
);

export const isMasterSolution = <A extends PickIdAndUseCase<Project>>(
  project: A,
): project is Extract<A, PickIdAndUseCase<MasterSolution>> => project.useCase === 'masterSolution';

export const requireMasterSolution = refinement.invariant(
  isMasterSolution,
  () => 'Expected master solution',
);

export const isSubmissionFeedback = <A extends PickIdAndUseCase<Project>>(
  x: A,
): x is Extract<A, PickIdAndUseCase<SubmissionFeedback>> => x.useCase === 'submissionFeedback';

export const requireSubmissionFeedback = refinement.invariant(
  isSubmissionFeedback,
  () => 'Expected submission feedback',
);

export const isStudentProject = <A extends PickIdAndUseCase<Project>>(
  x: A,
): x is Extract<A, PickIdAndUseCase<StudentProject>> =>
  isStudentAttempt(x) || isSubmissionFeedback(x);

/**
 * Check whether a project was created in and is associated with an exam.
 */
export const isExamProject = (
  project: DistributivePick<Project, '_id' | 'useCase' | 'examId'>,
): project is Pick<ExamProject, '_id' | 'useCase' | 'examId'> =>
  isStudentProject(project) && project.examId != null;

/**
 * Check whether a project was created in and is associated with an exam.
 */

export const isLtiProject = (
  project: DistributivePick<Project, '_id' | 'useCase' | 'ltiSessionId'>,
): project is Pick<LtiProject, '_id' | 'useCase' | 'ltiSessionId'> =>
  isStudentProject(project) && project.ltiSessionId != null;

/**
 * Check whether a project was created by the given student.
 */
export const isOwnProject = (
  userId: UserId,
  project: Pick<StudentProject, '_id' | 'studentId'>,
): boolean => userId === project.studentId;

/**
 * Check whether a project has submission feedback, i.e. whether a {@link SubmissionFeedback}
 * project exists.
 */
export const hasSubmissionFeedback = (
  x: DistributivePick<Project, '_id' | 'assistantFeedbackSnapshotId'>,
): x is Pick<SubmissionFeedback, '_id' | 'assistantFeedbackSnapshotId'> =>
  'assistantFeedbackSnapshotId' in x && x.assistantFeedbackSnapshotId != null;

/**
 * Check whether a project has a published snapshot, i.e. "is published".
 *
 * Unlike {@link isProjectPublished} we know that _if_ a snapshot exists, this must be
 * an {@link IsPublishable} project.
 */
export const hasPublishedSnapshot = (
  x: DistributivePick<Project, '_id' | 'publishedSnapshotId'>,
): x is OriginProject => 'publishedSnapshotId' in x && x.publishedSnapshotId != null;

/**
 * Require that a project has a published snapshot, i.e. "is published".
 * @see hasPublishedSnapshot
 */
export const requirePublishedSnapshot = refinement.invariant(
  hasPublishedSnapshot,
  ({ _id: projectId }) => ({
    message: `Project is missing ${'publishedSnapshotId' satisfies DistributiveKeyof<Project>}`,
    details: { projectId },
  }),
);

/**
 * Check if a project is published and therefore visible to users.
 *
 * This works for all kinds of projects and defaults to "true" if the project is
 * not an {@link IsPublishable} project (because those projects are never "hidden").
 *
 * If the presence of an actual snapshot is important, {@link hasPublishedSnapshot}
 * should be used instead.
 */
export const isProjectPublished = (
  project: DistributivePick<Project, '_id' | 'useCase' | 'publishedSnapshotId'>,
): boolean =>
  foldPartialProject(project, {
    environmentTemplate: hasPublishedSnapshot,
    masterSolution: hasPublishedSnapshot,
    studentTemplate: hasPublishedSnapshot,
    studentAttempt: constTrue,
    submissionFeedback: constTrue,
    regradeTemplate: constTrue,
  });

const originCopyUseCasePairEq: eq.Eq<
  readonly [OriginProject['useCase'], CopiedProject['useCase']]
> = eq.tuple(useCaseEq, useCaseEq);

const originCopyUseCases: ReadonlySet<
  readonly [OriginProject['useCase'], CopiedProject['useCase']]
> = readonlySet.fromReadonlyArray(originCopyUseCasePairEq)([
  ['environmentTemplate', 'masterSolution'],
  ['masterSolution', 'studentTemplate'],
  ['masterSolution', 'regradeTemplate'],
  ['studentTemplate', 'studentAttempt'],
]);

/**
 * Check whether a given {@link CopiedProject} can be updated from its {@link OriginProject}.
 */
export const canRefreshCopyFromOrigin =
  ({ useCase }: Pick<CopiedProject, '_id' | 'useCase'>) =>
  ({ useCase: originUseCase }: Pick<OriginProject, '_id' | 'useCase'>) =>
    readonlySet.elem(originCopyUseCasePairEq)([originUseCase, useCase], originCopyUseCases);

export const isCopiedProject = (
  x: DistributivePick<Project, '_id' | 'useCase'>,
): x is CopiedProject =>
  isOneOf(
    ...pipe(
      originCopyUseCases,
      readonlySet.map(useCaseEq)(readonlyTuple.snd),
      readonlySet.toReadonlyArray(useCaseOrd),
    ),
  )(x.useCase);

export const getOriginId: (_: Project) => option.Option<ProjectId> = foldProject({
  environmentTemplate: constant(option.none),
  masterSolution: ({ originId }) => option.fromNullable(originId),
  studentTemplate: ({ originId }) => option.some(originId),
  studentAttempt: ({ originId }) => option.some(originId),
  submissionFeedback: ({ originId }) => option.some(originId),
  regradeTemplate: ({ originId }) => option.some(originId),
});

export const getPublishedSnapshotId: (_: Project) => option.Option<SnapshotId> = foldProject({
  environmentTemplate: constant(option.none),
  masterSolution: ({ publishedSnapshotId }) => option.fromNullable(publishedSnapshotId),
  studentTemplate: ({ publishedSnapshotId }) => option.fromNullable(publishedSnapshotId),
  studentAttempt: constant(option.none),
  submissionFeedback: constant(option.none),
  regradeTemplate: constant(option.none),
});

const projectPublicProps = [
  '_id',
  'useCase',
  'lastUpdate',
  'name',
  'defaultSelectionKey',
  'publishedSnapshotId',
  'courseId',
  'semester',
  'taskId',
  'submitSnapshotId',
  'feedbackSubmissionId',
  'assistantFeedbackSnapshotId',
] satisfies Array<DistributiveKeyof<Project>>;
type AllPublicProps = (typeof projectPublicProps)[number];

export type PublicProject = DistributivePick<Project, AllPublicProps>;

export const removePrivateFields: (project: Project) => PublicProject =
  struct.distributivePick(projectPublicProps);

export const masterSolutionName = (name: string): string => `${name} - Master solution`;

export const studentTemplateName = (name: string): string => `${name} - Student template`;
