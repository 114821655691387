import { adt, iots } from '@code-expert/prelude';

export const ColorSchemeC = iots.union([iots.literal('light'), iots.literal('dark')]);

export type ColorScheme = iots.TypeOf<typeof ColorSchemeC>;

export const foldColorScheme = adt.foldFromKeys({
  light: null,
  dark: null,
});

// -------------------------------------------------------------------------------------------------

export const isLight = (colorScheme: ColorScheme) => colorScheme === 'light';

export const toggleColorScheme = (previousScheme: ColorScheme) =>
  isLight(previousScheme) ? 'dark' : 'light';

/**
 * Given a color scheme, pick either the light or the dark value.
 */
export function mkColorSchemeValue(colorScheme: ColorScheme) {
  return function <A>(lightValue: A, darkValue: A): A {
    return isLight(colorScheme) ? lightValue : darkValue;
  };
}
