import React from 'react';

import { Popover, Typography } from 'antd';

import type { ProjectEntryKey, ProjectId } from '/imports/domain';
import type { ProjectHooks } from '/imports/modules/Project/presentation/hooks';
import { renderProjectError } from '/imports/modules/Project/presentation/shared';
import { GuardRemoteEither } from '/imports/ui/components/GuardRemoteEither';
import { Icon } from '/imports/ui/foundation/Icons';
import { VStack } from '/imports/ui/foundation/Layout';

interface Props {
  hooks: ProjectHooks;
  projectId: ProjectId;
  fileKey: ProjectEntryKey;
  version: number;
}

const PopoverContent = ({ hooks, projectId, fileKey, version }: Props) => (
  <VStack gap="xs">
    <Typography.Text strong>Preview of version {version}</Typography.Text>
    <GuardRemoteEither
      value={hooks.useContent({ projectId, key: fileKey, version })}
      render={({ content }) => <Typography.Text code>{content}</Typography.Text>}
      alt={renderProjectError}
    />
  </VStack>
);

export const ContentPreview = (props: Props) => (
  <Popover content={<PopoverContent {...props} />} placement="left">
    <Icon name="eye" />
  </Popover>
);
