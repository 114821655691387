// Originally from https://github.com/danyk2099/html5-notifications-stub-polyfill

import { constVoid } from '@code-expert/prelude';

declare global {
  interface Window {
    Notification: $IntentionalAny;
  }
}

export interface IOptions {
  actions: $IntentionalAny;
  body: $IntentionalAny;
  dir: $IntentionalAny;
  lang: $IntentionalAny;
  tag: $IntentionalAny;
  icon: $IntentionalAny;
  image: $IntentionalAny;
}

class NotificationPolyfill {
  static permission: 'granted' | 'denied' | 'default' = 'denied';

  static maxActions = 10;

  public actions: $IntentionalAny;

  public badge: $IntentionalAny;

  public body: $IntentionalAny;

  public data: $IntentionalAny;

  public dir: $IntentionalAny;

  public lang: $IntentionalAny;

  public tag: $IntentionalAny;

  public icon: $IntentionalAny;

  public image: $IntentionalAny;

  public renotify: $IntentionalAny;

  public requireInteraction: $IntentionalAny;

  public silent: $IntentionalAny;

  public timestamp: $IntentionalAny;

  public vibrate: $IntentionalAny;

  static requestPermission = function () {
    return Promise.resolve('denied');
  };

  constructor(
    public title: string,
    public options: IOptions,
  ) {
    this.actions = options.actions;
    this.body = options.body;
    this.dir = options.dir;
    this.lang = options.lang;
    this.tag = options.tag;
    this.icon = options.icon;
    this.image = options.image;
  }

  public onclick = constVoid;

  public onclose = constVoid;

  public onerror = constVoid;

  public onshow = constVoid;

  public close = constVoid;
}

export function stubNotificationsIfNotAvailable() {
  if (window.Notification == null) {
    window.Notification = NotificationPolyfill;
  }
}
