import React from 'react';

import { invariant } from '@code-expert/prelude';
import { useForceUpdateWithDep } from '/imports/ui/hooks/useForceUpdate';

/**
 * Wraps `setInterval`. Triggers the function each intervalDelay.
 * @param fn function to call
 * @param intervalDelay in milliseconds
 * @param run if the intervalDelay should run or not
 */
export default function useInterval(fn: () => void, intervalDelay: number, run = true) {
  const { current } = React.useRef({
    fn,
    interval: undefined as NodeJS.Timeout | undefined,
    isMounted: false,
    clear: () => {
      if (current.interval) {
        clearInterval(current.interval);
        current.interval = undefined;
      }
    },
    set: (ms: number) => {
      invariant(current.interval == null, 'Expected interval to not be running');
      current.interval = setInterval(current.run, ms);
    },
    run: () => {
      invariant(current.interval != null, 'Expected interval to be running');
      invariant(current.isMounted, 'Expected component to be mounted');
      current.fn();
    },
  });

  current.fn = fn;

  React.useEffect(() => {
    current.isMounted = true;
    return () => {
      current.isMounted = false;
    };
  }, [current]);

  React.useEffect(() => {
    if (run && current.isMounted) {
      current.set(intervalDelay);
    }
    return current.clear;
  }, [run, current.isMounted, current, intervalDelay]);
}

export function useIntervalTrigger(intervalDelay: number, run = true) {
  const [dependency, update] = useForceUpdateWithDep();
  useInterval(update, intervalDelay, run);
  return dependency;
}
