import { makeClientLogger, sanitizeExtra } from '@code-expert/logging/client';
import type { Logger as ClientLogger, LogLevel, Serializable } from '@code-expert/logging/client';
import type { task } from '@code-expert/prelude';
import { option, pipe } from '@code-expert/prelude';
import { invokeProcedure } from '/imports/ui/hooks/useProcedure';

const publish =
  (whenLoggedIn: boolean) =>
  <A>(
    level: LogLevel,
    message: string,
    extra?: Record<string, Serializable<A>>,
  ): task.Task<void> => {
    const safeExtra = pipe(sanitizeExtra(extra), option.toUndefined);
    return invokeProcedure('logger_store')({
      level,
      message,
      ...(safeExtra != null ? { extra: safeExtra as $Inexpressible } : {}),
      whenLoggedIn,
    });
  };

export const Logger: ClientLogger & {
  persist: ClientLogger;
  persistAlways: ClientLogger;
} = makeClientLogger() as $IntentionalAny;

/**
 * Logs and persists the log message to the server if the user is logged in.
 */
Logger.persist = makeClientLogger(publish(true));

/**
 * Logs and persists the log message to the server, even for unauthenticated users.
 */
Logger.persistAlways = makeClientLogger(publish(false));
