import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import hash from '@emotion/hash';
// eslint-disable-next-line import/no-extraneous-dependencies
import canUseDom from 'rc-util/lib/Dom/canUseDom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { removeCSS, updateCSS } from 'rc-util/lib/Dom/dynamicCSS';

import { assertNonNull } from '@code-expert/prelude';
import type { CSSInterpolation, LayerConfig, MapToken, SeedToken, Theme } from './types';
import {
  ATTR_CACHE_PATH,
  ATTR_MARK,
  ATTR_TOKEN,
  CSS_IN_JS_INSTANCE,
  normalizeStyle,
  parseStyle,
  StyleContext,
  useGlobalCache,
} from './types';

function uniqueHash(path: (string | number)[], styleStr: string) {
  return hash(`${path.join('%')}${styleStr}`);
}

const isClientSide = canUseDom();

// Global effect style will mount once and not removed
// The effect will not save in SSR cache (e.g. keyframes)
const globalEffectStyleKeys = new Set();

/**
 * This is our own implementation of Antd's `useStyleRegister` function.
 *
 * It is targeted towards application styles, not component styles like theirs.
 *
 * Because it relies on a lot of cssinjs internals, this is likely to break with updates and we
 * must take extra care to keep it in sync.
 *
 * Fixes:
 * - FIX #1: Return class names instead of config data (this breaks SSR, but we don't use that).
 * - FIX #2: Always append styles to `body` in order to receive higher specificity than Antd
 *
 * @internal Do not use directly in components.
 * @returns A class name instead of internal data
 */
export function useStyleRegisterNoSSR(
  info: {
    theme: Theme<SeedToken, MapToken>;
    token: $IntentionalAny;
    path: string[];
    hashId?: string;
    layer?: LayerConfig;
  },
  styleFn: () => CSSInterpolation,
): string {
  const { token, path, hashId, layer } = info;
  const { autoClear, mock, hashPriority, /* FIX #2 container, */ transformers, linters } =
    React.useContext(StyleContext);
  const container = document.body; // FIX #2
  const tokenKey = token._tokenKey as string;

  const fullPath = [tokenKey, ...path];

  // Check if need insert style
  let isMergedClientSide = isClientSide;
  if (process.env['NODE_ENV'] !== 'production' && mock !== undefined) {
    isMergedClientSide = mock === 'client';
  }

  useGlobalCache(
    'style',
    fullPath,
    // Create cache if needed
    () => {
      const styleObj = styleFn();
      const [parsedStyle, effectStyle] = parseStyle(styleObj, {
        hashId,
        hashPriority,
        layer,
        path: path.join('-'),
        transformers,
        linters,
      });
      const styleStr = normalizeStyle(parsedStyle);
      const styleId = uniqueHash(fullPath, styleStr);

      if (isMergedClientSide) {
        const style = updateCSS(styleStr, styleId, {
          mark: ATTR_MARK,
          prepend: 'queue',
          attachTo: container,
        });

        (style as $IntentionalAny)[CSS_IN_JS_INSTANCE] = CSS_IN_JS_INSTANCE;

        // Used for `useCacheToken` to remove on batch when token removed
        style.setAttribute(ATTR_TOKEN, tokenKey);

        // Dev usage to find which cache path made this easily
        if (process.env['NODE_ENV'] !== 'production') {
          style.setAttribute(ATTR_CACHE_PATH, fullPath.join('|'));
        }

        // Inject client side effect style
        Object.keys(effectStyle).forEach((effectKey) => {
          if (!globalEffectStyleKeys.has(effectKey)) {
            globalEffectStyleKeys.add(effectKey);

            // Inject
            const style = effectStyle[effectKey];
            assertNonNull(style);
            updateCSS(normalizeStyle(style), `_effect-${effectKey}`, {
              mark: ATTR_MARK,
              prepend: 'queue',
              attachTo: container,
            });
          }
        });
      }

      return [styleStr, tokenKey, styleId];
    },
    // Remove cache if no need
    ([, , styleId], fromHMR) => {
      if ((fromHMR || autoClear) && isClientSide && styleId != null) {
        removeCSS(styleId, { mark: ATTR_MARK });
      }
    },
  );

  return [hashId, ...path].filter(Boolean).join(' '); // FIX #1
}
