import {
  adt,
  array,
  either,
  flow,
  nonEmptyArray,
  number,
  option,
  ord,
  pipe,
} from '@code-expert/prelude';
import { isSubmissionUnsound } from '/imports/domain/grading';
import type { SubmissionFeedbackStatus } from '/imports/domain/submission';
import {
  feedbackFromSubmission,
  foldSubmissionFeedbackStatus,
  foldSubmissionPublic,
  foldSubmissionStatus,
  isDispenseSubmission,
  isSubmissionPreResult,
  isSubmissionRegraded,
  isSubmissionStateLate,
  pickHighestPriorityFeedback,
  pickRelevantSubmission,
  submissionState,
} from '/imports/domain/submission';
import type { ExerciseTaskSubmission } from './ets';
import { isTaskInWindow, isTaskWindowClosed, taskDueDateO } from './ets';
import { isLinklikeTask, isTaskGraded } from './task';

// -------------------------------------------------------------------------------------------------
// Types

/** The task should be currently worked on */
export type TaskStatusTodo = { type: 'todo' };
export const taskStatusTodo: TaskStatus = { type: 'todo' };

/** The student has been dispensed from this task, no work is expected */
export type TaskStatusDispensed = { type: 'dispensed' };
export const taskStatusDispensed: TaskStatus = { type: 'dispensed' };

/** The task has not been worked on in the time window given */
export type TaskStatusLate = { type: 'late' };
export const taskStatusLate: TaskStatus = { type: 'late' };

/** There is unread feedback on one of the submissions towards this task  */
export type TaskStatusFeedback = { type: 'feedback'; status: SubmissionFeedbackStatus };
export const taskStatusFeedback: (status: SubmissionFeedbackStatus) => TaskStatus = (status) => ({
  type: 'feedback',
  status,
});

/** None of the submissions towards this task have received a final mark */
export type TaskStatusPreliminary = { type: 'preliminary' };
export const taskStatusPreliminary: TaskStatus = { type: 'preliminary' };

/** There is a problem in one or several submissions that are relevant for this task */
export type TaskStatusWarning = { type: 'warning'; reason: string; details?: string };
export const taskStatusWarning: (reason: string, details?: string) => TaskStatus = (
  reason,
  details,
) => ({
  type: 'warning',
  reason,
  details,
});

/** Supplemental information about this task that does not affect the outcome */
export type TaskStatusInfo = { type: 'info'; reason: string; details?: string };
export const taskStatusInfo: (reason: string, details?: string) => TaskStatus = (
  reason,
  details,
) => ({
  type: 'info',
  reason,
  details,
});

/** A list of attributes for a given task */
export type TaskStatus =
  | TaskStatusTodo
  | TaskStatusDispensed
  | TaskStatusLate
  | TaskStatusFeedback
  | TaskStatusPreliminary
  | TaskStatusWarning
  | TaskStatusInfo;

export const foldTaskStatus = adt.foldFromTags<TaskStatus, 'type'>('type');

// -------------------------------------------------------------------------------------------------
// Ord

/** Sort higher priority items to the front (lower numbers) */
export const ordTaskStatus = ord.contramap(
  foldTaskStatus({
    feedback: ({ status }) =>
      foldSubmissionFeedbackStatus(status, {
        unreadWithComments: () => 0 /* High priority, bring to front */,
        readWithComments: () => Infinity /* Low priority, push to end */,
        unread: () => Infinity /* Low priority, push to end */,
        read: () => Infinity /* Low priority, push to end */,
      }),
    dispensed: () => 1,
    warning: () => 2,
    preliminary: () => 3,
    late: () => 4,
    todo: () => 5,
    info: () => 6,
  }),
)(number.Ord);

// -------------------------------------------------------------------------------------------------
// Validations

/**
 * Common interface for task validation. If there is something of note, an `Either.Left` is
 * returned, otherwise the original data is returned unchanged.
 * @internal
 */
interface ValidateTaskFn {
  (a: ExerciseTaskSubmission): either.Either<TaskStatus, ExerciseTaskSubmission>;
}

/**
 * Check whether the submissions for this task have been partially regraded,
 * i.e. whether some submissions have been made after a Regrade.
 * @internal
 */
const validateFullyRegraded: ValidateTaskFn = either.fromPredicate(
  ({ isPartiallyRegraded }) => !isPartiallyRegraded,
  () =>
    taskStatusWarning(
      'Excluded submissions',
      'This submission is excluded from marking because it was created after the task was regraded. Please contact your course staff if you want it to be included.',
    ),
);

/**
 * Check whether the submissions for this task have been regraded, i.e. whether their result
 * might have changed due to a regrade-template being applied.
 * @internal
 */
const validateIsNotRegraded: ValidateTaskFn = either.fromPredicate(
  ({ submissions }) => !pipe(submissions, option.exists(array.every(isSubmissionRegraded))),
  () => taskStatusInfo('Regraded', 'This submission has been regraded.'),
);

/**
 * Check whether the task has been handed in on time. If it is not late and
 * currently active, mark it as "to-do".
 *
 * If a task was dispensed, don't return any of the other states here.
 *
 * @internal
 */
const validateTimelySubmission: (now: Date) => ValidateTaskFn = (now) =>
  flow(
    either.right,

    // A task is dispensed if there is a dispense-submission
    either.stopIf(
      ({ submissions }) => pipe(submissions, option.exists(array.some(isDispenseSubmission))),
      () => taskStatusDispensed,
    ),

    // A task should be worked on (to-do) if it is "in window"
    either.stopIfW(
      ({ exercise, task, submissions }) => {
        const isInWindow = isTaskInWindow(now, { exercise, task });
        return pipe(
          submissions,
          option.fold(
            () => isInWindow,
            array.every(
              foldSubmissionPublic({
                // If submissions have been made, they are no longer in a to-do state, except ...
                code: () => false,
                dispense: () => false,
                link: () => false,
                // ... presentations, which are to-do when called or absent
                presentation: (x) =>
                  foldSubmissionStatus(x.status, {
                    subscribed: () => false,
                    called: () => isInWindow,
                    alreadyCalled: () => isInWindow,
                    marked: () => false,
                    done: () => false,
                    absent: () => isInWindow,
                  }),
              }),
            ),
          ),
        );
      },
      () => taskStatusTodo,
    ),

    // A task is late if no submissions have been made in time or all are late
    either.stopIfW(
      ({ exercise, task, submissions }) =>
        // The task can only be late if it is graded
        isTaskGraded(task) &&
        // Link-like tasks can never be late
        !isLinklikeTask(task) &&
        // The task can only be late if the time window to work on is closed
        isTaskWindowClosed(now, { exercise, task }) &&
        pipe(
          submissions,
          option.map(
            nonEmptyArray.map(
              submissionState({
                dueDate: taskDueDateO({ exercise, task }),
                grading: task.grading,
              }),
            ),
          ),
          // The task is late if all submissions are late
          option.map(nonEmptyArray.every(isSubmissionStateLate)),
          // The task is late if no submissions have been made at all
          option.getOrElseW(() => true),
        ),
      () => taskStatusLate,
    ),
  );

/**
 * Check whether the submitted result is valid.
 * @internal
 */
const validateResult: ValidateTaskFn = flow(
  either.right,
  either.stopIf(
    ({ task: { grading }, submissions }) =>
      pipe(
        submissions,
        option.exists((subs) =>
          pipe(
            subs,
            pickRelevantSubmission,
            option.fold(
              () => pipe(subs, array.every(isSubmissionUnsound(grading))),
              isSubmissionUnsound(grading),
            ),
          ),
        ),
      ),
    () =>
      taskStatusWarning(
        'No result',
        'The marked submission has no result. It might be that your code crashed.',
      ),
  ),
  either.stopIfW(
    ({ submissions }) =>
      pipe(submissions, option.exists(nonEmptyArray.every(isSubmissionPreResult))),
    () => taskStatusPreliminary,
  ),
);

/**
 * Check whether there are submissions on this task that have unread feedback.
 * @internal
 */
const validateFeedback: ValidateTaskFn = flow(
  either.right,
  either.chain((ets) =>
    pipe(
      ets.submissions,
      option.chain(
        flow(array.map(feedbackFromSubmission), array.compact, pickHighestPriorityFeedback),
      ),
      option.fold(
        () => either.right(ets),
        (x) => either.left(taskStatusFeedback(x)),
      ),
    ),
  ),
);

// -------------------------------------------------------------------------------------------------
// Functions

/**
 * {@link TaskStatus} provides an account of various attributes of a task, mostly based on the
 * sub-states of submissions.
 *
 * - `Either.Right` There is nothing noteworthy about this task
 * - `Either.Left` Notes or problems about this task are available
 *
 * @see SubmissionState
 * @see taskProgress
 */
export function taskStatuses(
  now: Date,
  ets: ExerciseTaskSubmission,
): either.Either<NonEmptyArray<TaskStatus>, ExerciseTaskSubmission> {
  return pipe(
    either.getValidationSequenceT<TaskStatus>()(
      either.liftValidation(validateIsNotRegraded)(ets),
      either.liftValidation(validateFullyRegraded)(ets),
      either.liftValidation(validateTimelySubmission(now))(ets),
      either.liftValidation(validateFeedback)(ets),
      either.liftValidation(validateResult)(ets),
    ),
    // Sort higher priority items to the front
    either.mapLeft(nonEmptyArray.sort(ordTaskStatus)),
    either.map(() => ets),
  );
}

/**
 * An `Option`-based variation of {@link taskStatuses} that discards the checked data and just
 * returns a list of {@link TaskStatus}, if any.
 */
export function taskStatusesO(
  now: Date,
  ets: ExerciseTaskSubmission,
): option.Option<NonEmptyArray<TaskStatus>> {
  return pipe(taskStatuses(now, ets), either.swap, option.fromEither);
}

/**
 * Get the most relevant {@link TaskStatus} from a list. This should be used sparingly, as
 * information is thrown away.
 */
export function pickMostRelevantTaskStatus(xs: NonEmptyArray<TaskStatus>): TaskStatus {
  return pipe(xs, nonEmptyArray.sort(ordTaskStatus), nonEmptyArray.head);
}

/**
 * Check if a given {@link TaskStatus} exists in a list.
 */
export const hasTaskStatus =
  (type: TaskStatus['type']) =>
  (status: either.Either<NonEmptyArray<TaskStatus>, ExerciseTaskSubmission>): boolean =>
    pipe(
      status,
      either.fold(
        array.some((x) => x.type === type),
        () => false,
      ),
    );
