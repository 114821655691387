// This code runs on server side and client side
// The behaviour is copied from Meteor.absoluteUrl to make it similar to the old behaviour
export const joinPath = (url: string, path?: string): string => {
  if (!url.endsWith('/')) {
    url += '/';
  }
  if (path != null) {
    // join url and path with a / separator
    while (path.startsWith('/')) {
      path = path.slice(1);
    }
    url += path;
  }
  return url;
};
