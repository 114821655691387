import { iots, string } from '@code-expert/prelude';

export interface AccessCodeBrand {
  readonly AccessCode: unique symbol;
}

// Knowledge duplication from nanoid, but we don't want the nanoid dependency on the client
const accessCodeRegex = /[A-Za-z0-9_\\-]{8}/;

export const AccessCodeC = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, AccessCodeBrand> => string.isString(s) && accessCodeRegex.test(s),
  'AccessCode',
);

export type AccessCode = iots.TypeOf<typeof AccessCodeC>;
