import type { predicate } from '@code-expert/prelude';
import {
  array,
  eq,
  flow,
  iots,
  nat,
  nonEmptyArray,
  pipe,
  record,
  string,
  struct,
} from '@code-expert/prelude';
import { CourseId, GroupId, SemesterId, semesterIdEq, UserId, userIdEq } from './identity';

const groupStrictProps = {
  name: iots.maxLengthString(80),
  courseId: CourseId,
  semester: SemesterId,
  assistantIds: iots.array(UserId),
  maxSize: nat.Nat,
  locked: iots.boolean,
  studentIds: iots.array(UserId),
};

const groupPartialProps = {
  description: iots.maxLengthString(5000),
  lti: iots.boolean,
  staff: iots.literal(true),
  imported: iots.boolean,
  // default value should be 0
  studentCount: nat.Nat,
};

export const GroupWriteC = iots.struct(groupStrictProps, groupPartialProps);
export type GroupWrite = iots.TypeOf<typeof GroupWriteC>;

export const GroupC = iots.struct({ _id: GroupId, ...groupStrictProps }, groupPartialProps);
export type Group = iots.TypeOf<typeof GroupC>;

export const groupsPublicFields = {
  _id: 1,
  name: 1,
  description: 1,
  lti: 1,
  courseId: 1,
  semester: 1,
  assistantIds: 1,
  studentIds: 1,
  maxSize: 1,
  locked: 1,
  staff: 1,
} satisfies Partial<Record<keyof Group, 1>>;

export type GroupPublic = Pick<Group, keyof typeof groupsPublicFields>;
export const groupsEnrollFields = {
  _id: 1,
  studentCount: 1,
  maxSize: 1,
  semester: 1,
  courseId: 1,
  staff: 1,
};

export const areStudentsInGroupUniquePerSemester: predicate.Predicate<
  Array<Pick<Group, 'studentIds' | 'semester' | 'staff'>>
> = flow(
  array.filter(({ staff }) => staff !== true),
  nonEmptyArray.groupBy(struct.get('semester')),
  record.every(flow(array.chain(struct.get('studentIds')), array.isUnique(userIdEq))),
);

export const addStudentToGroups = <A extends Pick<Group, 'studentIds' | 'semester' | 'name'>>(
  allGroups: A[],
  semester: SemesterId,
  groupName: string,
  newStudent: UserId,
) =>
  allGroups.map((g) => {
    if (g.semester === semester && g.name !== groupName && g.studentIds.includes(newStudent)) {
      return { ...g, studentIds: g.studentIds.filter((s) => s !== newStudent) };
    }
    if (g.semester === semester && g.name === groupName) {
      return { ...g, studentIds: pipe([newStudent], array.union(userIdEq)(g.studentIds)) };
    }
    return g;
  });

const groupEq = eq.struct({
  name: string.Eq,
  semester: semesterIdEq,
});
export const uniqueGroups = <A extends Pick<Group, 'name' | 'semester'>>(
  groups: Array<A>,
): Array<A> => pipe(groups, array.uniq<A>(groupEq));

export const isLtiGroup = (x: Pick<Group, 'lti'>): boolean => x.lti === true;

export const isStaffGroup = (x: Pick<Group, 'staff'>): boolean => x.staff === true;

export const isLtiOrStaffGroup = (x: Pick<Group, 'lti' | 'staff'>): boolean =>
  isLtiGroup(x) || isStaffGroup(x);

export const isGroupAssistant = (group: Pick<Group, 'assistantIds'>, userId: UserId): boolean =>
  group.assistantIds.includes(userId);
