import type { ConnectionId } from '/imports/domain';
import { socket } from '/imports/ui/hooks/socket';

export const connection: { id: ConnectionId | undefined } = { id: undefined };

socket.on('initConnection', ({ connectionId }) => {
  if (connection.id == null) {
    console.debug('Socket connection established');
  } else if (connection.id !== connectionId) {
    console.debug('Socket connection refreshed');
  }
  connection.id = connectionId;
});
