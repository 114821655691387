import React from 'react';

import { Button, Result } from 'antd';
import type { ExceptionStatusType } from 'antd/es/result';
import { Link, useHistory } from 'react-router-dom';

import { useTitle } from '/imports/ui/hooks/useTitle';

export interface NotFoundProps {
  title?: string;
  text?: string;
  status?: ExceptionStatusType;
  showBackButton?: boolean;
}

function NotFound({
  title = 'Not found',
  text = 'Sorry, the page you visited does not exist.',
  status = '404',
  showBackButton = true,
}: NotFoundProps) {
  const history = useHistory();
  useTitle(title, text);
  return (
    <Result
      title={title}
      subTitle={text}
      extra={
        showBackButton && (
          <Button type="primary" onClick={history.goBack}>
            Go back
          </Button>
        )
      }
      status={status}
    />
  );
}
export default NotFound;

// -------------------------------------------------------------------------------------------------

export interface NotAuthorizedProps {
  text?: string;
}

export function NotAuthorized({
  text = 'Sorry, you are not authorized to access this page.',
}: NotAuthorizedProps) {
  const title = 'Not authorized';
  useTitle(title, text);
  return (
    <Result
      title={title}
      subTitle={text}
      extra={
        <Link to="/">
          <Button type="primary">Go to homepage</Button>
        </Link>
      }
      status="403"
    />
  );
}
