import { adt, iots } from '@code-expert/prelude';
import type { IconName } from '/imports/ui/foundation/Icons';

//Keep this file in syn with the clientPermissions in the api

export const ProjectReadPermission = iots.literal('project:read');
export type ProjectReadPermission = iots.TypeOf<typeof ProjectReadPermission>;

export const ProjectWritePermission = iots.literal('project:write');
export type ProjectWritePermission = iots.TypeOf<typeof ProjectWritePermission>;

export const UserReadPermission = iots.literal('user:read');
export type UserReadPermission = iots.TypeOf<typeof UserReadPermission>;
export const Permission = iots.union([
  ProjectReadPermission,
  ProjectWritePermission,
  UserReadPermission,
]);
export type Permission = iots.TypeOf<typeof Permission>;

// -------------------------------------------------------------------------------------------------

export const foldPermissions = adt.foldFromKeys<Permission>({
  'user:read': null,
  'project:read': null,
  'project:write': null,
});

export const permissionDescription = (permission: Permission, appName: string) =>
  foldPermissions(permission, {
    'user:read': () => ({
      title: 'Allow the app to read your user data',
      description:
        'The app can read information about you, e.g. the name, email, affiliation, etc.',
      iconName: 'user' as IconName,
    }),
    'project:read': () => ({
      title: 'Allow the app to read your Code Expert projects',
      description: `You can decide later individually which projects you want to share with the app ${appName}`,
      iconName: 'book-reader' as IconName,
    }),
    'project:write': () => ({
      title: 'Allow the app to write into your Code Expert projects',
      description: `You can decide later individually which projects you want to share with the app ${appName}`,
      iconName: 'pen' as IconName,
    }),
  });
