import React from 'react';

import { ProjectId } from '/imports/domain';
import { projectHooks } from '/imports/modules/Project/presentation/hooks';
import { ProjectBrowser } from '/imports/modules/Project/presentation/ProjectBrowser';
import { end, lit, type, url, zero } from '/imports/modules/Routing';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import { TopNavLayout } from '/imports/ui/layouts/TopNavLayout';

export const projectBrowserRoute = url(lit('project'), type('projectId', ProjectId), end);

export const projectBrowserRouter = zero().alt(
  projectBrowserRoute.parser.map((props) => (
    <PrivateRoute
      element={() => <ProjectBrowser hooks={projectHooks} {...props} />}
      denyCapabilities={['exam', 'lti']}
      layout={TopNavLayout}
    />
  )),
);
