import { mkEntityIdCodec } from '@code-expert/mongo-ts/identity';
import type { eq } from '@code-expert/prelude';
import { iots, string } from '@code-expert/prelude';

export const SwitchOrganisationIdBrand = Symbol('SwitchOrganisationId');
export const SwitchOrganisationIdC = mkEntityIdCodec(SwitchOrganisationIdBrand);
export type SwitchOrganisationIdC = typeof SwitchOrganisationIdC;
export type SwitchOrganisationId = iots.TypeOf<SwitchOrganisationIdC>;

export interface SwitchOrganisationUrlBrand {
  readonly SwitchOrganisationUrl: unique symbol;
}
export const SwitchOrganisationUrlC = iots.brand(
  iots.string,
  (s): s is iots.Branded<string, SwitchOrganisationUrlBrand> => s.length > 0,
  'SwitchOrganisationUrl',
);
export type SwitchOrganisationUrl = iots.TypeOf<typeof SwitchOrganisationUrlC>;

export const eqSwitchOrganisationUrl: eq.Eq<SwitchOrganisationUrl> = string.Eq;

export const SwitchOrganizationTypeC = iots.union([
  iots.literal('uas'),
  iots.literal('university'),
  iots.literal('other'),
]);
export type SwitchOrganisationType = iots.TypeOf<typeof SwitchOrganizationTypeC>;
export const switchOrganisationTypes = SwitchOrganizationTypeC.types;
export function foldSwitchOrganisationType<R>(
  t: SwitchOrganisationType,
  pattern: Record<SwitchOrganisationType, () => R>,
): R {
  return pattern[t]();
}

export const SwitchOrganisationC = iots.strict({
  _id: SwitchOrganisationIdC,
  url: SwitchOrganisationUrlC,
  type: SwitchOrganizationTypeC,
  name: iots.string,
  logo: iots.string,
});
export type SwitchOrganisationC = typeof SwitchOrganisationC;
export type SwitchOrganisation = iots.TypeOf<SwitchOrganisationC>;
