import type { eq } from '@code-expert/prelude';
import { assertNonNull, iots, record, string } from '@code-expert/prelude';
import { EnvironmentId, ProjectId } from '/imports/domain/identity';

//--------------------------------------------------------------------------------------------------
// Runner actions
//--------------------------------------------------------------------------------------------------

const runnerActionKeys = {
  compile: null,
  run: null,
  repl: null,
  test: null,
  submit: null,
};

export const runnerActions = record.keys(runnerActionKeys);

export type RunnerActions = keyof typeof runnerActionKeys;

const RunnerActionC = iots.keyof(runnerActionKeys);

//--------------------------------------------------------------------------------------------------
// Long-running job actions
//--------------------------------------------------------------------------------------------------

const longRunningJobActionKeys = {
  long_run: null,
  long_submit: null,
};

export const longRunningJobActions = record.keys(longRunningJobActionKeys);

export type LongRunningJobActions = keyof typeof longRunningJobActionKeys;

//--------------------------------------------------------------------------------------------------
// Browser actions
//--------------------------------------------------------------------------------------------------

const browserActionKeys = {
  browser: null,
};

export const browserActions = record.keys(browserActionKeys);

export type BrowserActions = keyof typeof browserActionKeys;

//--------------------------------------------------------------------------------------------------

export type AllActions = RunnerActions | LongRunningJobActions | BrowserActions;

const AllActionsC = iots.keyof({
  ...runnerActionKeys,
  ...longRunningJobActionKeys,
  ...browserActionKeys,
});

export const actionEq: eq.Eq<AllActions> = string.Eq;

const VersionInfoC = iots.struct(
  {
    name: iots.maxLengthString(80),
    createdDate: iots.date,
    actions: iots.array(AllActionsC),
  },
  {
    repoDigest: iots.maxLengthString(1024),
  },
);

export type VersionInfo = iots.TypeOf<typeof VersionInfoC>;

export interface Environment {
  _id: EnvironmentId;
  slug: string;
  archived?: Date;
  deprecated?: Date;
  replacedBy?: string;
  currentVersion?: VersionInfo;
  runnerName?: string;
  lastUpdateCheck?: Date;
  projectId?: ProjectId;
  limits: Record<RunnerActions, EnvironmentLimits>;
}

export interface EnvironmentLimits {
  /**
   * docker cpu time in seconds
   * @default 12
   */
  cpuTime: number;
  /**
   * docker cpu period limit in 1/100'000th (100'000 == 100%)
   * @default 100000
   */
  cpuPeriod: number;
  /**
   * docker cpu quota limit in 1/100'000th (200'000 == 2 Cores)
   * @default 200000
   */
  cpuQuota: number;
  /**
   * max file size in kB
   * @default 2048
   */
  maxFileSize: number;
  /**
   * max count of files user is allowed to create
   * @default 15
   */
  maxFileCount: number;
  /**
   * max number of file system changes per second
   * @default 50
   */
  maxFSChanges: number;
  /**
   * max count of files user can upload
   * @default 10
   */
  maxFileUploadCount: number;
  /**
   * max memory in mB
   * @default 300
   */
  memory: number;
  /**
   * max swap in mB
   * @default 300
   */
  swap: number;
  /**
   * max size of tmp dir
   * @default 50 mB
   */
  tmpSize: number;
  /**
   * hard Timeout in seconds
   * @default 600
   */
  hardTimeout: number;
  /**
   * soft Timeout in seconds
   * @default 600
   */
  softTimeout: number;
  /**
   * max length of stream in bytes
   * @default 32000
   */
  maxStreamLength: number;
  /**
   * max read/write rate from/to a device
   * @default -1 (unlimited)
   */
  readWriteMBps: number;
  /**
   * max read/write IO rate from/to a device
   * @default -1 (unlimited)
   */
  readWriteIOps: number;
  /**
   * max number of PIDs
   * @default 50
   */
  pids: number;
}

export const EnvironmentLimitsC = iots.strict({
  cpuTime: iots.number,
  cpuPeriod: iots.number,
  cpuQuota: iots.number,
  maxFileSize: iots.number,
  maxFileCount: iots.number,
  maxFSChanges: iots.number,
  maxFileUploadCount: iots.number,
  memory: iots.number,
  swap: iots.number,
  tmpSize: iots.number,
  hardTimeout: iots.number,
  softTimeout: iots.number,
  maxStreamLength: iots.number,
  readWriteMBps: iots.number,
  readWriteIOps: iots.number,
  pids: iots.number,
}) satisfies iots.Type<EnvironmentLimits, unknown>;

export const environmentPropsRequired = {
  _id: EnvironmentId,
  slug: iots.string,
  limits: iots.record(RunnerActionC, EnvironmentLimitsC),
};

export const environmentPropsOptional = {
  archived: iots.date,
  deprecated: iots.date,
  replacedBy: iots.maxLengthString(40),
  currentVersion: VersionInfoC,
  runnerName: iots.maxLengthString(80),
  lastUpdateCheck: iots.date,
  projectId: ProjectId,
};

export const EnvironmentC = iots.struct(environmentPropsRequired, environmentPropsOptional);

export interface AdminOverviewEnvironmentLimits {
  action: AllActions;
  key: AllActions;
  limits: EnvironmentLimits;
}

function limitsFromDocument(
  filterActions: Array<RunnerActions>,
  limits: Record<RunnerActions, EnvironmentLimits>,
) {
  return (Object.entries(limits) as Array<[RunnerActions, EnvironmentLimits]>)
    .filter(([key]) => filterActions.includes(key))
    .map<AdminOverviewEnvironmentLimits>(([key, value]: [RunnerActions, EnvironmentLimits]) => ({
      action: key,
      key,
      limits: value,
    }));
}

export interface AdminOverviewEnvironment {
  key: string;
  slug: string;
  runnerName: string | undefined;
  name: string;
  lastChange: Date;
  lastUpdateCheck: Date;
  actions: Array<AllActions>;
  projectId: ProjectId;
  archived?: Date;
  deprecated?: Date;
  limits: Array<AdminOverviewEnvironmentLimits>;
  replacedBy?: string;
}

export const environmentFromDocument = (env: Environment): AdminOverviewEnvironment => {
  assertNonNull(env.lastUpdateCheck);
  assertNonNull(env.currentVersion);
  assertNonNull(env.projectId);
  assertNonNull(env.limits);
  return {
    key: env._id,
    slug: env.slug,
    runnerName: env.runnerName,
    name: env.currentVersion.name,
    lastChange: env.currentVersion.createdDate,
    lastUpdateCheck: env.lastUpdateCheck,
    actions: env.currentVersion.actions,
    projectId: env.projectId,
    archived: env.archived,
    deprecated: env.deprecated,
    limits: limitsFromDocument(
      env.currentVersion.actions.filter((a): a is RunnerActions =>
        runnerActions.includes(a as $IntentionalAny),
      ),
      env.limits,
    ),
    replacedBy: env.replacedBy,
  };
};

export const limitsToDocument = (
  limits: Array<Pick<AdminOverviewEnvironmentLimits, 'limits' | 'action'>>,
) => Object.fromEntries(limits.map((limit) => [limit.action, limit.limits]));
