import { adt, iots, refinement, string, tagged } from '@code-expert/prelude';
import { CourseId, ExamId, NotificationId, SemesterId } from './identity';

export type Content = tagged.Tagged<'plain', string> | tagged.Tagged<'markdown', string>;

export const contentTypeAdt = tagged.build<Content>();

export interface BaseClientNotification {
  readonly id?: string;
  readonly key: string;
  readonly title: string;
}

export const Severity = ['info', 'warning', 'error', 'success'] as const;
type Severity = (typeof Severity)[number];

const SeverityC = iots.keyof({
  info: null,
  warning: null,
  error: null,
  success: null,
}) satisfies iots.Type<Severity, unknown>;

export interface BaseNotification {
  readonly _id: NotificationId;
  readonly lastChanged: Date;
}

const baseConstPropsStrict = {
  _id: NotificationId,
  lastChanged: iots.date,
};

export interface GlobalNotification extends BaseNotification {
  readonly kind: 'global';
  readonly title: string;
  readonly text?: string;
  readonly severity: Severity;
}

const GlobalNotificationC = iots.struct(
  {
    ...baseConstPropsStrict,
    kind: iots.literal('global'),
    title: iots.string,
    severity: SeverityC,
  },
  {
    text: iots.string,
  },
) satisfies iots.Type<GlobalNotification, unknown>;

export interface CourseNotification extends BaseNotification {
  readonly kind: 'course';
  readonly title: string;
  readonly text: string;
  readonly from: string;
  readonly semester: SemesterId;
  readonly courseId: CourseId;
  readonly createdAt: Date;
  readonly readBy: Array<string>;
}

const CourseNotificationC = iots.strict({
  ...baseConstPropsStrict,
  kind: iots.literal('course'),
  title: iots.string,
  text: iots.string,
  from: iots.string,
  semester: SemesterId,
  courseId: CourseId,
  createdAt: iots.date,
  readBy: iots.array(iots.string),
}) satisfies iots.Type<CourseNotification, unknown>;

export interface ExamNotification extends BaseNotification {
  readonly kind: 'exam';
  readonly title: string;
  readonly text: string;
  readonly from: string;
  readonly examId: ExamId;
  readonly createdAt: Date;
  readonly readBy: Array<string>;
}

const ExamNotificationC = iots.strict({
  ...baseConstPropsStrict,
  kind: iots.literal('exam'),
  title: iots.string,
  text: iots.string,
  from: iots.string,
  examId: ExamId,
  createdAt: iots.date,
  readBy: iots.array(iots.string),
}) satisfies iots.Type<ExamNotification, unknown>;

export type Notification = GlobalNotification | CourseNotification | ExamNotification;
export const foldNotification = adt.foldFromTags<Notification, 'kind'>('kind');

export const isCourseNotification = (n: Notification): n is CourseNotification =>
  string.Eq.equals(n.kind, 'course');

export const requireCourseNotification = refinement.invariant(
  isCourseNotification,
  () => 'Expected course notification',
);

export const isExamNotification = (n: Notification): n is ExamNotification =>
  string.Eq.equals(n.kind, 'exam');

export const requireExamNotification = refinement.invariant(
  isExamNotification,
  () => 'Expected exam notification',
);

export const NotificationC = iots.union([
  GlobalNotificationC,
  CourseNotificationC,
  ExamNotificationC,
]) satisfies iots.Type<Notification, unknown>;

export interface AppNotification extends BaseClientNotification {
  readonly kind: 'App';
  readonly content?: Content;
  readonly duration?: number;
  readonly severity: GlobalNotification['severity'];
}

export interface BrowserNotifications extends BaseClientNotification {
  readonly kind: 'Browser';
  readonly text: string;
  readonly link?: string;
}

export type ClientNotification = AppNotification | BrowserNotifications;
export const foldClientNotification = adt.foldFromTags<ClientNotification, 'kind'>('kind');
