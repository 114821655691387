import React from 'react';

import { Button, Result, Typography } from 'antd';
import AppContext from 'antd/es/app/context';

import { constVoid, fromThrown, tagged } from '@code-expert/prelude';
import { Logger } from '/imports/modules/Logger/client';
import { supportsArrayAt } from '/imports/ui/components/BrowserUpdate';
import { trackError } from '/imports/ui/helper/error';
import { DocumentTitle } from '/imports/ui/hooks/useTitle';
import { SendMessage } from './Messages/SendMessage';

const { userAgent } = navigator;

const CRASHED_TITLE = 'Code Expert crashed';
const CRASHED_SUBTITLE =
  'Please reload the page and try again. If this error persists, please get in touch.';
const CRASHED_SUBTITLE_OLD = (
  <Typography.Text type="danger">
    <strong>Your browser is not supported. </strong>Please use a supported browser or update your
    browser.
  </Typography.Text>
);

type State =
  | tagged.Tagged<'ok'>
  | tagged.Tagged<'error', Error>
  | tagged.Tagged<'asyncError', Error>;

const stateAdt = tagged.build<State>();

const formatError = (error: Error): string => {
  const causeStr =
    'cause' in error ? `\n\nCaused by:\n${formatError(fromThrown(error.cause))}` : '';
  return `[${error.name}] ${error.message}${causeStr}`;
};

/**
 * Class ErrorBoundary
 *
 * According to https://reactjs.org/docs/react-component.html#error-boundaries
 */
export class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
  state = stateAdt.ok();

  static contextType = AppContext;

  declare context: React.ContextType<typeof AppContext>;

  static getDerivedStateFromError(error: unknown) {
    // Update state displaying the fallback UI
    return stateAdt.error(fromThrown(error));
  }

  private unhandledErrorHandler = (event: ErrorEvent) => {
    const error = fromThrown(event.error);
    Logger.persist.error('ErrorBoundary: Uncaught error', { error, userAgent });
    this.setState(stateAdt.error(error));
  };

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    const error = fromThrown(event.reason);
    Logger.persist.error('ErrorBoundary: Unhandled promise rejection', { error, userAgent });
    this.setState(stateAdt.asyncError(error));
  };

  componentDidMount() {
    window.addEventListener('error', this.unhandledErrorHandler);
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidUpdate() {
    stateAdt.fold(this.state, {
      ok: constVoid,
      error: constVoid,
      asyncError: (error) => {
        void this.context.message.error(error.message);
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.unhandledErrorHandler);
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    trackError(error);
    Logger.persist.error('ErrorBoundary: Component error', {
      error,
      componentStack: errorInfo.componentStack ?? null,
      userAgent,
    });
  }

  render() {
    const isOldBrowser = !supportsArrayAt();
    return stateAdt.fold(this.state, {
      ok: () => <>{this.props.children}</>,
      asyncError: () => <>{this.props.children}</>,
      error: (error) => (
        <>
          <DocumentTitle title="Error" />
          <Result
            status="error"
            title={CRASHED_TITLE}
            subTitle={isOldBrowser ? CRASHED_SUBTITLE_OLD : CRASHED_SUBTITLE}
            extra={[
              <SendMessage
                key="SendMessage"
                hideCourse
                defaultRequest="codeExpert"
                additionalText={formatError(error)}
              >
                <Button>Send error to the Code Expert team</Button>
              </SendMessage>,
            ]}
          />
        </>
      ),
    });
  }
}
