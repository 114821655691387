import React from 'react';

import { Typography } from 'antd';

import { css, keyframes, styled } from '/imports/ui/foundation/Theme';
import useTimeout from '/imports/ui/hooks/useTimeout';

const DURATION = 2; // seconds
const PATH_LENGTH = 1364; // Measured in the browser with `pathElement.getTotalLength()`
const INITIAL_OFFSET = PATH_LENGTH / 8; // Start in a good looking position

const animationName = keyframes({ to: { strokeDashoffset: PATH_LENGTH + INITIAL_OFFSET } });

const useSvgStyles = css(({ tokens }) => ({
  fill: 'none',
  path: {
    animationName,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    strokeDashoffset: INITIAL_OFFSET,
    strokeLinecap: 'round',
    strokeWidth: 50,
    '&:first-child': {
      animationDelay: `${DURATION / 8}s`,
      animationDirection: 'reverse',
      animationDuration: `${DURATION}s`,
      stroke: tokens.colorAccent,
      strokeDasharray: PATH_LENGTH / 4,
    },
    '&:last-child': {
      animationDuration: `${DURATION}s`,
      stroke: tokens.colorPrimary,
      strokeDasharray: PATH_LENGTH / 4,
    },
  },
}));

const Svg = () => {
  const className = useSvgStyles();
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" viewBox="0 0 512 512">
      <path d="m72 27 355 213c20 11 32 32 32 55v173c0 11-12 17-21 12L83 267a65 65 0 0 1-31-55V39c0-11 11-17 20-12Z" />
      <path d="M438 27 83 240a65 65 0 0 0-31 55v173c0 11 11 17 20 12l355-213c20-11 32-32 32-55V39c0-11-12-17-21-12Z" />
    </svg>
  );
};

const StyledContainer = styled('div', ({ tokens }) => ({
  display: 'grid',
  alignContent: 'center',
  justifyItems: 'center',
  gap: tokens.paddingXS,
  textAlign: 'center',
  height: '100%',
  paddingBlock: tokens.padding,
  opacity: 0, // Keep visually hidden until set to visible
  variants: {
    visible: {
      true: {
        transition: 'opacity 0.5s ease-in',
        opacity: 1,
      },
    },
  },
}));

interface LoadingProps {
  /** Delay after which to show spinner (in milliseconds) */
  delay?: NamedDelay | number;
  /** A brief description of what’s happeing to show below spinner (e.g. «Loading …») */
  text?: string;
}

export const Loading = ({ delay = 'default', text }: LoadingProps) => {
  const [isVisible, setIsVisible] = React.useState(false);
  useTimeout(() => setIsVisible(true), getDelay(delay));
  return (
    <StyledContainer visible={isVisible}>
      <Svg />
      {text != null && <Typography.Text type="secondary">{text}</Typography.Text>}
    </StyledContainer>
  );
};

// -------------------------------------------------------------------------------------------------

type NamedDelay = keyof typeof namedDelay;

/** Delay in milliseconds */
const namedDelay = {
  none: 0,
  default: 400,
  long: 1000,
};

const getDelay = (delay: NamedDelay | number): number =>
  typeof delay === 'string' ? namedDelay[delay] : delay;
