import React from 'react';

import { Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import type { PermissionsInterface } from '@code-expert/pfs/dist/Permissions';
import { array, pipe, record } from '@code-expert/prelude';
import { Icon } from '/imports/ui/foundation/Icons';

export const Permissions = ({ permissions }: { permissions: PermissionsInterface }) => {
  interface DataType {
    key: string;
    value: string;
  }

  const dataSource: Array<DataType> = pipe(
    permissions,
    record.entries,
    array.map(([key, values]) => ({
      key,
      value: values.join(', '),
    })),
  );

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: 'key',
      key: 'key',
    },
    {
      dataIndex: 'value',
      key: 'value',
    },
  ];
  return (
    <Popover
      placement="bottom"
      content={
        <Table size="small" showHeader={false} pagination={false} {...{ columns, dataSource }} />
      }
    >
      <Icon name="ellipsis-h" />
    </Popover>
  );
};
