import React from 'react';

import { Route, useLocation } from 'react-router-dom';

import type { Parser } from '/imports/modules/Routing';
import { Loading } from '/imports/ui/components/Loading';
import NotFound from '/imports/ui/components/NotFound';
import { concatRoutes, runRoute } from '.';

export const notFound: React.JSX.Element = <NotFound />;

export function RunTypedRoutes({
  routes,
  fallback = notFound,
}: {
  routes: Array<Parser<React.JSX.Element>>;
  fallback?: React.JSX.Element;
}) {
  const { pathname, search } = useLocation();
  return (
    <Route
      path="*"
      render={() => (
        <React.Suspense fallback={<Loading />}>
          {runRoute(concatRoutes(routes), `${pathname}${search}`, fallback)}
        </React.Suspense>
      )}
    />
  );
}
