// eslint-disable-next-line no-restricted-imports
import { Formatter, query, zero } from 'fp-ts-routing';

import { adt, iots } from '@code-expert/prelude';
import type { PartialProps } from '@code-expert/type-utils';
import { ExamId, FileId, GroupId, SnapshotId, SubmissionId, TaskId } from '/imports/domain';
import { end, formatRoute, lit, Match, type, url } from '/imports/modules/Routing';

interface SharedParams {
  taskId: TaskId;
  submissionId?: SubmissionId;
  showSolution: boolean;
  fileKey?: FileId;
  snapshotId?: SnapshotId;
}

export interface ReviewRouteParams extends SharedParams {
  groupId: GroupId;
}

const baseRoute = url(
  lit('review'),
  type('groupId', GroupId),
  type('taskId', TaskId),
  query(
    iots.strict({
      fileKey: iots.union([FileId, iots.undefined]),
      snapshotId: iots.union([SnapshotId, iots.undefined]),
    }),
  ),
);

const mainRoute = baseRoute.then(end);

const submissionRoute = baseRoute.then(type('submissionId', SubmissionId)).then(end);

const solutionRoute = baseRoute
  .then(type('submissionId', SubmissionId))
  .then(lit('solution'))
  .then(end);

export const reviewRoute = new Match(
  zero<ReviewRouteParams>()
    .alt(mainRoute.parser.map((p) => ({ ...p, showSolution: false })))
    .alt(submissionRoute.parser.map((p) => ({ ...p, showSolution: false })))
    .alt(solutionRoute.parser.map((p) => ({ ...p, showSolution: true }))),
  new Formatter((route, { submissionId, showSolution, groupId, snapshotId, fileKey, taskId }) =>
    submissionId != null
      ? showSolution
        ? solutionRoute.formatter.run(route, { groupId, snapshotId, taskId, fileKey, submissionId })
        : submissionRoute.formatter.run(route, {
            groupId,
            snapshotId,
            taskId,
            fileKey,
            submissionId,
          })
      : mainRoute.formatter.run(route, { groupId, snapshotId, taskId, fileKey }),
  ),
);

type ReviewNavigation = (params: PartialProps<ReviewRouteParams, 'showSolution'>) => string;

export const reviewLink: ReviewNavigation = ({ showSolution = false, ...rest }) =>
  formatRoute(reviewRoute)({ ...rest, showSolution });

// -------------------------------------------------------------------------------------------------

export interface ExamReviewRouteParams extends SharedParams {
  examId: ExamId;
}

const examBaseRoute = url(
  lit('reviewExam'),
  type('examId', ExamId),
  type('taskId', TaskId),
  query(
    iots.strict({
      fileKey: iots.union([FileId, iots.undefined]),
      snapshotId: iots.union([SnapshotId, iots.undefined]),
    }),
  ),
);

const examMainRoute = examBaseRoute.then(end);

const examSubmissionRoute = examBaseRoute.then(type('submissionId', SubmissionId)).then(end);

const examSolutionRoute = examBaseRoute
  .then(type('submissionId', SubmissionId))
  .then(lit('solution'))
  .then(end);

export const examReviewRoute = new Match(
  zero<ExamReviewRouteParams>()
    .alt(examMainRoute.parser.map((p) => ({ ...p, showSolution: false })))
    .alt(examSubmissionRoute.parser.map((p) => ({ ...p, showSolution: false })))
    .alt(examSolutionRoute.parser.map((p) => ({ ...p, showSolution: true }))),
  new Formatter((route, { submissionId, showSolution, examId, fileKey, taskId, snapshotId }) =>
    submissionId != null
      ? showSolution
        ? examSolutionRoute.formatter.run(route, {
            examId,
            fileKey,
            snapshotId,
            taskId,
            submissionId,
          })
        : examSubmissionRoute.formatter.run(route, {
            examId,
            fileKey,
            snapshotId,
            taskId,
            submissionId,
          })
      : examMainRoute.formatter.run(route, { examId, fileKey, snapshotId, taskId }),
  ),
);

type ExamReviewNavigation = (params: PartialProps<ExamReviewRouteParams, 'showSolution'>) => string;

export const examReviewLink: ExamReviewNavigation = ({ showSolution = false, ...rest }) =>
  formatRoute(examReviewRoute)({ ...rest, showSolution });

// -------------------------------------------------------------------------------------------------

const foldReviewType = adt.foldFromProp('type');
export const reviewURL = (
  reviewData:
    | ({ type: 'exam' } & PartialProps<ExamReviewRouteParams, 'showSolution'>)
    | ({ type: 'exercise' } & PartialProps<ReviewRouteParams, 'showSolution'>),
): string =>
  foldReviewType(reviewData, {
    exam: examReviewLink,
    exercise: reviewLink,
  });
