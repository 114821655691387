import { date } from '@code-expert/prelude';

const timeFormats = {
  full: 'HH:mm:ss zzzz',
  long: 'HH:mm:ss z',
  medium: 'HH:mm:ss',
  short: 'HH:mm',
  any: 'HH:mm:ss zzzz',
};

/**
 * We define our own locale because we use English throughout the UI, but it is customary to have
 * a 24-hour clock in Switzerland, so we want to use that over an am/pm notation.
 */
export const cxLocale: date.Locale = {
  ...date.enUS,
  formatLong: {
    ...(date.enUS.formatLong as NonNullable<date.Locale['formatLong']>),
    time: ({ width = 'full' }: date.FormatLongFnOptions) =>
      timeFormats[width] ?? timeFormats['full'],
  },
  options: {
    ...date.enUS.options,
    weekStartsOn: 1,
  },
};

// -------------------------------------------------------------------------------------------------
// DateTime

const dateTimeFormatters = {
  calendar: date.format('iii d.M.yyyy'),
  calendarTime: date.format('iii d.M.yyyy, HH:mm'),
  date: date.format('d.M.yyyy'),
  dateTime: date.format('d.M.yyyy, HH:mm'),
  tabular: date.format('dd.MM.yyyy HH:mm'),
  timestamp: date.format('yyyy-MM-dd HH:mm:ss'),
  iso8601Date: date.format('yyyy-MM-dd'),
  iso8601Time: date.format('HH:mm:ss'),
  timezone: date.format('O'),
};

export type DateTimeFormat = keyof typeof dateTimeFormatters;

export const formatDateTime =
  (format: DateTimeFormat) =>
  (date: Date): string =>
    dateTimeFormatters[format](date);

// -------------------------------------------------------------------------------------------------
// Interval

export interface Interval {
  start: Date;
  end: Date;
}

const dateIntervalFormatters = {
  dateTime: ({ start, end }: Interval) =>
    date.isSameDay(start)(end)
      ? `${date.format('iii d.M.yyyy')(start)}, ${date.format('HH:mm')(start)}–${date.format(
          'HH:mm',
        )(end)}`
      : `${date.format('iii d.M.yyyy HH:mm')(start)} to ${date.format('iii d.M.yyyy HH:mm')(end)}`,
  distance: ({ start, end }: Interval) => {
    // Format dates that are relatively near with a more precise timestamp ("today at 12:15") for
    // easier human consumption.
    if (date.differenceInHours(start)(end) > 1 && date.differenceInCalendarDays(start)(end) < 2)
      return date.formatRelativeWithOptions({ locale: cxLocale })(start)(end);
    const distance = date.formatDistanceStrict(start)(end);
    return date.isBefore(start)(end) ? `${distance} ago` : `in ${distance}`;
  },
  time: ({ start, end }: Interval) =>
    date.isSameDay(start)(end)
      ? `${date.format('HH:mm')(start)}–${date.format('HH:mm')(end)}`
      : `${date.format('iii HH:mm')(start)} to ${date.format('iii HH:mm')(end)}`,
  duration: (interval: Interval) => {
    const duration = date.intervalToDuration(interval);
    const format = date.getSignificantElements(2)(duration);
    return date.formatDurationWithOptions({ format })(duration);
  },
};

export type DateIntervalFormat = keyof typeof dateIntervalFormatters;

export const formatDateInterval =
  (format: DateIntervalFormat) =>
  (interval: Interval): string =>
    dateIntervalFormatters[format](interval);

// -------------------------------------------------------------------------------------------------
// Distance

/**
 * Given a distance in milliseconds, format a human readable string, e.g. "half a minute"
 */
export const formatDistanceFromMs = (timeInMilliseconds: number): string =>
  timeInMilliseconds > 0
    ? date.formatDistanceWithOptions({ includeSeconds: true })(new Date(0))(
        new Date(timeInMilliseconds),
      )
    : '';
