import { constant, either, iots, pipe } from '@code-expert/prelude';

export const BooleanFromString: iots.BooleanFromStringC = new iots.Type<boolean, string, unknown>(
  'BooleanFromString',
  iots.boolean.is,
  (u, c) =>
    pipe(
      iots.keyof({ true: null, 1: null }).validate(u, c),
      either.as(true),
      either.alt(() => pipe(iots.keyof({ false: null, 0: null }).validate(u, c), either.as(false))),
    ),
  String,
);

/**
 * Encodes `true` as `'1'`. Useful for partial codecs where properties with the value `false` should
 * be omitted, e.g. for request parameters.
 */
export const TrueFromOne: iots.Type<true, '1', unknown> = new iots.Type(
  'TrueFromOne',
  (u: unknown): u is true => u === true,
  (u, context) => pipe(iots.literal('1').validate(u, context), either.as(true)),
  constant('1'),
);
