import { iots } from '@code-expert/prelude';
import { AppointmentC } from '/imports/domain/appointment';
import { CourseC } from '/imports/domain/course';
import { ExerciseVisibility } from '/imports/domain/exercise';
import { CourseId, ExerciseId, SemesterId, TaskId, UserId } from '/imports/domain/identity';
import { DispenseSubmissionC, PresentationSubmissionC } from '/imports/domain/submission';
import { UserC } from '/imports/domain/user';

const EligibleAppointment = iots.pick(AppointmentC, [
  '_id',
  'start',
  'end',
  'allocation',
  'lateAppointment',
]);

export type EligibleAppointment = iots.TypeOf<typeof EligibleAppointment>;

const SignUpContextSubmission = iots.union([
  iots.pick(PresentationSubmissionC, ['_id', 'kind', 'status']),
  iots.pick(DispenseSubmissionC, ['_id', 'kind']),
]);

export type SignUpContextSubmission = iots.TypeOf<typeof SignUpContextSubmission>;

const signupContextStrictProps = {
  user: iots.pick(UserC, ['_id']),
  course: iots.pick(CourseC, ['_id', 'appointments']),
  exercise: iots.strict({
    _id: ExerciseId,
    type: iots.literal('exercise'),
    name: iots.string,
    courseId: CourseId,
    semester: SemesterId,
    solutionDate: iots.date,
    handoutDate: iots.date,
    dueDate: iots.date,
    taskIds: iots.array(TaskId),
    public: ExerciseVisibility,
    solutionPublic: ExerciseVisibility,
  }),
  task: iots.strict({ _id: TaskId, semester: SemesterId }),
  submissions: iots.array(SignUpContextSubmission),
  appointments: iots.nonEmptyArray(EligibleAppointment),
};

const IncompleteSignUpContextSolo = iots.strict(signupContextStrictProps);

type IncompleteSignUpContextSolo = iots.TypeOf<typeof IncompleteSignUpContextSolo>;

const IncompleteSignUpContextPair = iots.strict({
  ...signupContextStrictProps,
  partner: iots.strict({ _id: UserId }),
  partnerSubmissions: iots.array(SignUpContextSubmission),
});

type IncompleteSignUpContextPair = iots.TypeOf<typeof IncompleteSignUpContextPair>;

export const IncompleteSignUpContext = iots.union([
  IncompleteSignUpContextPair,
  IncompleteSignUpContextSolo,
]);

export type IncompleteSignUpContext = iots.TypeOf<typeof IncompleteSignUpContext>;

export type SignUpContextSolo = IncompleteSignUpContextSolo & {
  isExerciseUnlocked: boolean;
};

export type SignUpContextPair = IncompleteSignUpContextPair & {
  isExerciseUnlocked: boolean;
  isPartnerExerciseUnlocked: boolean;
};

export type SignUpContext = SignUpContextSolo | SignUpContextPair;
