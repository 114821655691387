import { connectionAdt, useConnection } from '@code-expert/react-socketio';
import { socket } from '/imports/ui/hooks/socket';

/**
 * Gets the current connection status
 */
export const useConnectionStatus = () => {
  const status = useConnection(socket);
  return {
    connected: connectionAdt.is.connected(status),
    status,
  };
};
