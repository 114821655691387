import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from 'antd';
import type { RouteProps } from 'react-router-dom';
import { BrowserRouter, generatePath, Redirect, Route, Switch } from 'react-router-dom';

import { iots, requireNonNull, struct } from '@code-expert/prelude';
import type { NonEmptyArray } from '@code-expert/type-utils';
import { isExamUser } from '/imports/domain';
import { appRouter } from '/imports/modules/API/client';
import { handleInvitationsRouter } from '/imports/modules/Invitations/client';
import { projectBrowserRouter } from '/imports/modules/Project/client';
import { concatRoutes, end, lit, query, url } from '/imports/modules/Routing';
import { RunTypedRoutes } from '/imports/modules/Routing/RunTypedRoutes';
import { BrowserUpdate } from '/imports/ui/components/BrowserUpdate';
import { ErrorBoundary } from '/imports/ui/components/ErrorBoundary';
import type { GlobalContext, SavedEntryElements } from '/imports/ui/components/GlobalContext';
import {
  GlobalContextProvider,
  useGlobalContext,
  useGlobalContextWithActions,
} from '/imports/ui/components/GlobalContext';
import { Loading } from '/imports/ui/components/Loading';
import LockedUsers from '/imports/ui/components/LockedUsers';
import Notifications from '/imports/ui/components/Notifications/Notifications';
import { PrivateRoute, PublicRoute, uiSessionStates } from '/imports/ui/components/PrivateRoute';
import RedirectNonLtiUsers from '/imports/ui/components/RedirectNonLtiUsers';
import { styled, ThemeProvider } from '/imports/ui/foundation/Theme';
import { TopNavLayout, TopNavLayoutNoLogin } from '/imports/ui/layouts/TopNavLayout';
import { enrollRouter } from '/imports/ui/pages/enroll/routes';
import { ideRouter } from '/imports/ui/pages/ide/client';
import { ltiRouter } from '/imports/ui/pages/lti/routes';
import { printRouter } from '/imports/ui/pages/print/client';
import { reviewRouter } from '/imports/ui/pages/review/client';

const AdminNavigation = React.lazy(() => import('/imports/ui/pages/admin/AdminNavigation'));
const EnrolledNavigation = React.lazy(() => import('/imports/ui/pages/enrolled'));
const ExamNavigation = React.lazy(() => import('/imports/ui/pages/myExams'));
const ExamStudent = React.lazy(() => import('/imports/ui/pages/examStudent/ExamStudent'));
const ExamRoutes = React.lazy(() => import('/imports/ui/pages/examStudent/ExamRoutes'));
const ExamReview = React.lazy(() => import('/imports/ui/pages/examReview/ExamReview'));
const Info = React.lazy(() => import('/imports/ui/pages/info/InfoPage'));
const LoginDemo = React.lazy(() => import('/imports/ui/pages/login/LoginDemo'));
const LoginExam = React.lazy(() => import('/imports/ui/pages/login/LoginExam'));
const LoginEduId = React.lazy(() => import('/imports/ui/pages/login/LoginEduId'));
const LogoutExam = React.lazy(() => import('/imports/ui/pages/logout/LogoutExam'));
const LtiTestConsumer = React.lazy(() => import('/imports/ui/pages/lti/LtiTestConsumer'));
const MyGroupsNavigation = React.lazy(() => import('/imports/ui/pages/myGroups'));
const MyCourseNavigation = React.lazy(() => import('/imports/ui/pages/myCourses'));
const IdeRedirect = React.lazy(() => import('/imports/ui/pages/ide/Redirect'));
const UserNavigation = React.lazy(() => import('/imports/ui/pages/user/UserNavigation'));

function EntryRoute(props: RouteProps) {
  const { user, userCapabilities, preferredEntry } = useGlobalContext();

  return (
    <Route
      {...props}
      render={() => {
        if (user != null) {
          if (isExamUser(user)) return <Redirect to={`/examStudent/${user.examId}`} />;
          if (preferredEntry?.path != null) {
            const path = generatePath('/:path?/:semester?/:courseUrl?/:groupId?/', preferredEntry);
            return <Redirect to={path} />;
          }
          if (userCapabilities.includes('admin')) return <Redirect to="/admin" />;
          if (userCapabilities.includes('courseAdmin')) return <Redirect to="/mycourses" />;
          if (userCapabilities.includes('groupAdmin')) return <Redirect to="/mygroups" />;
          if (userCapabilities.includes('enrolled')) return <Redirect to="/enrolled" />;
        }
        return <Redirect to="/info" />;
      }}
    />
  );
}

const indexR = url(end);
const infoR = url(lit('info'), end);
const loginR = url(lit('login'), end);
const loginDemoR = url(
  lit('loginDemo'),
  query(iots.strict({ redirect: iots.union([iots.string, iots.undefined]) })),
  end,
);

const globalRouter = concatRoutes([
  indexR.parser.map(() => <EntryRoute />),
  infoR.parser.map(() => (
    // public({denyCapabilities: ['exam', 'lti'], layout: TopNavLayout, component: Info})
    <PublicRoute denyCapabilities={['exam', 'lti']} layout={TopNavLayout} component={Info} />
  )),
  loginR.parser.map(() => (
    <PublicRoute denyCapabilities={['exam', 'lti']} layout={TopNavLayout} component={LoginEduId} />
  )),
  loginDemoR.parser.map(() => (
    <PublicRoute denyCapabilities={['exam', 'lti']} layout={TopNavLayout} component={LoginDemo} />
  )),
]);

function CxApp() {
  const [, dispatch] = useGlobalContextWithActions();
  const persistEntryPoint = React.useCallback(
    (pathsToExtract: NonEmptyArray<SavedEntryElements>) =>
      (params: Record<SavedEntryElements, string>) => {
        dispatch({
          preferredEntry: struct.pick(params, pathsToExtract) as GlobalContext['preferredEntry'],
        });
      },
    [dispatch],
  );

  return React.useMemo(
    () => (
      <BrowserRouter>
        <BrowserUpdate />
        <Notifications />
        <RedirectNonLtiUsers />
        <LockedUsers />
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <PublicRoute
              path="/loginExam/:examId/:examToken/:useCase?"
              denyCapabilities={['exam', 'lti']}
              layout={TopNavLayoutNoLogin}
              component={LoginExam}
            />
            <PublicRoute
              path="/examRoutes/:examId/:examToken/:useCase?"
              denyCapabilities={['exam', 'lti']}
              layout={TopNavLayoutNoLogin}
              component={ExamRoutes}
            />
            <PublicRoute exact path="/logoutExam" component={LogoutExam} />
            <PrivateRoute
              path="/user"
              denyCapabilities={['lti']}
              layout={TopNavLayout}
              component={UserNavigation}
            />
            <PrivateRoute
              path="/enrolled/:semester?/:courseUrl?"
              allowCapabilities={['enrolled']}
              denyCapabilities={['lti']}
              layout={TopNavLayout}
              component={EnrolledNavigation}
              onMatch={persistEntryPoint(['path', 'semester', 'courseUrl'])}
            />
            <PrivateRoute
              path="/mygroups/:semester?/:courseUrl?/:groupId?"
              allowCapabilities={['groupAdmin']}
              denyCapabilities={['lti']}
              layout={TopNavLayout}
              component={MyGroupsNavigation}
              onMatch={persistEntryPoint(['path', 'semester', 'courseUrl', 'groupId'])}
            />
            <PrivateRoute
              path="/mycourses/:semester?/:courseUrl?"
              allowCapabilities={['courseAdmin']}
              denyCapabilities={['lti']}
              layout={TopNavLayout}
              component={MyCourseNavigation}
              onMatch={persistEntryPoint(['path', 'semester', 'courseUrl'])}
            />
            <PrivateRoute
              path="/admin"
              allowCapabilities={[]}
              denyCapabilities={['lti']}
              layout={TopNavLayout}
              component={AdminNavigation}
              onMatch={persistEntryPoint(['path'])}
            />
            <PrivateRoute
              path="/examStudent/:examId"
              allowCapabilities={['exam']}
              layout={TopNavLayout}
              component={ExamStudent}
              onMatch={() => {
                uiSessionStates.isExamSession.set(true);
              }}
            />
            <PrivateRoute
              path="/myexams/:semester?/:courseUrl?/:examId?"
              allowCapabilities={['courseAdmin']}
              layout={TopNavLayout}
              component={ExamNavigation}
            />
            <PrivateRoute
              path="/examReview/:examId/:examToken"
              allowCapabilities={['login']}
              layout={TopNavLayout}
              component={ExamReview}
            />
            <PrivateRoute
              exact
              path="/solve/:taskId"
              denyCapabilities={['lti']}
              component={IdeRedirect}
            />
            <PrivateRoute exact path="/submissionFeedback/:submissionId" component={IdeRedirect} />
            <PrivateRoute
              exact
              path="/lticonsumer"
              allowCapabilities={['admin']}
              component={LtiTestConsumer}
            />
            <RunTypedRoutes
              routes={[
                globalRouter,
                appRouter,
                enrollRouter,
                handleInvitationsRouter,
                ideRouter,
                ltiRouter,
                projectBrowserRouter,
                printRouter,
                reviewRouter,
              ]}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    ),
    [persistEntryPoint],
  );
}

const StyledApp = styled(App, ({ tokens }) => ({
  height: '100%',
  backgroundColor: tokens.colorBgLayout,
}));

createRoot(requireNonNull(document.getElementById('app'), 'Expect element #app to exist')).render(
  <ThemeProvider>
    <StyledApp>
      <ErrorBoundary>
        <GlobalContextProvider>
          <CxApp />
        </GlobalContextProvider>
      </ErrorBoundary>
    </StyledApp>
  </ThemeProvider>,
);
