import React from 'react';

import { App, Button, List, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { task } from '@code-expert/prelude';
import { permissionDescription } from '/imports/modules/API/application/domain/Permissions';
import { useGlobalContext } from '/imports/ui/components/GlobalContext';
import { GuardRemote } from '/imports/ui/components/GuardRemote';
import { ThemeSwitch } from '/imports/ui/components/ThemeSwitch';
import { Icon } from '/imports/ui/foundation/Icons';
import { Box, HStack } from '/imports/ui/foundation/Layout';
import { styled } from '/imports/ui/foundation/Theme';
import { invokeProcedure, useProcedure } from '/imports/ui/hooks/useProcedure';
import useTimeout from '/imports/ui/hooks/useTimeout';
import { DocumentTitle } from '/imports/ui/hooks/useTitle';
import { getUserMenu } from '/imports/ui/layouts/getUserName';
import { TopNavLayoutWrapper } from '/imports/ui/layouts/TopNavLayout';

const StyledAuthView = styled('div', ({ tokens }) => ({
  marginInline: tokens.marginXXL,
  marginBlock: tokens.marginXL,
}));

const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const getAppName = (appName: string) =>
  appName === 'code_expert_sync' ? 'Code Expert Sync' : appName;

export function AppAuthorise({
  clientId,
  code_challenge,
}: {
  clientId: string;
  code_challenge: string;
}) {
  const { message } = App.useApp();
  const { user } = useGlobalContext();

  const [dataR] = useProcedure('app_getAppInfo', { props: { clientId } });

  useTimeout(
    () => {
      window.close();
    },
    4 * 60 * 1000,
  );

  const authorize = () =>
    task
      .run(invokeProcedure('app_authorise')({ clientId, code_challenge }))
      .then(() => message.success('Your app was authorized', 5))
      .then(() => sleep(2500))
      .finally(() => window.close());

  const deny = () =>
    task
      .run(invokeProcedure('app_deny')({ clientId }))
      .then(() => message.warning('You denied the access for the app', 5))
      .then(() => sleep(2500))
      .finally(() => window.close());

  return (
    <TopNavLayoutWrapper
      menu={[
        {
          key: 'enrolled',
          label: <Link to="/enrolled">All exercises</Link>,
        },
        {
          key: 'app',
          label: 'Authorise app',
        },
        user
          ? {
              ...getUserMenu(user),
              style: { marginLeft: 'auto' },
            }
          : null,
        {
          key: 'themeSwitch',
          label: <ThemeSwitch />,
        },
      ]}
    >
      <DocumentTitle title="Authorise" />
      <GuardRemote
        value={dataR}
        render={(data) => (
          <StyledAuthView>
            <Typography.Paragraph>
              <strong>{getAppName(data.name)}</strong> on <em>{data.os}</em> would like to
            </Typography.Paragraph>
            <List
              itemLayout="horizontal"
              dataSource={data.permissions.map((permission) =>
                permissionDescription(permission, getAppName(data.name)),
              )}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Icon name={item.iconName} />}
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />

            <HStack gap mt>
              <Button onClick={deny}>Deny</Button>
              <Button type="primary" onClick={authorize}>
                Allow
              </Button>
            </HStack>
            <Box mt>
              <Typography.Paragraph type="secondary">
                Please only authorise the app if you have initiated the authorisation process.
              </Typography.Paragraph>
            </Box>
          </StyledAuthView>
        )}
      />
    </TopNavLayoutWrapper>
  );
}
