import React from 'react';

import { Alert } from 'antd';

import { flow, option } from '@code-expert/prelude';
import type { ProjectError } from '/imports/modules/Project/domain/shared';
import { projectErrorAdt } from '/imports/modules/Project/domain/shared';

export const renderProjectError: (_: ProjectError) => React.ReactNode = flow(
  projectErrorAdt.fold<React.ReactNode>({
    unauthorised: option.getOrElse(() => 'Unauthorised'),
    projectNotFound: (id) => `Project ${id} not found`,
    duplicateKey: (key) => (
      <>
        Duplicate key <code>{key}</code>; please use list view
      </>
    ),
    keyNotFound: (key) => `Key ${key} not found`,
  }),
  (message) => <Alert type="error" showIcon {...{ message }} />,
);
