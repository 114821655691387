import { nonEmptyArray, number, option, pipe, remote } from '@code-expert/prelude';
import type { CourseId, SemesterId } from '/imports/domain';
import { useCachedProcedure, useProcedure } from '/imports/ui/hooks/useProcedure';
import { usePublication } from '/imports/ui/hooks/usePublication';

export interface InboxCountHookProps {
  courseId: CourseId;
  semester: SemesterId;
}

type InboxCount = { inboxCount: number; lastChange: number };

export const useInboxCount = (props?: InboxCountHookProps): InboxCount => {
  const lastChange = pipe(
    [
      usePublication('messages.getLastForCourse', {
        method: 'trigger',
        propsO: option.fromNullable(props),
      }),
      usePublication('messages.getLastForUser', {
        method: 'trigger',
      }),
    ],
    nonEmptyArray.max(number.Ord),
  );

  const [inboxCountR] = useCachedProcedure('messages_getInboxCount', { props }, [lastChange]);
  const inboxCount = remote.getOrElse(() => 0)(inboxCountR);
  return { inboxCount, lastChange };
};

export function useNotificationCount() {
  const lastChange = usePublication('notifications.getForUser', { method: 'trigger' });

  const [notificationCountR] = useProcedure('notification_getInboxCount', { props: undefined }, [
    lastChange,
  ]);

  const notificationCount = pipe(
    notificationCountR,
    remote.getOrElse(() => 0),
  );

  return { notificationCount, lastChange };
}
