import type { Lazy, remoteEither, taskEither } from '@code-expert/prelude';
import type { CourseId, CourseUrl, GroupId, SemesterId } from '/imports/domain';
import type { Unauthorised } from '/imports/modules/Authorization/domain';
import type { GetGroupsP } from '/imports/modules/Invitations/application/rpc/getGroups';
import type { ListInvitationsP } from '/imports/modules/Invitations/application/rpc/listInvitations';
import type { RequestGroupsP } from '/imports/modules/Invitations/application/rpc/requests';
import type { AccessCode } from '/imports/modules/Invitations/domain/AccessCode';
import type { CreateInvitationException } from '/imports/modules/Invitations/domain/createInvitation';
import type { InvitationId } from '/imports/modules/Invitations/domain/Invitation';
import type { RegisterWithAccessCodeException } from '/imports/modules/Invitations/domain/registerWithAccessCode';
import type { RequestState } from '/imports/modules/Invitations/domain/Request';
import type { RequestResolution } from '/imports/modules/Invitations/domain/RequestResolution';
import type { Scope } from '/imports/modules/Invitations/domain/Scope';
import { useIntervalTrigger } from '/imports/ui/hooks/useInterval';
import { invokeProcedure, useCachedProcedure } from '/imports/ui/hooks/useProcedure';
import { usePublication } from '/imports/ui/hooks/usePublication';

export interface InvitationHooks {
  useRegisterWithAccessCode: (
    _: AccessCode,
  ) => remoteEither.RemoteEither<RegisterWithAccessCodeException, void>;

  useCreateInvitationCallback: Lazy<
    (_: Scope) => taskEither.TaskEither<CreateInvitationException, AccessCode>
  >;

  useListGroups: (
    courseUrl: CourseUrl,
    semester: SemesterId,
  ) => remoteEither.RemoteEither<Unauthorised, GetGroupsP>;

  useListInvitations: (_: {
    courseId: CourseId;
    groupIds: Array<GroupId>;
  }) => remoteEither.RemoteEither<Unauthorised, ListInvitationsP>;

  useRequests: (
    states: Array<RequestState>,
    params: { courseId: CourseId; groupIds: Array<GroupId> },
  ) => remoteEither.RemoteEither<Unauthorised, RequestGroupsP>;

  useResolveRequest: Lazy<(_: RequestResolution) => taskEither.TaskEither<Unauthorised, void>>;

  usePendingRequestCount: (_: {
    courseId: CourseId;
    semester: SemesterId;
  }) => remoteEither.RemoteEither<Unauthorised, number>;

  useChangeInvitationStatus: (_: {
    invitationId: InvitationId;
    active: boolean;
  }) => taskEither.TaskEither<Unauthorised, void>;
}

export const invitationHooks: InvitationHooks = {
  useRegisterWithAccessCode: (accessCode) =>
    useCachedProcedure('invitations_registerWithAccessCode', { props: { accessCode } }, [
      useIntervalTrigger(30000),
    ])[0],

  useCreateInvitationCallback: () => invokeProcedure('invitations_createInvitation'),

  useListGroups: (courseUrl, semester) =>
    useCachedProcedure('invitations_getGroups', { props: { courseUrl, semester } })[0],

  useListInvitations: (props) =>
    useCachedProcedure('invitations_listInvitations', { props }, [
      usePublication('invitations.getForCourse', { method: 'trigger', props }),
    ])[0],

  useRequests: (states, props) =>
    useCachedProcedure(
      'invitations_requests',
      {
        props: { states, ...props },
      },
      [usePublication('invitations.requests', { method: 'trigger', props: { states, ...props } })],
    )[0],

  useResolveRequest: () => invokeProcedure('invitations_resolveRequest'),

  usePendingRequestCount: (props) =>
    useCachedProcedure('invitations_pendingRequestCount', { props }, [
      usePublication('invitations.pendingRequests', { method: 'trigger', props }),
    ])[0],

  useChangeInvitationStatus: invokeProcedure('invitations_changeInvitationStatus'),
};
