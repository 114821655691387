import {
  adt,
  array,
  identity,
  invariant,
  iots,
  option,
  ord,
  pipe,
  record,
  string,
  struct,
} from '@code-expert/prelude';
import type { Period } from '/imports/domain/period';
import { PeriodsC } from '/imports/domain/period';
import { ordSemesterIdAsc } from '/imports/domain/semester';
import { SemesterId, SettingId } from './identity';

export const HeaderColor = iots.keyof({
  Black: null,
  Red: null,
  Gold: null,
  Green: null,
  Blue: null,
  Magenta: null,
});

export type HeaderColor = iots.TypeOf<typeof HeaderColor>;

export interface SettingSemester {
  key: SemesterId;
  current: boolean;
  visible: boolean;
  deleted?: true;
}

export const SettingSemester = iots.struct(
  {
    key: SemesterId,
    current: iots.boolean,
    visible: iots.boolean,
  },
  {
    deleted: iots.literal(true),
  },
) satisfies iots.Type<SettingSemester, unknown>;

export const settingSemesterOrd: ord.Ord<SettingSemester> = pipe(
  ordSemesterIdAsc,
  ord.contramap(({ key }) => key),
);

// -------------------------------------------------------------------------------------------------
// PublicSetting

export type SettingExamMode = {
  _id: SettingId;
  key: 'ExamMode';
  value: { active: boolean };
};

export const ExamMode = iots.strict({ active: iots.boolean });

export const SettingExamMode = iots.strict({
  _id: SettingId,
  key: iots.literal('ExamMode'),
  value: ExamMode,
}) satisfies iots.Type<SettingExamMode, unknown>;

export type SettingHeaderColor = {
  _id: SettingId;
  key: 'HeaderColor';
  value: { color: HeaderColor };
};

export const HeaderColorSetting = iots.strict({ color: HeaderColor });

export const SettingHeaderColor = iots.strict({
  _id: SettingId,
  key: iots.literal('HeaderColor'),
  value: HeaderColorSetting,
}) satisfies iots.Type<SettingHeaderColor, unknown>;

export type SettingPageTitle = {
  _id: SettingId;
  key: 'PageTitle';
  value: { title: string };
};

export const PageTitleSetting = iots.strict({ title: iots.string });

export const SettingPageTitle = iots.strict({
  _id: SettingId,
  key: iots.literal('PageTitle'),
  value: PageTitleSetting,
}) satisfies iots.Type<SettingPageTitle, unknown>;

export type SettingSemesters = {
  _id: SettingId;
  key: 'Semesters';
  value: Array<SettingSemester>;
};

export const SettingSemesters = iots.strict({
  _id: SettingId,
  key: iots.literal('Semesters'),
  value: iots.array(SettingSemester),
}) satisfies iots.Type<SettingSemesters, unknown>;

export type SettingTigerPython = {
  _id: SettingId;
  key: 'TigerPython';
  value: { url: string };
};

/**
 * @deprecated https://codeexpert.myjetbrains.com/youtrack/issue/cx-3031
 */
type SettingSuspendBackgroundTasks = {
  _id: SettingId;
  key: 'SuspendBackgroundTasks';
  value?: { from: Date; to: Date };
};

/**
 * @deprecated https://codeexpert.myjetbrains.com/youtrack/issue/cx-3031
 */
const SettingSuspendBackgroundTasks = iots.struct(
  {
    _id: SettingId,
    key: iots.literal('SuspendBackgroundTasks'),
  },
  {
    value: iots.strict({ from: iots.date, to: iots.date }),
  },
) satisfies iots.Type<SettingSuspendBackgroundTasks, unknown>;

export type SettingSuspensionPeriods = {
  _id: SettingId;
  key: 'SuspensionPeriods';
  value: Array<Period>;
};

export const SettingSuspensionPeriods = iots.strict({
  _id: SettingId,
  key: iots.literal('SuspensionPeriods'),
  value: PeriodsC,
}) satisfies iots.Type<SettingSuspensionPeriods, unknown>;

export const TigerPythonSetting = iots.strict({ url: iots.string });

export const SettingTigerPython = iots.strict({
  _id: SettingId,
  key: iots.literal('TigerPython'),
  value: TigerPythonSetting,
}) satisfies iots.Type<SettingTigerPython, unknown>;

/**
 * These settings are safe to publish to all users.
 */
export type PublicSetting =
  | SettingExamMode
  | SettingHeaderColor
  | SettingPageTitle
  | SettingSemesters
  | SettingSuspendBackgroundTasks
  | SettingSuspensionPeriods
  | SettingTigerPython;

export const PublicSetting = iots.union([
  SettingExamMode,
  SettingHeaderColor,
  SettingPageTitle,
  SettingSemesters,
  SettingSuspendBackgroundTasks,
  SettingSuspensionPeriods,
  SettingTigerPython,
]) satisfies iots.Type<PublicSetting, unknown>;

export const foldPublicSetting = adt.foldFromTags<PublicSetting, 'key'>('key');

export const publicSettingKeys = {
  SuspendBackgroundTasks: null /* FIXME remove cx-3031 */,
  SuspensionPeriods: null,
  HeaderColor: null,
  Semesters: null,
  ExamMode: null,
  PageTitle: null,
  TigerPython: null,
} satisfies Record<PublicSetting['key'], null>;

export function assertPublicSetting(x: Setting): asserts x is PublicSetting {
  invariant(record.has(x.key, publicSettingKeys), 'Expected record to have public setting');
}

export const requirePublicSetting = (x: Setting): PublicSetting => {
  assertPublicSetting(x);
  return x;
};

// -------------------------------------------------------------------------------------------------
// Setting (private)

/**
 * Danger: When using this for client-side data, make sure that the privateKey is kept secret at all times.
 */
export const SettingApi = iots.strict({
  _id: SettingId,
  key: iots.literal('API'),
  value: iots.strict({
    publicKey: iots.string,
    url: iots.string,
    // The privateKey should never be sent to the client!
    privateKey: iots.string,
  }),
});

export type SettingApi = iots.TypeOf<typeof SettingApi>;

export type SettingApiPublic = Pick<SettingApi, '_id' | 'key'> & {
  value: {
    publicKey: string;
    url: string;
  };
};

export type SettingBackupsPerHour = {
  _id: SettingId;
  key: 'BackupsPerHour';
  value: { backupsPerHour: number };
};

export const BackupsPerHour = iots.strict({ backupsPerHour: iots.number });

export const SettingBackupsPerHour = iots.strict({
  _id: SettingId,
  key: iots.literal('BackupsPerHour'),
  value: BackupsPerHour,
}) satisfies iots.Type<SettingBackupsPerHour, unknown>;

export type SettingLdap = {
  _id: SettingId;
  key: 'LDAP';
  value: { sysDn: string; searchDn: string; host: string; sysPwd: string };
};

export const LdapSetting = iots.strict({
  sysDn: iots.string,
  searchDn: iots.string,
  host: iots.string,
  sysPwd: iots.string,
});

export const SettingLdap = iots.strict({
  _id: SettingId,
  key: iots.literal('LDAP'),
  value: LdapSetting,
}) satisfies iots.Type<SettingLdap, unknown>;

export type SettingLogging = {
  _id: SettingId;
  key: 'Logging';
  value: { level: 'info' | 'warn' | 'error' | 'debug' };
};

export const LoggingSetting = iots.strict({
  level: iots.keyof({ info: null, warn: null, error: null, debug: null }),
});

export const SettingLogging = iots.strict({
  _id: SettingId,
  key: iots.literal('Logging'),
  value: LoggingSetting,
}) satisfies iots.Type<SettingLogging, unknown>;

export type SettingLtiConsumers = {
  _id: SettingId;
  key: 'LtiConsumers';
  value: Array<{ key: string; title: string; secret: string }>;
};

export const LtiConsumer = iots.strict({
  key: iots.string,
  title: iots.string,
  secret: iots.string,
});

export const SettingLtiConsumers = iots.strict({
  _id: SettingId,
  key: iots.literal('LtiConsumers'),
  value: iots.array(LtiConsumer),
}) satisfies iots.Type<SettingLtiConsumers, unknown>;

export type SettingScanditLicence = {
  _id: SettingId;
  key: 'ScanditLicence';
  value: { scanditLicence: string };
};

export const ScanditLicenceSetting = iots.strict({ scanditLicence: iots.string });

export const SettingScanditLicence = iots.strict({
  _id: SettingId,
  key: iots.literal('ScanditLicence'),
  value: ScanditLicenceSetting,
}) satisfies iots.Type<SettingScanditLicence, unknown>;

export type SettingSupportEmail = {
  _id: SettingId;
  key: 'SupportEmail';
  value: { supportEmail: string };
};

export const SupportEmailSetting = iots.strict({ supportEmail: iots.string });

export const SettingSupportEmail = iots.strict({
  _id: SettingId,
  key: iots.literal('SupportEmail'),
  value: SupportEmailSetting,
}) satisfies iots.Type<SettingSupportEmail, unknown>;

/**
 * These settings must be kept private to super admins.
 */
export type PrivateSetting =
  | PublicSetting
  | SettingApi
  | SettingBackupsPerHour
  | SettingLdap
  | SettingLogging
  | SettingLtiConsumers
  | SettingScanditLicence
  | SettingSupportEmail;

export const foldSetting = adt.foldFromTags<PrivateSetting, 'key'>('key');

export type Setting = Exclude<PrivateSetting, SettingApi> | SettingApiPublic;

export const removePrivateFields: (_: PrivateSetting) => Setting = foldSetting<Setting>({
  API: struct.evolvePartial({ value: struct.omit(['privateKey']) }),
  BackupsPerHour: identity,
  ExamMode: identity,
  HeaderColor: identity,
  LDAP: identity,
  Logging: identity,
  LtiConsumers: identity,
  PageTitle: identity,
  ScanditLicence: identity,
  Semesters: identity,
  SupportEmail: identity,
  SuspendBackgroundTasks: identity,
  SuspensionPeriods: identity,
  TigerPython: identity,
});

export const PrivateSetting = iots.union([
  // PublicSetting ...
  SettingExamMode,
  SettingHeaderColor,
  SettingPageTitle,
  SettingSemesters,
  SettingSuspendBackgroundTasks,
  SettingSuspensionPeriods,
  SettingTigerPython,
  // ... PublicSetting
  SettingApi,
  SettingBackupsPerHour,
  SettingLdap,
  SettingLogging,
  SettingLtiConsumers,
  SettingScanditLicence,
  SettingSupportEmail,
]) satisfies iots.Type<PrivateSetting, unknown>;

// -------------------------------------------------------------------------------------------------

/**
 * Map setting keys to value types.
 */
export type SettingRecord = { [K in Setting['key']]: Extract<Setting, { key: K }>['value'] };

export type SettingRecordWithDefault = Pick<SettingRecord, keyof typeof defaultSettings>;

export const defaultSettings = {
  ExamMode: {
    active: false,
  },
  HeaderColor: {
    color: 'Black' as const,
  },
  LDAP: {
    host: '',
    searchDn: '',
    sysDn: '',
    sysPwd: '',
  },
  Logging: {
    level: 'info' as const,
  },
  LtiConsumers: [],
  PageTitle: {
    title: 'ETH Code Expert',
  },
  ScanditLicence: {
    scanditLicence: '',
  },
  BackupsPerHour: {
    backupsPerHour: 20,
  },
  Semesters: [],
  SupportEmail: {
    supportEmail: 'expert@inf.ethz.ch',
  },
  SuspendBackgroundTasks: undefined,
  SuspensionPeriods: [],
  TigerPython: {
    url: 'https://webtigerpython.ethz.ch',
  },
} satisfies Partial<SettingRecord>;

export const lookupSetting =
  (settings: Array<Setting>) =>
  <K extends Setting['key']>(k: K): option.Option<SettingRecord[K]> =>
    pipe(
      settings,
      array.findFirst(({ key }) => string.Eq.equals(key, k)),
      option.map(struct.get('value')),
    ) as $Inexpressible;

/**
 * Lookup a setting by key in a settings collection, falling back to defaults if setting can't be found.
 */
export function lookupSettingWithDefaults(settings: Array<Setting>) {
  return <K extends keyof typeof defaultSettings>(key: K): SettingRecordWithDefault[K] =>
    pipe(
      lookupSetting(settings)(key),
      option.getOrElseW(() => defaultSettings[key]),
    );
}
