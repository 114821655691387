import { constant, date, flow, iots, option, pipe, tagged, task } from '@code-expert/prelude';
import type { OnlineStatus } from '/imports/domain';
import { Location } from '/imports/domain';
import { socket } from '/imports/ui/hooks/socket';
import { invokeProcedure } from '/imports/ui/hooks/useProcedure';

/**
 * Publish at least this often to keep the connection status alive.
 *
 * This needs to be slightly shorter than the TTL index we set on the Connections collection,
 * otherwise connections might get removed too early.
 */
const PUBLICATION_INTERVAL = { seconds: 50 };

/**
 *  User is marked as "away" after this timeout.
 */
const INACTIVITY_TIMEOUT = { minutes: 1 };

// -------------------------------------------------------------------------------------------------
// Init activity signals

socket.once('initSocket', () => {
  const dispatch = mkDispatch({ location: readLocation(), status: 'active' });

  trackLocation((location) => dispatch(adt.location(location)));

  trackActivity(date.milliseconds(INACTIVITY_TIMEOUT), 'active', (status) =>
    dispatch(adt[status]()),
  );
});

// -------------------------------------------------------------------------------------------------
// State updates

interface State {
  location: Location;
  status: OnlineStatus;
}

type Action = tagged.Tagged<'active'> | tagged.Tagged<'away'> | tagged.Tagged<'location', Location>;

const adt = tagged.build<Action>();

const reducer = (state: State, action: Action): option.Option<State> =>
  adt.fold(action, {
    active: () =>
      state.status !== 'active' ? option.some<State>({ ...state, status: 'active' }) : option.none,
    away: () => (state.status !== 'away' ? option.some({ ...state, status: 'away' }) : option.none),
    location: (location) =>
      state.location !== location ? option.some({ ...state, location }) : option.none,
  });

const mkDispatch = (state: State) => {
  const publicationinterval = date.milliseconds(PUBLICATION_INTERVAL);
  let lastPublished = 0;

  const publish = (nextState: State) => {
    lastPublished = date.now();
    void task.run(invokeProcedure('users.connections.ping')(nextState));
    return nextState;
  };

  const dispatch = (a: Action) => {
    state = pipe(reducer(state, a), option.fold(constant(state), publish));
  };

  const heartbeatMonitor = () => {
    const nextScheduledHeartbeat = lastPublished + publicationinterval;
    if (pipe(date.now(), date.isAfter(nextScheduledHeartbeat))) {
      publish(state);
    }
  };

  setInterval(heartbeatMonitor, date.milliseconds({ seconds: 1 }));

  return dispatch;
};

// -------------------------------------------------------------------------------------------------
// Utils

const readLocation = () => pipe(location.pathname + location.search, iots.parseSync(Location));

/**
 * We observe the location through polling because there are no reliable ways of subscribing
 * to location change events at the time of writing.
 *
 * One alternative we tried was to proxy history push/replace, but it didn't prove reliable.
 */
const trackLocation = (onChange: (newLocation: Location) => void): void => {
  let location = readLocation();
  const checkLocationChange = () => {
    const current = readLocation();
    if (current !== location) {
      location = current;
      onChange(current);
    }
  };
  setInterval(checkLocationChange, date.milliseconds({ seconds: 1 }));
};

const trackActivity = (
  timeoutMs: number,
  initialStatus: 'away' | 'active',
  onStatusChange: (status: 'away' | 'active') => void,
): void => {
  let lastActive = initialStatus === 'active' ? date.now() : 0;
  let status: 'away' | 'active' = initialStatus;

  const setStatus = (s: 'away' | 'active') => () => {
    if (status !== s) {
      status = s;
      onStatusChange(s);
    }
  };

  const updateLastActive = () => void (lastActive = date.now());

  // tab in or out
  window.addEventListener('focus', flow(updateLastActive, setStatus('active')));
  window.addEventListener('blur', setStatus('away'));

  // screen activity
  document.addEventListener('mousemove', flow(updateLastActive, setStatus('active')));
  document.addEventListener('mousedown', flow(updateLastActive, setStatus('active')));
  document.addEventListener('touchend', flow(updateLastActive, setStatus('active')));
  document.addEventListener('keydown', flow(updateLastActive, setStatus('active')));

  // activity timeout
  setInterval(
    () => {
      const timeout = lastActive + timeoutMs;
      if (pipe(date.now(), date.isAfter(timeout))) {
        setStatus('away')();
      }
    },
    date.milliseconds({ seconds: 1 }),
  );
};
