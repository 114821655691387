import { config } from '/imports/config/client';
import { socket } from '/imports/ui/hooks/socket';

socket.once('initSocket', () => {
  console.debug('Started Code Expert', config.VERSION);
});

/**
 * Ensure the client is running the same application version as is running on the server.
 * If there is a mismatch, we need to reload the client to fetch the newest app code.
 */
socket.on('initSocket', ({ version }) => {
  if (config.VERSION !== version) {
    window.location.reload();
  }
});
