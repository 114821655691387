import { adt, iots } from '@code-expert/prelude';
import { CourseId, ExamId, ExerciseId, SemesterId } from '/imports/domain/identity';
import type { User } from '/imports/domain/user';
import { isExamUser } from '/imports/domain/user';

const examStatusKeys = {
  closed: null,
  open: null,
  review: null,
};

export const foldExamStatus = adt.foldFromKeys(examStatusKeys);

export type ExamStatus = adt.TypeOfKeys<typeof foldExamStatus>;

const ExamStatusC = iots.keyof(examStatusKeys);

export interface ExamUserEDozImport {
  firstname: string;
  lastname: string;
  legi: string;
  email: string;
}

export interface TypedExamUserEDozImport {
  type: 'db' | 'exam' | 'ldap';
  user: ExamUserEDozImport;
}

export interface Exam {
  readonly _id: ExamId;
  readonly name: string;
  readonly courseId: CourseId;
  readonly semester: SemesterId;
  readonly exerciseId: ExerciseId;
  readonly examUsers?: Array<ExamUserEDozImport>;
  readonly accessToken: string;
  readonly instructions: string;
  readonly status: ExamStatus;
}

export const examPropsStrict = {
  _id: ExamId,
  name: iots.string,
  courseId: CourseId,
  semester: SemesterId,
  exerciseId: ExerciseId,
  accessToken: iots.minLengthString(43),
  instructions: iots.string,
  status: ExamStatusC,
};

export const examPropsPartial = {
  examUsers: iots.array(
    iots.strict({
      firstname: iots.string,
      lastname: iots.string,
      legi: iots.string,
      email: iots.string,
    }),
  ),
};

export const ExamC = iots.struct(examPropsStrict, examPropsPartial) satisfies iots.Type<
  Exam,
  unknown
>;

export const examPublicFields = {
  _id: 1,
  name: 1,
  courseId: 1,
  semester: 1,
  exerciseId: 1,
  instructions: 1,
  status: 1,
} as const;

export type ExamPublicFields = keyof typeof examPublicFields;

export type ExamPublic = Pick<Exam, ExamPublicFields>;

// -------------------------------------------------------------------------------------------------

export const isInProgress = (exam: ExamPublic): boolean => exam.status === 'open';

export const isOpenForReview = (exam: ExamPublic): boolean => exam.status === 'review';

export const hasExamAccess = (examId: ExamId) => (user: User) =>
  !isExamUser(user) || (isExamUser(user) && user.examId === examId);
