import React from 'react';

import { flow } from '@code-expert/prelude';
import { zero } from '/imports/modules/Routing';
import { PrivateRoute } from '/imports/ui/components/PrivateRoute';
import { pathToUrl } from '/imports/ui/helper/url';
import type { ExercisePrintViewParams, TaskPrintViewUrlParams } from './routes';
import { exercisePrintViewPath, exercisePrintViewRoute, taskPrintViewRoute } from './routes';

const Print = React.lazy(() => import('./Print'));

const exercisePrintView = (args: ExercisePrintViewParams) => (
  <PrivateRoute element={() => <Print {...args} />} denyCapabilities={['exam', 'lti']} />
);

export const exercisePrintViewUrl = flow(exercisePrintViewPath, pathToUrl);

const taskPrintView = (args: TaskPrintViewUrlParams) => (
  <PrivateRoute element={() => <Print {...args} />} denyCapabilities={['exam', 'lti']} />
);

export const printRouter = zero()
  .alt(exercisePrintViewRoute.parser.map(exercisePrintView))
  .alt(taskPrintViewRoute.parser.map(taskPrintView));
